import React, { useState, useEffect } from 'react'
import { Box, Dialog, Grid, makeStyles } from '@material-ui/core'
import Text from '../../common/Text'
import Button from '../../common/Button'
import { IMPORTING_TYPES } from '../../../utils/constants'
import SelectableImage from '../SelectableImage'
import ImportFromGalleryOpenButton from './ImportFromGalleryOpenButton'
import ImportFromGalleryAuxiliaryButton from './ImportFromGalleryAuxiliaryButton'
import { generateDataAttrs } from '../../../utils/utils'
import Loader from '../Loader'

const useSelectedImages = (initialState = []) => {
  const [selectedImages, setSelectedImages] = useState(initialState)

  const addImageToSelection = (image) => {
    setSelectedImages([...selectedImages, { ...image, type: 'url' }])
  }

  const removeImageFromSelection = (image) => {
    setSelectedImages(selectedImages.filter(({ id }) => id !== image.id))
  }

  const replaceSelectedImage = (image) => {
    setSelectedImages([{ ...image, type: 'url' }])
  }

  return {
    selectedImages,
    setSelectedImages,
    addImageToSelection,
    removeImageFromSelection,
    replaceSelectedImage,
  }
}

const getDataByImportingType = (importingType) => {
  switch (importingType) {
    case IMPORTING_TYPES.Logo:
      return {
        title: 'Select your logo',
        header: 'Logos on File:',
      }

    case IMPORTING_TYPES.MLS:
      return {
        title: 'Select property images',
        header: 'Property photos:',
      }

    default:
      return {
        title: 'Select your logo',
        header: 'Logos on File:',
      }
  }
}

const styles = {
  container: {
    padding: '15px 10px',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px 15px',
    marginBottom: 20,
    maxHeight: 400,
    overflowY: 'scroll',
  },
}
const useStyles = makeStyles(styles)

export default function ImportFromGalleryModal({
  gallery = [],
  imgMaxQuantity,
  imagesArray,
  setImagesArray,
  image,
  importingType = IMPORTING_TYPES.MLS,
  ...props
}) {
  const customOnSave = props.onSave
  const isGalleryEmpty = !gallery.length
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  // const [importedImages, setImportedImages] = useState([])
  const [quantityToShow, setQuantityToShow] = useState(8)
  const [error, setError] = useState('')
  const {
    selectedImages,
    setSelectedImages,
    removeImageFromSelection,
    replaceSelectedImage,
    addImageToSelection,
  } = useSelectedImages([])

  useEffect(() => {
    setSelectedImages([])
    setError('')
  }, [open])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleImageSelect = (image) => {
    const wasImageSelected = selectedImages.some(({ id }) => id === image.id)

    if (wasImageSelected) {
      removeImageFromSelection(image)
    } else {
      const howManyImagesCanBeSelected = calculateHowManyImagesCanBeSelected()

      if (howManyImagesCanBeSelected === 1) {
        replaceSelectedImage(image)
      } else {
        addImageToSelection(image)
      }
    }

    setError('')
  }

  const handleSelectClick = () => {
    const imageIndex = imagesArray.findIndex((img) => img.id === image?.id)

    const quantityLeft = imgMaxQuantity - selectedImages.length

    const selectedImagesId = selectedImages.map((_image) => _image.id)
    const isImageAlreadySelected = imagesArray.find((_image) =>
      selectedImagesId.includes(_image.id),
    )
    if (isImageAlreadySelected && imgMaxQuantity > 1) {
      setError('This image is already selected, please select another.')
      return
    }

    let newImagesArray
    if (calculateHowManyImagesCanBeSelected() === 1) {
      newImagesArray = imagesArray.map((_image, index) => {
        if (
          index === imageIndex &&
          !imagesArray.includes(selectedImages[0].id)
        ) {
          return { ...selectedImages[0] }
        }
        return _image
      })
    } else {
      newImagesArray = [
        ...selectedImages,
        ...imagesArray.slice(0, quantityLeft),
      ]
    }
    if (customOnSave) {
      customOnSave(newImagesArray)
    }
    setImagesArray(newImagesArray)
    handleClose()
  }

  const calculateHowManyImagesCanBeSelected = () => {
    if (imgMaxQuantity === 1) return 1
    const quantityLeft = imgMaxQuantity - imagesArray.length + 1
    return quantityLeft
  }

  return (
    <>
      <Grid container alignItems="center" onClick={handleClickOpen}>
        <ImportFromGalleryOpenButton
          importingType={importingType}
          isNoLogo={image?.isNoLogo}
        />
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{ style: { borderRadius: '0', margin: 16, maxWidth: 375 } }}
      >
        <Grid className={classes.container}>
          {isGalleryEmpty ? (
            <Loader
              loading={false}
              text={
                'No MLS image found. Please upload an image from you local device.'
              }
              setLoadingStatus={() => {}}
              dataAttrs={{ metaType: 'modal' }}
              onClose={handleClose}
              closeTitle={'OK'}
            />
          ) : (
            <>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 20 }}
              >
                <Text type="strong">
                  {getDataByImportingType(importingType)?.title || ''}
                </Text>
                <Text
                  type="medium"
                  onClick={handleClose}
                  style={{ cursor: 'pointer', marginTop: 5 }}
                >
                  Cancel
                </Text>
              </Grid>
              <Grid style={{ marginBottom: 20 }}>
                <Text type="muted">
                  {getDataByImportingType(importingType)?.header || ''}
                </Text>
              </Grid>
              <Grid className={classes.imageGrid}>
                {gallery.slice(0, quantityToShow).map((image) => {
                  const howManyImagesCanBeSelected = calculateHowManyImagesCanBeSelected()
                  const isThisImageSelected = !!selectedImages.find(
                    (_image) => _image.id === image.id,
                  )

                  const isImageDisabled =
                    howManyImagesCanBeSelected > 1 &&
                    selectedImages.length === howManyImagesCanBeSelected &&
                    !isThisImageSelected

                  return (
                    image.id && (
                      <Grid
                        key={image?.id}
                        container
                        direction="column"
                        alignItems="center"
                        onClick={() => {
                          if (isImageDisabled) {
                            setError(
                              `You can only select ${howManyImagesCanBeSelected} image.`,
                            )
                          }
                        }}
                        {...generateDataAttrs({
                          metaAction: isThisImageSelected
                            ? 'click-unselect'
                            : 'click-select',
                        })}
                      >
                        <SelectableImage
                          key={image?.id}
                          disabled={isImageDisabled}
                          itemKey={image?.id}
                          checked={isThisImageSelected}
                          onSelect={() => handleImageSelect(image)}
                        >
                          <img
                            src={image?.url || image?.photoUrl}
                            alt=""
                            height="75"
                            width="145"
                          />
                          <Text type="muted">
                            {image?.name || image?.caption || image?.id || ''}
                          </Text>
                        </SelectableImage>
                      </Grid>
                    )
                  )
                })}
              </Grid>
              <Grid container justify="center">
                <ImportFromGalleryAuxiliaryButton
                  importingType={importingType}
                  quantityToShow={quantityToShow}
                  setQuantityToShow={setQuantityToShow}
                />
              </Grid>
              <Box my={3} textAlign="center">
                {error && (
                  <Text type="error" fontSize="12px">
                    {error}
                  </Text>
                )}
              </Box>
              <Grid container justify="center">
                <Button
                  medium
                  title={`Select (${
                    selectedImages.length
                  }/${calculateHowManyImagesCanBeSelected()})`}
                  disabled={selectedImages.length === 0}
                  clickHandler={handleSelectClick}
                  primary
                  dataAttrs={{
                    metaAction: 'click-select-image-from-gallery',
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    </>
  )
}
