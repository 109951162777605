import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import Routes from './routes'
import Theme from './assets/theme'
import { HeapIntegration } from './components/custom/HeapIntegration'
import AppInitialization from './components/custom/AppInitialization'
import CombinedContexts from './CombinedContexts'
import { initSentry } from './initSentry'

const history = createBrowserHistory()

initSentry()

function App() {
  return (
    <CombinedContexts>
      <HeapIntegration />
      <AppInitialization>
        <Router history={history}>
          <Routes />
          <Theme />
        </Router>
      </AppInitialization>
    </CombinedContexts>
  )
}

export default App
