import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorBoundary } from '../../modules/shared/components/ErrorBoundary'

export default function CustomGridContainer({
  children,
  isFullScreen = false,
  noMinHeight = false,
}) {
  const styles = (theme) => ({
    container: {
      minHeight: noMinHeight ? '' : '80vh',
      maxWidth: isFullScreen ? '90%' : '80%',
      margin: '10% 0 0 10%',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        maxWidth: isFullScreen ? '100%' : 350,
        margin: '5% auto 0 auto',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: isFullScreen ? '100%' : 500,
        marginTop: '2%',
        marginBottom: '5%',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: isFullScreen ? '100%' : 650,
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <Grid className={classes.container} container>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Grid>
  )
}
