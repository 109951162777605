import React, { useEffect, useState, memo } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from './Button'
import { presentActionSheet } from '../../services/appServices'
import MenuDropdown from './MenuDropdown'
import { useRequestContext } from '../../context/RequestContext'

const styles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleSpacing: {
    marginBottom: '-4%',
  },
  title: {
    flexGrow: 1,
    padding: '5px 0px 0px 0px',
  },
  label: {
    fontFamily: 'StyreneRegular',
    fontSize: '12px',
    color: '#292929',
    opacity: '0.65',
    padding: '5px 0px 5px 0px',
  },
  label2: {
    fontFamily: 'StyreneRegular',
    fontSize: '12px',
    color: '#292929',
    opacity: '0.65',
    marginTop: '20px',
  },
  field: {
    marginTop: '8px',
    borderRadius: '5px',
    border: '1px solid var(--blue)',
    padding: '10px',
    '&::placeholder': {
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      color: 'var(--blue)',
      opacity: '0.5',
    },
    width: '90%',
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
  },
  textContainer: {
    width: '100%',
    marginTop: 2,
  },
  errorText: {
    fontSize: '10px',
    color: 'var(--red)',
    fontFamily: 'StyreneRegular',
    position: 'relative',
  },
  btnRemove: {
    fontFamily: 'StyreneRegular',
    fontSize: '10px',
    color: 'var(--blue)',
    opacity: '0.65',
    marginTop: '-5px',
  },
  btnAdd: {
    width: '100%!important',
    fontFamily: 'StyreneRegular',
    fontSize: '10px',
    textAlign: 'center',
    color: 'var(--blue)',
    borderStyle: 'dashed',
    borderColor: 'var(--blue)',
    borderWidth: 1,
    borderRadius: 5,
    opacity: '0.65',
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: '-1%',
  },
  buttonInvisible: {
    backgroundColor: 'transparent',
    justifyContent: 'end',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

function FeatureLists({
  name,
  changeFeaturesList,
  maxFeatures,
  maxItems,
  minItems ,
  placeholder = 'Feature',
  featuresListInitialState,
}) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const { isEmbedded } = useRequestContext()

  // Load the component initial state
  const [featuresInputList, setfeaturesInputList] = useState(
    featuresListInitialState,
  )

  // Initialize the features counting
  const [currentCounting, setCurrentCounting] = useState(1)

  /**
   * Listen for changes into featuresInputList
   * warning: can become heavy!
   */
  useEffect(() => {
    changeFeaturesList(featuresInputList)
  }, [featuresInputList])

  // Handle input title change
  const handleInputTitleChange = (e, featureIndex) => {
    const { name, value } = e.target
    const list = [...featuresInputList]
    list[featureIndex][name] = value
    setfeaturesInputList(list)
  }

  // Handle input item change
  const handleInputItemChange = (e, featureIndex, featureItemIndex) => {
    const { name, value } = e.target
    const list = [...featuresInputList]
    list[featureIndex].features[featureItemIndex][name] = value
    setfeaturesInputList(list)

    // Check the new feature status
    isFeatureItemsFilled(featureIndex)
  }

  // Handle click event on IOS app button
  const handleClick = (featureIndex) => {
    const options = []
    // add option to IOS action sheet
    options.push({
      actionID: 'addFeature',
      actionLabel: 'Add Feature',
      callback: 'handleAddClick',
    })

    options.push({
      actionID: 'removeFeature',
      actionLabel: 'Remove Feature',
      callback: 'handleRemoveClick',
      style: 'red',
    })

    //function that handles with IOS action Sheet
    presentActionSheet(options, (actionID) => {
      switch (actionID) {
        case 'handleAddClick':
          handleAddClick()
          break
        case 'handleRemoveClick':
          handleRemoveClick(featureIndex)
          break
      }
    })
  }

  // Handle click event of the Remove button
  const handleRemoveClick = (featureIndex) => {
    const list = [...featuresInputList]
    if (list.length > 1) {
      list.splice(featureIndex, 1)
      setfeaturesInputList(list)

      // Update the features counting
      setCurrentCounting((previousCounting) => previousCounting - 1)
    } else {
      setfeaturesInputList([
        {
          ...list,
          title: '',
          features: list[0].features.map((_feature) => {
            return {
              ..._feature,
              item: '',
            }
          }),
        },
      ])
    }
  }

  // Handle click event of the Add button
  const handleAddClick = () => {
    if (
      currentCounting < maxFeatures &&
      isFeatureItemsFilled(currentCounting - 1)
    ) {
      // Create a new feature structure
      const newFeature = [{ title: '', error: true, features: [] }]

      // Create the number of items according to the rule
      newFeature[0].features = Array.from({ length: maxItems }, () => ({
        item: '',
      }))

      // Add the new feature structure
      setfeaturesInputList([...featuresInputList, newFeature[0]])

      // Update the features counting
      setCurrentCounting((previousCounting) => previousCounting + 1)
    }
  }

  // Function to evaluate if the item for a given feature is filled
  const isFeatureItemsFilled = (featureIndex) => {
    // Check if the feature still exists
    if (!featuresInputList[featureIndex]) return true
    let filledItems = 0

    // Loop over the feature items
    featuresInputList[featureIndex].features.map((feature) => {
      // Check each feature item length
      if (feature.item.length > 0) {
        filledItems++
      }
    })

    // Update the feature error status
    const list = [...featuresInputList]

    // Check if the minimum number of items was filled
    if (filledItems >= minItems) {
      list[featureIndex]['error'] = false
      setfeaturesInputList(list)
      return true
    } else {
      list[featureIndex]['error'] = true
      setfeaturesInputList(list)
      return false
    }
  }

  return (
    // Define the component container
    <Grid container direction="column">
      <div className="App" style={{ marginBottom: '25px' }}>
        {featuresInputList?.map((feature, featureIndex) => {
          return (
            <div
              key={'feature' + featureIndex}
              className={classes.title}
              style={{
                marginBottom:
                  featureIndex + 1 === featuresInputList.length ? '0px' : '10px',
              }}
            >
              <Grid container direction="row" spacing={1}>
                <Grid item xs={7} className={classes.center}>
                  <label htmlFor={name} className={classes.label}>
                    Feature {featureIndex + 1} (max {maxItems})
                  </label>
                </Grid>
                <Grid item xs={5} className={classes.gridButton}>
                  {!isEmbedded ? (
                    <MenuDropdown
                      title=""
                      icon={<MoreHorizIcon />}
                      showIcon
                      style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 20,
                      }}
                      small
                      bordered
                      secondary
                      noBorder
                    >
                      <MenuItem
                        onClick={handleAddClick}
                        disabled={!(currentCounting < maxFeatures)}
                        style={{
                          borderBottom: '1px solid #D8D8D8',
                          display: 'flex',
                        }}
                      >
                        <Grid style={{ cursor: 'pointer' }}>
                          <Grid style={{ display: 'flex' }}>
                            <AddIcon style={{ marginRight: 5 }} />
                            <label>Add Feature</label>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleRemoveClick(featureIndex)
                        }}
                      >
                        <Grid style={{ cursor: 'pointer' }}>
                          <Grid style={{ display: 'flex' }}>
                            <RemoveIcon style={{ marginRight: 5 }} />
                            <label>Remove Feature</label>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </MenuDropdown>
                  ) : (
                    <Button
                      title=""
                      endIcon={<MoreHorizIcon />}
                      style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 20,
                      }}
                      onClick={() => {
                        handleClick(featureIndex)
                      }}
                      small
                      bordered
                      secondary
                      noBorder
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12}>
                  <input
                    name={'title'}
                    key={'title' + featureIndex}
                    value={feature.title}
                    placeholder={placeholder}
                    onChange={(e) => handleInputTitleChange(e, featureIndex)}
                    className={classes.field}
                    style={{ marginTop: '0px' }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.center}>
                  <label htmlFor={name} className={classes.label2}>
                    Feature {featureIndex + 1} bullets (1 required, max{' '}
                    {maxItems})
                  </label>
                </Grid>
              </Grid>

              {feature.features?.map((featureItem, featureItemIndex) => (
                // Generate N features items according to the existing rule
                <input
                  name={'item'}
                  key={'item' + featureIndex + featureItemIndex}
                  value={featureItem.item}
                  placeholder={placeholder}
                  onChange={(e) =>
                    handleInputItemChange(e, featureIndex, featureItemIndex)
                  }
                  className={classes.field}
                />
              ))}
            </div>
          )
        })}
      </div>
    </Grid>
  )
}

export default memo(FeatureLists)
