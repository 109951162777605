import React from 'react'
import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generateDataAttrs } from '../../utils/utils'

export default function CustomAddressInput({
  label,
  name,
  error,
  type = 'textarea',
  handleInputChange,
  dataAttrs = {},
  ...props
}) {
  const styles = {
    label: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      color: '#292929',
      opacity: '0.65',
    },
    field: {
      marginTop: '8px',
      borderRadius: '5px',
      border: '1px solid var(--blue)',
      padding: '10px',
      '&::placeholder': {
        fontFamily: 'StyreneMedium',
        fontSize: '12px',
        color: 'var(--blue)',
        opacity: '0.5',
      },
      width: '90%',
      color: 'var(--blue)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
    },
    textContainer: {
      width: '100%',
      marginTop: 2,
      marginBottom: '5px',
    },
    errorText: {
      fontSize: '10px',
      color: 'var(--red)',
      fontFamily: 'StyreneRegular',
      position: 'relative',
      left: '0px',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const meta = { ...dataAttrs, metaName: name, metaType: type }

  return (
    <Grid container direction="column" {...generateDataAttrs(meta)}>
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      <div className={classes.textContainer}>
        <Field
          as={type}
          name={name}
          {...props}
          className={classes.field}
          onChange={(e) => handleInputChange(e)}
        />
        {error && <span className={classes.errorText}>{error}</span>}
      </div>
    </Grid>
  )
}
