import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import InformationInput from '../InformationInput'
import Text from '../../common/Text'
import { formatMoney } from '../../../utils/utils'
import TemplatePresentation from '../../common/TemplatePresentation'
import useHistoryPrefix from '../../../hooks/useHistoryPrefix'
import { useInputDataContext } from '../../../context/InputDataContext'
import { toMoney } from '../DynamicForm/formatters/money.formatter'
import { usePackageDetailsContext } from '../../../context/PackageDetailsContext'
import { useStageSelectionContext } from '../../../context/StageSelectionContext'
import EmailBlastTemplatePresentation from './EmailBlastTemplatePresentation'

const styles = () => ({
  container: {
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
    padding: '0 0 20px 0',
  },
  divider: {
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
  },
})

export default function MarketingRequestReview({
  showTotalCost = true,
  isEditable = true,
}) {
  const { packageDetailsData: packageDetailsDataCtx } =
    usePackageDetailsContext()
  const { formMarketingDataCtx } = useInputDataContext()
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const calculatePackageBudget = (packageTypeKey) => {
    const selectedPackage = formMarketingDataCtx[packageTypeKey] || []
    return Object.values(selectedPackage)
      .flat()
      .map((item) => {
        if (item?.dynamicFormData?.paidAdBudget)
          return formatMoney(item?.dynamicFormData?.paidAdBudget.value)

        if (item?.formData?.paidAdBudget)
          return formatMoney(item?.formData?.paidAdBudget.value)

        if (item?.formData?.submittableData?.totalEstimatedCost) {
          const {value} = item?.formData?.submittableData?.totalEstimatedCost
          return +value
        }

        return null
      })
      .filter((budgetIsNotNull) => budgetIsNotNull)
      .reduce((total, budget) => total + budget, 0)
  }

  const updateCategoryWithFilledItems = (packageType) => {
    const packageSelectedTemplates = packageDetailsDataCtx[packageType]
    return packageSelectedTemplates
      .map((category) => {
        const inputData =
          formMarketingDataCtx?.[packageType]?.[category.templateCategory] ?? []

        const selectedTemplateImages = inputData.map((data) => {
          if (data.itemId < 0) {
            const rtrTemplateIdx = Math.abs(+data.itemId) - 1
            const rtrTemplate =
              category.rtrTemplates[rtrTemplateIdx] ||
              category.rtrTemplates.find(
                (rtrTemplate) => rtrTemplate.id === rtrTemplateIdx,
              )

            const name =
              category.displayName +
              ` #${rtrTemplateIdx.toString().padStart(3, '0')}`

            return { ...rtrTemplate, isRTR: true, itemId: data.itemId, name }
          }
          return category.images.find((image) => image.itemId === data.itemId)
        })

        return {
          category,
          displayName: category.displayName,
          displaySubtitle: category.displaySubtitle,
          inputData,
          images: selectedTemplateImages,
        }
      })
      .filter((category) => category.images.length)
  }

  const digitalPackageEstimatedCost = calculatePackageBudget('digital')

  const printPackageEstimatedCost = calculatePackageBudget('print')

  const rawEstimatedCost = digitalPackageEstimatedCost + printPackageEstimatedCost

  const estimatedCost = toMoney(rawEstimatedCost, 2, 0)

  return (
    <Grid style={{ marginTop: 10 }}>
      {showTotalCost && formMarketingDataCtx['digital'] && (
        <Grid className={classes.container}>
          <InformationInput
            label='Estimated Cost'
            information={estimatedCost}
          />
        </Grid>
      )}
      <Grid
        container
        direction='column'
        className={classes.container}
        style={{ marginTop: 20 }}
      >
        <Text>Package Details</Text>
        <Grid style={{ marginTop: 20 }}>
          <Text type='muted'>Digital Asset cost</Text>
          <Text type='medium'>
            {toMoney(digitalPackageEstimatedCost)}
          </Text>
        </Grid>
        <Grid style={{ marginTop: 20 }}>
          <Text type='muted'>Print Asset cost</Text>
          <Text type='medium'>{toMoney(printPackageEstimatedCost, 2, 0)}</Text>
        </Grid>
      </Grid>
      {Object.keys(packageDetailsDataCtx).map((packageTypeKey, index) => {
        const formattedTemplatesData =
          updateCategoryWithFilledItems(packageTypeKey)
        return (
          <SelectedItemsView
            key={index}
            selectedTemplates={formattedTemplatesData}
            packageType={packageTypeKey}
            isEditable={isEditable}
            packageCost={calculatePackageBudget(packageTypeKey)}
          />
        )
      })}
    </Grid>
  )
}

const SelectedItemsView = ({ packageType, selectedTemplates, isEditable }) => {
  const styles = (theme) => ({
    container: {
      borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
      padding: '0 0 20px 0',
    },
    categoryText: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
    templateCategoryTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: isEditable ? 'space-between' : 'center',
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const history = useHistoryPrefix()
  const { flow = 'marketing', subflow, requestTypePath } = useParams()
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const handleEditClick = () => {
    if (packageType === 'print') {
      history.push(`/${flow}/${subflow}/${requestTypePath}/print/package`)
    } else if (packageType === 'digital') {
      history.push(`/${flow}/${subflow}/${requestTypePath}/digital/package`)
    }
  }

  return (
    <>
      {selectedTemplates.length > 0 && (
        <>
          <Grid className={classes.container}>
            {selectedTemplates.map((selectedTemplateCategory) => {
              if (
                selectedTemplateCategory.inputData.some(
                  (_item) => !_item?.alreadySent,
                )
              ) {
                return (
                  <Grid
                    style={{ marginTop: 20 }}
                    key={selectedTemplateCategory.displayName}
                  >
                    <Grid>
                      <Grid className={classes.templateCategoryTitleContainer}>
                        <Text type='strong' component='span'>
                          {selectedTemplateCategory.displayName} -{' '}
                          {stageSelectionDataCtx.stageName}
                        </Text>
                        {isEditable && (
                          <Text
                            type='blue'
                            component='span'
                            onClick={handleEditClick}
                            style={{ marginTop: 3 }}
                          >
                            Edit
                          </Text>
                        )}
                      </Grid>
                      <Text type='muted' style={{ marginBottom: 20 }}>
                        {selectedTemplateCategory.displaySubtitle || ''}
                      </Text>
                    </Grid>

                    {selectedTemplateCategory.inputData.map((data, index) => {
                      const currentImage = selectedTemplateCategory.images.find(
                        (image) => image && image.itemId === data.itemId,
                      )
                      const template = {
                        ...data,
                        ...currentImage,
                      }

                      if (!data?.alreadySent) {
                        if (data?.emailData) {
                          return (
                            <Grid
                              className={
                                !(
                                  index <
                                  selectedTemplateCategory.inputData.length - 1
                                ) || classes.container
                              }
                            >
                              <EmailBlastTemplatePresentation
                                key={index}
                                inputData={data}
                              />
                            </Grid>
                          )
                        }

                        return (
                          <TemplatePresentation
                            templateCategory={
                              selectedTemplateCategory.category.templateCategory
                            }
                            key={index}
                            template={template}
                          />
                        )
                      }
                    })}
                  </Grid>
                )
              }
            })}
          </Grid>
        </>
      )}
    </>
  )
}
