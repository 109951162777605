import { capitalize } from '@material-ui/core'
import axios from 'axios'
import JSZip from 'jszip'
import { kebabCase } from 'lodash'

export const selectFieldsByPackageType = {
  digital: {
    lastRTRRenderStage: 'lastRTRRenderStageForDigital',
    lastRTRBaseTemplate: 'lastRTRBaseTemplateForDigital',
  },
  print: {
    lastRTRRenderStage: 'lastRTRRenderStageForPrint',
    lastRTRBaseTemplate: 'lastRTRBaseTemplateForPrint',
  },
}

export const formatPackagesData = (data) => {
  const mountPackagesDataArray = []
  data.sections.forEach((section) => {
    return {
      options: section.options.forEach((option) => {
        mountPackagesDataArray.push({
          title: section.title,
          thumbnailURL: option.thumbnailURL,
          info: {
            section: section.title,
            name: option.name,
          },
        })
      }),
    }
  })
  return mountPackagesDataArray
}

export const isAddressValid = (address) => {
  if (address.length < 3) {
    return false
  }

  return true
}

export const formatNewInstallDataToBeSubmitted = (data) => {
  try {
    const signDataFormattedToBeSubmitted = data.map((sign) => {
      return {
        ...sign,
        deliveryType: sign.deliveryType.value,
        options: formatNewRequestOptionObject(sign),
      }
    })
    return signDataFormattedToBeSubmitted
  }
  catch (err) {
    console.log(err)
  }
}

export const formatNotNewRequestOptionObject = (options) => {
  const arrayOfKeyValuesOptions = formatObjectIntoArrayOfKeyValues(options)
  const formattedOptionsObject = reduceKeyValueArrayToOptionObject(
    arrayOfKeyValuesOptions,
  )

  return formattedOptionsObject
}

export const formatObjectIntoArrayOfKeyValues = (object) => {
  const arrayOfKeyValues = Object.entries(object)
    .map(([key, value]) => {
      if (value !== '') {
        return {
          [key]: value.formattedValue || value.value,
        }
      }
      return null
    })
    .filter((object) => object)
  return arrayOfKeyValues
}

export const formatNewRequestOptionObject = (data) => {
  const arrayOfKeyValuesFormData = formatObjectIntoArrayOfKeyValues(
    data.options.formData,
  )
  const arrayOfKeyValuesDynamicFormData = formatObjectIntoArrayOfKeyValues(
    data.options.dynamicFormData,
  )

  const arrayOfKeyValues = [
    ...arrayOfKeyValuesFormData,
    ...arrayOfKeyValuesDynamicFormData,
  ]

  const formattedOptionsObject = reduceKeyValueArrayToOptionObject(
    arrayOfKeyValues,
  )

  return formattedOptionsObject
}

export const reduceKeyValueArrayToOptionObject = (array) => {
  const formattedOptionsObject = array.reduce((storage, object) => {
    const key = Object.keys(object).pop()
    storage[key] = object[key]
    return storage
  }, {})
  return formattedOptionsObject
}

export const formDataBuilder = (optionsArray) => {
  const formDataBuilder = {}
  optionsArray.forEach((option) => {
    if (option.type === 'label') {
      formDataBuilder[option.name] = option.information
    } else if (option.type === 'select') {
      formDataBuilder[option.name] = {
        value: option.options[0].value,
        label: option.options[0].label,
        type: option.type,
        optionLabel: option.label,
        required: option.required,
      }
    } else if (option.type === 'imageSelector') {
      formDataBuilder[option.name] = {
        name: option.name,
        label: option.label,
        value: option.value,
        type: option.type,
      }
    } else {
      formDataBuilder[option.name] = {
        value: '',
        label: '',
        type: option.type,
        required: option.required,
      }
    }
  })
  return formDataBuilder
}

export const formatMoney = (data) => {
  return parseFloat(
    data
      .toString()
      .replace(/[.$\s]/g, '')
      .replace(/,/g, '.'),
  )
}

export const formatAsMoney = (number) => {
  if (!number) return ''

  return (
    '$' +
    new Intl.NumberFormat('en-US', { maximumFractionDigits: 3 }).format(number)
  )
}

export const waitForFonts = (font) =>
  new Promise((resolve, reject) => {
    document.fonts
      .load(font)
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })

export const computeSignageRequestTotalCost = (signs) => {
  const totalCost = signs.reduce(
    (acc, curr) => {
      const storage = acc
      const { value } = curr
      const [minEstimatedCost, maxEstimatedCost] = value.split('-')
      storage.minCost += formatMoney(minEstimatedCost)
      storage.maxCost += formatMoney(maxEstimatedCost)
      return storage
    },
    {
      minCost: 0,
      maxCost: 0,
    },
  )
  return totalCost
}

export const sumAndFormatCosts = (firstCost, secondCost) => {
  const [minRemovalEstimatedCost, maxRemovalEstimatedCost] = firstCost.split(
    '-',
  )
  const [minEstimatedCost, maxEstimatedCost] = secondCost.split('-')
  const summedMinEstimatedCost =
    formatMoney(minRemovalEstimatedCost) + formatMoney(minEstimatedCost)
  const summedMaxEstimatedCost =
    formatMoney(maxRemovalEstimatedCost) + formatMoney(maxEstimatedCost)
  const formattedValue = `$${summedMinEstimatedCost} - $${summedMaxEstimatedCost}`
  return formattedValue
}

export const triggerDownload = (data, fileName, options) => {
  const blob = options ? new Blob([data], options) : new Blob([data])
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.download = fileName
  document.body.appendChild(link)
  link.click()
}

export const zipDownloadImages =  async (urls = [], folderName, fileNamePrefix = '') => {
  const zip = new JSZip()
  const folder = zip.folder(folderName || 'AVMarketing')
  let imageIndex = 0

  for (const url of urls) {
    imageIndex++
    await axios({
      url,
      responseType: 'arraybuffer',
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }).then(({ data }) => {
      const fileName = `${fileNamePrefix}_${imageIndex}_photo.png`
      folder.file(fileName, data)
    })
  }

  const content = await zip.generateAsync({ type: 'blob' })
  await triggerDownload(content, 'avenue8_posts.zip')
}

export const renderNumberOfSteps = (requestType) => {
  switch (requestType) {
    case 'digital':
      return 5
    case 'digitalprint':
      return 5
    default:
      return 3
  }
}

export const statesOptions = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AB',
    value: 'AB',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'AE',
    value: 'AE',
  },
  {
    label: 'AA',
    value: 'AA',
  },
  {
    label: 'AP',
    value: 'AP',
  },
  {
    label: 'BC',
    value: 'BC',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MB',
    value: 'MB',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NB',
    value: 'NB',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NF',
    value: 'NF',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'NT',
    value: 'NT',
  },
  {
    label: 'NS',
    value: 'NS',
  },
  {
    label: 'NU',
    value: 'NU',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'ON',
    value: 'ON',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PE',
    value: 'PE',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'PQ',
    value: 'PQ',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SK',
    value: 'SK',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
  {
    label: 'YT',
    value: 'YT',
  },
]

export const generateDataAttrs = (dataAttrs) =>
  Object.fromEntries(
    Object.entries(dataAttrs)
      .filter(([, v]) => !!v)
      .map(([attributeKey, attributeValue]) => [
        kebabCase(`data${capitalize(attributeKey)}`),
        attributeValue.toString(),
      ]),
  )

export const formatMlsImages = (imageArray) =>
  imageArray.map((currentImage) => ({
    url: currentImage.photoUrl,
    id: currentImage.id,
    name: currentImage.caption || currentImage.id,
    image: currentImage,
    type: 'url',
  }))

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(b64toBlob(file))
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
// Input example: [["$1200", "    "], ["2BD", "2BA", " | "]]
// Output example: $1200    2BD | 2BA
export const generateComboLine = (arr) =>
  arr
    .map((curr) => {
      const separator = curr.slice(-1)
      const entries = curr.slice(0, -1).filter((c) => c)

      if (entries.length > 1) {
        return entries.join(separator)
      } else if (entries.length === 1) {
        if (curr.length == 2) {
          return entries[0] + separator
        } else {
          return entries[0]
        }
      } else {
        return null
      }
    })
    .join('')
    .trim()

export const addressFormatter = ({ city, firstAddressLine, state, zip }) =>
  `${firstAddressLine}, ${city} ${state} ${zip}`

export const wordsCounter = (text) => {
  const trimmedText = text.trim()
  if (trimmedText.length === 0) return 0
  return trimmedText.split(' ').length
}
