import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BuildAListFormStyles = makeStyles((theme) => ({
  FlexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  FlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  FlexShrink: {
    display: 'flex',
    flexShrink: 1,
  },
  FlexVerticalCenter: {
    alignItems: 'center',
  },
  Form: {
    width: 'calc(100% - 16px)',
  },
  FieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& > input': {
      maxWidth: '100%',
    },
  },
  FieldMargins: {
    marginTop: 7,
    marginBottom: 15,
  },
  RightMargin: {
    marginRight: 7,
  },
  MutedText: {
    fontSize: 14,
    color: 'gray',
    fontFamily: 'StyreneRegular',
  },
  SectionTitle: {
    lineHeight: '3em',
  },
  RadioButtonContainer: {
    '& > label': {
      alignItems: 'start',
      marginTop: 7,
    },
  },
  SelectField: {
    backgroundColor: 'white',
    width: '100% !important',
  },
  InputErrorMessage: {
    fontSize: '10px',
    color: 'var(--red)',
    fontFamily: 'StyreneRegular',
    position: 'relative',
    left: '0px',
  },
  InputField: {
    marginBottom: '8px',
    borderRadius: '5px',
    border: '1px solid var(--blue)',
    resize: 'none',
    padding: '10px',
    '&::placeholder': {
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      color: 'var(--blue)',
      opacity: '0.5',
    },
    width: '100%',
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
  },
}))
