import { propertySearchApi } from './api'

const formatListingData = (listingData) => {
  return {
    street: listingData.firstAddressLine,
    unit: listingData.secondAddressLine,
    city: listingData.city,
    state: listingData.state,
    zipCode: listingData.zip,
  }
}

/**
 *
 * @param {*} data: Either an string (MLS Id) or Listing Data Object { firstAddressLine, secondAddressLine, city, state & zip }
 * @param {*} isUsingMlsId: Boolean
 * @returns
 */

export const getListingDetails = async (data, isUsingMlsId) => {
  const dataToBeSent = isUsingMlsId
    ? `mlsId=${data}`
    : new URLSearchParams(formatListingData(data)).toString()

  try {
    const { data } = await propertySearchApi.get(
      `/api/v2/marketing-search?${dataToBeSent}`,
    )
    if (data) {
      return { success: true, data }
    }
    return { success: false }
  } catch (err) {
    return { success: false }
  }
}
