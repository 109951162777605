import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from './Text'

/*eslint-disable*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: 'transparent',
  },
  expanded: {
    background: 'transparent',
    minHeight: '45px',
    margin: 0
  },
  summaryRoot: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.65)',
    padding: 0,
    opacity: 0.65,
  },
  summaryExpanded: {
    opacity: 1,
  },
  detailsRoot: {
    padding: '20px 0',
  }
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular
  // },
  // icon: { marginRight: 5 }
}))

export default function SimpleAccordion({
  accordions = [{ id: 0, title: '', body: '', icon: '' }]
}) {
  const classes = useStyles()

  return (
    <Grid className={classes.root}>
      {accordions.map((accordion, index) => {
        return (
          <Accordion key={accordion?.id} elevation={0} classes={{ root: classes.root, expanded: classes.expanded}}>
            <AccordionSummary
              expandIcon={
                accordion?.icon ? accordion?.icon : <ExpandMoreIcon />
              }
              aria-controls="panel${index}-content"
              id={`"panel${index}-header"`}
              classes={{ expandIcon: classes.icon, root: classes.summaryRoot, expanded: classes.summaryExpanded }}
            >
              <Text type="sectionName">
                {accordion?.title}
              </Text>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.detailsRoot }}>{accordion?.body}</AccordionDetails>
          </Accordion>
        )
      })}
    </Grid>
  )
}
