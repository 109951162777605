import React from 'react'
import { Grid } from '@material-ui/core'
import Text from '../../../../components/common/Text'
import CheckboxItem from '../../../../components/common/Checkbox'

export default function Checkbox({
  field,
  onFieldChange,
  value,
  error,
}) {
  return (
    <Grid data-meta-name={field.name} data-meta-type='checkbox-group'>
      <Text type='muted'>{field.label}</Text>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns:
            field.name === 'getSignage'
              ? '100%'
              : field.options.length % 2 === 0
              ? '50% 50%'
              : '100%',
          marginTop: '15px',
        }}
      >
        {field.options &&
          field.options.map((option) => {
            const { label } = option
            return (
              <CheckboxItem
                label={label}
                type={'radio'}
                name={field.name}
                key={label}
                value={option.value}
                checked={value === option.value}
                onChange={(e) => {
                  onFieldChange(
                    field._uid,
                    { value: e.target.value, ...field },
                    field.label,
                  )
                }}
              />
            )
          })}
      </Grid>
      <Text type='error' style={{ marginBottom: 15 }}>
        {error}
      </Text>
    </Grid>
  )
}
