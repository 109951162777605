import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import ReactCardFlip from 'react-card-flip'
import { ErrorBoundary } from '@sentry/react'
import Text from '../../../../../../components/common/Text'
import { fromMoney } from '../../../../../../components/custom/DynamicForm/formatters/money.formatter'
import { RtrTemplate } from '../../../../components/TemplatePreview/TemplateRTRPreview'
import { RTRImage } from '../../../../components/RTRImage'
import { CustomizePrintTemplateStyles } from './CustomizePrintTemplate.styles'
import DynamicFormGenerator from '../../../../../../components/custom/DynamicFormGenerator'
import { RTRTemplateData } from '../../../../utils/formatTemplateData'
import { RTR_PREVIEW_IMAGE_SIZE } from '../../../../../../utils/constants'
import { PrintModalStore } from '../../../../store/PrintModal.store'
import {
  formatDynamicFormOptions,
  optionDataToTemplate,
  normalizeInputPackage,
  fillImageSelectorWithPlaceholder,
} from '../../../../utils/inputPackage'
import { useListingDescriptionContext } from '../../../../../../context/ListingDescriptionContext'
import { useAgentDataContext } from '../../../../../../hooks/useAgentData'

export type PrintTemplatePreviewProps = {
  categoryTitle: string
  template: RtrTemplate
  stepName: string
  baseTemplateData: RTRTemplateData
}

type RuleType = {
  type: string
  value: string | number
}

export enum ECardSide {
  'PREVIEW',
  'EDIT',
}

export const PrintTemplatePreview = ({
  categoryTitle,
  stepName,
  template,
  baseTemplateData,
}: PrintTemplatePreviewProps) => {
  const classes = CustomizePrintTemplateStyles()

  const formData = PrintModalStore(({ formData }) => formData)
  const setFormData = PrintModalStore(({ setFormData }) => setFormData)
  const listingDescriptionImages =
    useListingDescriptionContext().listingDescription.images

  const { fetchAgentLogos, agentLogos } = useAgentDataContext()
  const [cardSide, setCardSide] = useState<ECardSide>(ECardSide.PREVIEW)

  const { inputs: inputsPackage = [] } = template?.input_package || []

  // normalize names to camelCase
  const normalizedInputPackage = React.useMemo(
    () => normalizeInputPackage(inputsPackage),
    [inputsPackage],
  )

  // format options and only include the form enabled ones
  const inputPackageFormOptions = React.useMemo(
    () =>
      formatDynamicFormOptions(normalizedInputPackage).filter(
        (input) => input?.metadata?.isFormField !== false,
      ),
    [normalizedInputPackage],
  )

  //This fills the form to make sure there are placeholders or values
  const filledInputPackageOptions = Object.fromEntries(
    normalizedInputPackage.map((input) => {
      const name: string = (input?.metadata?.formName ?? input.name) as string
      if (input.type === 'imageSelector') {
        const listingDescriptionImagesUrls = listingDescriptionImages.map(
          (image) => image.url,
        )
        return fillImageSelectorWithPlaceholder(
          input,
          listingDescriptionImagesUrls,
          baseTemplateData?.[name],
          agentLogos,
        )
      }

      const filledValue =
        baseTemplateData?.[name] ?? input?.placeholder ?? undefined

      if (
        input?.metadata?.formatter === 'money' &&
        typeof filledValue === 'string'
      ) {
        return [
          name,
          {
            ...input,
            formattedValue: filledValue,
            value: fromMoney(filledValue),
          },
        ]
      }

      if (input?.metadata?.rules) {
        let baseValue = baseTemplateData?.[name]
        const { rules } = input?.metadata
        const maxLengthRule = rules.find(
          (rule: RuleType) => rule.type === 'max-length',
        )
        if (maxLengthRule && baseValue) {
          baseValue = baseValue.slice(0, maxLengthRule.value)
        }

        return [
          name,
          {
            ...input,
            value: baseValue ?? input?.placeholder ?? undefined,
          },
        ]
      }

      const filledInput = {
        placeholder: 'placeholder',
        ...input,
        value: filledValue,
        formattedValue: null,
      }

      return [name, filledInput]
    }),
  )

  const previouslySavedFormData: Record<string, any> =
    formData?.children?.[stepName] ?? {}

  const templateFormData = {
    ...filledInputPackageOptions,
    ...previouslySavedFormData,
  }

  const setTemplateFormData = (data: Record<string, any>) => {
    const previousChildren = formData?.children ?? {}
    const updatedData = {
      ...formData,
      children: {
        ...previousChildren,
        [stepName]: data,
      },
    }
    setFormData(updatedData)
  }

  const formDataValues = Object.fromEntries(
    Object.values(optionDataToTemplate(templateFormData)).map((input) => [
      input.name,
      input.formattedValue ?? input.value,
    ]),
  )

  let templateDataForRender = {
    ...baseTemplateData,
    ...formDataValues,
  }

  // TODO: Improve this, this type of rule should come from Strapi
  if (templateDataForRender?.agentLogo) {
    templateDataForRender = {
      ...templateDataForRender,
      layerOptions: {
        ['agentLogo']: {
          preserveAspectRatio: true,
        },
      },
    }
  }

  const cardStyles = {
    background: '#F3F3F7',
    paddingBottom: 16,
    minHeight: 300,
    maxWidth: '100%',
    opacity: 0.99,
  }

  useEffect(() => {
    fetchAgentLogos?.()?.then((d) => console.log(`Found ${d} agent logos`))
  }, [])

  const title = categoryTitle && stepName ? `${categoryTitle} ${stepName}` : ''
  return (
    <>
      <Grid className={classes.previewContainer}>
        <div style={{ marginBottom: 8 }}>
          <Text type={'sectionName'}>{title}</Text>
        </div>
        <ReactCardFlip
          isFlipped={cardSide === ECardSide.EDIT}
          cardStyles={{ front: cardStyles, back: cardStyles }}
        >
        <div id='front'>
          <Grid container direction='column' style={{ padding: 16 }}>
            <Grid
              className={classes.previewBtnContainer}
              onClick={() => setCardSide(ECardSide.EDIT)}
            >
              <CreateIcon fontSize={'large'} />
              <Text type='regular'>Edit</Text>
            </Grid>
            <Grid
              container
              justify='center'
              style={{
                marginTop: '80px',
              }}
            >
              {cardSide === ECardSide.PREVIEW && (
                <RTRImage
                  key={template.id}
                  id={template.id}
                  layers={template.layers}
                  data={templateDataForRender}
                  width={RTR_PREVIEW_IMAGE_SIZE}
                  previewWidth={'100%'}
                  ignoreCache
                />
              )}
            </Grid>
          </Grid>
        </div>

        <div id='back'>
          <Grid container direction='column' style={{ padding: 16 }}>
            <Grid
              className={classes.previewBtnContainer}
              onClick={() => setCardSide(ECardSide.PREVIEW)}
            >
              <CreateIcon fontSize={'large'} />
              <Text type='regular'>Preview</Text>
            </Grid>
            <Grid style={{ marginTop: '80px' }}>
              {formData && (
                <ErrorBoundary showDialog>
                  <DynamicFormGenerator
                    formData={templateFormData}
                    setFormData={setTemplateFormData}
                    options={inputPackageFormOptions}
                    isEditing={true}
                    layers={template.layers}
                  />
                </ErrorBoundary>
              )}
            </Grid>
          </Grid>
        </div>
      </ReactCardFlip>
    </Grid>
  </>
)
}
