import { makeStyles } from '@material-ui/core/styles'

export const ImageSelectorStyles = makeStyles(() => ({
  container: {
    marginTop: '8px',
    width: '88%',
    maxHeight: '70px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '5px',
    marginBottom: '15px',
  },
  itemContainer: {
    display: 'flex',
    background: 'rgba(255,255,255, 0.5)',
    borderRadius: 5,
    alignItems: 'center',
    cursor: 'move',
    padding: '0 8px',
    height: 70,
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: 'red',
  },
  leftItemContainer: {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
  },
  draggableImage: {
    width: '72px',
    height: '46px',
    pointerEvents: 'none',
    borderRadius: 5,
  },
  successful: {
    fontSize: '12px',
    marginTop: '8px',
    fontFamily: 'StyreneRegular',
    color: '#12B777',
  },
  imageContainer: {
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderColor: '#F3F3F7', 
    height: 63, 
    width: 95, 
    backgroundColor: '#F3F3F7', 
    borderRadius: 5, 
  },
  label: {
    fontFamily: 'StyreneRegular',
    padding: '8px 0px 8px 0px',
    fontSize: '12px',
    color: '#292929',
    opacity: '0.65',
  },
}))
