import create from 'zustand'

export type MarketingStore = {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
}

export const useMarketingStore = create<MarketingStore>((set) => ({
  isDialogOpen: false,
  setIsDialogOpen: (value) => set({ isDialogOpen: value }),
}))
