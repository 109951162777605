import { FormikErrors } from 'formik'
import { merge } from 'lodash'
import React from 'react'
import { useRequestContext } from '../../../../../../../context/RequestContext'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import {
  BuildAListForm,
  BuildAListFormValue,
} from '../../BuildAListForm/BuildAListForm'
import {
  BuildAListSectionDataRecord,
  usePTRFormContextProvider,
} from '../CustomizePrintModalContext'

export const BuildAListSection = (): JSX.Element => {
  const { listingAddressCtx: { zip: listingZip = '' } = {} } =
    useRequestContext() as {
      listingAddressCtx: {
        firstAddressLine: string
        secondAddressLine: string
        city: string
        state: string
        zip: string
      }
    }

  const updateFormData = PrintModalStore(
    ({ formData, setFormData }) =>
      (buildAList: BuildAListFormValue) => {
        const presentation = {
          zip: {
            label: 'Postal Code',
            value: buildAList.zip,
          },
        }
        setFormData(
          merge(formData, {
            presentationData: presentation,
            submittableData: {
              zip: { key: 'zip', label: 'zip', value: buildAList.zip },
              distance: {
                key: 'distance',
                label: 'distance',
                value: buildAList.distance,
              },
              address: {
                key: 'address',
                label: 'address',
                value: buildAList.address,
              },
            },
          }),
        )
      },
  )
  const setError = PrintModalStore(
    ({ setError }) =>
      (value: boolean) =>
        setError('buildAList', value),
  )
  const { mailingSectionData, setMailingSectionData } =
    usePTRFormContextProvider()
  const handleFormChange = (
    values: Partial<BuildAListFormValue>,
    errors: FormikErrors<BuildAListFormValue> = mailingSectionData.data.errors,
  ) => {
    const updatedValues: BuildAListSectionDataRecord['values'] = {
      ...mailingSectionData.data.values,
      ...values,
    }
    const updatedSectionData = {
      ...mailingSectionData,
      data: { values: updatedValues, errors },
    }
    updateFormData(updatedValues)
    setError(Object.keys(errors).length > 0)
    setMailingSectionData(updatedSectionData)
  }

  React.useEffect(() => {
    setMailingSectionData({
      ...mailingSectionData,
      data: {
        values: {
          ...mailingSectionData?.data?.values,
          zip: mailingSectionData?.data?.values?.zip || listingZip,
        },
      },
    })
  }, [])

  return (
    <BuildAListForm
      onlyZip
      value={mailingSectionData.data.values}
      onChange={handleFormChange}
    />
  )
}
