import * as fontFiles from '../assets/fonts/index'

export function useEmbedFonts() {
  const source: { [x: string]: string } = {
    [fontFiles.StyreneRegular]: `@font-face {
      font-family: 'Styrene A';
      src: url(PLACEHOLDER);
    }`,
    [fontFiles.StyreneMedium]: `@font-face {
      font-family: 'Styrene A';
      src: url(PLACEHOLDER);
      font-weight: 500;
      font-style: normal;
    }`,

    [fontFiles.StyreneItalic]: `@font-face {
      font-family: 'Styrene A';
      src: url(PLACEHOLDER);
      font-weight: 500;
      font-style: italic;
    }`,
    [fontFiles.Pragmatica]: `@font-face {
      font-family: 'Pragmatica';
      src: url(PLACEHOLDER);
      font-weight: normal;
      font-style: normal;
    }`,
    [fontFiles.CanelaLight]: `@font-face {
        font-family: 'Canela';
        src: url(PLACEHOLDER);
        font-weight: 300;
        font-style: normal;
    }`,
    [fontFiles.MoonTimeRegular]: `@font-face {
      font-family: 'MoonTime';
      src: url(PLACEHOLDER);
      font-weight: normal;
      font-style: normal;
    }`,
    [fontFiles.RobotoRegular]: `@font-face {
      font-family: 'Roboto';
      src: url(PLACEHOLDER);
      font-weight: normal;
      font-style: normal;
    }`,
    [fontFiles.Bookmania]: `@font-face {
      font-family: 'Bookmania';
      src: url(PLACEHOLDER);
      font-weight: bold;
      font-style: normal;
    }`,
    [fontFiles.BookmaniaRegularItalic]: `@font-face {
      font-family: 'Bookmania';
      src: url(PLACEHOLDER);
      font-weight: normal;
      font-style: italic;
    }`,
    [fontFiles.BookmaniaItalic]: `@font-face {
      font-family: 'Bookmania';
      src: url(PLACEHOLDER);
      font-weight: 300;
      font-style: italic;
    }`,
  }

  const getFontsAsDataURI = async () => {
    const result = await Promise.all(
      Object.keys(source).map(async (k: string) => {
        const encodedFont = await fetch(k)
          .then((resp) => resp.blob())
          .then((blob) => {
            return new Promise((resolve) => {
              const f = new FileReader()
              f.onload = () => resolve(f.result)
              f.readAsDataURL(blob)
            })
          })

        return source[k].replace('PLACEHOLDER', `"${encodedFont}"`)
      }),
    )

    return result
  }

  return { getFontsAsDataURI }
}
