import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { generateDataAttrs } from '../../utils/utils'
import Button from './Button'

const stepsToEls = (number) => {
  const arr = new Array(number).fill(0)
  return arr
}

const Step = (props) => {
  const { first, active, number, isBulletCounter = false, bulletText } = props
  const styles = {
    active: {
      width: '30px',
      height: '30px',
      border: '1px solid var(--blue)',
      borderRadius: '50%',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '30px',
      fontSize: '12px',
      fontFamily: 'StyreneMedium',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    inactive: {
      width: '30px',
      height: '30px',
      opacity: '0.35',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '30px',
      fontSize: '12px',
      fontFamily: 'StyreneRegular',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    divider: {
      borderTop: '1px solid \'var(--blue)',
      opacity: '0.35',
    },
    numberContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    bulletTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    activeBullet: {
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '20px',
      fontSize: '11px',
      fontFamily: 'StyreneMedium',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    inactiveBullet: {
      opacity: '0.35',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '20px',
      fontSize: '11px',
      fontFamily: 'StyreneRegular',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      {!first && (
        <Grid item xs>
          <hr className={classes.divider} />
        </Grid>
      )}
      {!isBulletCounter ? (
        <Grid item xs className={classes.numberContainer}>
          <div className={active ? classes.active : classes.inactive}>
            {number}
          </div>
        </Grid>
      ) : (
        <>
          <Grid item className={classes.bulletTitleContainer}>
            <div
              className={active ? classes.activeBullet : classes.inactiveBullet}
            ></div>
          </Grid>
          <Grid style={{ marginTop: 20 }}>
            <Grid item xs className={classes.bulletTitleContainer}>
              <div
                className={
                  active ? classes.activeBullet : classes.inactiveBullet
                }
              >
                <span>&#8226;</span>
              </div>
            </Grid>
            <Grid item xs className={classes.bulletTitleContainer}>
              <div
                className={
                  active ? classes.activeBullet : classes.inactiveBullet
                }
              >
                <span>{bulletText}</span>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default function StepCounter(props) {
  const history = useHistory()
  const {
    activeStep,
    handlePrevious,
    handleNext,
    numberOfSteps = null,
    isNextButtonDisabled,
    showOnlyNavButtons = false,
    nextButtonLabel = 'Next',
    isBulletCounter = false,
    bulletTextList,
    dataAttrs = {},
  } = props
  const styles = (theme) => ({
    container: {
      width: '100%',
      marginTop: '-5%',
      [theme.breakpoints.up('sm')]: {
        marginTop: '0%',
      },
    },
    backButton: {
      '&:disabled': {
        border: '2px solid rgba(42, 41, 166, 0.15)',
        color: 'rgba(42, 41, 166, 0.35)',
      },
    },
    backButtonBullet: {
      width: '130px !important',
      '&:disabled': {
        border: '2px solid rgba(42, 41, 166, 0.15)',
        color: 'rgba(42, 41, 166, 0.35)',
      },
    },
    nextButtonBullet: {
      width: '130px!important',
    },
    gridNumber: {
      width: '100%',
      marginTop: '25px',
      marginBottom: '35px',
    },
    gridBullet: {
      width: '100%',
      marginTop: '25px',
      marginBottom: '25px',
    },
  })

  const isBackButtonDisabled = false //activeStep === 1
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.container}
      direction="row"
      justify="space-between"
      alignItems="center"
      {...generateDataAttrs({
        ...dataAttrs,
        metaName: 'step-counter',
        testName: 'step-counter',
        metaStepCount: isNaN(+numberOfSteps) ? null : +numberOfSteps,
      })}
    >
      {!showOnlyNavButtons &&
        numberOfSteps &&
        stepsToEls(numberOfSteps).map((_, index) => {
          const isFirst = index === 0
          const isActive = index + 1 === activeStep
          return (
            <Grid
              item
              key={index}
              xs={!isFirst}
              {...generateDataAttrs({
                metaName: 'step',
                testName: 'step-counter-step',
                metaIdx: index + 1,
                metaActive: isActive ? true : void 0,
              })}
            >
              {!isBulletCounter ? (
                <Step
                  first={isFirst}
                  number={index + 1}
                  active={isActive}
                  numberOfSteps={numberOfSteps}
                  isBulletCounter={isBulletCounter}
                />
              ) : (
                <Step
                  first={isFirst}
                  number={index + 1}
                  active={isActive}
                  numberOfSteps={numberOfSteps}
                  isBulletCounter={isBulletCounter}
                  bulletText={bulletTextList[index]}
                />
              )}
            </Grid>
          )
        })}
      <Grid
        item
        className={!isBulletCounter ? classes.gridNumber : classes.gridBullet}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button
              small
              bordered
              secondary
              title="Back"
              className={
                !isBulletCounter ? classes.backButton : classes.backButtonBullet
              }
              disabled={isBackButtonDisabled}
              clickHandler={
                !handlePrevious ? () => history.goBack() : handlePrevious
              }
              dataAttrs={{
                metaAction: 'go-back',
                testName: 'step-counter-back-button',
              }}
            />
          </Grid>
          <Grid item>
            <Button
              small
              bordered
              primary
              title={nextButtonLabel}
              className={!isBulletCounter ? '' : classes.nextButtonBullet}
              disabled={isNextButtonDisabled}
              clickHandler={handleNext}
              dataAttrs={{
                metaAction: 'go-next',
                testName: 'step-counter-next-button',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
