import React, { useEffect } from 'react'
import { Grid, Box, capitalize } from '@material-ui/core'
import { Radio } from 'av8-ui'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import { merge } from 'lodash'

type PrintDesignSection = {
  options: OptionType[]
}

type OptionType = {
  id: string
  label: string
  value: string
}

export enum PrintDesignValues {
  PrintAndMail = 'printAndMail',
  DownloadOnly = 'downloadOnly',
}

export default function PrintDesignSection({ options }: PrintDesignSection) {
  const { printingAndDesignSectionData, setPrintingAndDesignSectionData } =
    usePTRFormContextProvider()

  const updateFormData = PrintModalStore(
    ({ formData, setFormData }) =>
      (value: string) => {
        const presentation = {
          printingAndDesignSectionData: {
            label: 'Print and Design',
            value: capitalize(value),
          },
        }

        const submittable = {
          productionNeeds: {
            label: 'Print & Mail',
            value:
              printingAndDesignSectionData === PrintDesignValues.DownloadOnly
                ? 'DownloadOnly'
                : 'Print & Mail',
            key: 'productionNeeds',
          },
        }
        setFormData(
          merge(formData, {
            presentationData: presentation,
            submittableData: submittable,
          }),
        )
      },
  )

  useEffect(() => {
    const optionLabel = options.find(
      (c) => c.value === printingAndDesignSectionData,
    )

    updateFormData(optionLabel?.label ?? '')
  }, [printingAndDesignSectionData])
  return (
    <Box px={2} width="100%">
      <Grid container>
        {options.map((option: OptionType) => {
          return (
            <Grid item xs={6} key={option.id}>
              <Radio
                key={option.id}
                label={option.label}
                value={option.value}
                checked={printingAndDesignSectionData === option.value}
                onChange={() => setPrintingAndDesignSectionData(option.value)}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
