import React from 'react'
import { Grid } from '@material-ui/core'
import Av8Loading from '../../../assets/icons/AV8Loading.gif'
import { RTR_PREVIEW_IMAGE_SIZE } from '../../../utils/constants'

export const ImageLoading = () => (
  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{
      textAlign: 'center',
      height: RTR_PREVIEW_IMAGE_SIZE,
      width: RTR_PREVIEW_IMAGE_SIZE,
      maxWidth: '100%',
    }}
  >
    <img src={Av8Loading} alt={'Loading'} height={55} width={150} />
  </Grid>
)
