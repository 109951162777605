import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Modal from './Modal'
import ContentLibraryInfoModal from '../custom/ContentLibraryInfoModal'
import useHistoryPrefix from '../../hooks/useHistoryPrefix'
import { useAuthContext } from '../../context/AuthContext'
import { useRequestContext } from '../../context/RequestContext'
import { PrintTemplateReviewPresentation } from '../../modules/marketing/modules/PrintRTR/components/PrintTemplateReviewPresentation'
import { SocialMediaTemplateReviewPresentation } from '../../modules/marketing/modules/SocialMedia/SocialMediaTemplateReviewPresentation'

export default function TemplatePresentation({
  templateCategory,
  template = {},
}) {

  const history = useHistoryPrefix()
  const { isEmbedded } = useRequestContext()
  const { agentId } = useAuthContext()

  const [showInfoModal, setShowInfoModal] = useState(false)

  const handleContentGridRedirection = () => {
    history.push(`/content?mbd=${isEmbedded}&agentId=${agentId}`)
  }

  const templateReviewPresentation = (templateCategory, template) => {
    switch(templateCategory) {
      case 'postcard':
      case 'double-sided-flyer':
      case 'flyer':
          return <PrintTemplateReviewPresentation template={template} />
      case 'social-media': 
        return <SocialMediaTemplateReviewPresentation template={template} setShowInfoModal={setShowInfoModal} />
      default:
        return <PrintTemplateReviewPresentation template={template} />
    }
  }

  return (
    <Grid key={`${template.url}`} style={{ marginTop: 20 }}>
      <Modal open={showInfoModal}>
        <ContentLibraryInfoModal
          text={
            'Your marketing content is processing and will be in your content library soon.'
          }
          handleClose={() => {
            setShowInfoModal(false)
            handleContentGridRedirection()
          }}
        />
      </Modal>
      {templateReviewPresentation(templateCategory, template)}
    </Grid>
  )
}
