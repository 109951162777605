import { Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import Accordion from '../Accordion'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { AccordionIds, AccordionsBodyName } from '../mockApi'
import PrintNeedsSection from '../Sections/PrintNeedsSection'
import MailingSection from '../Sections/Mailing'
import PrintDesignSection, {
  PrintDesignValues,
} from '../Sections/PrintDesignSection'
import ShippingSection from '../Sections/Shipping'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: 'transparent',
  },
}))

type AccordionType = {
  id: number
  title: string
  body: string
  icon: React.ReactNode
  expanded: boolean
  disabled?: boolean
  options: OptionType[]
}

export type OptionType = {
  id: string
  label: string
  value: string
  type?: 'select' | '' | any
  options?: any
}

export type ChildOptionType = {
  id: string
  label: string
  value: string
}

export type FormAccordionManagerType = {
  accordions: AccordionType[]
}

const bodyComponentSelector = (
  bodyName: string,
  options: OptionType[],
  disabled?: boolean,
): JSX.Element => {
  const bodyComponents: any = {
    [AccordionsBodyName.PrintAndDesign]: (
      <PrintDesignSection options={options} />
    ),
    [AccordionsBodyName.Mailing]: (
      <MailingSection options={options} disabled={disabled} />
    ),
    [AccordionsBodyName.PrintNeeds]: <PrintNeedsSection options={options} />,
    [AccordionsBodyName.Shipping]: <ShippingSection options={options} />,
  }

  return bodyComponents[bodyName]
}

export default function FormAccordionManager({
  accordions,
}: FormAccordionManagerType) {
  const classes = useStyles()

  const enableContinue = PrintModalStore(({ enableContinue }) => enableContinue)

  const clearErrors = PrintModalStore(({ clearErrors }) => clearErrors)
  const { cleanOptions, printingAndDesignSectionData, mailingSectionData } =
    usePTRFormContextProvider()
  const [accordionsState, setAccordionsState] =
    useState<AccordionType[]>(accordions)

  useEffect(() => {
    if (printingAndDesignSectionData === PrintDesignValues.PrintAndMail) {
      handlePrintAndMailSelection()
    }
    if (printingAndDesignSectionData === PrintDesignValues.DownloadOnly) {
      handleDownloadOnlySelection()
    }
  }, [printingAndDesignSectionData])

  const handleDownloadOnlySelection = () => {
    //Disable and close sections that aren't download
    const accordionsToBeUpdated = [
      AccordionIds.Mailing,
      AccordionIds.PrintNeeds,
      AccordionIds.Shipping,
    ]
    updateDisabledAccordionsState(accordionsToBeUpdated, true)
    updateExpandAccordionsState(accordionsToBeUpdated, false)
    clearErrors()
    cleanOptions()
    enableContinue()
  }

  const handlePrintAndMailSelection = () => {
    const accordionsToBeUpdated = [
      AccordionIds.Mailing,
      AccordionIds.PrintNeeds,
      AccordionIds.Shipping,
    ]
    updateDisabledAccordionsState(accordionsToBeUpdated, false)
    updateExpandAccordionsState(accordionsToBeUpdated, true)
  }

  useEffect(() => {
    if (mailingSectionData?.section.length) {
      enableContinue()
    }
  }, [mailingSectionData])

  const toggleExpand = (accordionId: number) => {
    setAccordionsState((lastAccordionState) => {
      return lastAccordionState.map((accordion) => {
        if (accordion.id === accordionId) {
          return {
            ...accordion,
            expanded: !accordion.expanded,
          }
        }
        return accordion
      })
    })
  }

  const updateExpandAccordionsState = (
    accordionIds: number[],
    expanded: boolean,
  ) => {
    setAccordionsState((lastAccordionState) => {
      return lastAccordionState.map((accordion) => {
        if (accordionIds.includes(accordion.id)) {
          return {
            ...accordion,
            expanded: expanded,
          }
        }
        return accordion
      })
    })
  }

  const updateDisabledAccordionsState = (
    accordionIds: number[],
    disabled: boolean,
  ) => {
    setAccordionsState((lastAccordionState) => {
      return lastAccordionState.map((accordion) => {
        if (accordionIds.includes(accordion.id)) {
          return {
            ...accordion,
            disabled: disabled,
          }
        }
        return accordion
      })
    })
  }

  return (
    <Grid className={classes.root}>
      {accordionsState.map((accordion: AccordionType) => {
        const { id, title, body, icon, expanded, disabled, options } = accordion
        return (
          <Accordion
            key={accordion.id}
            id={id}
            title={title}
            icon={icon}
            expanded={expanded}
            disabled={disabled}
            handleExpandClick={toggleExpand}
          >
            {bodyComponentSelector(body, options, disabled) || ''}
          </Accordion>
        )
      })}
    </Grid>
  )
}
