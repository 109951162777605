import React, { useEffect, useState } from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import Text from '../../../../../../components/common/Text'
import { RtrTemplate } from '../../../../components/TemplatePreview/TemplateRTRPreview'
import { RTRTemplateData } from '../../../../utils/formatTemplateData'
import {
  PrintCategories,
  PrintCategory,
  printCategoryData,
} from './printCategory.data'
import { renderTemplate } from '../../../../actions/renderTemplate'
import { ImageLoading } from '../../../../components/ImageLoading'
import { renderPngInBackend } from '../../../../../shared/utils/svgRtrConverters'
import PrintTemplateReviewForm from '../CustomizePrintModalForm/PrintTemplateReviewForm'
import {
  ChildImage,
  PrintModalStepImage,
} from '../../../../store/PrintModal.store'
import RTRError from '../../../../../../assets/RTRError.png'
import Image from '../../../../../../components/common/Image'
import DownloadOnlyForm from './DownloadOnlyForm'
import {
  normalizeInputPackage,
  optionDataToTemplate,
} from '../../../../utils/inputPackage'
import { ErrorBoundary } from '@sentry/react'
import { toMoney } from '../../../../../../components/custom/DynamicForm/formatters/money.formatter'

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    marginTop: 15,
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 375,
    },
  },
  formContainer: {
    background: '#F3F3F7',
    width: '100%',
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {},
  },
  formSection: {
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 375,
    },
    marginBottom: 75,
  },
}))

export type PrintTemplateReviewProps = {
  template: RtrTemplate
  templateCategory: PrintCategory
  formData: Record<string, any>
  setFormData: (data: Record<string, any>) => void
  setStepImages: (stepImages: PrintModalStepImage[]) => void
  appendStepImage: (stepImage: PrintModalStepImage) => void
  onTemplatesReady?: (templates: string[]) => void
  baseTemplateData: RTRTemplateData
  handleDownloadImages: () => void
  disableContinue: () => void
}

export function PrintTemplateReview(params: PrintTemplateReviewProps) {
  const {
    template,
    templateCategory,
    formData,
    setFormData,
    baseTemplateData,
    setStepImages,
    handleDownloadImages,
    disableContinue,
  } = params

  const [childrenImagesAvailable, setChildrenImagesAvailable] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [childrenImages, setChildrenImages] = useState<PrintModalStepImage[]>(
    [],
  )

  //This flag is used to make it ignore cache only when mounting this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldIgnoreCache, setShouldIgnoreCache] = useState(true)

  const classes = useStyles()
  const steps = printCategoryData[templateCategory].steps

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stepsNames = steps
    .filter((step) => step.type === 'edit')
    .map((step) => step.name)

  const stepsTemplates: ChildImage[] = steps
    .map((step, idx) => {
      const stepTemplate = template.children[idx]

      if (!stepTemplate) {
        return null
      }
      const inputFormData: Record<string, any> =
        formData?.children?.[step.name] ?? {}

      const { inputs: inputsPackage = [] } = template?.input_package || []

      // normalize names to camelCase
      const normalizedInputPackage = normalizeInputPackage(inputsPackage)

      const filledInputPackageOptions = Object.fromEntries(
        normalizedInputPackage.map((input) => {
          const name: string = (input?.metadata?.formName ??
            input.name) as string
          const filledInput = {
            ...input,
            value: baseTemplateData?.[name] ?? undefined,
          }
          return [name, filledInput]
        }),
      )

      const templateFormData = {
        ...filledInputPackageOptions,
        ...inputFormData,
      }
      templateFormData.price = toMoney(templateFormData?.price || 0)

      const data: Record<string, any> = Object.fromEntries(
        Object.values(optionDataToTemplate(templateFormData)).map((input) => [
          input.name,
          input.value,
        ]),
      )

      return {
        step: step.name,
        template: stepTemplate,
        data,
      }
    })
    .filter((value): value is ChildImage => value !== null)

  useEffect(() => {
    const renderChildren = async () => {
      const stepsImagesRenderingPromises = stepsTemplates.map(
        async (stepTemplate): Promise<PrintModalStepImage> => {
          let data = { ...baseTemplateData, ...stepTemplate.data }

          // TODO: Improve this, this type of rule should come from Strapi
          if (data?.agentLogo) {
            data = {
              ...data,
              layerOptions: {
                ['agentLogo']: {
                  preserveAspectRatio: true,
                },
              },
            }
          }

          const renderResult = await renderTemplate({
            id: stepTemplate.template.id,
            layers: stepTemplate.template.layers,
            data,
          })

          const { base64encoded, url, blob } = await renderPngInBackend(
            renderResult.image,
          )

          return {
            ...stepTemplate,
            svgImage: renderResult.image,
            image: base64encoded,
            localUrl: url,
            blob,
          }
        },
      )
      try {
        const childImages = await Promise.all(stepsImagesRenderingPromises)
        setStepImages(childImages)
        setChildrenImages(childImages)
      } catch (error) {
        setHasError(true)
      }
    }

    renderChildren()
      .then(() => {
        setChildrenImagesAvailable(true)
        setShouldIgnoreCache(false)
      })
      .catch((error) => {
        console.error('Failed to render images', error)
      })
  }, [])

  const dataForTemplate = {
    ...baseTemplateData,
    // this formats the data in { step: url }
    ...Object.fromEntries(
      childrenImages.map((childImage: PrintModalStepImage) => [
        childImage.step,
        childImage.image,
      ]),
    ),
  }

  const cardStyles = {
    paddingBottom: 16,
    paddingTop: 16,
    minHeight: 300,
    maxWidth: '100%',
  }

  const loadTemplateFormData = () => {
    const updatedData = {
      ...formData,
      template: {
        dataForTemplate,
      },
    }
    setFormData(updatedData)
  }

  useEffect(() => {
    loadTemplateFormData()
  }, [childrenImagesAvailable])

  useEffect(() => {
    if (
      templateCategory === PrintCategories.postCard &&
      !formData?.presentationData?.printingAndDesignSectionData?.value
    ) {
      disableContinue()
    }
  }, [])

  return (
    <Grid container direction='column' alignItems='center'>
      <Box textAlign='left' width='100%' className={classes.previewContainer}>
        <Text type='sectionName' fontSize='14px'>
          Preview:
        </Text>
      </Box>
      {templateCategory === PrintCategories.postCard ? (
        <>
          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.previewContainer}
            style={{ marginTop: '5px', ...cardStyles }}
          >
            {hasError ? (
              <Image medium src={RTRError} />
            ) : childrenImagesAvailable ? (
              childrenImages.map((childImage: PrintModalStepImage) => (
                <Box my={3} key={childImage.step} boxShadow={1}>
                  <img src={childImage.localUrl} width={'100%'} />
                </Box>
              ))
            ) : (
              <ImageLoading />
            )}
          </Grid>
          <Grid container justify='center' className={classes.formContainer}>
            <Grid className={classes.formSection}>
              <ErrorBoundary
                fallback={<p>Oops... something went wrong here</p>}
              >
                <PrintTemplateReviewForm
                  reviewFormData={formData?.reviewFormData}
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </>
      ) : (
        <DownloadOnlyForm
          childrenImages={childrenImages}
          handleDownloadImages={handleDownloadImages}
          templateCategory={templateCategory}
        />
      )}
    </Grid>
  )
}
