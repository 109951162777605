import React, { useEffect, useState } from 'react'
import { Box, Dialog, Grid } from '@material-ui/core'
import { A8Button, Typography } from 'av8-ui'

import SelectableImage from '../../../../components/custom/SelectableImage'
import { ImportFromGalleryStyles } from './ImportFromGalleryModal.styles'
import ImportFromGalleryAuxiliaryButton from '../../../../components/custom/EmailTemplate/ImportFromGalleryAuxiliaryButton'
import Text from '../../../../components/common/Text'

export type Image = {
  id: string
  url: string
  name: string
}

export type ImportFromGalleryModalProps = {
  open?: boolean
  openButton?: JSX.Element
  title: string
  subTitle: string
  images: Image[]
  quantityOfImagesToShow?: number
  selectableImagesLimit: number
  alreadySelectedImages?: Image[]
  importingType: 'MLS' | 'Logo'
  buttonClickHandler: (images: Image[], cancel?: boolean) => void
}

export type Ref = HTMLDivElement

export const ImportFromGalleryModal = React.forwardRef<
  Ref,
  ImportFromGalleryModalProps
>((props, ref) => {
  const {
    openButton,
    title,
    subTitle,
    open: openInitialState = false,
    images = [],
    quantityOfImagesToShow = 8,
    selectableImagesLimit,
    alreadySelectedImages = [],
    importingType = 'MLS',
    buttonClickHandler,
  } = props
  const previouslySelectedGalleryImages = alreadySelectedImages.filter(
    (image) => images.includes(image),
  )
  const classes = ImportFromGalleryStyles()
  const [open, setOpen] = useState(openInitialState)
  const [selectedImages, setSelectedImages] = useState<Image[]>(
    previouslySelectedGalleryImages,
  )
  const [quantityToShow, setQuantityToShow] = useState(quantityOfImagesToShow)
  const [error, setError] = useState('')

  useEffect(() => {
    setSelectedImages([])
    setError('')
  }, [open])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    buttonClickHandler([], true)
    setOpen(false)
  }

  const handleImageSelect = (image: Image) => {
    let updatedSelectedImages: Image[] = []
    const isAlreadySelected = selectedImages.find(
      (_image) => _image.id === image.id,
    )

    if (!isAlreadySelected) {
      updatedSelectedImages = [...selectedImages, { ...image }]
    } else {
      updatedSelectedImages = selectedImages.filter(
        (_image) => _image.id !== image.id,
      )
    }
    setError('')
    setSelectedImages(updatedSelectedImages)
  }

  const handleSelectClick = () => {
    handleClose()
    buttonClickHandler(selectedImages)
  }

  const calculateHowManyImagesCanBeSelected = () => {
    if (selectableImagesLimit === 1) return 1
    return selectableImagesLimit - selectedImages.length
  }

  return (
    <>
      {openButton && (
        <Grid container alignItems='center' ref={ref} onClick={handleClickOpen}>
          {openButton}
        </Grid>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{ style: { borderRadius: '0', margin: 16, maxWidth: 375 } }}
      >
        <Grid className={classes.container}>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            style={{ marginBottom: 20 }}
          >
            <Typography variant='Subheading' fontWeight='bold'>
              {title ?? ''}
            </Typography>
            <Typography
              variant='Caption'
              onClick={handleClose}
              style={{ cursor: 'pointer', marginTop: 5 }}
            >
              Cancel
            </Typography>
          </Grid>
          <Grid style={{ marginBottom: 20 }}>
            <Typography variant='Caption' color='grey'>
              {subTitle ?? ''}
            </Typography>
          </Grid>
          <Grid className={classes.imageGrid}>
            {images?.length > 0 ? (
              images.slice(0, quantityToShow).map((image) => {
                const isThisImageSelected = !!selectedImages.find(
                  (_image) => _image.id === image.id,
                )

                const isImageDisabled =
                  selectedImages.length === selectableImagesLimit &&
                  !isThisImageSelected

                return (
                  <Grid
                    key={image.id}
                    container
                    direction='column'
                    alignItems='center'
                    onClick={() => {
                      if (isImageDisabled) {
                        setError(
                          `You already selected all allowed images. (${selectableImagesLimit})`,
                        )
                      }
                    }}
                  >
                    <SelectableImage
                      key={image?.id}
                      disabled={isImageDisabled}
                      checked={isThisImageSelected}
                      itemKey={image.id}
                      onSelect={() => handleImageSelect(image)}
                    >
                      <div className={classes.selectableImageContainer}>
                        <img src={image.url} alt='' height='75' width='125' style={{ objectFit: 'scale-down' }} />
                        <Typography
                          variant='Caption'
                          color='grey'
                          style={{ wordWrap: 'break-word' }}
                        >
                          {image.name || 'ImageName.jpg'}
                        </Typography>
                      </div>
                    </SelectableImage>
                  </Grid>
                )
              })
            ) : (
              <Grid style={{ gridColumn: '1 / 3' }}>
                <Text type='regular'>
                  Hmm, seems like there's no MLS images.
                </Text>
              </Grid>
            )}
          </Grid>
          <Grid container justify='center'>
            <ImportFromGalleryAuxiliaryButton
              importingType={importingType}
              quantityToShow={quantityToShow}
              setQuantityToShow={setQuantityToShow}
            />
          </Grid>
          <Box my={3} textAlign='center'>
            {error && (
              <Typography variant='error' style={{ fontSize: '12px' }}>
                {error}
              </Typography>
            )}
          </Box>
          <Grid container justify='center'>
            <A8Button
              variant='contained'
              disabled={selectedImages.length === 0}
              onClick={handleSelectClick}
              wide
            >
              {`Select (${
                selectedImages.length
              }/${calculateHowManyImagesCanBeSelected()})`}
            </A8Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
})
