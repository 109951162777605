import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Loading from '../../common/Loading'
import ErrorMsg from '../../common/ErrorMsg'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Button from '../../common/Button'
import Header from '../../common/Header'
import HeaderNavigation from '../../common/HeaderNavigation'
import StepCounter from '../../common/StepCounter'
import CustomizeEmailTemplateBody from '../EmailTemplate/CustomizeEmailTemplateBody'
import emailRTROptionsParser from '../EmailTemplate/emailRTROptionsParser'
import { useInputDataContext } from '../../../context/InputDataContext'
import { useEmailRTRContext } from '../../../context/EmailRTRContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useRequestContext } from '../../../context/RequestContext'
import { 
  submitEmailRTRequest,
  submitEmailRTRDraft } from '../../../services/marketingServices'
import { generateDataAttrs } from '../../../utils/utils'
import Loader from '../Loader'
import EmailRequestConfirmation from '../EmailTemplate/EmailRequestConfirmation'
import Modal from '../../common/Modal'
import { handleLeaveWebview } from '../../../services/appServices'
import { useStageSelectionContext } from '../../../context/StageSelectionContext'
import { useListingDescriptionContext } from '../../../context/ListingDescriptionContext'

const useStyles = makeStyles((theme) => ({
  container: {},
  bodyContainer: {
    padding: '15px 20px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
      margin: '5% auto 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      marginTop: '2%',
      marginBottom: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    marginTop: '12px',
  },
  media: {
    width: '100%',
    minHeight: 300,
    maxHeight: 335,
    marginTop: 7,
    marginBottom: 15,
  },
  dotListClass: {
    position: 'absolute',
    marginBottom: 0,
    top: 410,
    height: 20,
  },
  carouselContainer: {
    position: 'relative',
    marginBottom: 15,
  },
  title: {
    fontFamily: 'Bookmania',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
  },
  subTitle: {
    fontFamily: 'Bookmania',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
    marginBottom: '25px',
  },
  sectionName: {
    fontFamily: 'StyreneA',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: '#292929',
    opacity: '0.65',
    marginBottom: '15px',
  },
  formContainer: {
    background: '#F3F3F7',
    padding: '3%',
  },
  btnPreview: {
    width: '64px',
    height: '64px',
    borderRadius: '0px',
    background: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '20px',
    marginLeft: '73%',
    paddingLeft: '7px',
    border: '0px',
  },
}))

export default function PackageDialog({
  isEditing, // Flag to determine if the Dialog is in editing mode
  packageType,
  inputData,
  image,
  isDeepLink = false,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(isDeepLink)
  const loading = false
  const error = ''
  const formRef = useRef()

  // Flag to determine whether or not display the error alert
  const [isError, setIsError] = useState(false)

  const { isEmbedded } = useRequestContext()

  const [errorMsg, setErrorMsg] = useState({
    title: '',
    show: false,
    message: '',
    btnText: '',
    close: false,
  })

  // Object to persist the email blast data
  const {
    emailRTRData,
    EmailBodyData,
    HeroContentData,
    setEmailRTRData,
    setEmailBodyData,
    setHeroContentData,
  } = useEmailRTRContext()

  const {
    stageSelection: stageSelectionDataCtx,
  } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } = useListingDescriptionContext()
  const { listingAddressCtx } = useRequestContext()

  useEffect(() => {
    setEmailRTRData(isEditing ? inputData : {})
  }, [open])

  const { agentId, agentData } = useAuthContext()

  const [buttonIsEnabled, setButtonIsEnabled] = useState(true)
  const [confirmationShow, setConfirmationShow] = useState(false)
  const [receivedStatus, setReceivedStatus] = useState({
    show: false,
    message: '',
    noButton: {},
    yesText: '',
  })
  const [emailLoadingStatus, setEmailLoadingStatus] = useState({
    loading: false,
    message: '',
    show: false,
    error: false,
  })

  // Steper handlers
  const numberOfSteps = 3
  const [activeStep, setActiveStep] = useState(1)
  const bulletTextList = ['Headline', 'Details', 'Review']
  const {
    formMarketingDataCtx,
    setFormMarketingDataCtx,
  } = useInputDataContext()

  const buttonProps = isEditing
    ? {
        primary: true,
        title: 'Edit',
        disabled: inputData?.alreadySent || false,
      }
    : {
        secondary: true,
        title: 'Customize',
      }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (emailLoadingStatus.error) {
      setEmailLoadingStatus({
        ...emailLoadingStatus,
        error: false,
        show: false,
      })
    } else {
      if (isDeepLink) {
        if (isEmbedded) {
          return handleLeaveWebview()
        } else {
          return handleLeaveSubApp()
        }
      } else {
        setOpen(false)
      }
    }
  }

  const handlePrevious = () => {
    // Check if the current step isn't the first
    if (activeStep > 1) {
      setActiveStep((activeStep) => activeStep - 1)

      //showEmailPreview()
    } else {
      handleClose()
    }
  }

  const handleNext = () => {
    // First of all check if the activeStep is the last one
    if (activeStep !== numberOfSteps) {
      setActiveStep((activeStep) => activeStep + 1)
    }
  }

  const handleConfirmation = () => {
    setConfirmationShow(true)
  }

  const handleSave = async (save) => {
    setConfirmationShow(false)
    setEmailLoadingStatus({
      ...emailLoadingStatus,
      message: 'Processing your request...',
      show: true,
      loading: true,
      error: false,
    })

    const values = formRef.current.values

    const { mailingLists, emailData } = emailRTRData
    const updatedEmailData = {
      ...emailData,
      ...values,
      description: EmailBodyData.description,
      propertyUrl: EmailBodyData.propertyUrl,
    }
    const previousInputDataTemplates =
      formMarketingDataCtx?.[packageType]?.['email-rtr'] || []

    const filteredInputData = previousInputDataTemplates.filter(
      (data) => data.itemId !== image.id,
    )

    let processedEmailData = { ...updatedEmailData }
    try {
      if (!save) {
        const {
          newOptions,
          newImageUrl,
          formattedEmailData,
        } = await emailRTROptionsParser(
          mailingLists,
          image.html,
          updatedEmailData,
          [],
          image.identifier,
          agentId,
          listingDescriptionCtx.imagesIds,
          stageSelectionDataCtx.value,
          listingAddressCtx,
          isDeepLink,
        )
      processedEmailData = { ...formattedEmailData }
        const templates = [
          {
            packageType: 'digital',
            templateCategory: 'email-rtr',
            options: newOptions,
            imageUrl: newImageUrl,
            auxiliaryImageUrls: [],
            templateId: image.id,
            name: image.name,
            layers: image.layers,
          },
        ]

        const requestPayload = {
          agentId,
          headline: listingDescriptionCtx.headline,
          stage: stageSelectionDataCtx.value,
          stageName: stageSelectionDataCtx.stageName,
          listing: {
            ...listingAddressCtx,
            ...listingDescriptionCtx,
            images: listingDescriptionCtx.imagesIds,
          },
          agent: {
            profile_picture: agentData.profile.photo,
            dre: agentData.dre,
            logo: HeroContentData.agentLogo,
            url: EmailBodyData.agentUrl,
          },
          templates,
        }

        const response = await submitEmailRTRequest(requestPayload)
        const emailBlastData = requestPayload
        delete emailBlastData.templates[0].options['emailTemplate']
        if (response?.success) {
          const draftPayload = {
            orderConfirmationId: response.data.orderId,
            agentId,
            emailBlastData,
          }
          await submitEmailRTRDraft(draftPayload)
        } else {
          throw new Error('Error on creating Request')
        }
      }
      const updatedFormDataCtx = {
        [packageType]: {
          ...(formMarketingDataCtx?.[packageType] || {}),
          ['email-rtr']: [
            ...filteredInputData,
            {
              templateName: image.name,
              identifier: image.identifier,
              alreadySent: !save,
              mailingLists,
              emailHTML: image.html,
              emailData: processedEmailData,
              itemId: image.id,
              options: {
                dynamicFormData: {
                  campaignStartDate: {
                    value: processedEmailData.emailSendDate || '',
                  },
                },
              },
            },
          ],
        },
      }

      // send the data to the context as a return
      setFormMarketingDataCtx({
        ...formMarketingDataCtx,
        ...updatedFormDataCtx,
      })

      const hasInputs = Object.values({
        ...formMarketingDataCtx,
        ...updatedFormDataCtx,
      }).some((inputData) =>
        Object.values(inputData).some((data) => {
          return Object.values(data).some((request) => !request?.alreadySent)
        }),
      )

      //reset Context
      setEmailRTRData({
        emailHTML: '',
        emailData: { agentEmail: emailRTRData?.emailData?.agentEmail },
        mailingLists: [],
      })
      setEmailBodyData({})
      setHeroContentData({})

      setActiveStep(1) // change to initial step for future edit
      if (save) {
        setEmailLoadingStatus({
          ...emailLoadingStatus,
          message: 'Your Email template has been saved!',
          loading: false,
          show: true,
        })
      } else {
        setEmailLoadingStatus({
          ...emailLoadingStatus,
          loading: false,
          show: false,
        })
        setReceivedStatus((prevState) => {
          return hasInputs || isDeepLink
            ? {
                ...prevState,
                show: true,
                message:
                  'We’ve started creating your email. You’ll receive a preview in your inbox within 1 business day. Would you like to request more marketing assets?',
                noButton: {},
                yesText: isDeepLink ? 'Finish' : 'Continue',
              }
            : {
                ...prevState,
                show: true,
                message:
                  'We’ve started creating your email. You’ll receive a preview in your inbox within 1 business day. Would you like to request more marketing assets?',
                noButton: { noText: 'Finish' },
                yesText: 'Request More',
              }
        })
      }
    } catch (error) {
      console.log(error)
      setEmailLoadingStatus({
        ...emailLoadingStatus,
        show: true,
        message: 'We were unable to complete your request. Please try again.',
        loading: false,
        error: true,
      })
    }
  }
  const handleReceived = (returnToPackage) => {
    if (returnToPackage) {
      setReceivedStatus((prevState) => ({
        ...prevState,
        show: false,
      }))
      handleClose()
    } else {
      setReceivedStatus((prevState) => ({
        ...prevState,
        show: false,
      }))
      if (isEmbedded) {
        return handleLeaveWebview()
      } else {
        return handleLeaveSubApp()
      }
    }
  }

  const handleLeaveSubApp = () => {
    window.location.href = `${window.location.origin}/account`
  }

  const buttonsDialog = {
    yesText: 'Send Preview',
  }
  if (!isDeepLink) {
    buttonsDialog.noText = 'Not Now'
  }

  return (
    <>
      <EmailRequestConfirmation
        open={confirmationShow}
        title="Send"
        subtitle="Preview?"
        message={
          'This will begin the email campaign process, and you will no longer be able to edit your email draft in the app. You’ll still be able to preview it before launching the campaign. Please note: it can take up to 1 business day to receive your preview. '
        }
        email={emailRTRData?.emailData?.agentEmail}
        {...buttonsDialog}
        onNo={() => {
          handleSave(true)
        }}
        onYes={() => {
          handleSave(false)
        }}
      />
      <EmailRequestConfirmation
        open={receivedStatus.show}
        title="Request"
        subtitle="Received."
        message={receivedStatus.message}
        email={emailRTRData?.emailData?.agentEmail}
        {...receivedStatus.noButton}
        yesText={receivedStatus.yesText}
        onNo={() => {
          handleReceived(false)
        }}
        onYes={() => {
          handleReceived(true)
        }}
        bottomMessage
      />
      {emailLoadingStatus.show && (
        <Modal open={emailLoadingStatus.show}>
          <Loader
            loading={emailLoadingStatus.loading}
            text={emailLoadingStatus.message}
            setLoadingStatus={setEmailLoadingStatus}
            dataAttrs={{ metaType: 'modal' }}
            onClose={handleClose}
            closeTitle={'OK'}
          />
        </Modal>
      )}
      <Button
        small
        bordered
        onClick={handleClickOpen}
        style={{ marginBottom: '1px' }}
        {...buttonProps}
        dataAttrs={{
          metaAction: `click-${image.identifier}-customize`,
        }}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        // fullWidth
        fullScreen
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{ style: { borderRadius: '0' } }}
      >
        <Header>
          <HeaderNavigation />
        </Header>
        <Grid className={classes.bodyContainer}>
          <Grid container justify="space-between" alignItems="center">
            <StepCounter
              numberOfSteps={3}
              activeStep={activeStep}
              handlePrevious={handlePrevious}
              handleNext={
                activeStep == numberOfSteps ? handleConfirmation : handleNext
              }
              nextButtonLabel={activeStep == numberOfSteps ? 'Preview' : 'Next'}
              isNextButtonDisabled={!buttonIsEnabled}
              isBulletCounter
              bulletTextList={bulletTextList}
              {...generateDataAttrs({
                metaAction:
                  activeStep == numberOfSteps
                    ? `click-${image.identifier}-preview`
                    : `click-${image.identifier}-next`,
              })}
            />
          </Grid>
          {error && <ErrorMsg />}
          {loading && <Loading heightVh="20vh" />}
          <Grid>
            <div className={classes.title}>
              {activeStep == numberOfSteps ? 'Review' : 'Customize'}
            </div>
            <div className={classes.subTitle}>Email Template</div>
          </Grid>
          <CustomizeEmailTemplateBody
            step={bulletTextList[activeStep - 1]}
            handleClose={handleClose}
            imgMaxQuantity={image.image_count}
            isEditing={isEditing}
            emailHTML={image.html}
            setButtonIsEnabled={setButtonIsEnabled}
            formRef={formRef}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            isError={isError}
            setError={setIsError}
          />
        </Grid>
      </Dialog>
    </>
  )
}
