import React, { useState, useRef, useEffect } from 'react'
import ReactCardFlip from 'react-card-flip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateIcon from '@material-ui/icons/Create'
import Button from '../../common/Button'
import ImageSelector from './ImageSelector'
import FormikInput from '../../common/FormikInput'
import HtmlPreviewer from '../../common/HtmlPreviewer'
import { makeStyles, Grid } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { useAuthContext } from '../../../context/AuthContext'
import { useEmailRTRContext } from '../../../context/EmailRTRContext'
import * as Yup from 'yup'
import NoLogoImg from '../../../assets/images/NoLogo.png'
import { getAgentLogos } from '../../../services/agentServices'
import { v4 as uuidv4 } from 'uuid'
import { useListingDescriptionContext } from '../../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../../context/StageSelectionContext'
import { fromMoney, toMoney } from '../DynamicForm/formatters/money.formatter'
import { useRequestContext } from '../../../context/RequestContext'

// Define the Hero Content form validations
const validationSchema = Yup.object().shape({
  heading: Yup.string()
    .min(2, 'The headline is too short')
    .required('You must write a headline to listing'),
  bedrooms: Yup.number()
    .optional()
    .nullable()
    .min(0, 'Invalid bed number')
    .max(15, 'The maximum allowed input for bed is 15'),
  bathrooms: Yup.number()
    .optional()
    .nullable()
    .min(0, 'Invalid bath number')
    .max(15, 'The maximum allowed input for bath is 15'),
  price: Yup.string().optional().nullable().min(0, 'Invalid listing price'),
})

const noLogoImage = {
  id: uuidv4(),
  name: 'No Logo',
  url: NoLogoImg,
  isNoLogo: true,
}

export default function HeroContent({
  step,
  emailHTML,
  isEmailPreview,
  setEmailPreviewFlag,
  isEditing,
  styles,
  setButtonIsEnabled,
  loading,
}) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const { isEmbedded } = useRequestContext()

  const heroContentFormRef = useRef()
  const { agentId } = useAuthContext()
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()
  const { listingDescription: listingDescriptionCtx } = useListingDescriptionContext()
  const {
    emailRTRData,
    setHeroContentData,
    HeroContentData,
  } = useEmailRTRContext()

  // Property images handler
  const [heroContentImages, setHeroContentImages] = useState(
    isEditing
      ? [{ url: HeroContentData.headerImage }]
      : listingDescriptionCtx.images.slice(0, 1),
  )
  const [heroContentLogo, setHeroContentLogo] = useState(
    isEditing && HeroContentData?.agentLogo
      ? [{ url: HeroContentData.agentLogo, name: 'Logo' }]
      : [noLogoImage],
  )
  const [agentLogos, setAgentLogos] = useState([])

  // Hero content form initial data
  const formInitialValues = {
    heading: HeroContentData.heading,
    price: HeroContentData.price,
    bedrooms: HeroContentData.bedrooms,
    bathrooms: HeroContentData.bathrooms,
  }

  useEffect(() => {
    const fetchAgentLogos = async () => {
      try {
        const { data } = await getAgentLogos(agentId)
        setAgentLogos([noLogoImage, ...data])
      } catch (err) {
        console.error(err)
      }
    }
    fetchAgentLogos()
  }, [])

  const nextButtonHandler = (values) => {
    const isValidValues = validationSchema.isValidSync(values)
    if (!loading) {
      if (isValidValues) {
        setButtonIsEnabled(true)
      } else {
        setButtonIsEnabled(false)
      }
    }
  }

  useEffect(() => {
    if (step === 'Details') handleHeroContentPreview(false)
  }, [step])

  /**
   * Function to handle the Hero Content Preview
   *
   * @param {*} returnData_ determine whether or not the data will be returned
   * @returns
   */
  const handleHeroContentPreview = (returnData_) => {
    // Send the form data to the email template
    const form = heroContentFormRef.current

    if (form) {
      const values = {
        ...form.values,
        headerImage: '',
        agentLogo: '',
        stage: stageSelectionDataCtx.stageName,
      }

      let formattedPrice = ''
      // Adjusts in values
      if (typeof values.price === 'string') {
        formattedPrice = fromMoney(values.price)
      } else {
        formattedPrice = values.price
        values.price = toMoney(values.price)
      }

      if (values.bedrooms) {
        values.bedrooms = parseInt(values.bedrooms)
      }
      if (values.bathrooms) {
        values.bathrooms = parseInt(values.bathrooms)
      }

      const _values = {
        ...form.values,
        price: formattedPrice || null,
        bedrooms: values.bedrooms || null,
        bathrooms: values.bathrooms || null,
      }

      nextButtonHandler(_values)
      //possible validation
      // Update the preview flag
      if (!returnData_) {
        setEmailPreviewFlag(true)
      }

      // Check if the property hero image exists
      if (heroContentImages[0]) {
        values.headerImage =
          heroContentImages[0].base64 ||
          heroContentImages[0].url ||
          heroContentImages[0].photoUrl ||
          heroContentImages[0].image.photoUrl
      }

      // Check if the agent logo exists
      if (heroContentLogo[0] && !heroContentLogo[0].isNoLogo) {
        values.agentLogo = heroContentLogo[0].url
      }

      if (returnData_) {
        return values
      } else {
        setHeroContentData((prevState) => ({
          ...prevState,
          ...values,
          formattedPrice,
        }))
      }
    }
  }

  return (
    <ReactCardFlip
      isFlipped={!isEmailPreview}
      flipDirection="horizontal"
      containerStyle={{
        marginTop: 35,
        marginBottom: 25,
        overflow: 'hidden',
      }}
    >
      <div id="cardFront">
        <Grid
          className={classes.previewContainer}
          style={{ overflowY: 'hidden' }}
        >
          <Button
            startIcon={<CreateIcon size={24} />}
            title="Edit"
            className={classes.btnEdit}
            style={{
              display: 'block',
              position: 'absolute',
            }}
            onClick={() => setEmailPreviewFlag(false)}
            dataAttrs={{ metaAction: 'click-edit-hero-content' }}
          />
          <Grid item>
            <HtmlPreviewer
              html={emailHTML}
              data={{
                ...emailRTRData.emailData,
                ...HeroContentData,
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div id="cardBack">
        <Grid className={classes.formContainer}>
          <Button
            startIcon={<VisibilityIcon size={24} />}
            title="Preview"
            className={classes.btnPreview}
            style={{ display: 'block' }}
            onClick={() => handleHeroContentPreview(false)}
            dataAttrs={{ metaAction: 'click-preview-hero-content' }}
          />
          <Grid item>
            <Formik
              innerRef={heroContentFormRef}
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
            >
              {({ values, setFieldValue, errors }) => {
                const handleNumberInputChange = (e, fieldName) => {
                  const { value } = e.target

                  const selectRegex = () => {
                    switch (fieldName) {
                      case 'bathrooms':
                        return /^\d+(\.5{0,1})?$/
                      case 'bedrooms':
                        return /^\d+?$/
                      default:
                        return /^\d+(\.5{0,1})?$/
                    }
                  }

                  if (selectRegex().test(value.toString()) || value === '') {
                    setFieldValue(fieldName, value)
                  }
                }
                const handleTextChange = (e, fieldName) => {
                  const { value } = e.target

                  setFieldValue(fieldName, value)
                  nextButtonHandler({
                    ...values,
                    [fieldName]: e.target.value,
                  })
                }
                return (
                  <Form>
                    <div style={{ marginBottom: '20px' }}>
                      <ImageSelector
                        isLogoSelector
                        imgMaxQuantity={1}
                        images={heroContentLogo}
                        setImages={setHeroContentLogo}
                        gallery={agentLogos}
                        isEmbedded={isEmbedded}
                      />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <ImageSelector
                        imgMaxQuantity={1}
                        images={heroContentImages || []}
                        setImages={setHeroContentImages}
                        isEmbedded={isEmbedded}
                      />
                    </div>
                    <FormikInput
                      id="heading"
                      name="heading"
                      label="Headline*"
                      as="textarea"
                      placeholder="e.g Modern Luxury and Timeless Elegance"
                      rows="4"
                      maxLength="45"
                      lengthCounting={`${values?.heading?.length}/45`}
                      value={values.heading}
                      onChange={(e) => handleTextChange(e, 'heading')}
                      error={errors.heading}
                    />
                    <FormikInput
                      id="price"
                      name="price"
                      label="List price"
                      placeholder="$0,000,000"
                      rows="1"
                      maxLength={999999999}
                      error={errors.price}
                      setFieldValue={setFieldValue}
                      value={values.price}
                      isCurrency
                    />
                    <Grid container className={classes.grid}>
                      <FormikInput
                        id="bedrooms"
                        as="input"
                        name="bedrooms"
                        label="Bed"
                        onChange={(e) => handleNumberInputChange(e, 'bedrooms')}
                        placeholder="e.g. 2"
                        rows="1"
                        error={errors.bedrooms}
                      />
                      <FormikInput
                        id="bathrooms"
                        name="bathrooms"
                        label="Bath"
                        onChange={(e) =>
                          handleNumberInputChange(e, 'bathrooms')
                        }
                        placeholder="e.g. 2"
                        rows="1"
                        error={errors.bathrooms}
                      />
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </ReactCardFlip>
  )
}
