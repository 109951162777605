import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { handleLeaveWebview } from '../../services/appServices'
import { useContentGridContext } from '../../context/ContentGridContext'
import { useRequestContext } from '../../context/RequestContext'

const styles = {
  iconText: {
    fontFamily: 'StyreneMedium',
    fontSize: '14px',
    cursor: 'pointer',
  },
}

export default function HeaderNavRightIcon({ pathname }) {
  const { setCurrentFlow, isEmbedded } = useRequestContext()
  const {
    isSelecting,
    setIsSelecting,
    setSelectedImages,
  } = useContentGridContext()
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const currentPage = pathname.split('/')[pathname.split('/').length - 1]

  const handleLeaveSubApp = () => {
    window.location.href = `${window.location.origin}/account`
  }

  const handleEnteringSelectionMode = () => {
    setCurrentFlow('Select Content')
    setIsSelecting(true)
  }

  const handleLeavingSelectionMode = () => {
    setCurrentFlow('Content Library')
    setIsSelecting(false)
    setSelectedImages([])
  }

  const handleDoneButtonClick = (...args) => {
    if (isEmbedded) {
      return handleLeaveWebview(...args)
    } else {
      return handleLeaveSubApp(...args)
    }
  }

  const blankIconPages = [
    '/',
    'signage',
    'propertyselection',
    'installsignage',
    'outdoordetails',
  ]

  const doneIconPages = ['requestreceived']

  let component
  if (pathname.match(/\/content/) && !isSelecting) {
    component = (
      <Typography
        className={classes.iconText}
        onClick={handleEnteringSelectionMode}
      >
        Select
      </Typography>
    )
  } else if (pathname.match(/\/content/) && isSelecting) {
    component = (
      <Typography
        className={classes.iconText}
        onClick={handleLeavingSelectionMode}
      >
        Exit
      </Typography>
    )
  } else if (blankIconPages.includes(currentPage)) {
    component = ''
  } else if (doneIconPages.includes(currentPage)) {
    component = (
      <Typography className={classes.iconText} onClick={handleDoneButtonClick}>
        Done
      </Typography>
    )
  } else {
    component = (
      <Typography
        className={classes.iconText}
        onClick={() => alert('not available yet')}
      />
    )
  }

  return <>{component}</>
}
