import React, { createContext, useContext, useState } from 'react'

const AuthContext = createContext({})

export const useAuthContext = () => useContext(AuthContext)

export default function AuthContextProvider({ children }) {
  const [agentId, setAgentId] = useState(null)
  const [isAuthed, setIsAuthed] = useState(false)
  const [authSBL, setAuthSBL] = useState(null)
  const [agentData, setAgentData] = useState(null)

  return (
    <AuthContext.Provider
      value={{
        agentId,
        setAgentId,
        isAuthed,
        setIsAuthed,
        authSBL,
        setAuthSBL,
        agentData,
        setAgentData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
