import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import { Document, Page } from 'react-pdf'
import Text from '../common/Text'

const styles = (theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr 40px',
  },
  button: {
    outline: 0,
    transition: 'all .5s',
    border: 0,
    borderRadius: 35,
    background: '#2a29a6',
    color: 'white',
    fontSize: 12,
    minWidth: 30,
    minHeight: 30,
    cursor: 'pointer',
    '&:disabled': {
      background: 'rgba(42, 41, 166, 0.45)',
    },
  },
  footer: {
    marginTop: 2,
    gridColumn: '1 / 4',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
})
export default function PDFViewer({ source, ...props }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [numberOfPages, setNumberOfPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [isPDFLoading, setIsPDFLoading] = useState(true)

  function onDocumentLoadSuccess({ numPages }) {
    setNumberOfPages(numPages)
    setPageNumber(1)
    setIsPDFLoading(false)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <Grid className={classes.container}>
      <Grid container alignItems="center">
        {numberOfPages > 1 && (
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className={classes.button}
          >
            <ChevronLeft />
          </button>
        )}
      </Grid>
      <Document file={source} onLoadSuccess={onDocumentLoadSuccess} {...props}>
        <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
      </Document>
      <Grid container alignItems="center" justify="flex-end">
        {numberOfPages > 1 && (
          <button
            type="button"
            className={classes.button}
            disabled={pageNumber >= numberOfPages}
            onClick={nextPage}
          >
            <ChevronRight />
          </button>
        )}
      </Grid>
      <Grid className={classes.footer}>
        {!isPDFLoading && (
          <Text type="muted" fontSize="14">
            Page {pageNumber || (numberOfPages ? 1 : '--')} of{' '}
            {numberOfPages || '--'}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}
