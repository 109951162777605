import React from 'react'
import TextAreaInput from '../../../../components/common/TextAreaInput'

const TextAreaField = ({ field, onFieldChange, type, value, error }) => {
  const { placeholder = '' } = field?.metadata
  return (
    <div key={field._uid}>
      <TextAreaInput
        label={field.label}
        name={field.name}
        type={type}
        value={value}
        error={error}
        placeholder={placeholder}
        {...field}
        onChange={(e) => {
          onFieldChange(
            field._uid,
            { value: e.target.value, ...field },
            field.label,
          )
        }}
      />
    </div>
  )
}

export default TextAreaField
