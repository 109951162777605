import React from 'react'
import { Box, Grid } from '@material-ui/core'

import Text from '../../common/Text'
import Accordion from '../../common/Accordion'
import Button from '../../common/Button'
import { useListingMlsImagesContext } from '../../../context/ListingMlsImagesContext'
import { IMPORTING_TYPES } from '../../../utils/constants'

const ImportFromGalleryAuxiliaryButton = ({
  importingType,
  quantityToShow,
  setQuantityToShow,
}) => {
  const { listingMlsImages } = useListingMlsImagesContext()

  switch (importingType) {
    case IMPORTING_TYPES.Logo:
      return (
        <Grid style={{ maxWidth: 230 }}>
          <Accordion
            accordions={[
              {
                id: 1,
                title: "Don't see your logo?",
                body: (
                  <Box textAlign="center">
                    <Text type="muted" component="span">
                      If you have a custom logo for your DBA or realtor license
                      and don’t see it here, please contact your{' '}
                    </Text>
                    <Text
                      type="muted"
                      component="span"
                      fontSize="13px"
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                      }}
                    >
                      Agent Experience Manager
                    </Text>
                    <Text type="muted" component="span">
                      {' '}
                      for more details.
                    </Text>
                  </Box>
                ),
              },
            ]}
          />
        </Grid>
      )

    case IMPORTING_TYPES.MLS:
      return (
        <Grid
          container
          justify="center"
          style={{ marginBottom: 20, height: 35 }}
        >
          <Button
            clickHandler={() => {
              setQuantityToShow((quantityToShow) => quantityToShow + 6)
            }}
            title="Load More"
            muted
            noBorder
            disabled={quantityToShow >= listingMlsImages.length}
            fullWidth
          />
        </Grid>
      )

    default:
      return <h5>Oops</h5>
  }
}

export default ImportFromGalleryAuxiliaryButton
