import { React, memo } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import { HtmlFormatter } from '../../utils/htmlFormatter'

const styles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    padding: '0',
  },
}

function HtmlPreviewer({ html, data }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    // Define the component container
    <Grid container direction="column" className={classes.container}>
      <div className="App">
        <div
          dangerouslySetInnerHTML={{
            __html: HtmlFormatter(html, data),
          }}
        />
      </div>
    </Grid>
  )
}

export default memo(HtmlPreviewer)
