import React from 'react'
import { Typography } from 'av8-ui'
import { Formik, Field, FormikHelpers, FormikErrors } from 'formik'
import { SelectContainer } from '../../../../../../components/common/FormikSelect.style'
import { DebouncedSubmitOnChangeFormik } from '../../../../../shared/components/Formik/DebouncedSubmitOnChangeFormik'
import { PrintNeedsFormStyles } from './PrintNeedsForm.styles'

type PaperWeightType = 'standard'
type PaperFinishType = 'standard' | 'glossy' | string

export const emptyPrintNeedsFormRecord: (size?: string) => PrintNeedsRecord = (
  size = '7x7',
) => {
  return {
    values: {
      paperFinishType: 'standard',
      paperWeightType: 'standard',
      quantity: '',
      size: size,
    },
    errors: {},
  }
}
export type PrintNeedsRecord = {
  values: {
    quantity: string
    size: string
    paperWeightType: PaperWeightType
    paperFinishType: PaperFinishType
  }
  errors: FormikErrors<PrintNeedsFormValue>
}

export type PrintNeedsFormValue = PrintNeedsRecord['values']

export type PrintNeedsFormProps = {
  paperSizeList: string[]
  paperWeightList: string[]
  paperFinishList: string[]
  maxQuantity?: number
  disableQuantity?: boolean
  disablePaperSize?: boolean
  disablePaperWeightType?: boolean
  disablePaperFinishType?: boolean
  value?: PrintNeedsFormValue
  onChange?: (
    value: Partial<PrintNeedsFormValue>,
    errors?: FormikErrors<PrintNeedsFormValue>
  ) => void
}

export const PrintNeedsForm: React.FC<PrintNeedsFormProps> = (props) => {
  const classes = PrintNeedsFormStyles()

  const initialValues: PrintNeedsFormValue = {
    quantity: props.value?.quantity ?? '',
    paperFinishType: props.value?.paperFinishType ?? 'glossy',
    paperWeightType: props.value?.paperWeightType ?? 'standard',
    size: props.value?.size ?? '4x6',
  }

  const handleSubmit = async (
    values: Partial<PrintNeedsFormValue>,
    helpers: FormikHelpers<PrintNeedsFormValue>,
  ) => {
    const errors = await helpers.validateForm(values)
    helpers.setErrors(errors)
    if (props.onChange) {
      props.onChange({ ...(props.value ?? {}), ...values }, errors)
    }
  }

  const handleValidation = (values: PrintNeedsFormValue) => {
    const errors: FormikErrors<PrintNeedsFormValue> = {}

    if (
      isNaN(values.quantity as any) ||
      !Number.isInteger(parseFloat(values.quantity)) ||
      /(^\d{1,4}$)/.test(values.quantity) === false ||
      parseFloat(values.quantity) <= 0
    ) {
      if (props.disableQuantity)
        errors.quantity = 'Please check your mailing options'
      else errors.quantity = 'Invalid quantity'
    } else if (
      props.maxQuantity &&
      parseInt(values.quantity) > props.maxQuantity
    ) {
      errors.quantity = `Quantity should be equal or below ${props.maxQuantity}`
    }

    return errors
  }

  const renderOption = (
    title: string,
    name: string,
    list: string[],
    disabled = false,
  ) => (
    <div className={classes.FlexRow}>
      <div className={classes.FieldContainer}>
        <Typography
          className={[classes.MutedText, classes.SectionTitle].join(' ')}
        >
          {title}
        </Typography>
        <SelectContainer style={{ width: '100%' }}>
          <div className={['select', classes.SelectField].join(' ')}>
            <Field
              as="select"
              name={name}
              type="input"
              id={name}
              disabled={disabled}
            >
              {list.map((value, idx) => (
                <option key={`${name}-option-${idx}`} value={value}>
                  {value}
                </option>
              ))}
            </Field>
          </div>
        </SelectContainer>
      </div>
    </div>
  )

  const renderQuantity = (errors: FormikErrors<PrintNeedsFormValue>) => {
    return (
      <div className={classes.FlexRow}>
        <div className={classes.FieldContainer}>
          <Typography
            className={[classes.MutedText, classes.SectionTitle].join(' ')}
          >
            Quantity
          </Typography>
          <Field
            disabled={props.disableQuantity}
            className={classes.InputField}
            type={'number'}
            name={'quantity'}
            placeholder={'Example: 100'}
            isCurrency={false}
            min="1"
            error={errors.quantity}
          />
          {errors.quantity && (
            <span className={classes.InputErrorMessage} data-meta-name="error">
              {errors.quantity}
            </span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidation}
        enableReinitialize
        validateOnBlur
      >
        {({ errors }) => (
          <form className={classes.Form}>
            <DebouncedSubmitOnChangeFormik debounceMs={500} />
            {renderQuantity(errors)}
            {renderOption(
              'Paper Size:',
              'size',
              props.paperSizeList,
              props.disablePaperSize,
            )}
            {renderOption(
              'Paper Weight:',
              'paperweight',
              props.paperWeightList,
              props.disablePaperWeightType,
            )}
            {renderOption(
              'Paper Finish:',
              'paperfinish',
              props.paperFinishList,
              props.disablePaperFinishType,
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}
