import { contentGridApi } from './api'

export const getAgentLogos = async (agentId) => {
  try {
    const { data } = await contentGridApi.get(
      `/template-logos?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}
