import React, { useState, useContext, createContext } from 'react'
import { RtrTemplate } from '../../../../components/TemplatePreview/TemplateRTRPreview'
import {
  emptyPrintNeedsFormRecord,
  PrintNeedsRecord,
} from '../PrintNeedsForm/PrintNeedsForm'

export type BuildAListSectionDataRecord = {
  recordCount?: number
  values: {
    zip: string
    distance: string
  }
  errors: {
    zip?: string
    distance?: string
  }
}

type UploadListDataType = {
  CSVList: File
  reach: number
}

export type MailingSectionDataType = {
  section: string
  data: BuildAListSectionDataRecord | UploadListDataType | any
}

export type CustomizePrintModalFormContextType = {
  cleanOptions: () => void
  printingAndDesignSectionData: string
  setPrintingAndDesignSectionData: (value: string) => void
  mailingSectionData: MailingSectionDataType
  setMailingSectionData: (value: MailingSectionDataType) => void
  printNeedsSectionData: PrintNeedsRecord
  setPrintingNeedsSectionData: (value: PrintNeedsRecord) => void
  shippingSectionData: string
  setShippingSectionData: (value: string) => void
  rtrTemplate: RtrTemplate
  setRtrTemplate: (template: RtrTemplate) => void
}

const CustomizePrintModalFormContext =
  createContext<CustomizePrintModalFormContextType>({
    cleanOptions: () => {},
    printingAndDesignSectionData: '',
    setPrintingAndDesignSectionData: () => {},
    mailingSectionData: { section: 'mailing', data: {} },
    setMailingSectionData: () => [],
    printNeedsSectionData: emptyPrintNeedsFormRecord(),
    setPrintingNeedsSectionData: () => [],
    shippingSectionData: '',
    setShippingSectionData: () => {},
    setRtrTemplate: () => {},
    rtrTemplate: {} as any,
  })

export const usePTRFormContextProvider =
  (): CustomizePrintModalFormContextType =>
    useContext<CustomizePrintModalFormContextType>(
      CustomizePrintModalFormContext,
    )

export const PTRFormContextProvider: React.FC = ({ children }) => {
  const [printingAndDesignSectionData, setPrintingAndDesignSectionData] =
    useState('')
  const [mailingSectionData, setMailingSectionData] =
    useState<MailingSectionDataType>({ section: '', data: {} })
  const [printNeedsSectionData, setPrintingNeedsSectionData] =
    useState<PrintNeedsRecord>(emptyPrintNeedsFormRecord())
  const [shippingSectionData, setShippingSectionData] = useState<string>('')
  const [rtrTemplate, setRtrTemplate] = useState<RtrTemplate>({} as any)

  const cleanOptions = () => {
    setMailingSectionData({ section: '', data: {} })
  }

  return (
    <CustomizePrintModalFormContext.Provider
      value={{
        cleanOptions,
        printingAndDesignSectionData,
        setPrintingAndDesignSectionData,
        mailingSectionData,
        setMailingSectionData,
        printNeedsSectionData,
        setPrintingNeedsSectionData,
        shippingSectionData,
        setShippingSectionData,
        rtrTemplate,
        setRtrTemplate,
      }}
    >
      {children}
    </CustomizePrintModalFormContext.Provider>
  )
}
