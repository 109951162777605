import React, { useState, useEffect } from 'react'
import ReactCardFlip from 'react-card-flip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateIcon from '@material-ui/icons/Create'
import Button from '../../common/Button'
import ImageSelector from './ImageSelector'
import DynamicFormGenerator from '../DynamicFormGenerator'
import HtmlPreviewer from '../../common/HtmlPreviewer'
import { makeStyles, Grid } from '@material-ui/core'
import { getTemplateModalOptions } from '../../../services/marketingServices'
import { useAuthContext } from '../../../context/AuthContext'
import { useEmailRTRContext } from '../../../context/EmailRTRContext'
import { reduceKeyValueArrayToOptionObject } from '../../../utils/utils'
import { useListingDescriptionContext } from '../../../context/ListingDescriptionContext'
import { useTemplateTypesContext } from '../../../context/TemplateTypesContext'
import Loader from '../Loader'
import Modal from '../../common/Modal'
import { useRequestContext } from '../../../context/RequestContext'

export default function EmailBody({
  step,
  imgMaxQuantity,
  emailHTML,
  isEmailPreview,
  setEmailPreviewFlag,
  styles,
  isEditing,
}) {
  // Styles handlers
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Context data
  const { templateTypes } = useTemplateTypesContext()

  const { listingDescription: listingDescriptionCtx } = useListingDescriptionContext()
  const { emailRTRData, setEmailBodyData, EmailBodyData } = useEmailRTRContext()

  //formData initial values
  const initialFormData = {
    description: {
      value: EmailBodyData?.description || '',
    },
    propertyUrl: {
      value:
        EmailBodyData.propertyUrl && isEditing ? EmailBodyData.propertyUrl : '',
    },
    agentUrl: {
      value: EmailBodyData.agentUrl && isEditing ? EmailBodyData.agentUrl : '',
    },
    featuresList: {
      value:
        EmailBodyData.featuresList && isEditing
          ? [...EmailBodyData.featuresList]
          : [],
    },
  }

  const { isEmbedded } = useRequestContext()

  // Dynamic form handlers
  const { agentId } = useAuthContext()
  const [options, setOptions] = useState([])
  const [emailPreviewStatus, setEmailPreviewStatus] = useState({
    show: false,
    loading: false,
  })
  const [formData, setFormData] = useState(initialFormData)
  const [placeHolder, setPlaceHolder] = useState(isEditing ? false : true)

  // Property images handler
  const [emailBodyImages, setEmailBodyImages] = useState(
    isEditing
      ? emailRTRData.emailData.emailBodyImages
      : listingDescriptionCtx.images.slice(0, imgMaxQuantity),
  )
  // Try to load the dynamic form options
  useEffect(() => {
    const fetchModalOptions = async () => {
      const templateType = templateTypes.find(
        (templateType) => templateType.key === 'email-rtr',
      )
      const response = await getTemplateModalOptions(agentId, templateType.id)

      if (response.success) {
        setOptions(response.data)
      }
    }
    fetchModalOptions()
  }, [])

  /**
   * Function to handle the Hero Content Preview
   *
   * @param {*} returnData_ determine whether or not the data will be returned
   * @returns
   */
  const handleEmailBodyPreview = (returnData_) => {
    // Update the preview flag
    if (!returnData_) {
      setEmailPreviewFlag(true)
    }

    // Create the structure to get the formData values
    const formValues = []

    if (formData) {
      // Loop over the formData and get its values
      const isFormEdited =
        formData != {}
          ? Object.keys(formData).some(function (key) {
              return formData[key].value !== ''
            })
          : false

      if (isFormEdited) {
        Object.entries(formData).forEach(([fieldName, fieldValuesObj], idx) => {
          formValues[idx] = { [fieldName]: fieldValuesObj.value }
        })
        setPlaceHolder(false)
      }

      // Update the current email body images
      if (emailBodyImages) {
        const lastIdx = formValues.length + 1
        formValues[lastIdx] = {
          listingImages: [],
        }
        emailBodyImages.map((item, idx) => {
          formValues[lastIdx]['listingImages'][idx] =
            item.base64 || item.url || item.image.photoUrl || item.photoUrl
        })
        formValues[lastIdx + 1] = {
          emailBodyImages: emailBodyImages,
        }
      }
      setEmailBodyData((prevState) => ({
        ...prevState,
        ...reduceKeyValueArrayToOptionObject(formValues),
      }))
    }
  }

  /**
   * Update the email body preview in real-time when a given form field
   * is updated
   */
  useEffect(() => {
    handleEmailBodyPreview(true, placeHolder)
  }, [isEmailPreview, emailBodyImages])

  useEffect(() => {
    const currentStep = 'Details'
    if (step !== currentStep) handleEmailBodyPreview(true, placeHolder)
  }, [step])

  useEffect(() => {
    if (isEmailPreview) {
      setEmailPreviewStatus(true)
      setTimeout(() => {
        setEmailPreviewStatus(false)
      }, 1000)
    }
  }, [isEmailPreview])

  return (
    <>
      {emailPreviewStatus && (
        <Modal open={emailPreviewStatus}>
          <Loader
            loading={emailPreviewStatus}
            text={'Re-rendering...'}
            setLoadingStatus={() => {}}
            dataAttrs={{ metaType: 'modal' }}
            onClose={() => {}}
            closeTitle={''}
          />
        </Modal>
      )}
      <ReactCardFlip
        isFlipped={!isEmailPreview}
        flipDirection="horizontal"
        containerStyle={{
          marginTop: 35,
          marginBottom: 25,
          overflow: 'hidden',
        }}
      >
        <div id="cardFront">
          <Grid>
            <Button
              startIcon={<CreateIcon size={24} />}
              title="Edit"
              className={classes.btnPreview}
              style={{
                display: 'block',
                position: 'absolute',
                marginTop: '6.5%',
              }}
              onClick={() => setEmailPreviewFlag(false)}
              dataAttrs={{ metaAction: 'click-edit-body-content' }}
            />
          </Grid>
          <Grid
            className={classes.previewContainer}
            style={{ paddingTop: '80px' }}
          >
            <HtmlPreviewer html={emailHTML} data={emailRTRData.emailData} />
          </Grid>
        </div>

        <div id="cardBack">
          <Grid className={classes.formContainer}>
            <Button
              startIcon={<VisibilityIcon size={24} />}
              title="Preview"
              className={classes.btnPreview}
              style={{ display: 'block' }}
              onClick={() => handleEmailBodyPreview(false, false)}
              dataAttrs={{ metaAction: 'click-preview-body-content' }}
            />
            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
              {imgMaxQuantity > 0 && (
                <ImageSelector
                  imgMaxQuantity={imgMaxQuantity}
                  images={emailBodyImages}
                  setImages={setEmailBodyImages}
                  isEmbedded={isEmbedded}
                />
              )}
            </div>
            <div>
              {formData && options && (
                <>
                  <DynamicFormGenerator
                    formData={formData}
                    setFormData={setFormData}
                    options={options}
                    isEmailPreview={isEmailPreview}
                  />
                </>
              )}
            </div>
          </Grid>
        </div>
      </ReactCardFlip>
    </>
  )
}
