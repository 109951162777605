import React from 'react'
import { Grid, Box } from '@material-ui/core'
import Image from '../../../../../components/common/Image'
import Text from '../../../../../components/common/Text'
import OptionsPresentation from '../../../../../components/common/OptionsPresentation'
import { StrapiImage } from '../../../components/TemplatePreview/TemplateRTRPreview'

type PrintTemplateReviewPresentationProps = {
  template: StrapiImage & {
    formData: {
      template: Record<string, any>
      presentationData: Record<string, any>
      metadata: Record<string, any>
    }
  }
}

const acceptedMetadataPresentationTypes = ['front', 'back', 'outside', 'inside']

export const PrintTemplateReviewPresentation = ({
  template,
}: PrintTemplateReviewPresentationProps): JSX.Element => {
  const printTemplateOptionsData = template?.formData?.presentationData || {}
  // Sort following accepet Metadata order, filter accepeted types and map to get urls
  const childrenImages = Object.keys(template?.formData?.metadata || {})
    .sort(function (a, b) {
      return (
        acceptedMetadataPresentationTypes.indexOf(a) -
        acceptedMetadataPresentationTypes.indexOf(b)
      )
    })
    .filter((metadata) => acceptedMetadataPresentationTypes.includes(metadata))
    .map((item) => template?.formData?.metadata[item])

  return (
    <Grid key={`${template.url}`} style={{ marginTop: 20 }}>
      <OptionsPresentation options={printTemplateOptionsData} />
      <Grid
        container
        justify='center'
        direction='column'
        alignItems='center'
        style={{ marginTop: 30 }}
      >
        {template.isRTR && template?.layers?.length ? (
          childrenImages.map((url: string) => (
            <Box my={3} key={url} boxShadow={1}>
              <img src={url} width={'100%'} />
            </Box>
          ))
        ) : (
          <Image medium src={template.url} />
        )}
        <Text type='blue' style={{ marginTop: 10 }}>
          {template.name || ''}
        </Text>
      </Grid>
    </Grid>
  )
}
