/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RTREngine } from 'av8-rtrt-renderer'
import { useRTRenderParams } from '../../../hooks/useRTRender'
import { RenderedTemplateImage, useRTRStore } from '../store/rtr.store'
import { uploadTemplateImage } from '../utils/uploadImage'
import { generateRTRImageParamsHash } from '../utils/generateRTRImageParamsHash'
import { RTRTemplateData } from '../utils/formatTemplateData'
import {
  renderPngInBackend,
  svgToBlobUrl,
} from '../../shared/utils/svgRtrConverters'
import { swapPropertyImageForCropped } from '../utils/swapPropertyImageForCropped'
import { v4 as uuidv4 } from 'uuid'

export type RenderHighResolutionTemplateParams<T = RTRTemplateData> =
  useRTRenderParams<T> & {
    index: number
    agentId: number
    ignoreCache?: boolean
  }

const engine: RTREngine = new RTREngine()
engine.setup()

export async function renderHighResolutionTemplate(
  params: RenderHighResolutionTemplateParams,
): Promise<string> {
  const { index, agentId, id, layers, data, ignoreCache = false } = params
  const paramsHash = generateRTRImageParamsHash(params)

  const savedImage: RenderedTemplateImage =
    useRTRStore.getState().images[id]?.[paramsHash]

  if (savedImage && !ignoreCache) {
    return svgToBlobUrl(savedImage)
  }

  let { width = undefined, height = undefined } = params

  if (!width && !height) {
    width = layers[0].width
    height = layers[0].height
  }

  const aspectRatio = layers[0].width / layers[0].height

  if (!width) throw Error('At least one dimension must be informed')

  if (!height) height = width / aspectRatio

  const templateData = swapPropertyImageForCropped(data)

  const image = await engine.render(layers, templateData, width, height)
  const renderedImage = await renderPngInBackend(image)

  const fileBlob = new File([renderedImage.blob], `photo${index}.png`, {
    type: 'image/png',
  })

  const response = await uploadTemplateImage(agentId, fileBlob)

  useRTRStore.getState().setRTRImage(id, paramsHash, image)

  return response.url
}

export async function renderHighResolutionSvg(params: {
  image: SVGElement
  agentId: number
  width?: any
  height?: any
}): Promise<string> {
  const { agentId, image: original, width, height } = params

  const image = original.cloneNode(true) as SVGElement
  // Pre-generation image adjustment

  image.setAttribute('width', width ?? '100%')
  image.setAttribute('height', height ?? '100%')

  const renderedImage = await renderPngInBackend(image)

  const fileBlob = new File([renderedImage.blob], `${uuidv4()}.png`, {
    type: 'image/png',
  })

  const response = await uploadTemplateImage(agentId, fileBlob)

  return response.url
}
