import React from 'react'
import { Grid } from '@material-ui/core'
import Text from './Text'
import { generateDataAttrs } from '../../utils/utils'

export default function ErrorMsg({
  errorMsg = 'Oops, something went wrong...',
  subErrorMsg = 'Please try again later.',
}) {
  return (
    <Grid
      {...generateDataAttrs({
        metaName: 'error-message',
      })}
    >
      <Text>{errorMsg}</Text>
      <Text type="muted">{subErrorMsg}</Text>
    </Grid>
  )
}
