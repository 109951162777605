import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Select from '../../../../components/common/Select'
import Text from '../../../../components/common/Text'

const SelectField = ({ field, onFieldChange, value, error }) => {
  useEffect(() => {
    //This is used to set the first option as default value in case users doesn't change it
    if (value) return

    onFieldChange(
      field._uid,
      { value: field.options[0].value, ...field },
      field.label,
    )
  }, [])
  return (
    <Grid data-meta-name={field.name} data-meta-type='checkbox-group'>
      <Select
        label={field.label}
        options={field.options}
        name={field.name}
        key={field.label}
        value={value}
        onChange={(e) => {
          onFieldChange(
            field._uid,
            { value: e.target.value, ...field },
            field.label,
          )
        }}
      />
      <Text type='error' style={{ marginBottom: 15 }}>
        {error}
      </Text>
    </Grid>
  )
}

export default SelectField
