import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const TemplateStepperStyles = makeStyles((theme?: Theme) => {
  const containerSm = theme ? {
    [theme.breakpoints.up('sm')]: {
      marginTop: '0%',
    },
  } : {}

  return {
    container: {
      width: '100%',
      marginBottom: 16,
      ...containerSm,
    },
    stepContainer: {
      margin: 0,
    },
    bulletDivider: {
      borderTop: '1px solid \'var(--blue)',
      opacity: '0.35',
      margin: '-4px -8px 0 -8px',
    },
    numericDivider: {
      borderTop: '1px solid \'var(--blue)',
      opacity: '0.35',
    },
    backButton: {
      '&:disabled': {
        border: '2px solid rgba(42, 41, 166, 0.15)',
        color: 'rgba(42, 41, 166, 0.35)',
      },
    },
    backButtonBullet: {
      width: '130px!important',
      '&:disabled': {
        border: '2px solid rgba(42, 41, 166, 0.15)',
        color: 'rgba(42, 41, 166, 0.35)',
      },
    },
    nextButtonBullet: {
      width: '130px!important',
    },
  }
})
