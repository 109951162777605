import React, { useState, useCallback } from 'react'
import update from 'immutability-helper'
import { Grid, makeStyles } from '@material-ui/core'
import ReorderIcon from '@material-ui/icons/Reorder'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { usePackageDetailsContext } from '../../context/PackageDetailsContext'
import Image from '../common/Image'
import Text from '../common/Text'
import Modal from '../common/Modal'
import MLSLoader from './MLSLoader'
import DndItem from './DragNDrop/DnDItem'
import { isSocialMedia } from '../../utils/filters'
import DnDProvider from '../custom/DnDProvider'
import { generateDataAttrs } from '../../utils/utils'
import { RTREngine } from 'av8-rtrt-renderer'

const styles = (theme) => ({
  container: {
    marginTop: 30,
  },
  imageGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 10,
    rowGap: 10,
    margin: '10px 0 40px 0',
  },
  gridItem: {
    height: 125,
  },
  nonDraggableContainer: {
    width: 115,
    height: 75,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 145,
    },
  },
  hiddenImageOverlay: {
    position: 'absolute',
    width: 115,
    height: 75,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    color: '#A6A6A6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 145,
    },
  },
  imageContainer: {
    position: 'relative',
    width: '115px',
    height: '75px',
    borderRadius: 5,
    marginBottom: 5,
    cursor: 'move',
    border: '2px solid white',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.up('sm')]: {
      width: 145,
    },
  },
  reOrderIcon: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    zIndex: 3,
    color: 'white',
    fontSize: '16',
  },
  draggableImage: {
    width: '115px',
    height: '75px',
    pointerEvents: 'none',
    borderRadius: 5,
    filter: 'brightness(0.5)',
    [theme.breakpoints.up('sm')]: {
      width: 145,
    },
  },
})

export default function DnDGrid({ cover, images = [] }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const {
    packageDetailsData: packageDetailsDataCtx,
    setPackageDetailsData: setPackageDetailsDataCtx,
  } = usePackageDetailsContext()

  const [gridImages, setGridImages] = useState([cover, ...images])
  const [isReArranging, setIsReArranging] = useState(false)
  const [loadingReRenderStatus, setLoadingReRenderStatus] = useState({
    loading: false,
    message: '',
    show: false,
  })

  const handleReArrangeClick = () => {
    setIsReArranging((isReArranging) => !isReArranging)
  }

  const updateTemplateContext = (data) => {
    const { digital } = packageDetailsDataCtx

    const socialMediaCategoryIndex = digital.findIndex(isSocialMedia)
    const templateId = digital[socialMediaCategoryIndex].rtrTemplates.findIndex(
      (c) => c.id === cover.id,
    )

    const updateCurrentState = (initial) => {
      return update(initial, {
        digital: {
          [socialMediaCategoryIndex]: {
            rtrTemplates: {
              [templateId]: {
                $apply: function (item) {
                  return {
                    ...item,
                    ...data,
                  }
                },
              },
            },
          },
        },
      })
    }

    setPackageDetailsDataCtx(updateCurrentState)
  }

  const handleDoneClick = async () => {
    setLoadingReRenderStatus({
      message: 'Re-rendering',
      loading: true,
      show: true,
    })

    //build new cover image
    const { layers, dataUsedForRender = {} } = cover

    const newCoverImage = gridImages[0].baseUrl ?? gridImages[0].url

    const engine = new RTREngine()
    engine.setup()

    const newDataUsedForRender = {
      ...dataUsedForRender,
      propertyImage: newCoverImage,
    }

    const imagePreviewSVG = await engine.render(
      layers,
      newDataUsedForRender,
      1080,
      1080,
    )
    // Post-generation image adjustment
    imagePreviewSVG?.setAttribute('width', '100%')
    imagePreviewSVG?.setAttribute('height', '100%')

    //re-order and update images get all images besides the cover
    // Use baseUrl instead of url, as this image
    // could be a RTR prior to re-arranging
    // For carousel images, we don't need anything
    // but the URL and isHidden
    const [, ...rest] = gridImages.map(
      ({ baseUrl, url, isHidden = false }) => ({
        url: baseUrl ?? url,
        isHidden,
      }),
    )

    updateTemplateContext({
      url: imagePreviewSVG,
      dataUsedForRender: newDataUsedForRender,
      baseUrl: newCoverImage,
      carouselImages: [...rest],
    })

    setLoadingReRenderStatus({
      message: '',
      loading: false,
      show: false,
    })

    setIsReArranging((isReArranging) => !isReArranging)
  }

  const handleHideClick = (url, currentStatus) => {
    const updatedGridImages = gridImages.map((image) => {
      if (image.url === url) {
        return { ...image, isHidden: !currentStatus }
      }
      return image
    })

    setGridImages(updatedGridImages)
    updateTemplateContext({
      carouselImages: updatedGridImages.filter((_, i) => i !== 0),
    })
  }

  const moveAndUpdateImages = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = gridImages[dragIndex]
      setGridImages(
        update(gridImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [gridImages],
  )

  const showArrangeButton =
    gridImages?.filter((c) => !c.isHidden)?.length > 1 ?? true

  return (
    <>
      <Modal open={loadingReRenderStatus.show}>
        <MLSLoader
          loading={loadingReRenderStatus.loading}
          text={loadingReRenderStatus.message}
          setModalStatus={setLoadingReRenderStatus}
        />
      </Modal>
      <Grid className={classes.container}>
        <Grid style={{ marginBottom: 20 }}>
          <Text type="muted">
            Not the correct information? Edit your information in Listing
            Details.
          </Text>
        </Grid>
        <Grid container justify="space-between">
          <Text type="muted" component="span">
            {isReArranging ? 'Tap+hold and drag to re-arrange' : 'Images'}
          </Text>
          {showArrangeButton && (
            <Text
              type="blue"
              component="span"
              style={{ cursor: 'pointer' }}
              onClick={isReArranging ? handleDoneClick : handleReArrangeClick}
              {...generateDataAttrs({
                metaAction: isReArranging
                  ? 'finish-listing-images-reordering'
                  : 'listing-images-reordering',
              })}
            >
              {isReArranging ? 'Done' : 'Re-arrange'}
            </Text>
          )}
        </Grid>
        <Grid className={classes.imageGridContainer}>
          <DnDProvider>
            {gridImages?.length &&
              gridImages?.map((image, index) => {
                const isCoverImage = index === 0
                return (
                  <Grid key={image.url} className={classes.gridItem}>
                    {isReArranging && (!image.isHidden || isCoverImage) ? (
                      <DndItem
                        key={image.id}
                        index={index}
                        id={image.id}
                        moveItems={moveAndUpdateImages}
                      >
                        <div className={classes.imageContainer}>
                          <span
                            className={classes.reOrderIcon}
                            {...generateDataAttrs({
                              metaAction: 'dnd-reorder-img',
                              metaId: image.id,
                              metaIdx: index,
                            })}
                          >
                            <ReorderIcon />
                          </span>
                          <img
                            src={image.baseUrl || image.url}
                            alt={`Slide ${index} Image`}
                            className={classes.draggableImage}
                          />
                        </div>
                      </DndItem>
                    ) : (
                      <Grid className={classes.nonDraggableContainer}>
                        <Image miniGrid src={image.baseUrl || image.url} />
                        {image.isHidden && (
                          <Grid className={classes.hiddenImageOverlay}>
                            <VisibilityOffIcon />
                          </Grid>
                        )}
                      </Grid>
                    )}
                    <Text type="regular" fontSize="12px">
                      {isCoverImage
                        ? 'Cover Image'
                        : `Slide ${index + 1} Image`}
                    </Text>
                    {isCoverImage || isReArranging ? (
                      ''
                    ) : (
                      <Text
                        type="blue"
                        fontSize="12px"
                        onClick={() =>
                          handleHideClick(image.url, image.isHidden)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {image.isHidden ? 'Show' : 'Hide'}
                      </Text>
                    )}
                  </Grid>
                )
              })}
          </DnDProvider>
        </Grid>
      </Grid>
    </>
  )
}
