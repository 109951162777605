import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../components/common/Layout'
import StepCounter from '../components/common/StepCounter'
import CustomGridContainer from '../components/common/CustomGridContainer'
import PagePresentation from '../components/common/PageTitle'
import Checkbox from '../components/common/Checkbox'
import Loading from '../components/common/Loading'
import Input from '../components/common/Input'
import { useAuthContext } from '../context/AuthContext'
import { getStageSelectionOptions } from '../services/marketingServices'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import {useStageSelectionContext} from '../context/StageSelectionContext'

export default function StageSelection() {
  const styles = (theme) => ({
    subText: {
      fontFamily: 'StyreneRegular',
      color: '#292929',
      fontSize: '12px',
      opacity: '0.65',
      marginBottom: '5px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 15,
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { flow, subflow } = useParams()
  const {
    stageSelection: stageSelectionDataCtx,
    setStageSelection: setStageSelectionDataCtx,
  } = useStageSelectionContext()
  const { agentId } = useAuthContext()
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showCustomStageInput, setShowCustomStageInput] = useState(
    stageSelectionDataCtx.value === 'custom-stage',
  )
  const [customStageInput, setCustomStageInput] = useState(
    stageSelectionDataCtx.stageName || '',
  )
  const history = useHistoryPrefix()

  useEffect(() => {
    const loadPageData = async () => {
      setLoading(true)
      const response = await getStageSelectionOptions(agentId)
      if (response.success) {
        setPageData(response.data[0])
        setLoading(false)
      }
    }
    loadPageData()
  }, [])

  const checkIfNextButtonIsDisabled = () => {
    if (showCustomStageInput) {
      return customStageInput.length < 2
    }
    return !stageSelectionDataCtx.value
  }

  const handleCheckboxChange = (event, option) => {
    const flowName = option.metadata.packages.join('')
    setStageSelectionDataCtx({
      value: event.target.value,
      selectedFlowName: flowName,
      stageName: option.label,
    })
    setShowCustomStageInput(option.value === 'custom-stage')
  }

  const handleCustomInputChange = (e) => {
    setCustomStageInput(e.target.value)
  }

  const handleNextPageClick = () => {
    if (showCustomStageInput) {
      setStageSelectionDataCtx({
        ...stageSelectionDataCtx,
        stageName: customStageInput,
      })
    }
    const { selectedFlowName: packageType } = stageSelectionDataCtx
    history.push([flow, subflow, packageType, 'listingdescription'].join('/'))
  }

  const isNextButtonDisabled = checkIfNextButtonIsDisabled()

  return (
    <Layout>
      {loading && <Loading />}
      {!loading && (
        <>
          <CustomGridContainer>
            <Grid item>
              <StepCounter
                numberOfSteps={5}
                activeStep={2}
                isNextButtonDisabled={isNextButtonDisabled}
                handleNext={handleNextPageClick}
              />
            </Grid>
            <PagePresentation
              CTATextUpper={'What stage'}
              CTATextLower={'is this listing in?'}
              component={'span'}
              isSingleLine
            />
            <Grid>
              <Typography className={classes.subText}>Select one</Typography>
              {pageData &&
                pageData.options?.map((option, i) => {
                  return (
                    <Checkbox
                      label={option.label}
                      key={option.label}
                      value={option.value}
                      defaultChecked={
                        stageSelectionDataCtx.value === option.value
                      }
                      name={'stageSelection'}
                      type={'radio'}
                      onChange={(event) => handleCheckboxChange(event, option)}
                      dataAttrs={{
                        metaName: 'listing-stage',
                        metaAction: 'select-listing-stage',
                        metaValue: option.value,
                        metaType: 'checkbox',
                        metaIdx: i + 1,
                      }}
                    />
                  )
                })}
            </Grid>
            <Grid>
              {showCustomStageInput && (
                <Input
                  name="custom-stage"
                  label="Custom Stage"
                  value={customStageInput}
                  onChange={handleCustomInputChange}
                  maxLength={20}
                  lengthCounting={`${customStageInput.length}/20`}
                />
              )}
            </Grid>
            <StepCounter
              showOnlyNavButtons
              isNextButtonDisabled={isNextButtonDisabled}
              handleNext={handleNextPageClick}
            />
          </CustomGridContainer>
        </>
      )}
    </Layout>
  )
}
