import { usePackageDetailsContext } from '../context/PackageDetailsContext'

const useUpdatePackage = () => {
  const {
    packageDetailsData: packageDetailsDataCtx,
    setPackageDetailsData: setPackageDetailsDataCtx,
  } = usePackageDetailsContext()

  const updateEntirePackageTypeData = (packageType, packageData) => {
    setPackageDetailsDataCtx({
      ...packageDetailsDataCtx,
      [packageType]: packageData,
    })
  }

  const updateCategoryFromPackage = (
    packageType,
    templateCategory,
    newCategoryData,
  ) => {
    const newPackageDetailsData = packageDetailsDataCtx[packageType].map(
      (category) => {
        if (category.templateCategory === templateCategory) {
          return newCategoryData
        }
        return category
      },
    )
    updateEntirePackageTypeData(packageType, newPackageDetailsData)
  }

  const updateImagesFromCategory = (packageType, templateCategory, image) => {
    const newPackageDetailsData = packageDetailsDataCtx[packageType].map(
      (_category) => {
        if (_category.templateCategory === templateCategory) {
          const newImagesArray = _category.images.map((_image) => {
            if (_image.itemId === image.itemId) {
              return image
            }
            return _image
          })
          return { ..._category, images: newImagesArray }
        }
        return _category
      },
    )
    updateEntirePackageTypeData(packageType, newPackageDetailsData)
  }

  const updateChildFromRTR = (packageType, templateCategory, child) => {
    const newPackageDetailsData = packageDetailsDataCtx[packageType].map(
      (_category) => {
        if (_category.templateCategory === templateCategory) {
          return {
            ..._category,
            images: _category.images.map((_image) => {
              return {
                ..._image,
                children: _image.children?.map((_child) => {
                  if (_child.id === child.id) {
                    return child
                  }
                  return _child
                }),
              }
            }),
          }
        }
        return _category
      },
    )
    // return newPackageDetailsData
    updateEntirePackageTypeData(packageType, newPackageDetailsData)
  }

  return {
    updateEntirePackageTypeData,
    updateCategoryFromPackage,
    updateImagesFromCategory,
    updateChildFromRTR,
  }
}

export default useUpdatePackage
