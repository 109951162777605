import DOMPurify from 'dompurify'
import Handlebars from 'handlebars'

// Helper to check for equality
Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
  return arg1 == arg2 ? options.fn(this) : options.inverse(this)
})

// Helper to put feature in rows
Handlebars.registerHelper('eachRow', function (items, numColumns, options) {
  let result = ''

  for (let i = 0; i < items.length; i += numColumns) {
    result += options.fn({
      columns: items.slice(i, i + numColumns),
    })
  }

  return result.replace(
    /mj-column-per-100/g,
    `mj-column-per-${Math.round(100 / numColumns)}`,
  )
})

export function escapeHtml(unsafe) {
  const HTML = `<html><body>${unsafe}</body></html>`
  // eslint-disable-next-line
  return HTML.replace(/"/g, '"').replace(/\//gi, '\\/')
}

export function HtmlFormatter(html, data) {
  // Compile the HTML with handlebars
  const template = Handlebars.compile(html)
  return DOMPurify.sanitize(template(data))
}
