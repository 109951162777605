import React from 'react'
import {
  makeStyles,
  Accordion as AccordionMUI,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from '../../../../../../components/common/Text'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: 'transparent',
  },
  expanded: {
    background: 'transparent',
    margin: '0 !important',
  },
  summaryRoot: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.65)',
    padding: '0px 10px',
    maxHeight: 45,
    opacity: 0.65,
  },
  summaryExpanded: {
    opacity: 1,
  },
  icon: {},
  detailsRoot: {
    padding: '20px 0',
  },
  disabled: {
    background: '#F3F3F7',
  },
}))

export type AccordionType = {
  id: number
  title: string
  icon?: React.ReactNode
  expanded: boolean
  disabled?: boolean
  children: React.ReactNode
  handleExpandClick: (id: number) => void
}

export default function Accordion({
  id,
  title,
  icon = '',
  expanded = false,
  disabled = false,
  children,
  handleExpandClick,
}: AccordionType) {
  const classes = useStyles()
  return (
    <AccordionMUI
      elevation={0}
      classes={{ root: classes.root, expanded: classes.expanded }}
      expanded={expanded}
      disabled={disabled}
      onChange={() => handleExpandClick(id)}
    >
      <AccordionSummary
        expandIcon={icon ? icon : <ExpandMoreIcon />}
        aria-controls='panel-content'
        id={'panel-header'}
        classes={{
          expandIcon: classes.icon,
          root: classes.summaryRoot,
          expanded: classes.summaryExpanded,
          disabled: classes.disabled,
        }}
      >
        <Text type='sectionName'>{title}</Text>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsRoot }}>
        {children}
      </AccordionDetails>
    </AccordionMUI>
  )
}
