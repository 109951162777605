import React from 'react'
import { Grid } from '@material-ui/core'
import Text from '../common/Text'
import Button from '../common/Button'
import AV8Logo from '../../assets/icons/AV8LogoBlue.png'
import AV8Loading from '../../assets/icons/AV8Loading.gif'
import { generateDataAttrs } from '../../utils/utils'

export default function MLSLoader({
  loading,
  text,
  setModalStatus,
  dataAttrs = {},
}) {
  const handleClose = () => {
    setModalStatus({ message: '', loading: false, show: false })
  }
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textAlign: 'center' }}
      {...generateDataAttrs({
        ...dataAttrs,
        metaType: 'modal',
        metaName: 'mls-loader',
      })}
    >
      {loading ? (
        <img src={AV8Loading} alt={''} height={48} width={140} />
      ) : (
        <img src={AV8Logo} alt={'AV8 Logo'} height={16} width={120} />
      )}
      <Text style={{ marginTop: 20 }} {...generateDataAttrs({ loading })}>
        {text}
      </Text>

      {!loading && (
        <Button
          style={{ marginTop: 25 }}
          medium
          bordered
          secondary
          title="Close"
          onClick={handleClose}
          {...generateDataAttrs({
            metaType: 'close-mls-loading-modal',
            metaAction: 'close',
          })}
        >
          Close
        </Button>
      )}
    </Grid>
  )
}
