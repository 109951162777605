import { v4 as uuidv4 } from 'uuid'

export enum AccordionIds {
  PrintAndDesign = 0,
  Mailing = 1,
  PrintNeeds = 2,
  Shipping = 3,
}

export enum AccordionsBodyName {
  PrintAndDesign = 'printAndDesign',
  Mailing = 'mailing',
  PrintNeeds = 'printNeeds',
  Shipping = 'shipping',
}

export enum MailingValues {
  Myself = 'myself',
  BuildList = 'buildList',
  SOI = 'soi',
  UploadList = 'uploadList',
}

enum PrintDesignValues {
  PrintAndMail = 'printAndMail',
  DownloadOnly = 'downloadOnly',
}

export const accordionsMockData = [
  {
    id: AccordionIds.PrintAndDesign,
    title: 'Print & Design',
    body: AccordionsBodyName.PrintAndDesign,
    icon: '',
    expanded: true,
    disabled: false,
    options: [
      {
        id: uuidv4(),
        label: 'Print \n& Mail',
        value: PrintDesignValues.PrintAndMail,
      },
      {
        id: uuidv4(),
        label: 'Download Only',
        value: PrintDesignValues.DownloadOnly,
      },
    ],
  },
  {
    id: AccordionIds.Mailing,
    title: 'Mailing',
    body: AccordionsBodyName.Mailing,
    icon: '',
    expanded: false,
    disabled: true,
    options: [
      // {
      //   id: uuidv4(),
      //   label: 'Myself',
      //   value: MailingValues.Myself,
      // },
      {
        id: uuidv4(),
        label: 'SOI',
        value: MailingValues.SOI,
        disabled: true,
      },
      {
        id: uuidv4(),
        label: 'Build List',
        value: MailingValues.BuildList,
      },
      {
        id: uuidv4(),
        label: 'Upload List',
        value: MailingValues.UploadList,
      },
    ],
  },
  {
    id: AccordionIds.PrintNeeds,
    title: 'Print Needs',
    body: AccordionsBodyName.PrintNeeds,
    icon: '',
    expanded: false,
    disabled: true,
    options: [
      {
        id: uuidv4(),
        label: 'Quantity',
        type: 'input',
        value: '',
      },
      {
        id: uuidv4(),
        label: 'Size',
        type: 'select',
        value: '',
        options: [
          {
            id: uuidv4(),
            label: '4x6',
            value: '4x6',
          },
          {
            id: uuidv4(),
            label: '6x9',
            value: '6x9',
          },
          {
            id: uuidv4(),
            label: '6x11',
            value: '6x11',
          },
        ],
      },
      {
        id: uuidv4(),
        label: 'Paper Weight',
        type: 'select',
        value: '',
        options: [
          {
            id: uuidv4(),
            label: 'Standard',
            value: 'standard',
          },
        ],
      },
      {
        id: uuidv4(),
        label: 'Paper Finish',
        type: 'select',
        value: '',
        options: [
          {
            id: uuidv4(),
            label: 'Glossy',
            value: 'glossy',
          },
        ],
      },
    ],
  },
  {
    id: AccordionIds.Shipping,
    title: 'Shipping',
    body: AccordionsBodyName.Shipping,
    icon: '',
    expanded: false,
    disabled: true,
    options: [],
  },
]

export const mockFormOptionsAPICall = {
  success: true,
  data: {
    accordions: accordionsMockData,
  },
}

export const mockEstimatedCostPriceAPICall = {
  success: true,
  data: {
    unitPrice: 0.5,
    discountPerUnitPrice: 0,
    discountPerUnitPercentage: 0,
  },
}
