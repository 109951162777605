import React from 'react'
import { List, ListItem, Grid } from '@material-ui/core'
import Divider from './Divider'
import SectionPresentation from './SectionPresentation'
import { generateDataAttrs } from '../../utils/utils'

export default function SectionList(props) {
  const { sectionsData, packageType, excludeList = [] } = props
  return (
    <List style={{ maxWidth: '100%' }}>
      {sectionsData &&
        sectionsData.map((sectionData, index) => {
          const { templateCategory: categoryName } = sectionData
          if (excludeList.indexOf(categoryName) === -1) {
            return (
              <Grid
                key={`${sectionData.packageType}-${index}`}
                style={{ maxWidth: '100%' }}
                {...generateDataAttrs({
                  metaType: 'section',
                  metaCategory: categoryName,
                  metaIdx: index + 1,
                })}
              >
                <Divider black />
                <ListItem disableGutters>
                  <SectionPresentation
                    sectionData={sectionData}
                    packageType={packageType}
                  />
                </ListItem>
              </Grid>
            )
          }
        })}
    </List>
  )
}
