import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Text from '../common/Text'
import AV8Loading from '../../assets/icons/AV8Loading.gif'
import Modal from '../common/Modal'
import { generateDataAttrs } from '../../utils/utils'

export default function RTRLoader({ loading, estimatedTime }) {
  const [remainingTime, setRemainingTime] = useState(estimatedTime)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRemainingTime((remainingTime) => Math.max(0, remainingTime - 1))
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [remainingTime])

  return (
    <Modal open={loading}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        {...generateDataAttrs({
          metaName: 'rtr-loader',
          metaType: 'modal',
        })}
      >
        <img src={AV8Loading} alt={'Loading'} height={55} width={150} />
        <Text style={{ marginTop: 20 }}>Rendering images...</Text>
        <Text type="muted">Estimated time: {remainingTime}s</Text>
      </Grid>
    </Modal>
  )
}
