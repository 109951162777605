import React from 'react'
import Carousel from 'react-multi-carousel'
import { generateDataAttrs } from '../../../../utils/utils'
// import { useMarketingContext } from '../../../../context/MarketingContext'
import { responsive, TemplateCarouselStyles } from './TemplateCarousel.styles'
import { useMarketingStore } from '../../store/marketing.store'

export const TemplateCarousel: React.FC<{ imgQty: number }> = (props) => {
  const { imgQty, children } = props
  const classes = TemplateCarouselStyles(imgQty)
  const isDialogOpen = useMarketingStore((state) => state.isDialogOpen)

  return (
    <Carousel
      swipeable={!isDialogOpen}
      draggable={!isDialogOpen}
      partialVisible
      arrows={false}
      responsive={responsive}
      removeArrowOnDeviceType="mobile"
      containerClass="carousel-container"
      className={classes.carouselContainer}
      {...generateDataAttrs({ metaName: 'carousel' })}
    >
      {children}
    </Carousel>
  )
}
