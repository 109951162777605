import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../common/Text'
import Carousel from '../common/Carousel'
import PDFViewer from './PDFViewer'

export default function ContentGridDialogItem({ post }) {
  const styles = (theme) => ({
    carouselContainer: {
      height: 300,
      width: 300,
      paddingBottom: 35,
      [theme.breakpoints.up('sm')]: {
        height: 450,
        width: 450,
        alignSelf: 'center',
        paddingBottom: 0,
      },
    },
    dotListClass: {
      position: 'relative',
      marginBottom: 5,

      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        bottom: -40,
        left: 0,
      },
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: -40,
        left: 0,
      },
    },
    mb10: {
      marginBottom: 10,
    },
    media: {
      width: 300,
      height: 300,
      objectFit: 'contain',
      [theme.breakpoints.up('sm')]: {
        height: 450,
        width: 450,
      },
    },
    mediaContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 35,
      },
    },
    subtitleContainer: {
      marginTop: 10,
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.mb10}
      >
        <Text type="muted">
          {post?.ContentGridMedia[0]?.type === 'video' ? 'Video' : 'Slide'}
        </Text>
      </Grid>
      <Grid container justify="center" className={classes.mediaContainer}>
        <Carousel
          isInContentGrid
          className={classes.carouselContainer}
          dotListClass={classes.dotListClass}
        >
          {post?.ContentGridMedia?.map((media, index) => {
            const isMediaTypeVideo =
              media.type === 'video' || media?.mime?.includes('video')

            const isPDF =
              media?.type === 'pdf' ||
              media?.mime?.includes('pdf') ||
              media?.ext?.includes('pdf')

            return isMediaTypeVideo ? (
              <video
                autoPlay
                preload="metadata"
                className={classes.media}
                key={index}
              >
                <source src={`${media.src}#t=0.1`} type="video/mp4" />
              </video>
            ) : isPDF ? (
              <div key={index} className={classes.media}>
                <PDFViewer file={media?.src} className={classes.gridItem} />
              </div>
            ) : (
              <img
                src={media.src}
                alt={media.src}
                key={index}
                className={classes.media}
              />
            )
          })}
        </Carousel>
      </Grid>
      <Grid className={classes.subtitleContainer}>
        <Text type="regular" data-test-name="dialog-item-subtitle">
          {post.full_description}
        </Text>
      </Grid>
    </>
  )
}
