import React from 'react'
import { default as MaterialDivider } from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export default function Divider(props) {
  const { black } = props
  const styles = {
    divider: { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
    black: { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const dividerClasses = classNames({
    [classes.divider]: true,
    [classes.black]: black,
  })

  return <MaterialDivider className={dividerClasses}></MaterialDivider>
}
