import React from 'react'
import { format } from 'date-fns'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import Text from '../common/Text'

const styles = {
  name: {
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeigth: '16px',
    color: 'var(--blue)',
  },
  mutedText: {
    fontFamily: 'StyreneRegular',
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeigth: '16px',
    color: '#292929',
    opacity: '0.65',
    marginBottom: 10,
  },
  text: {
    fontFamily: 'StyreneMedium',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeigth: '16px',
    color: '#292929',
    marginBottom: 14,
  },
  container: {
    alignSelf: 'flex-start',
    marginLeft: '9px',
  },
  infoContainer: {
    textAlign: 'center',
    margin: '0px 0 20px 0',
  },
  dateContainer: {
    textAlign: 'center',
  },
}

const useStyles = makeStyles(styles)
export default function PackageFilledInformation({ inputData, packageType }) {
  if (!inputData.dynamicFormData) {
    const { dynamicFormData, formData } = inputData.options
    inputData = Object.assign(inputData, { dynamicFormData, formData })
  }

  const classes = useStyles()
  let formattedStartDate = ' '
  let formattedEndDate = ' '
  if (inputData?.dynamicFormData?.campaignStartDate?.value) {
    formattedStartDate = format(
      inputData.dynamicFormData.campaignStartDate.value,
      'MM/dd/yyyy',
    )

    if (inputData?.dynamicFormData?.campaignEndDate) {
      formattedEndDate = format(
        inputData.dynamicFormData.campaignEndDate.value,
        'MM/dd/yyyy',
      )
    }
  }

  const selectPackageFilled = () => {
    switch (packageType) {
      case 'digital':
        return digitalPackageFilledInformation
      case 'print':
        return printPackageFilledInformation
      case 'outdoor':
        return outdoorPackageFilledInformation
      default:
        return <h3>No package selected</h3>
    }
  }

  const outdoorPackageFilledInformation = (
    <Grid>
      {inputData?.formData?.costEstimate?.value && (
        <Grid className={classes.infoContainer}>
          <Text type="muted">Cost estimate</Text>
          <Text type="medium">{inputData?.formData?.costEstimate.value}</Text>
        </Grid>
      )}
    </Grid>
  )
  const combinedInputData = {
    ...(inputData?.formData || {}),
    ...(inputData?.dynamicFormData || {}),
  }
  const digitalPackageFilledInformation = (
    <Grid item>
      {combinedInputData.paidAdBudget && (
        <>
          <Typography className={classes.mutedText}>Budget</Typography>
          <Typography className={classes.text}>
            {combinedInputData.paidAdBudget?.formattedValue ||
              combinedInputData.paidAdBudget?.value}
          </Typography>
        </>
      )}
      {inputData?.dynamicFormData?.campaignStartDate?.value && (
        <Grid container justify="center">
          <>
            <Grid className={classes.dateContainer}>
              <Typography className={classes.mutedText}>
                Campaign Dates
              </Typography>
              <Typography className={classes.text}>
                {formattedStartDate}
                {formattedEndDate != ' ' ? ` - ${formattedEndDate}` : ''}
              </Typography>
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  )
  const printPackageFilledInformation = (
    <Grid item className={classes.container}>
      {inputData?.dynamicFormData && (
        <>
          {inputData.dynamicFormData.quantity && (
            <>
              <Typography className={classes.mutedText}>Quantity</Typography>
              <Typography className={classes.text}>
                {inputData?.dynamicFormData.quantity.value}
              </Typography>
            </>
          )}
          {inputData.dynamicFormData.material && (
            <>
              <Typography className={classes.mutedText}>Material</Typography>
              <Typography className={classes.text}>
                {inputData?.dynamicFormData.material.label}
              </Typography>
            </>
          )}
        </>
      )}
    </Grid>
  )

  return <>{selectPackageFilled()}</>
}
