/**
 * Generates rule to validate given value to be a positive number
 * @param {string} errorMessage
 * @param {Object} options
 * @returns {function(*=): (boolean|string)} validator function
 */
export const isPositiveNumberRule = (errorMessage, options) => {
  const { includesZero = false } = options
  /**
   * Validates if given value is a positive number
   * @param {any} v input value
   * @returns {boolean|string} true if valid, string if invalid
   */
  const rule = (v) =>
    !v ||
    (!Number.isNaN(+v) && (includesZero ? +v >= 0 : +v > 0)) ||
    errorMessage
  rule.options = options
  return rule
}
