import emailRTROptionsParser from '../../../../../components/custom/EmailTemplate/emailRTROptionsParser'
import {
  formatObjectIntoArrayOfKeyValues,
  reduceKeyValueArrayToOptionObject,
} from '../../../../../utils/utils'

export const parseEmailRTROptions = async (
  inputData,
  image,
  agentId,
  packageType,
  templateCategory,
) => {
  let imageUrl = inputData?.emailData?.headerImage || image.baseUrl || image.url
  const optionsArray = formatObjectIntoArrayOfKeyValues({
    ...inputData.formData,
    ...inputData.dynamicFormData,
  })
  let options = reduceKeyValueArrayToOptionObject(optionsArray)
  if (inputData?.mailingLists && inputData?.emailHTML && inputData?.emailData) {
    const { newOptions, newImageUrl } = await emailRTROptionsParser(
      inputData.mailingLists,
      inputData.emailHTML,
      inputData.emailData,
      options,
      image.identifier,
      agentId,
    )
    options = newOptions
    imageUrl = newImageUrl
  }

  const formattedTemplateData = {
    packageType,
    templateCategory,
    options,
    imageUrl,
    auxiliaryImageUrls: image.carouselImages
      ?.filter(({ isHidden }) => isHidden !== true)
      ?.map((c) => c.url),
    templateId: image?.itemId || image?.id,
    name: image.name,
    layers: image.layers,
  }

  return formattedTemplateData
}
