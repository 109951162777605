import React, { useEffect, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useEmailRTRContext } from '../../../context/EmailRTRContext'
import HtmlPreviewer from '../../common/HtmlPreviewer'
import MultiSelector from './MultiSelector'
import { Formik, Form } from 'formik'
import FormikInput from '../../common/FormikInput'
import * as Yup from 'yup'
import { visualMarketingS3URL } from '../../../services/api'
import Text from '../../common/Text'
import { getHubspotMailingList } from '../../../services/contentServices'
import { getMailingListsByEmail } from '../../../services/marketingServices'
import { useAuthContext } from '../../../context/AuthContext'

export default function EmailReview({
  step,
  emailHTML,
  styles,
  formRef,
  setButtonIsEnabled,
  loading,
}) {
  const { emailRTRData, setEmailRTRData } = useEmailRTRContext()
  const { agentId } = useAuthContext()

  // Styles handlers
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Multi-selector items initial state
  const initialListingItems = [
    {
      type: 'file_selector',
      id: 1,
      listLabel: 'Upload List',
      errorLabel:
        'No cities for our mailing lists were found for your zip code',
      uploadLabel: 'Custom List',
      templateLabel: 'Download CSV Template',
      templateDownload: `${visualMarketingS3URL}/csvTemplate/Contacts+Import+Template.csv`,
      format: ['.csv', '.numbers'],
      value: 'CSV',
      hide: false,
    },
    {
      id: 2,
      label: 'Send to SOI',
      value: 'SOI',
      plainText: true,
      hide: false,
    },
    {
      type: 'MLS_list',
      id: 3,
      listLabel: 'Send to',
      highlightedLabel: 'Personal List(s)',
      singleArticle: 'a',
      options: [],
      value: 'PersonalList',
      hide: false,
    },
    {
      type: 'MLS_list',
      id: 4,
      listLabel: 'Send',
      highlightedLabel: 'MLS List(s)',
      singleArticle: 'an',
      options: [],
      value: 'MLSList',
      isExclusionary: true,
      hide: false,
    },
  ]

  // Define form validations
  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      .min(2, 'The subject is too short')
      .required('You must write a subject to listing'),
    previewText: Yup.string()
      .min(2, 'The preview text is too short')
      .max(140, 'The preview text is too long')
      .required('You must write a preview text to listing'),
  })

  const formInitialValues = {
    subject: emailRTRData?.emailData?.subject || '',
    emailSendDate: emailRTRData?.emailData?.emailSendDate || '',
    previewText: emailRTRData?.emailData?.previewText || '',
  }

  const [mailingLists, setMailingLists] = useState(emailRTRData.mailingLists)
  const [listingItems, setListingItems] = useState(initialListingItems)

  useEffect(() => {
    setEmailRTRData((prevState) => ({
      ...prevState,
      mailingLists: mailingLists,
    }))
    const values = formRef.current.values
    const isValidValues = validationSchema.isValidSync(values)
    const currentStep = 'Review'
    if (step === currentStep) {
      if (isValidValues && mailingLists.length) {
        setButtonIsEnabled(true)
      } else {
        setButtonIsEnabled(false)
      }
    }
  }, [mailingLists, step])

  useEffect(() => {
    async function getAgentMailingLists() {
      const response = await getMailingListsByEmail(agentId)
      if (response.success) {
        const soiLists = response.data.filter(ml => ml.is_soi === 'true')
        const otherLists = response.data.filter(ml => ml.is_soi !== 'true')

        setListingItems(() => {
          const newListingItems = [...listingItems]
          if (otherLists.length > 0) {
            newListingItems[2].options = otherLists
          } else {
            newListingItems[2].hide = true
          }

          if (soiLists.length > 0) {
            newListingItems[1].value = soiLists
            
          } else {
            newListingItems[1].hide = true
          }
          return newListingItems
        })
      }
    }

    getAgentMailingLists()
  }, [])

  useEffect(() => {
    async function getMLSMailingLists() {
      const response = await getHubspotMailingList()
      if (response.success) {
        setListingItems(() => {
          const newListingItems = [...listingItems]
          newListingItems[3].options = response.data
          return newListingItems
        })
      }
    }

    getMLSMailingLists()
  }, [])

  const nextButtonHandler = (values) => {
    const isValidValues = validationSchema.isValidSync(values)
    if (!loading) {
      if (isValidValues && mailingLists.length) {
        setButtonIsEnabled(true)
      } else {
        setButtonIsEnabled(false)
      }
    }
  }

  return (
    <div>
      <div style={{ marginTop: '3%' }}></div>
      <Grid style={{ marginBottom: '15px' }}>
        <Formik
          innerRef={formRef}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize
        >
          {({ values, setFieldValue, errors }) => {
            const handleTextChange = (e, fieldName) => {
              const { value } = e.target

              setFieldValue(fieldName, value)
              nextButtonHandler({
                ...values,
                [fieldName]: e.target.value,
              })
            }

            const handleDateChange = (value, fieldName) => {
              setFieldValue(fieldName, value)
            }
            return (
              <Form>
                <FormikInput
                  key="subject-1"
                  id="subject"
                  as="input"
                  name="subject"
                  label="Email Subject Line*"
                  placeholder="e.g Your Future Home Awaits..."
                  maxLength="45"
                  rows="1"
                  onChange={(e) => handleTextChange(e, 'subject')}
                  value={values.subject}
                  error={errors.subject}
                />
                <FormikInput
                  key="preview-1"
                  id="previewText"
                  as="input"
                  name="previewText"
                  label="Preview Text*"
                  placeholder="e.g A modern and luxurious house..."
                  maxLength="140"
                  rows="1"
                  onChange={(e) => handleTextChange(e, 'previewText')}
                  value={values.previewText}
                  error={errors.previewText}
                />
                <FormikInput
                  key="date-1"
                  id="emailSendDate"
                  as="datePicker"
                  value={values.emailSendDate}
                  name="emailSendDate"
                  label="Email Send Date"
                  rows="1"
                  onChange={handleDateChange}
                />
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Text type="muted" style={{ marginBottom: 5 }}>
        Send To a List*
      </Text>
      <MultiSelector
        listingItems={listingItems}
        selectedOptions={mailingLists}
        setSelectedOptions={setMailingLists}
        maxSelections={2}
      />
      <Grid className={classes.previewContainer} style={{ marginTop: '3%' }}>
        <Text type="sectionName">Preview</Text>
        <HtmlPreviewer html={emailHTML} data={emailRTRData.emailData} />
      </Grid>
    </div>
  )
}
