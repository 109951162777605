import React from 'react'
import { makeStyles, Dialog } from '@material-ui/core'
import Button from '../../common/Button'
import checkmarkSvg from '../../../assets/icons/Checkmark.svg'

const useStyles = makeStyles(() => ({
  disabledContainer: {
    width: 340,
    height: 340,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledLabel: {
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    width: '220px',
    textAlign: 'center',
    marginBottom: 56,
  },
  checkmark: {
    transform: 'scale(1.7)',
    backgroundColor: '#2a29a6',
    height: '36px',
    width: '36px',
    border: '1px solid #2a29a6',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '38px',
    '& > div': {
      background: `url(${checkmarkSvg}) no-repeat`,
      width: '20px',
      height: '16px',
      marginTop: '5px',
    },
  },
  finishButton: {
    width: 220,
    height: 50,
    fontSize: 14,
  },
}))

export default function EmailSentDialog({ handleFinishClick, isDialogOpen }) {
  const classes = useStyles()
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={isDialogOpen}
      fullWidth
      disableBackdropClick
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          pointerEvents: 'none',
        },
      }}
      PaperProps={{
        style: {
          borderRadius: '10px',
          margin: 0,
          maxWidth: 340,
          width: '100%',
        },
      }}
    >
      <div className={classes.disabledContainer}>
        <span className={classes.checkmark}>
          <div />
        </span>
        <p className={classes.disabledLabel}>
          We’ve started creating your email. You’ll receive a preview in your
          inbox within 1 business day.
        </p>
        <Button
          className={classes.finishButton}
          bordered
          primary
          title={'Finish'}
          onClick={() => {
            handleFinishClick()
          }}
          dataAttrs={{ metaAction: 'click-email-sent-confirmation' }}
        />
      </div>
    </Dialog>
  )
}
