import {
  uploadSingleListingImage,
  uploadMultipleListingImage,
} from '../../../services/marketingServices'
import { uploadSingleCSVList } from '../../../services/contentServices'
import { HtmlFormatter } from '../../../utils/htmlFormatter'

//Upload a CSV file with the Mailing List
const uploadMailingList = async (csv, name) => {
  const csvPayload = new FormData()
  csvPayload.append('files.csv', csv) //file that was stored in the context
  csvPayload.append('data', `{ "name": "${name}" }`) //filename

  const response = await uploadSingleCSVList(csvPayload)
  if (!response.success) return null
  return response.data
}

//Upload a single image
const uploadTemplateImage = async (image, agentId) => {
  const imagePayload = new FormData()
  imagePayload.append('image', image)

  const response = await uploadSingleListingImage(agentId, imagePayload)
  if (!response.success) return null
  return response.data
}

//Upload multiple images
const uploadMultipleTemplateImages = async (images, agentId) => {
  const imagesPayload = new FormData()
  images.map((_image) => {
    imagesPayload.append('images', _image)
  })

  const response = await uploadMultipleListingImage(agentId, imagesPayload)
  if (!response.success) return null
  return response.data
}

//check if it is a CSV and call upload if necessary
const formatMailingList = async (mailingLists) => {
  let formattedMailingList = []
  for (const list of mailingLists.values()) {
    switch (list.type) {
      case 'MLS_list': {
        formattedMailingList = [...formattedMailingList, list.options]
        break
      }
      case 'file_selector': {
        if (list.url.includes('blob')) {
          const base64 = list.base64.split('base64,')[1]
          const buffer = Buffer.from(base64, 'base64') //turn base64 from context to a buffer
          const blob = new Blob([buffer], {
            type: `text/${list.format.toUpperCase()}`,
          })
          const fileBlob = new File([blob], `mailingList.${list.format}`, {
            type: `text/${list.format.toUpperCase()}`,
          })
          const response = await uploadMailingList(fileBlob, 'mailingList') //call the function to upload the csv and return its urls
          if (!response) throw new Error('Error on uploading Mailing List')
          formattedMailingList = [...formattedMailingList, response.csv.url]
        } else {
          formattedMailingList = [...formattedMailingList, list.url]
        }
        break
      }
      default: {
        formattedMailingList = [...formattedMailingList, list.value]
        break
      }
    }
  }
  return formattedMailingList
}

function isBase64Image(image) {
  const knownTypes = {
    '/': 'jpg',
    i: 'png',
    R: 'gif',
  }

  if (!knownTypes[image[0]]) {
    return false
  }
  return knownTypes[image[0]] // returns their respective type
}

const formatEmailData = async (emailData, agentId) => {
  const header = emailData.headerImage
  let headerUrl = null
  const formattedHeader = header.split('base64,')[1]
  //Check if Header Image is a Base64 and return your type
  const headerType = formattedHeader ? isBase64Image(formattedHeader) : null
  if (headerType) {
    const buffer = Buffer.from(formattedHeader, 'base64')
    const blob = new Blob([buffer], { type: `image/${headerType}` })
    const fileBlob = new File([blob], `photoHeader.${headerType}`, {
      type: `image/${headerType}`,
    })
    const response = await uploadTemplateImage(fileBlob, agentId) //call the function to upload and return its url
    if (!response) throw new Error('Error on uploading a single Image')
    headerUrl = response.url
  }
  const formattedEmailBodyImages = [...emailData.emailBodyImages]
  let base64Images = []
  for (const [idx, image] of Object.entries(emailData.emailBodyImages)) {
    if (image.base64) {
      const formattedImage = image?.base64.split('base64,')[1]
      //Check if each body image is a Base64 and return your type
      const type = isBase64Image(formattedImage)
      if (type) {
        //upload to uploadCareServices and save in a array
        const buffer = Buffer.from(formattedImage, 'base64')
        const blob = new Blob([buffer], { type: `image/${type}` })
        const fileBlob = new File([blob], `photo.${type}`, {
          type: `image/${type}`,
        })
        base64Images = [...base64Images, { fileBlob, idx }]
      }
    }
  }
  //check if exist base64images to upload
  if (base64Images.length > 0) {
    //call the function to upload multiples images
    const response = await uploadMultipleTemplateImages(
      base64Images.map((image) => {
        return image.fileBlob
      }),
      agentId,
    )
    if (!response) throw new Error('Error on uploading Multiple Images')
    //save return url on your respective index
    if (response.length) {
      base64Images.map((image, index) => {
        formattedEmailBodyImages[image.idx] = { url: response[index].imageUrl }
      })
    }
  }

  console.log('formattedEmailBodyImages', formattedEmailBodyImages)

  const formattedEmailData = {
    ...emailData,
    emailBodyImages: formattedEmailBodyImages,
    listingImages: formattedEmailBodyImages.map((image) => image.url),
    headerImage: headerUrl || header,
  }
  return formattedEmailData
}

export default async function emailRTROptionsParser(
  mailingLists,
  emailHTML,
  emailData,
  options,
  identifier,
  agentId,
  mlsImagesId = [],
  stageEnum,
  listingAddress,
  isEditing = false,
) {
  const formattedEmailData = await formatEmailData(emailData, agentId)
  formattedEmailData.displayRTRFooter = false // Force this to false before final compilation
  const formattedMailingList = await formatMailingList(mailingLists)
  const imageUrl = formattedEmailData.headerImage
  options = {
    csvEmailListUrl: '',
    mailingLists: [],
  }
  const emailDataToParse = { ...formattedEmailData, lists: [] }
  formattedMailingList.map((_list, index) => {
    if (mailingLists[index]?.type == 'file_selector') {
      options.csvEmailListUrl = _list
      emailDataToParse.lists.push({
        csvEmailListUrl: _list,
        csvName: mailingLists[index].name,
      })
    } else {
      if (mailingLists[index]?.options) {
        options.mlsMailingLists = _list
        emailDataToParse.lists.push({
          mlsMailingLists: _list,
        })
      } else {
        options.mailingLists.push(_list)
        emailDataToParse.lists.push({
          defaultList: _list,
        })
      }
    }
  })
  if (options.csvEmailListUrl === '') {
    delete options.csvEmailListUrl
  }
  if (!options.mailingLists.length) {
    delete options.mailingLists
  }

  const HTML = `<html><body>${HtmlFormatter(
    emailHTML,
    formattedEmailData,
  )}</body></html>`
  options = {
    isEditing: isEditing ? 'yes' : 'no', // Edit email flag MONDAY column
    templateIdentifier: identifier,
    emailTemplate: HTML,
    headline: emailData.heading,
    estimatedPrice: emailData.formattedPrice,
    bedCount: emailData.bedrooms || 0,
    bathCount: emailData.bathrooms || 0,
    subject: emailData.subject,
    description: emailData.description,
    propertyUrl: emailData.propertyUrl,
    images: formattedEmailData.listingImages,
    year: emailData.year,
    emailData: JSON.stringify({
      ...emailDataToParse,
      stage: emailDataToParse.stage,
      stageEnum,
      images: mlsImagesId,
      listing: { ...listingAddress },
    }),
    previewText: emailData.previewText,
    ...options,
  }

  if( emailData?.emailSendDate ){
    options = {
      ...options,
      campaignSendData: emailData.emailSendDate,
    }
  }

  return {
    newOptions: options,
    newImageUrl: imageUrl,
    formattedEmailData,
  }
}