import create from 'zustand'
import { StrapiImage } from '../components/TemplatePreview/TemplateRTRPreview'

export type RenderedTemplateImage = SVGElement

export type RTRImageCache = { [paramsHash: string]: RenderedTemplateImage }

export type RtrStore = {
  images: Record<StrapiImage['itemId'], RTRImageCache>
  setRTRImage: (itemId: StrapiImage['itemId'], key: string, image: RenderedTemplateImage) => void,
  clear: () => void
}

export const useRTRStore = create<RtrStore>(set => ({
  images: {},
  clear: () => set({ images: {} }),
  setRTRImage: (itemId, key, image) => {
    set(state => {
      const { [itemId]: templateCache = {} } = state.images
      templateCache[key] = image
      state.images[itemId] = templateCache
      return state
    })
  },
}))
