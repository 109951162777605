import React, { useState } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import { Devices, GetApp } from '@material-ui/icons'
import useDownload from '../../hooks/useDownload'
import MenuDropdown from '../common/MenuDropdown'
import Modal from '../common/Modal'
import MLSLoader from '../custom/MLSLoader'
import { createPost } from '../../services/contentServices'
import { useAuthContext } from '../../context/AuthContext'
import { useRequestContext } from '../../context/RequestContext'
import { generateDataAttrs } from '../../utils/utils'
import { useListingDescriptionContext } from '../../context/ListingDescriptionContext'
import { RTR_FULL_RENDER_IMAGE_SIZE } from '../../utils/constants'
import { renderHighResolutionSvg } from '../../modules/marketing/actions/renderHighResolutionTemplate'

export default function SaveButton({
  template,
  url,
  shouldBuildBeforeDownload = false,
  auxiliaryImageUrls = [],
  metaData = {},
}) {
  const { agentId } = useAuthContext()
  const { isEmbedded } = useRequestContext()
  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()
  const [loadingModalStatus, setLoadingModalStatus] = useState({
    loading: false,
    message: '',
    show: false,
  })
  const [handleDownload] = useDownload(
    url,
    shouldBuildBeforeDownload,
    auxiliaryImageUrls,
  )

  const handleExportToDevice = async () => {
    if (!isEmbedded) {
      setLoadingModalStatus({
        loading: true,
        show: true,
        message: 'Preparing your download...',
      })
    }
    try {
      // DIRTY HACK: We know url comes from Social Media only, so we pass that as final size
      if (template.url && typeof template.url === 'object') {
        await handleDownload(
          RTR_FULL_RENDER_IMAGE_SIZE,
          RTR_FULL_RENDER_IMAGE_SIZE,
        )
      } else {
        await handleDownload()
      }

      if (!isEmbedded) {
        setLoadingModalStatus({
          loading: false,
          show: true,
          message: 'Download completed.',
        })
      }
    } catch (err) {
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Oops, something went wrong, please try again.',
      })
      console.error(err)
    }
  }

  const handleSaveToContentLibrary = async () => {
    setLoadingModalStatus({
      loading: true,
      show: true,
      message: 'Saving to your Content Library...',
    })
    try {
      let finalUrl = url
      if (template.url && typeof template.url === 'object') {
        finalUrl = await renderHighResolutionSvg({
          agentId: agentId || -1,
          image: template.url,
          width: RTR_FULL_RENDER_IMAGE_SIZE,
          height: RTR_FULL_RENDER_IMAGE_SIZE,
        }).catch((err) => {
          console.error('Failed to render/upload higher res template')
          throw err
        })
      }

      const images = [finalUrl, ...auxiliaryImageUrls]
      await createPost(
        agentId,
        listingDescriptionCtx.headline,
        listingDescriptionCtx.description,
        images,
      )
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Content saved to your Library.',
      })
    } catch (err) {
      console.error(err)
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Oops, something went wrong, please try again.',
      })
    }
  }

  return (
    <>
      <Modal open={loadingModalStatus.show}>
        <MLSLoader
          loading={loadingModalStatus.loading}
          text={loadingModalStatus.message}
          setModalStatus={setLoadingModalStatus}
        />
      </Modal>
      <MenuDropdown
        title="Save"
        icon={<GetApp />}
        style={{ height: 40 }}
        {...metaData}
      >
        <MenuItem
          onClick={handleExportToDevice}
          style={{
            borderBottom: '1px solid #D8D8D8',
          }}
          {...generateDataAttrs({
            ...metaData,
            metaType: 'save-template',
            metaAction: 'download-to-device',
          })}
        >
          <Grid container alignItems="center">
            <Devices
              style={{
                fontSize: 24,
                marginRight: 10,
              }}
            />
            Export to Your Device
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={handleSaveToContentLibrary}
          {...generateDataAttrs({
            ...metaData,
            metaType: 'save-template',
            metaAction: 'save-to-content-library',
          })}
        >
          <Grid container alignItems="center">
            <GetApp
              style={{
                fontSize: 24,
                marginRight: 10,
              }}
            />
            Save to Content Library
          </Grid>
        </MenuItem>
      </MenuDropdown>
    </>
  )
}
