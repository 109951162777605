/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export type CropResult = {
  x: number
  y: number
  size?: number
  refImage: HTMLImageElement
  refCrop: Crop
}

export type CroppableImageProps = {
  url: string
  onCompleted: (cropResult: CropResult) => void
  initialCrop: Crop
}

const calculateRelative = (
  distanceToCenter: number,
  boxWidth: number,
  canvasWidth: number,
) => (distanceToCenter - boxWidth / 2) / (canvasWidth - boxWidth) || 0

const normalize = (x: number) => Math.max(0, Math.min(100, Math.round(x * 100)))

export function CroppableImage({
  url,
  onCompleted,
  initialCrop,
}: CroppableImageProps): JSX.Element {
  const [image, setImage] = useState<HTMLImageElement>(new Image(100, 100))
  const [crop, setCrop] = useState(initialCrop)

  const updateCrop = (crop: Crop) => {
    if (!crop || !image.src) return
    const x = calculateRelative(
      crop.x! + crop.width! / 2,
      crop.width!,
      image?.width ?? 100,
    )

    const y = calculateRelative(
      crop.y! + crop.height! / 2,
      crop.height!,
      image?.height ?? 100,
    )

    onCompleted({
      x: normalize(x),
      y: normalize(y),
      // size,
      refCrop: crop,
      refImage: image,
    })
  }

  return (
    <ReactCrop
      src={url}
      crop={crop}
      imageStyle={{
        maxHeight: 300,
        maxWidth: 300,
        objectFit: 'contain',
      }}
      crossorigin={'anonymous'}
      keepSelection
      onImageLoaded={(image: HTMLImageElement) => {
        setImage(image)
        updateCrop({
          ...crop,
          width: image.width,
          height: image.height,
        })
      }}
      onChange={setCrop}
      onComplete={updateCrop}
    />
  )
}
