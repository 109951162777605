import { createContext, useContext } from 'react'

export type MarketingHelpers = {
  lastFetchedListingAddress: Record<string, any> | null;
  lastFetchedTemplateStage: string | null;
  shouldUploadImagesAgain: boolean;
  lastRTRRenderStageForDigital: string | null;
  lastRTRRenderStageForPrint: string | null;
}

export type MarketingHelpersContext = {
  marketingHelpers: MarketingHelpers;
  setMarketingHelpers: (marketingHelpers: MarketingHelpers) => void;
}

export const MarketingHelpersContext = createContext<MarketingHelpersContext>({
  marketingHelpers: {
    lastFetchedListingAddress: null,
    lastFetchedTemplateStage: null,
    shouldUploadImagesAgain: true,
    lastRTRRenderStageForDigital: null,
    lastRTRRenderStageForPrint: null,
  },
  setMarketingHelpers: () => {},
})

export const useMarketingHelpersContext = (): MarketingHelpersContext => useContext<MarketingHelpersContext>(MarketingHelpersContext)
