import styled from 'styled-components'
import checkmarkSvg from '../../assets/icons/CheckmarkMin.svg'

export const Container = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  /* Customize label (container) */
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 8px;
    height: 20px;
    width: 20px;
    background-color: rgba(255, 255, 255, 0.25);
    border: 1px solid #fff;
    border-radius: 50%;
  }

  .information {
    font-family: 'StyreneMedium';
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2a29a6;
    margin-left: 15px;
    height: 38px;
    display: flex;
    align-items: center;
  }

  .container input:checked ~ .checkmark {
    background-color: #2a29a6;
  }

  /* Create indicator - hidden when not checked */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show indicator when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style indicator */
  .container .checkmark:after {
    background: url(${checkmarkSvg}) no-repeat;
    top: 1px;
    left: 3px;
    width: 16px;
    height: 16px;
  }
`
