import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generateDataAttrs } from '../../utils/utils'

export default function PageTitle({
  CTATextUpper,
  CTATextLower,
  isSingleLine,
  dataAttrs = {},
  ...rest
}) {
  const styles = (theme) => ({
    container: {
      margin: '0% 0 7% 0%',
      width: '100%',
      maxWidth: 300,
      [theme.breakpoints.up('sm')]: {
        marginTop: '5%',
        textAlign: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: 350,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '0',
        maxWidth: 500,
      },
    },
    greeting: {
      fontSize: '35px',
      fontFamily: 'BookmaniaBold',
      color: '#292929',
      letterSpacing: '-1.5px',
    },
    cta: {
      fontSize: '35px',
      fontFamily: 'BookmaniaRegularItalic',
      color: '#292929',
      letterSpacing: '-2px',
      lineHeight: isSingleLine ? '20px' : '1',
    },
    body1: {
      lineHeight: 1,
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.container}
      direction="column"
      justify="space-between"
      alignItems="flex-start"
      data-meta-name="container"
    >
      <Grid item {...generateDataAttrs(dataAttrs)}>
        <Typography
          className={classes.greeting}
          {...rest}
          classes={{ body1: classes.body1 }}
        >
          {CTATextUpper}
        </Typography>

        {isSingleLine && <span>&nbsp;&nbsp;</span>}
        <Typography className={classes.cta} {...rest}>
          {CTATextLower}
        </Typography>
      </Grid>
    </Grid>
  )
}
