import React, { useEffect, useState } from 'react'
import { capitalize, Grid } from '@material-ui/core'
import AV8Loading from '../../../../assets/icons/AV8Loading.gif'
import Text from '../../../common/Text'
import { makeStyles } from '@material-ui/styles'

const styles = () => ({
  estimatesTitle: {
    fontFamily: 'Bookmania, BookmaniaBold, "Times New Roman", serif',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 10,
  },
  estimatesWrapper: {
    backgroundColor: '#E8E8F8',
    display: 'flex',
    flexWrap: 'noWrap',
    flexDirection: 'row',
    padding: '12px 20px',
    margin: '0.2em 0',
  },
  estimate: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '8px',
  },
  estimateProperty: {
    color: '#9796CA',
    fontFamily: 'StyreneMedium, StyreneA, Verdana, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 5,
    opacity: 0.65,
  },
  estimateValue: {
    color: '#2A29A6',
    fontFamily: 'StyreneMedium, StyreneA, Verdana, sans-serif',
    fontWeight: 400,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
  },
})

const { format: bigNumberFormatter } = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  compactDisplay: 'short',
  notation: 'compact',
  maximumFractionDigits: 2,
})
const estimatePropsDict = {
  spend: {
    show: false,
  },
  actions: {
    order: 3,
  },
  reach: {
    formatter: bigNumberFormatter,
    order: 2,
  },
  impressions: {
    formatter: bigNumberFormatter,
    order: 1,
  },
}

export const AdsEstimatesPresentation = ({
  title = 'Ad Delivery Estimates',
  comment = 'Values based on estimates provided by Facebook',
  failMessage = 'We will get in touch with campaign estimations',
  data = {},
  loading,
  success,
  error = null,
  formError = null,
  style = {},
} = {}) => {
  const useStyles = makeStyles(styles)
  const classNames = useStyles()
  const [estimatesData, setEstimatesData] = useState({})

  useEffect(() => {
    const formattedEstimate = Object.fromEntries(
      Object.entries(data || {})
        .map(([propName, rawValue]) => {
          const {
            label: proposedLabel,
            order,
            formatter = (v) => v,
            show = true,
          } = estimatePropsDict[propName] || {}
          const label = proposedLabel || capitalize(propName)
          const value =
            rawValue < 0 || Number.isNaN(rawValue) ? 'N/A' : formatter(rawValue)
          return [propName, { label, value, order, show }]
        })
        .sort(([keyA, { order: a }], [keyB, { order: b }]) => {
          return Number.isNaN(a - b)
            ? keyA.localeCompare(keyB, 'en', { numeric: true })
            : a - b
        }),
    )

    setEstimatesData(formattedEstimate)
  }, [data])

  const estimates = Object.entries(estimatesData || {}).map(
    ([key, { label, value, show }]) =>
      show && (
        <div key={key} className={classNames.estimate}>
          <Text className={classNames.estimateProperty}>{label}</Text>
          <Text className={classNames.estimateValue}>{value}</Text>
        </div>
      ),
  )

  const errorMessage = (
    <Text
      color={'#292929'}
      type={'muted'}
      fontSize={'10px'}
      style={{ marginTop: 10, textAlign: 'center' }}
    >
      {
        typeof error === 'string'
            ? error
            : 'Could not get estimations, please try again later.'
      }
    </Text>
  )

  const formErrorMessage = (
    <Text
      color={'#292929'}
      type={'muted'}
      fontSize={'10px'}
      style={{ marginTop: 10, textAlign: 'center' }}
    >
      No estimations available. Please check the information provided.
    </Text>
  )

  const loadingAnimation = (
    <div style={{ maxWidth: '100%', padding: 18 }}>
      <img src={AV8Loading} style={{ width: '100%' }} alt={'loading'} />
      <Text type={'muted'}>Loading estimates...</Text>
    </div>
  )

  const estimatesBlock = (
    <>
      <div className={classNames.estimatesWrapper} style={style}>
        {estimates}
      </div>
      {comment && (
        <Text
          color={'#292929'}
          type={'muted'}
          fontSize={'10px'}
          style={{ marginTop: 10, textAlign: 'center' }}
        >
          {comment}
        </Text>
      )}
    </>
  )

  return (
    <Grid style={{ marginTop: 20 }}>
      {loading ? (
        loadingAnimation
      ) : success ? (
        <>
          {title && (
            <Text className={classNames.estimatesTitle}>
              {title}
            </Text>
          )}
          {error ? errorMessage : formError ? formErrorMessage : estimatesBlock}
        </>
      ) : (
        <Text type="regular">
          {failMessage}
        </Text>
      )}
    </Grid>
  )
}
