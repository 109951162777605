import { TemplateImage } from '../components/TemplatePreview/TemplatePreviews'
import {
  scaleCrop1080p,
  scaleCrop1080pAtPosition,
} from '../../../services/uploadCareServices'
import { formatAsMoney, generateComboLine } from '../../../utils/utils'
import { ListingDescription } from '../../../context/ListingDescriptionContext'
import { RtrTemplate } from '../components/TemplatePreview/TemplateRTRPreview'

export type FormatTemplateDataParams = {
  listingDescriptionData: ListingDescription
  stageName: string
  addressLine: string
}

export type RTRTemplateData = ListingDescription & {
  images: TemplateImage[]
  propertyImage: string
  stageName: string
  imagesIds: number[]
  price: string
  addressLine: string
  [k: string]: any
}

export type AgentInfo = {
  id: number
  dre: string
  dreExpirationDate: Date
  carUsername: string
  carPassword?: any
  isBroker: boolean
  isSubscribed: boolean
  firstName: string
  lastName: string
  companyName?: any
  birthDate: Date
  email: string
  phone?: string
  firstAddressLine: string
  secondAddressLine: string
  city: string
  state: string
  zip: string
  profile?: {
    name: string
    email: string
    phone: string
    photo: string
    socialMedia: any[]
  }
}

export function formatTemplateData(
  params: FormatTemplateDataParams,
): RTRTemplateData {
  const { listingDescriptionData, stageName, addressLine } = params
  const { images } = listingDescriptionData
  const [{ url: propertyImage }] = images
  return {
    ...listingDescriptionData,
    propertyImage,
    images: images.map<TemplateImage>((image) => ({
      ...image,
      url: image.crop
        ? scaleCrop1080pAtPosition(
            image.url,
            image.crop?.x,
            image.crop?.y,
            image.crop?.size,
          )
        : scaleCrop1080p(image.url),
    })),
    stageName,
    addressLine,
    price: formatAsMoney(listingDescriptionData.estimatedPrice),
    comboLine: generateComboLine([
      [formatAsMoney(listingDescriptionData.estimatedPrice), '    '],
      [
        parseInt(listingDescriptionData.bathCount) > 0
          ? listingDescriptionData.bathCount + 'BA'
          : '',
        parseInt(listingDescriptionData.bedCount) > 0
          ? listingDescriptionData.bedCount + 'BD'
          : '',
        ' | ',
      ],
    ]),
  }
}

export function getPaperSizeFromRTRTemplate(rtrTemplate: RtrTemplate) {
  const {
    children: [
      {
        input_package: { height, width },
      },
    ],
  } = rtrTemplate
  const padding = 0.25
  const format = `${Math.floor(height - padding)}x${Math.floor(
    width - padding,
  )}`
  return format
}
