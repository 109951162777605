import React from 'react'
import { DndProvider as ReactDndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}

export default function DnDProvider({ children }) {
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

  return <ReactDndProvider backend={backendForDND}>{children}</ReactDndProvider>
}
