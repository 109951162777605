import React, { memo } from 'react'
import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import { generateDataAttrs } from '../../utils/utils'
import Datepicker from './Datepicker'

function FormikInput({
  label = '',
  name,
  error = false,
  as = 'input',
  lengthCounting = false,
  isCurrency = false,
  type,
  setFieldValue,
  maxLength = void 0,
  ...props
}) {
  const styles = (theme) => ({
    container: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: 12,
      },
    },
    label: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      color: '#292929',
      opacity: '0.65',
    },
    field: {
      marginTop: '8px',
      borderRadius: '5px',
      border: '1px solid var(--blue)',
      resize: 'none',
      padding: lengthCounting ? '10px 10px 22px' : '10px',
      '&::placeholder': {
        fontFamily: 'StyreneMedium',
        fontSize: '12px',
        color: 'var(--blue)',
        opacity: '0.5',
      },
      width: '90%',
      color: 'var(--blue)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
    },
    textContainer: {
      width: '100%',
      marginTop: 2,
      marginBottom: lengthCounting ? '7px' : '15px',
    },
    textCounter: {
      backgroundColor: 'var(--white)',
      borderRadius: '5px',
      bottom: '1px',
      color: 'var(--blue-opaque)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      left: '1px',
      padding: '3px 0 1px 10px',
      position: 'absolute',
      textAlign: 'right',
      width: '90%',
      marginRight: '10px',
      height: '18px',
    },
    errorText: {
      fontSize: '10px',
      color: 'var(--red)',
      fontFamily: 'StyreneRegular',
      position: 'relative',
      left: '0px',
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  let fieldComponent = ''

  switch (as) {
    case 'datePicker':
      fieldComponent = (
        <Datepicker
          label={label}
          fieldName={name}
          value={props.value}
          dateFormat="MM/dd/yyyy"
          minDate={new Date()}
          onChange={props.onChange}
          placeholderText={props?.placeholder || 'MM/DD/YYYY'}
        />
      )
      break
    default:
      if (isCurrency) {
        const withValueCap = (inputObj) => {
          const { value } = inputObj
          if (value <= maxLength) return true
          return false
        }
        fieldComponent = (
          <NumberFormat
            thousandSeparator={true}
            name={name}
            allowNegative={false}
            isAllowed={withValueCap}
            onChange={(e) => setFieldValue(name, e.target.value)}
            prefix={'$'}
            className={classes.field}
            {...props}
          />
        )
      } else {
        fieldComponent = (
          <Field
            as={as}
            name={name}
            type={type}
            className={classes.field}
            maxLength={maxLength}
            {...props}
          />
        )
      }
      break
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      {...generateDataAttrs({ metaName: name, metaType: type || 'input' })}
    >
      {label && (
        <label htmlFor={name} className={classes.label}>
          {label}
        </label>
      )}
      <div className={classes.textContainer}>
        <div style={{ display: 'flex', position: 'relative' }}>
          {fieldComponent}
          {lengthCounting && (
            <div className={classes.textCounter}>{lengthCounting}</div>
          )}
        </div>
        {error && (
          <span className={classes.errorText} data-meta-name="error">
            {error}
          </span>
        )}
      </div>
    </Grid>
  )
}

export default memo(FormikInput)
