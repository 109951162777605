import React, { useState } from 'react'
import { readString } from 'react-papaparse'
import Text from '../../../../../../../components/common/Text'
import { AdsEstimatesPresentation } from '../../../../../../../components/custom/MarketingRequest/ads-estimates/AdsEstimatesPresentation'
import { PrintCSVTemplateS3URL } from '../../../../../../../utils/constants'
import {
  readRemoteCsvList,
  renameFile,
} from '../../../../../../../utils/csvUtils'
import UploadCSVList from '../../../../../components/UploadCSVList/UploadCSVList'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { useAuthContext } from '../../../../../../../context/AuthContext'

export default function UploadListSection() {
  const { agentId } = useAuthContext() as any
  const { mailingSectionData, setMailingSectionData } =
    usePTRFormContextProvider()

  const [reach, setReach] = useState<number>(
    mailingSectionData?.data?.reach || 0,
  )
  const [error, setError] = useState<boolean>(false)
  const [csvFile, setCsvFile] = useState<File>(
    mailingSectionData.data?.CSVList || null,
  )

  const handleCSVListChange = async (file: File) => {
    try {
      const renamedFile = renameFile(
        file,
        `contact-list-${agentId}-${Date.now()}`,
      )
      const csvAsString = await readFile(file)
      const results = readString(csvAsString as string)
      setReach(results?.data?.length - 1)
      setCsvFile(renamedFile)
      setMailingSectionData({
        ...mailingSectionData,
        data: {
          CSVList: renamedFile,
          reach: results?.data?.length - 1,
        },
      })
    } catch (err) {
      console.log(err)
      setError(true)
    }
  }

  const validateCSV = async (file: File) => {
    try {
      const templateCsvParsedResults = await readRemoteCsvList(
        PrintCSVTemplateS3URL,
      )
      const validHeader = templateCsvParsedResults?.data[0] as string[]

      const uploadedCsvAsString = await readFile(file)
      const results = readString(uploadedCsvAsString as string)
      const csvHeader = results?.data[0] as string[]
      if (!csvHeader) return false

      const isHeaderValid =
        csvHeader.length === validHeader.length &&
        csvHeader.every((value: string, index: number): boolean => {
          return (
            value.trim().toLocaleLowerCase() ===
            validHeader[index].trim().toLocaleLowerCase()
          )
        })

      return isHeaderValid
    } catch (err) {
      return false
    }
  }

  const readFile = (file: File) => {
    return new Promise(function (resolve) {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = function () {
        resolve(reader.result)
      }
    })
  }

  return (
    <>
      <UploadCSVList
        onChange={handleCSVListChange}
        validateCSVFileRule={validateCSV}
        file={csvFile}
      />
      {reach > 0 && (
        <>
          <Text type="muted">Reach estimates</Text>
          <AdsEstimatesPresentation
            comment=""
            title=""
            data={{ reach }}
            loading={false}
            success={!error}
          />
        </>
      )}
    </>
  )
}
