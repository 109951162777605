import React, { useEffect, useState } from 'react'
import { RtrTemplate } from '../../../../components/TemplatePreview/TemplateRTRPreview'
import { PrintCategory, printCategoryData } from './printCategory.data'
import { CustomizePrintWizard } from './CustomizePrintWizard'
import {
  CustomizeTemplateModal,
  EModalOpenState,
} from '../../../../components/CustomizeTemplateModal'
import { PrintModalStore } from '../../../../store/PrintModal.store'
import { ErrorBoundary } from '@sentry/react'
import { PTRFormContextProvider } from '../CustomizePrintModalForm/CustomizePrintModalContext'

export function CustomizePrintModal(params: CustomizePrintModalProps) {
  const {
    isEditing,
    packageData: { templateCategory: categoryName },
    image: template,
  } = params

  const setStep = PrintModalStore(({ setStep }) => setStep)
  const clearStore = PrintModalStore(({ clear }) => clear)
  const [open, setOpen] = useState<EModalOpenState>(EModalOpenState.CLOSE)
  const printCategory = printCategoryData[categoryName]
  const {
    title: categoryTitle,
    steps: [firstCategoryStep],
  } = printCategory

  // When closing the custom print wizard modal, we should save to their content grid
  const closeHandler = () => {
    clearStore()
    setOpen(EModalOpenState.CLOSE)
  }

  useEffect(() => {
    if (open && !PrintModalStore.getState().step) {
      setStep(firstCategoryStep)
    }
    return clearStore
  }, [open])

  return (
    <CustomizeTemplateModal
      isEditing={isEditing}
      open={open}
      onModalStateChange={setOpen}
    >
      <ErrorBoundary showDialog>
        <PTRFormContextProvider>
          <CustomizePrintWizard
            title={categoryTitle}
            closeHandler={closeHandler}
            categoryName={categoryName}
            template={template}
            steps={printCategory.steps}
          />
        </PTRFormContextProvider>
      </ErrorBoundary>
    </CustomizeTemplateModal>
)
}

export type CustomizePrintModalProps = {
  isEditing: boolean
  packageData: { templateCategory: PrintCategory; [k: string]: any }
  saveHandler: (inputData: Record<string, any>) => void
  image: RtrTemplate
}
