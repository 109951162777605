import React, { useState, useEffect } from 'react'
import Checkbox from './Checkbox'
import Field from './Input'
import TextAreaItem from './Textarea'
import Select from './Select'
import Datepicker from './Datepicker'
import { fieldMeetsCondition } from '../../../../utils/dynamicForm'

const Form = ({ options, values, setValues, isEditing = false }) => {
  const [currentPageData, setCurrentPageData] = useState(options)

  useEffect(() => {
    setCurrentPageData(options)
    if(isEditing) {
      return 
    }
    setValues((currentValues) => {
      const newValues = options.reduce((obj, field) => {
        if (field.component === 'field_group') {
          for (const subField of field.fields) {
            obj[subField._uid] = ''
          }
        } else {
          obj[field._uid] = ''
        }

        return obj
      }, {})

      return Object.assign({}, newValues, currentValues)
    })
  }, [options])

  const onFieldChange = (fieldId, fieldObjectWithvalue) => {
    setValues(oldValues => ({ ...oldValues, [fieldId]: fieldObjectWithvalue }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <h2>{currentPageData?.label}</h2>
      {currentPageData?.filter(fieldMeetsCondition(values)).map((field) => {
        switch (field.type) {
          case 'select':
            return (
              <Select
                key={field._uid}
                field={field}
                onFieldChange={onFieldChange}
                value={values[field._uid]?.value}
                error={values[field._uid]?.error}
              />
            )
          case 'textarea':
            return (
              <TextAreaItem
                key={field._uid}
                field={field}
                onFieldChange={onFieldChange}
                value={values[field._uid]?.value}
                error={values[field._uid]?.error}
              />
            )
          case 'datepicker':
            return (
              <Datepicker
                key={field._uid}
                field={field}
                onFieldChange={onFieldChange}
                value={values[field._uid]?.value}
                error={values[field._uid]?.error}
              />
            )
          case 'radio':
          case 'options':
            return (
              <Checkbox
                key={field._uid}
                field={field}
                onFieldChange={onFieldChange}
                value={values[field._uid]?.value}
                error={values[field._uid]?.error}
              />
            )
          case 'input':
            return (
              <Field
                key={field._uid}
                field={field}
                onFieldChange={onFieldChange}
                value={values[field._uid]?.value}
                error={values[field._uid]?.error}
              />
            )
          default:
            'Wrong type'
        }
      })}
    </form>
  )
}

export default Form
