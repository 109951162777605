import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from 'react-multi-carousel'
import { generateDataAttrs } from '../../../utils/utils'
import { useMarketingHelpersContext } from '../../../context/MarketingHelpersContext'
import SignageDetailsItem from '../../../modules/signage/components/SignageDetailsItem'

// This is used only for Signage
export const SectionCarousel = ({ data }) => {
  const imgQuantity = data?.images.length || 0
  const { marketingHelpers } = useMarketingHelpersContext()

  const styles = (theme) => ({
    imageContainer: {
      width: 250,
      height: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: 250,
    },
    name: {
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: 'var(--blue)',
    },
    carouselContainer: {
      width: '100%',
      position: 'relative',
      left: imgQuantity === 1 ? -50 : 0,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        left: 0,
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        display: 'flex',
        justifyContent: imgQuantity < 4 ? 'center' : 'flex-start',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    carouselItemContainer: {
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
      },
    },
    mutedText: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: '#292929',
      opacity: '0.65',
      marginBottom: 10,
    },
    text: {
      fontFamily: 'StyreneMedium',
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: '#292929',
      marginBottom: 14,
    },
  })

  const useStyles = makeStyles(styles)

  const classes = useStyles()
  const responsive = {
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 960, min: 601 },
      items: 2,
      partialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 1280, min: 961 },
      items: 3,
      partialVisibilityGutter: 60,
    },
    largeDesktop: {
      breakpoint: { max: 4000, min: 1281 },
      items: 4,
      partialVisibilityGutter: 60,
    },
  }
  return (
    <Carousel
      swipeable={!marketingHelpers?.isDialogOpen}
      draggable={!marketingHelpers?.isDialogOpen}
      partialVisible
      arrows={false}
      responsive={responsive}
      removeArrowOnDeviceType="mobile"
      containerClass="carousel-container"
      className={classes.carouselContainer}
      {...generateDataAttrs({ metaName: 'carousel' })}
    >
      {data?.images.map((image, index) => {
        return (
          <SignageDetailsItem
            key={`${data.displayName} #${index + 1}`}
            sign={image}
            index={index}
            category={data}
          />
        )
      })}
    </Carousel>
  )
}
