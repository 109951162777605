import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export default function Image(props) {
  const {
    large,
    medium,
    small,
    url,
    rider = false,
    miniGrid,
    mini,
    rounded,
    renderAsDiv,
    ...rest
  } = props
  const styles = (theme) => ({
    large: { width: '300px', height: '300px' },
    medium: { width: '250px', height: '250px' },
    small: { width: '90px', height: '90px' },
    mini: { width: '72px', height: '46px' },
    miniGrid: {
      width: '115px',
      height: '75px',
      [theme.breakpoints.up('sm')]: {
        width: '145px',
      },
    },
    rider: { width: '250px', height: '75px', borderRadius: 0, marginTop: 85 },
    rounded: { borderRadius: 5 },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const buttonClasses = classNames({
    [classes.large]: large,
    [classes.medium]: medium,
    [classes.small]: small,
    [classes.rider]: rider,
    [classes.miniGrid]: miniGrid,
    [classes.mini]: mini,
    [classes.rounded]: rounded,
  })

  if (renderAsDiv) {
    return (
      <div
        variant="outlined"
        alt="AV8 Template"
        className={buttonClasses}
        url={url}
        data-meta-name="image"
        {...rest}
      ></div>
    )
  }
  return (
    <img
      variant="outlined"
      alt="AV8 Template"
      className={buttonClasses}
      url={url}
      data-meta-name="image"
      {...rest}
    ></img>
  )
}
