import * as hash from 'hash.js'
import { useRTRenderParams } from '../../../hooks/useRTRender'

export function generateRTRImageParamsHash<T>(params: useRTRenderParams<T>): string {
  try {
    const { layers, data, width, height = width } = params

    const serializedData = JSON.stringify(data)

    const serializedLayers = layers.map(({ id }) => id).join('')

    const stringParams = [serializedLayers, serializedData, width, height].join('')

    return hash.sha1().update(stringParams).digest('hex')
  } catch (err) {
    console.error('Failed to generate image hash with params:', params, err)
    return ''
  }
}
