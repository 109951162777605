import React from 'react'
import { generateDataAttrs } from '../../utils/utils'

// eslint-disable-next-line react/display-name
export const StyledTaggedComponent = (StyledComponent) =>
  function TaggedComponent({ children = [], dataAttrs = {}, ...props }) {
    const _dataAttrs = generateDataAttrs(dataAttrs)
    const mergedProps = { ..._dataAttrs, ...props }
    return <StyledComponent {...mergedProps}>{children}</StyledComponent>
  }
