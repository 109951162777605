import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generateDataAttrs } from '../../utils/utils'

export default function Input({
  label,
  name,
  error,
  warning,
  lengthCounting,
  maxLength,
  value = '',
  onChange,
  dataAttrs = {},
  ...props
}) {
  const styles = () => ({
    label: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      color: '#292929',
      opacity: '0.65',
    },
    field: {
      marginTop: '8px',
      borderRadius: '5px',
      border: '1px solid var(--blue)',
      padding: '10px',
      '&::placeholder': {
        fontFamily: 'StyreneMedium',
        fontSize: '12px',
        color: 'var(--blue)',
        opacity: '0.5',
      },
      width: '90%',
      color: 'var(--blue)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
    },
    textContainer: {
      width: '100%',
      position: 'relative',
      marginTop: 2,
      marginBottom: '15px',
    },
    textCounter: {
      position: 'absolute',
      top: error ? '30%' : '45%',
      right: '8%',
      textAlign: 'right',
      width: maxLength > 100 ? '70px' : '50px',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      color: 'var(--blue)',
      opacity: '0.5',
    },
    messageWrapper: {
      paddingRight: 10,
    },
    errorMessage: {
      fontSize: '10px',
      color: 'var(--red)',
      fontFamily: 'StyreneRegular',
      position: 'relative',
      left: '0px',
    },
    warningMessage: {
      fontSize: '10px',
      color: 'var(--light-grey)',
      fontFamily: 'StyreneRegular',
      position: 'relative',
      left: '0px',
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const Message = () => {
    if (error) {
      return <span className={classes.errorMessage}>{error}</span>
    }
    if (warning) {
      return <span className={classes.warningMessage}>{warning}</span>
    }
    return <></>
  }

  return (
    <Grid container direction="column" {...generateDataAttrs(dataAttrs)}>
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      <div className={classes.textContainer}>
        <input
          name={name}
          className={classes.field}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          {...props}
        />
        {lengthCounting && (
          <span className={classes.textCounter}>{lengthCounting}</span>
        )}
        {!lengthCounting && <br />}
        <div className={classes.messageWrapper}>
          <Message />
        </div>
      </div>
    </Grid>
  )
}
