import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import CustomGridContainer from '../components/common/CustomGridContainer'
import Layout from '../components/common/Layout.js'
import Loading from '../components/common/Loading'
import ExistingSignageReview from '../components/custom/Signage/ExistingSignageReview'
import NewSignageReview from '../components/custom/Signage/NewSignageReview'
import { useAuthContext } from '../context/AuthContext'
import { useSignageContext } from '../context/SignageContext'
import { useRequestContext } from '../context/RequestContext'
import ReviewRequestView from '../components/custom/ReviewRequestView'
import { useConfirmationDialog } from '../components/custom/ModalDialog'
import {
  getSignOptions,
  submitSignageRequest,
} from '../services/signageServices'

import { formatNotNewRequestOptionObject } from '../utils/utils'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useSignageStore } from '../modules/signage/store/signage.store'

const SIGNAGE_STEP_COUNT = 3

export default function ReviewSignageRequest() {
  const history = useHistoryPrefix()
  const { getConfirmation } = useConfirmationDialog()
  const signsStore = useSignageStore((store) => store.signs)

  const { flow = 'signage', subflow, requestTypePath, packageType = 'outdoor' } = useParams()
  const { listingAddressCtx } = useRequestContext()
  const { requestTypes, dataToBePresented, setDataToBePresented } =
    useSignageContext()

  const { agentId } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({})
  const [options, setOptions] = useState([])
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)

  useEffect(() => {
    const loadPageData = async () => {
      const onLoad = selectOnLoadFunction(requestTypePath)
      onLoad()
      setLoading(false)
    }
    loadPageData()
  }, [])

  useEffect(() => {
    const isValid = !Object.values(formData).some(
      (field) => field.value === '' && field.required,
    )
    setIsNextButtonDisabled(!isValid)
  }, [formData, isNextButtonDisabled])

  const renderReviewComponent = (requestType) => {
    switch (requestType) {
      case 'new-installation':
        return <NewSignageReview />
      case 'removal':
      case 're-installation':
        return (
          <ExistingSignageReview
            options={options}
            formData={formData}
            setFormData={setFormData}
          />
        )
      default:
        return <h3>Ooops</h3>
    }
  }

  const renderCTAText = (requestType) => {
    switch (requestType) {
      case 're-installation':
        return '\ninstalling signage\nrequest'
      case 'removal':
        return '\nremove signage\nrequest'
      case 'new-installation':
        return 'signage request'
      default:
        return 'request'
    }
  }

  const selectOnLoadFunction = (requestType) => {
    switch (requestType) {
      case 'new-installation':
        return onLoadNewSignage
      case 'removal':
      case 're-installation':
        return onLoadExistingSignage
      default:
        return () => {}
    }
  }

  const onLoadExistingSignage = async () => {
    try {
      const requestType = requestTypes.find(
        (requestType) => requestType?.type === requestTypePath,
      )
      const response = await getSignOptions(
        requestType.id,
        undefined,
        listingAddressCtx.city,
      )
      if (response.success) {
        setOptions(response.data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onLoadNewSignage = () => {
    const signsDataArray = Object.values(signsStore)
    setDataToBePresented(signsDataArray)
  }

  const formatSignageData = () => {
    const requestType = requestTypes.find(
      (requestType) => requestType.type === requestTypePath,
    )
    let formattedData = {
      agentId,
      request: requestType,
      listing: listingAddressCtx,
    }
    let signDataFormattedToBeSubmitted
    if (requestTypePath === 'new-installation') {
      signDataFormattedToBeSubmitted = dataToBePresented.map(
        (sign) => sign.requestFormattedData,
      )

      formattedData = {
        ...formattedData,
        signs: signDataFormattedToBeSubmitted,
      }
    } else {
      signDataFormattedToBeSubmitted = formatNotNewRequestOptionObject(formData)
      formattedData = {
        ...formattedData,
        options: signDataFormattedToBeSubmitted,
      }
    }
    console.log('formattedData', formattedData)
    return formattedData
  }

  const handleSignageRequestSubmit = async () => {
    const formattedData = formatSignageData()
    const response = await submitSignageRequest(formattedData)
    return response
  }

  const handleNextClick = async () => {
    setLoading(true)
    const response = await handleSignageRequestSubmit()
    if (response.success) {
      setLoading(false)
      const pathname = [
        flow,
        subflow,
        requestTypePath,
        packageType,
        'requestreceived',
      ].join('/')
      history.push({
        pathname,
        state: {
          data: response.data,
          options: formData,
        },
      })
    } else {
      setLoading(false)
      getConfirmation({
        title: '',
        message:
          'Ooops, something went wrong... Please try again or get in touch with us.',
        firstButtonText: 'OK',
      })
      Sentry.captureException('Error while submitting a request')
    }
  }

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <CustomGridContainer>
          <ReviewRequestView
            numberOfSteps={SIGNAGE_STEP_COUNT}
            activeStep={SIGNAGE_STEP_COUNT}
            handleNext={handleNextClick}
            isNextButtonDisabled={isNextButtonDisabled}
            CTATextLower={renderCTAText(requestTypePath)}
            reviewComponent={renderReviewComponent(requestTypePath)}
          />
        </CustomGridContainer>
      )}
    </Layout>
  )
}
