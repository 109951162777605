import React from 'react'
import { SelectContainer } from './Select.style'
import Text from '../common/Text'
import { StyledTaggedComponent } from './StyledTaggedComponent'

const Select = StyledTaggedComponent(
  ({ label, options = [], onChange, value }) => {
    return (
      <SelectContainer data-meta-type="select">
        <label htmlFor="custom-select">
          <Text type="muted">{label}</Text>
        </label>
        <div className="select">
          <select id="custom-select" onChange={onChange} value={value}>
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </SelectContainer>
    )
  },
)

export default Select
