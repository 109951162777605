import React, { createContext, useState, useContext } from 'react'

export const InputDataContext = createContext({})

export const useInputDataContext = () => useContext(InputDataContext)

export default function InputDataContextProvider({ children }) {
  const [formMarketingDataCtx, setFormMarketingDataCtx] = useState({})
  const [formSignageDataCtx, setFormSignageDataCtx] = useState({})

  return (
    <InputDataContext.Provider
      value={{
        formMarketingDataCtx,
        setFormMarketingDataCtx,
        formSignageDataCtx,
        setFormSignageDataCtx,
      }}
    >
      {children}
    </InputDataContext.Provider>
  )
}
