import React, { useState, useEffect } from 'react'
import { capitalize } from '@material-ui/core'
import {
  PrintModalStep,
  PrintModalStore,
} from '../../../../store/PrintModal.store'
import StepCounter from '../../../../../../components/common/StepCounter'

export type CustomizePrintModalStepperProps = {
  steps: PrintModalStep[]
  saveHandler: () => void
  closeHandler: () => void
}

export function CustomizePrintModalStepper({
  steps,
  saveHandler,
  closeHandler,
}: CustomizePrintModalStepperProps) {
  const mayContinue = PrintModalStore(({ mayContinue }) => mayContinue)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const setStep = PrintModalStore(({ setStep }) => setStep)
  const hasErrors = PrintModalStore(({ hasErrors }) => hasErrors())
  const enableContinue = PrintModalStore(({ enableContinue }) => enableContinue)
  const disableContinue = PrintModalStore(
    ({ disableContinue }) => disableContinue,
  )
  const step = PrintModalStore(({ step }) => step) || {
    type: 'edit',
    name: 'front',
  }
  const stepsNames = steps.map(({ name }) => capitalize(name))

  useEffect(() => {
    if (step) {
      setCurrentStepIdx(
        steps.findIndex((stepItem) => stepItem.name === step.name),
      )
    }
  }, [step, steps])

  const goToPreviousStep = () => {
    enableContinue()

    const previousStepIdx = currentStepIdx - 1
    if (previousStepIdx < 0) {
      closeHandler()
    } else {
      const previousStep = steps[previousStepIdx]
      setStep(previousStep)
    }
  }

  const goToNextStep = () => {
    const nextStepIdx = currentStepIdx + 1

    if (nextStepIdx >= steps.length) {
      saveHandler()
    } else {
      const nextStep = steps[nextStepIdx]
      setStep(nextStep)
    }

    if (currentStepIdx === steps.length) {
      disableContinue()
    } else {
      enableContinue()
    }
  }
  return (
    <StepCounter
      showOnlyNavButtons
      numberOfSteps={steps.length}
      activeStep={currentStepIdx + 1}
      handlePrevious={goToPreviousStep}
      handleNext={goToNextStep}
      nextButtonLabel={step?.type === 'review' ? 'Save' : 'Next'}
      bulletTextList={stepsNames}
      isNextButtonDisabled={!mayContinue || hasErrors}
    />
  )
}
