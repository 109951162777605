import React from 'react'
import StepCounter from '../common/StepCounter'
import PagePresentation from '../common/PageTitle'
import EmailSentDialog from './EmailTemplate/EmailSentDialog'

export default function ReviewRequestView({
  numberOfSteps,
  activeStep,
  handleNext,
  isNextButtonDisabled,
  CTATextLower,
  reviewComponent,
  nextButtonLabel = 'Send',
  isEmailSentDialogOpen = false,
  handleFinishClick = () => {},
}) {
  return (
    <>
      <EmailSentDialog
        isDialogOpen={isEmailSentDialogOpen}
        handleFinishClick={handleFinishClick}
      />
      <StepCounter
        numberOfSteps={numberOfSteps}
        activeStep={activeStep}
        handleNext={handleNext}
        nextButtonLabel={nextButtonLabel}
        isNextButtonDisabled={isNextButtonDisabled}
      />
      <PagePresentation
        CTATextUpper={'Review your'}
        CTATextLower={CTATextLower}
      />
      {reviewComponent}
      <StepCounter
        handleNext={handleNext}
        nextButtonLabel={nextButtonLabel}
        showOnlyNavButtons
        isNextButtonDisabled={isNextButtonDisabled}
      />
    </>
  )
}
