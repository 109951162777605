import { createContext, useContext } from 'react'
import { TemplateImage } from '../modules/marketing/components/TemplatePreview/TemplatePreviews'

export type ListingDescription = {
  headline: string;
  description: string;
  estimatedPrice: string;
  bedCount: string;
  bathCount: string;
  images: TemplateImage[];
  imagesIds: number[];
}

export type ListingDescriptionContext = {
  listingDescription: ListingDescription;
  setListingDescription: (listingDescription: ListingDescription) => void;
}

export const ListingDescriptionContext = createContext<ListingDescriptionContext>({
  listingDescription: {
    headline: '',
    description: '',
    estimatedPrice: '',
    bedCount: '',
    bathCount: '',
    images: [],
    imagesIds: [],
  },
  setListingDescription: () => {},
})

export const useListingDescriptionContext = (): ListingDescriptionContext => useContext<ListingDescriptionContext>(ListingDescriptionContext)
