import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Image from '../../../../components/common/Image'
import Text from '../../../../components/common/Text'
import Button from '../../../../components/common/Button'
import { RTRImage } from '../../components/RTRImage'
import OptionsPresentation from '../../../../components/common/OptionsPresentation'
import PNGDownload from '../../../../components/custom/PNGDownload'
import { RTR_PREVIEW_IMAGE_SIZE } from '../../../../utils/constants'
import { StrapiImage } from '../../components/TemplatePreview/TemplateRTRPreview'
import { formatTemplateData } from '../../utils/formatTemplateData'
import { useRequestContext } from '../../../../context/RequestContext'
import { useStageSelectionContext } from '../../../../context/StageSelectionContext'
import { useListingDescriptionContext } from '../../../../context/ListingDescriptionContext'

type SocialMediaTemplateReviewPresentationProps = {
  template: StrapiImage & {
    formData: {
      template: Record<string, any>
    }
    dynamicFormData: {
      template: Record<string, any>
    }
    carouselImages?: [{ isHidden?: boolean; url: string }]
    builder?: (size: any, layers: any) => () => Promise<SVGElement>
  }
  setShowInfoModal: (open: boolean) => void
}

export const SocialMediaTemplateReviewPresentation = ({
  template,
  setShowInfoModal,
}: SocialMediaTemplateReviewPresentationProps): JSX.Element => {
  const [generatedRTR, setGeneratedRTR] = useState<SVGElement | undefined>()

  //TO DO: Move this RTRData to when the package is saved
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()

  const { selectedPropertyCtx } = useRequestContext() as any
  const socialMediaTemplateRTRData =
    formatTemplateData({
      listingDescriptionData: listingDescriptionCtx,
      addressLine: selectedPropertyCtx,
      stageName: stageSelectionDataCtx.stageName,
    }) || {}

  const socialMediaTemplateOptionsData =
    { ...template.formData, ...template.dynamicFormData } || {}

  const isRTR = template.isRTR && template?.layers?.length > 0

  // We keep both to maintain retrocomapatibility
  const isSvgRTR = template.url && typeof template.url === 'object'

  const renderImage = () => {
    if (isSvgRTR) {
      return (
        <Image
          medium
          renderAsDiv={true}
          dangerouslySetInnerHTML={{
            __html: new XMLSerializer().serializeToString(template.url as any),
          }}
        />
      )
    }

    if (isRTR) {
      return (
        <RTRImage
          id={Math.abs(template.itemId) - 1}
          layers={template.layers}
          width={RTR_PREVIEW_IMAGE_SIZE}
          data={socialMediaTemplateRTRData}
          onRenderFinished={(result) => setGeneratedRTR(result)}
        />
      )
    }

    return <Image medium src={template.url} />
  }

  useEffect(() => {
    if (isSvgRTR) setGeneratedRTR(template.url as SVGElement)
  }, [template])

  return (
    <>
      <Grid container justify="center" direction="column" alignItems="center">
        {renderImage()}
        <Text type="blue" style={{ marginTop: 10 }}>
          {template.name || ''}
        </Text>
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Grid container justify="center" style={{ flex: '1' }}>
          <Text type="muted">View and download this post</Text>
        </Grid>
        <Grid container justify="space-evenly" style={{ marginTop: 15 }}>
          <Button
            style={{ width: 165, height: 50 }}
            primary
            title="Content Library"
            clickHandler={() => setShowInfoModal(true)}
          />
          <PNGDownload
            disabled={isRTR && !generatedRTR}
            url={isRTR ? generatedRTR : template.url}
            auxiliaryImageUrls={
              template?.carouselImages
                ?.filter(({ isHidden }) => !isHidden || isHidden !== true)
                ?.map((c) => c.url) ?? []
            }
            shouldRenderInBackend={isRTR}
          />
        </Grid>
      </Grid>
      <OptionsPresentation options={socialMediaTemplateOptionsData} />
    </>
  )
}
