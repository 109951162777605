import React from 'react'
import AV8Loading from '../../../assets/icons/AV8Loading.gif'
import RTRError from '../../../assets/RTRError.png'
import {
  RENDER_STATUS,
  useRTRender,
  useRTRenderParams,
} from '../../../hooks/useRTRender'
import Image from '../../../components/common/Image'
import { RTRTemplateData } from '../utils/formatTemplateData'
import { useEffect } from 'react'

export type RTRImageParams = useRTRenderParams<RTRTemplateData> & {
  shadow?: string
  previewWidth?: string
  previewHeight?: string
  onRenderFinished?: (image: SVGElement) => void
  onRenderErrored?: () => void
}

export const RTRImage: React.FC<RTRImageParams> = (params) => {
  const imageStyle = {
    boxShadow: params.shadow ?? '1px 1px 12px 1px rgba(20,20,20,0.1)',
    height: params.previewHeight ?? '100%',
    width: params.previewWidth ?? '250px',
  }

  const { renderedImage: image, error, status } = useRTRender(params)

  useEffect(() => {
    if (status === RENDER_STATUS.FINISHED && image) {
      params.onRenderFinished?.(image)
    } else if (
      [RENDER_STATUS.RENDER_ERROR, RENDER_STATUS.ENGINE_ERROR].includes(status)
    ) {
      params.onRenderErrored?.()
    }
  }, [status])

  if (error) {
    return <Image medium src={RTRError} />
  }

  if ([RENDER_STATUS.IDLE, RENDER_STATUS.RENDERING].includes(status)) {
    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          width: 250,
          height: 250,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          alt={'rendering template'}
          src={AV8Loading}
        />
      </div>
    )
  }
  // Post-generation image adjustment

  image?.setAttribute('width', '100%')
  image?.setAttribute('height', '100%')

  return (
    <Image
      style={imageStyle}
      draggable={false}
      renderAsDiv={true}
      dangerouslySetInnerHTML={{
        __html: new XMLSerializer().serializeToString(image as SVGElement),
      }}
    />
  )
}
