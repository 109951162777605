import { useHistory } from 'react-router-dom'
import { HISTORY_PUSH_PREFIX as prefix } from '../utils/constants'

const addPrefix = (pathname) =>
  `${prefix}/${pathname}`.trim().replace(/\/\//, '/')

const useHistoryPrefix = () => {
  const history = useHistory()

  const push = (target, ...rest) => {
    if (typeof target === 'string') {
      return history.push(addPrefix(target), ...rest)
    } else if (typeof target === 'object') {
      const { pathname = '' } = target
      return history.push(
        {
          ...target,
          pathname: addPrefix(pathname),
        },
        ...rest,
      )
    }
    return history.push(target, ...rest)
  }

  return {
    ...history,
    push,
  }
}

export default useHistoryPrefix
