import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Document, Page } from 'react-pdf'

export default function ContentGridMedia({
  media,
  isSelecting = false,
  ...props
}) {
  const styles = (theme) => ({
    gridItem: {
      cursor: 'pointer',
      height: '90px',
      width: '90px',
      objectFit: 'contain',
      filter: isSelecting ? 'brightness(50%)' : '',
      animation: 'all 1s ease',
      [theme.breakpoints.up('sm')]: {
        height: '150px',
        width: '150px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '170px',
        width: '170px',
      },
      canvas: {
        width: 160,
        height: 160,
      },
    },
    shimmerEffect: {
      height: '90px',
      width: '90px',
      background: '#f6f7f8',
      backgroundImage:
        'linear-gradient(to right, #f6f7f8 0%,#edeef1 20%, #f6f7f8 40%, #f6f7f8 100% )',
      backgroundSize: '90px 90px',
      animation: '$placeholderShimmer 1s linear infinite forwards',
      [theme.breakpoints.up('sm')]: {
        height: '150px',
        width: '150px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '170px',
        width: '170px',
      },
    },
    '@keyframes placeholderShimmer': {
      '0%': {
        backgroundPosition: '-45px 0',
      },
      '100%': {
        backgroundPosition: '45px 0',
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const isMediaTypeVideo =
    media.ContentGridMedia[0]?.type === 'video' ||
    media?.ContentGridMedia[0]?.mime?.includes('video')

  const isPDF =
    media.ContentGridMedia[0]?.type === 'pdf' ||
    media?.ContentGridMedia[0]?.mime?.includes('pdf')

  return isMediaTypeVideo ? (
    <div className={classes.shimmerEffect}>
      <video preload="metadata" className={classes.gridItem} {...props}>
        <source
          src={`${media?.ContentGridMedia[0]?.src}#t=0.1`}
          type="video/mp4"
        />
      </video>
    </div>
  ) : isPDF ? (
    <div className={classes.shimmerEffect} onClick={props.onClick}>
      <Document
        loading=""
        file={media?.ContentGridMedia[0]?.src}
        className={classes.gridItem}
        onLoadError={console.error}
        onLoadSuccess={() => {}}
      >
        <Page pageNumber={1} />
      </Document>
    </div>
  ) : (
      <img
        src={
          media?.ContentGridMedia[0]?.formats?.thumbnail.url ||
          media?.ContentGridMedia[0]?.src
        }
        alt={''}
        className={classes.gridItem}
        {...props}
      />
  )
}
