import React, { useCallback } from 'react'
import Checkbox from '../../common/Checkbox'
import Popper from './MultiSelectorPopper'
import FileSelector from './MultiSelectorFile'
import ListSelector from './MultiSelectorList'

export default function MultiSelector({
  listingItems,
  selectedOptions,
  setSelectedOptions,
  maxSelections,
}) {
  //Possibles type: default, popper and file_selector

  const handleChange = (e, item) => {
    handleOption(item)
  }

  // function to save checked options on the return
  const handleOption = (item) => {
    const isThisOptionSelected = selectedOptions.some(
      (_item) => _item.id === item.id,
    )

    const isOnlyExclusionaryOptionSelected = !!selectedOptions[0]?.isExclusionary

    if (isThisOptionSelected) {
      const sameBase64 = selectedOptions.some(
        (_item) => item?.base64 && _item.base64 === item.base64,
      )

      const sameOptions = selectedOptions.some(
        (_item) => item?.options && _item.options === item.options,
      )

      const isTypeException = ['file_selector', 'MLS_list'].includes(item.type)

      setSelectedOptions((selectedOptions) =>
        selectedOptions.filter((_item) => _item.id !== item.id),
      )

      if (isTypeException && !sameBase64 && !sameOptions) {
        setSelectedOptions((selectedOptions) => [...selectedOptions, item])
      }
    } else {
      if (item.isExclusionary || isOnlyExclusionaryOptionSelected){
        setSelectedOptions([item])
      }
      else{
        if (
          maxSelections &&
          maxSelections !== 0 &&
          selectedOptions[0]?.id !== item.id
        ) {
          if (selectedOptions.length === maxSelections) {
            handleOption(selectedOptions[0])
          }
        }
        setSelectedOptions((prevState) => [...prevState, item])
      }
    }
  }

  // Boolean function that check if the option is checked, it is very important that each option has a different value
  const IsChecked = (item) => {
    return selectedOptions.some((_item) => _item.value === item.value)
  }

  // memoized function to save checked options on the return
  const optionHandler = useCallback(
    (value) => {
      handleOption(value)
    },
    [selectedOptions],
  )

  return (
    <div>
      <div>
        {listingItems?.map((item, index) => {
          if (!item.hide) {
            switch (item.type) {
              case 'popper': {
                return (
                  <Popper
                    key={item.id}
                    list={item}
                    listingItems={listingItems}
                    optionHandler={optionHandler}
                    IsChecked={IsChecked}
                    parentIndex={index}
                    parentSelectedOptions={selectedOptions}
                  />
                )
              }
              case 'file_selector': {
                return (
                  <FileSelector
                    key={item.id}
                    list={item}
                    optionHandler={optionHandler}
                    IsChecked={IsChecked}
                    parentIndex={index}
                    value={item.value}
                    parentSelectedOptions={selectedOptions}
                  />
                )
              }
              case 'MLS_list': {
                return (
                  <ListSelector
                    key={item.id}
                    list={item}
                    optionHandler={optionHandler}
                    IsChecked={IsChecked}
                    parentIndex={index}
                    value={item.value}
                    parentSelectedOptions={selectedOptions}
                  />
                )
              }
              default: {
                return (
                  <Checkbox
                    key={item.id}
                    checked={IsChecked(item)}
                    type={'checkbox'}
                    label={item.label}
                    value={item.value}
                    plainText={item.plainText}
                    onChange={(e) =>
                      handleChange(e, {
                        name: 'SOI',
                        value: item.value,
                        type: 'default',
                        id: item.id,
                      })
                    }
                  />
                )
              }
            }
          }
        })}
      </div>
    </div>
  )
}
