import React, { memo, useEffect, useState } from 'react'
import { Box, Grid, makeStyles, MenuItem } from '@material-ui/core'
import MenuDropdown from './MenuDropdown'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

type FeatureType = {
  value: any
}

export type FeatureItemsType = {
  name: string
  features?: FeatureType[]
  placeholder: string
  maxFeatures: number
  featuresItemsInitialValue?: FeatureType[]
  handleInputItemChange: (featuresItems: FeatureType[]) => void
}

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  field: {
    marginTop: '8px',
    borderRadius: '5px',
    border: '1px solid var(--blue)',
    padding: '10px',
    '&::placeholder': {
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      color: 'var(--blue)',
      opacity: '0.5',
    },
    width: '90%',
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
  },
  label: {
    fontFamily: 'StyreneRegular',
    fontSize: '12px',
    color: '#292929',
    opacity: '0.65',
    marginTop: '5px',
  },
}))

const featureItemDefaultValue = { value: '' }

function FeatureItems({
  name,
  placeholder,
  maxFeatures = 8,
  featuresItemsInitialValue,
  handleInputItemChange,
}: FeatureItemsType) {
  const classes = useStyles()

  const initializeEmptyFeatures = () => {
    let emptyFeaturesList: FeatureType[] = []
    for (let i = 0; i < maxFeatures; i++) {
      emptyFeaturesList = [...emptyFeaturesList, { value: `Feature ${i + 1}` }]
    }
    return emptyFeaturesList
  }

  const [featuresItems, setFeaturesItems] = useState(
    featuresItemsInitialValue || initializeEmptyFeatures(),
  )

  useEffect(() => {
    !!handleInputItemChange && handleInputItemChange(featuresItems)
  }, [featuresItems])

  const handleInputChange = (e: any, index: number) => {
    const newFeaturesItems = featuresItems?.map((feature, featureIndex) => {
      if (featureIndex === index) {
        return { value: e.target.value }
      }
      return feature
    })
    setFeaturesItems(newFeaturesItems)
  }

  const handleAddFeatureClick = () => {
    setFeaturesItems((lastFeatureItems) => {
      const newFeaturesItems = [...lastFeatureItems, featureItemDefaultValue]
      return newFeaturesItems
    })
  }

  const handleRemoveFeatureClick = () => {
    setFeaturesItems((lastFeatureItems) => {
      //removes last feature
      const newFeaturesItems = lastFeatureItems.slice(
        0,
        lastFeatureItems.length - 1,
      )
      return newFeaturesItems
    })
  }

  return (
    <Box mb={3}>
      <Grid container direction='row' spacing={1}>
        <Grid item xs={10} className={classes.center}>
          <label htmlFor={name} className={classes.label}>
            Features (1 required, max {maxFeatures})
          </label>
        </Grid>
        <Grid item xs={2}>
          <AddOrRemoveFeature
            handleAddFeatureClick={handleAddFeatureClick}
            handleRemoveFeatureClick={handleRemoveFeatureClick}
            currentCounting={featuresItems?.length || 0}
            maxFeatures={maxFeatures}
          />
        </Grid>
      </Grid>
      {featuresItems?.map((feature, index) => (
        <input
          name={'feature'}
          key={'feature' + index}
          value={feature.value}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e, index)}
          className={classes.field}
        />
      ))}
    </Box>
  )
}

const AddOrRemoveFeature = ({
  handleAddFeatureClick = () => {},
  handleRemoveFeatureClick = () => {},
  currentCounting = 0,
  maxFeatures = 4,
}: any) => {
  return (
    <MenuDropdown
      title=''
      icon={<MoreHorizIcon />}
      showIcon
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        width: 20,
      }}
      small
      bordered
      secondary
      noBorder
    >
      <MenuItem
        onClick={handleAddFeatureClick}
        disabled={!(currentCounting < maxFeatures)}
        style={{
          borderBottom: '1px solid #D8D8D8',
          display: 'flex',
        }}
      >
        <Grid style={{ cursor: 'pointer' }}>
          <Grid style={{ display: 'flex' }}>
            <AddIcon style={{ marginRight: 5 }} />
            <label>Add Feature</label>
          </Grid>
        </Grid>
      </MenuItem>
      <MenuItem onClick={handleRemoveFeatureClick}>
        <Grid style={{ cursor: 'pointer' }}>
          <Grid style={{ display: 'flex' }}>
            <RemoveIcon style={{ marginRight: 5 }} />
            <label>Remove Feature</label>
          </Grid>
        </Grid>
      </MenuItem>
    </MenuDropdown>
  )
}

export default memo(FeatureItems)
