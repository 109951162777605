import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import PagePresentation from '../components/common/PageTitle'
import CustomGridContainer from '../components/common/CustomGridContainer'
import Layout from '../components/common/Layout.js'
import ButtonCard from '../components/common/ButtonCard'
import Loading from '../components/common/Loading'
import Error from '../components/common/ErrorMsg'
import { useAuthContext } from '../context/AuthContext'
import { getSignRequestType, getSignType } from '../services/signageServices'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useSignageContext } from '../context/SignageContext'
import { useRequestContext } from '../context/RequestContext'

export default function SignageNeedPage() {
  const history = useHistoryPrefix()
  const { setCurrentFlow, setSelectedPropertyCtx } = useRequestContext()
  const {
    setRequestTypes,
    setSignTypes,
    setInstallationType,
  } = useSignageContext()
  const { agentId } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [showPage, setShowPage] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setCurrentFlow('Outdoor Signage')
    setSelectedPropertyCtx('')

    const loadPageData = async () => {
      try {
        const requestTypesResponse = await getSignRequestType(agentId)
        const signTypes = await getSignType(agentId)
        if (requestTypesResponse.success) {
          setShowPage(true)
          setRequestTypes(requestTypesResponse.data)
          setSignTypes(signTypes.data)

          setLoading(false)
        } else {
          setLoading(false)
          setShowPage(true)
        }
      } catch (err) {
        setHasError(true)
      }
    }
    loadPageData()
  }, [])

  const handleNavigation = (path, flow) => {
    setCurrentFlow(flow)
    history.push(path)
  }

  return (
    <Layout>
      {loading && <Loading />}
      {hasError && <Error />}
      {showPage && (
        <CustomGridContainer>
          <PagePresentation
            CTATextUpper={'What are your'}
            CTATextLower={'signage needs?'}
          />
          <Grid style={{ marginBottom: 15 }}></Grid>
          <Grid style={{ alignSelf: 'center' }}>
            <ButtonCard
              large
              bordered
              secondary
              boxMedium
              title={'New Sign'}
              cardTitle={
                'Order and request delivery or installation of a new sign.'
              }
              clickHandler={() => {
                setInstallationType('new-installation')
                handleNavigation(
                  'signage/new-installation/propertyselection',
                  'Order / Install New',
                )
              }}
            />

            <ButtonCard
              large
              bordered
              secondary
              boxMedium
              title={'Existing Sign'}
              cardTitle={
                'Request the installation or removal of an existing sign.'
              }
              clickHandler={() =>
                handleNavigation(
                  'signage/existing/propertyselection',
                  'Install / Remove Signage',
                )
              }
            />
          </Grid>
        </CustomGridContainer>
      )}
    </Layout>
  )
}
