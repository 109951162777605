export const toMoney = (value, maxFraction = 0, minFraction = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maxFraction,
    minimumFractionDigits: minFraction ?? maxFraction,
  })
  const numericValue = Number.isNaN(+value) ? 0 : +value
  return formatter.format(+numericValue)
}
export const fromMoney = (value, fractionDigits = 0) => {
  const onlyDigits = value.replace(/\D/g, '')
  if (Number.isNaN(+onlyDigits) || onlyDigits === '') {
    return 0
  }
  const divider = Math.pow(10, fractionDigits)
  return parseInt(onlyDigits, 10) / divider
}
export default { get: fromMoney, set: toMoney }
