import React, { useEffect, useState } from 'react'
import { Box, capitalize } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Header from '../../../../../../components/common/Header'
import HeaderNavigation from '../../../../../../components/common/HeaderNavigation'
import { renderPdfInBackend } from '../../../../../shared/utils/svgRtrConverters'
import { zipAndDownloadFiles, zipAndReturnBase64 } from '../../../../../shared/utils/zipFilesAndDownload'
import { calculateTemplateSizeToRender } from '../../utils/calculateTemplateSizeToRender'
import { CustomizePrintModalStyles } from './CustomizePrintModal.styles'
import { RtrTemplate } from '../../../../components/TemplatePreview/TemplateRTRPreview'
import {
  PrintModalStep,
  PrintModalStepImage,
  PrintModalStore,
} from '../../../../store/PrintModal.store'
import { PrintCategory, printCategoryData } from './printCategory.data'
import { useInputDataContext } from '../../../../../../context/InputDataContext'
import { PrintTemplatePreview } from './PrintTemplatePreview'
import { formatTemplateData } from '../../../../utils/formatTemplateData'
import { useRequestContext } from '../../../../../../context/RequestContext'
import { PrintTemplateReview } from './PrintTemplateReview'
import {
  TemplateStepData,
  TemplateStepper,
} from '../../../../components/TemplateStepper/TemplateStepper'
import { toRtrTemplateImageItemId } from '../../../../../shared/utils/rtrTemplateImageItemId'
import { useListingDescriptionContext } from '../../../../../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../../../../../context/StageSelectionContext'
import { usePTRFormContextProvider } from '../CustomizePrintModalForm/CustomizePrintModalContext'
import useContentLibrary from '../../../../../../hooks/useContentLibrary'
import MLSLoader from '../../../../../../components/custom/MLSLoader'
import Modal from '../../../../../../components/common/Modal'
import { CustomizePrintModalStepper } from './CustomizePrintModalStepper'
import { downloadZIPFile, sendUserToAppHomeScreen } from '../../../../../../services/appServices'
import { uploadTemplateImage } from '../../../../utils/uploadImage'
import { useAuthContext } from '../../../../../../context/AuthContext'
import { MailingValues } from '../CustomizePrintModalForm/mockApi'
import {
  uploadMultipleListingImage,
  uploadToBucket,
} from '../../../../../../services/marketingServices'
import { PrintDesignValues } from '../CustomizePrintModalForm/Sections/PrintDesignSection'
import { zipDownloadImages } from '../../../../../../utils/utils'
import { searchPeopleInZipCodeToCSVFile } from '../../actions/dataAxleProxy'
import { useAgentRTRContext } from '../../../../../../context/AgentRTRContext'
import { useConfirmationDialog } from '../../../../../../components/custom/ModalDialog'

export type CustomizePrintWizardParams = {
  title: string
  closeHandler: () => void
  categoryName: PrintCategory
  template: RtrTemplate
  steps: PrintModalStep[]
}

// Currently our backend only accepts front/back as keys to send emails.
const mapStepToKey: { [key: string]: string } = {
  front: 'front',
  back: 'back',
  outside: 'front',
  inside: 'back',
}

export const CustomizePrintWizard: React.FC<CustomizePrintWizardParams> = (
  params,
) => {
  const { closeHandler, categoryName, template, title, steps } = params

  const { formMarketingDataCtx, setFormMarketingDataCtx } =
    useInputDataContext() as {
      formMarketingDataCtx: Record<string, any>
      setFormMarketingDataCtx: React.Dispatch<
        React.SetStateAction<Record<string, any>>
      >
    }

  const {
    handleSaveToContentLibrary,
    uploadBlobImages,
    loadingModalStatus,
    setLoadingModalStatus,
  } = useContentLibrary()

  const { getConfirmation } = useConfirmationDialog()

  const { selectedPropertyCtx, isEmbedded } = useRequestContext() as {
    selectedPropertyCtx: string
    isEmbedded: boolean
  }
  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()
  const { agentId } = useAuthContext() as any

  const classes = CustomizePrintModalStyles()
  const step = PrintModalStore(({ step }) => step)
  const formData = PrintModalStore(({ formData }) => formData)

  const setStepImages = PrintModalStore(({ setStepImages }) => setStepImages)
  const appendStepImage = PrintModalStore(
    ({ appendStepImage }) => appendStepImage,
  )

  const setFormData = PrintModalStore(({ setFormData }) => setFormData)

  const disableContinue = PrintModalStore(
    ({ disableContinue }) => disableContinue,
  )

  const {
    printingAndDesignSectionData,
    mailingSectionData,
    printNeedsSectionData,
    shippingSectionData,
    setRtrTemplate,
  } = usePTRFormContextProvider()

  const [activeTemplate, setActiveTemplate] = useState<RtrTemplate>(
    template?.children[0] ?? null,
  )
  // negative itemIds indicate RTR generated images
  const rtrTemplateIdx = toRtrTemplateImageItemId(template.id)
  const currentStepIdx = step
    ? steps.findIndex((stepItem) => stepItem === step)
    : 0

  const { agentInfo } = useAgentRTRContext()

  const baseTemplateData = {
    ...formatTemplateData({
      listingDescriptionData: listingDescriptionCtx,
      addressLine: selectedPropertyCtx,
      stageName: stageSelectionDataCtx.stageName,
    }),
    ...agentInfo,
  }

  useEffect(() => {
    const previousPackageData: Record<string, any> =
      formMarketingDataCtx?.print ?? {}
    const previousCategoryData: Record<string, any>[] =
      previousPackageData?.[categoryName] ?? []
    const { formData: previousFormData = {} } =
      previousCategoryData.find(({ itemId }) => rtrTemplateIdx === itemId) ?? {}
    setFormData(previousFormData)

    setRtrTemplate(template)
  }, [])

  useEffect(() => {
    const nonNegativeStepIdx = currentStepIdx < 0 ? 0 : currentStepIdx
    setActiveTemplate(
      step?.type === 'review'
        ? template
        : template.children?.[nonNegativeStepIdx] ?? template,
    )
  }, [step, currentStepIdx])

  const validateAndSave = async () => {
    const previousPackageData: Record<string, any> =
      formMarketingDataCtx?.print ?? {}
    const previousCategoryData: Record<string, any>[] =
      previousPackageData?.[categoryName] ?? []

    setLoadingModalStatus({
      loading: true,
      show: true,
      message: `Your ${
        printCategoryData[categoryName]?.title || categoryName
      } template is being saved.`,
    })
    let metadata
    const metadataImagesURL = await uploadHighResStepImages()
    metadata = metadataImagesURL

    // this means the upload failed
    if (!metadataImagesURL) {
      setLoadingModalStatus({
        loading: false,
        show: false,
        message: '',
      })
      return
    }

    if (mailingSectionData?.section === MailingValues.UploadList) {
      try {
        const { data } = await uploadToBucket(
          mailingSectionData?.data?.CSVList,
          agentId,
          'authenticated-read',
        )
        metadata = { ...metadata, mailingList: data.Location }
      } catch (err) {
        console.log(err)
      }
    }

    if (mailingSectionData?.section === MailingValues.BuildList) {
      try {
        const { values: { zip = '', distance = '' } = {} } =
          mailingSectionData.data

        const {
          values: { quantity = '0' },
        } = printNeedsSectionData

        const data = await searchPeopleInZipCodeToCSVFile(
          agentId,
          zip,
          distance,
          parseInt(quantity),
        )

        metadata = { ...metadata, mailingList: data.Location }
      } catch (err) {
        console.log(err)
      }
    }

    const formDataWithDataFromReviewForm = {
      ...formData,
      rehydrateFormData: {
        printingAndDesignSectionData,
        mailingSectionData,
        printNeedsSectionData,
        shippingSectionData,
      },
      metadata: {
        ...metadata,
        presentationData: formData.presentationData,
      },
    }

    const updatedData = {
      digital: formMarketingDataCtx.digital,
      print: {
        ...previousPackageData,
        [categoryName]: [
          ...previousCategoryData.filter(
            ({ itemId }) => itemId !== rtrTemplateIdx,
          ),
          {
            itemId: rtrTemplateIdx,
            formData: formDataWithDataFromReviewForm,
            dynamicFormData: {},
          },
        ],
      },
    }

    setFormMarketingDataCtx(updatedData)

    if (printingAndDesignSectionData === PrintDesignValues.DownloadOnly) {
      await saveToContentLibrary()
    }
    //If there's any digital or postcard asset should continue
    // if not it should go back to account screen
    const hasPostcardOrDigitalAsset =
      Object.keys(updatedData?.digital).length > 0 ||
      updatedData?.print?.postcard?.length > 0
    if (!hasPostcardOrDigitalAsset) {
      await handleDownloadImages({ asPDF: true })
      await saveToContentLibrary()
      handleHomeScreenRedirection()
    }

    setLoadingModalStatus({
      loading: false,
      show: false,
      message: '',
    })
    closeHandler()
  }

  const uploadHighResStepImages = async () => {
    try {
      const { stepImages } = PrintModalStore.getState()
      let metadataImagesURL = {}
      await Promise.all(
        stepImages.map(async (image, index) => {
          const fileBlob = new File([image.blob], `photo${index}.png`, {
            type: 'image/png',
          })

          const uploadedImage = await uploadTemplateImage(agentId, fileBlob)
          const key = mapStepToKey[image.step] || 'parent'
          metadataImagesURL = { ...metadataImagesURL, [key]: uploadedImage.url }
        }),
      )
      console.log(metadataImagesURL)
      return metadataImagesURL
    } catch (err) {
      getConfirmation({
        title: '',
        message:
          'Ooops, something went wrong... Please try again or get in touch with us.',
        firstButtonText: 'OK',
      })
      return
    }
  }

  const saveToContentLibrary = async () => {
    // Get current step images
    const { stepImages } = PrintModalStore.getState()

    // Upload RTR
    const images = await uploadBlobImages(stepImages.map((c) => c.blob))

    if (!images.length) return

    //Save images to content library.
    await handleSaveToContentLibrary(images)

    closeHandler()
  }

  const handleHomeScreenRedirection = () => {
    if (isEmbedded) {
      sendUserToAppHomeScreen()
    } else {
      window.location.href = `${window.location.origin}/account`
    }
  }

  const handleDownloadImages = async ({
    asPDF = false,
  }: { asPDF?: boolean } = {}) => {
    try {
      setLoadingModalStatus({
        loading: true,
        show: true,
        message: 'Downloading assets...',
      })
      const { stepImages } = PrintModalStore.getState()
      const imagesFormData = new FormData()
      if (asPDF) {
        const stepPdfFilesPromises = stepImages
          // removes steps' images without a SVG image already rendered
          .filter(
            (
              stepImage: PrintModalStepImage,
            ): stepImage is PrintModalStepImage & { svgImage: SVGElement } => {
              return !!stepImage.svgImage
            },
          )
          // push to render as PDF in the backend
          .map(
            async (
              stepImage: PrintModalStepImage & { svgImage: SVGElement },
            ) => {
              const {
                template: { input_package: stepInputPackage },
              } = stepImage
              const size = calculateTemplateSizeToRender(stepInputPackage)
              const {
                base64encoded: image,
                url: localUrl,
                blob,
              } = await renderPdfInBackend(stepImage.svgImage, size)
              return { ...stepImage, image, localUrl, blob }
            },
          )
       
        const stepPdfs = await Promise.all(stepPdfFilesPromises)
        if(isEmbedded) {
            const pdfFilesToDownload = stepPdfs.map(({ blob }) => ({
              blob,
              filename: 'template.pdf',
            }))
            const base64zip = await zipAndReturnBase64(
              pdfFilesToDownload,
              'AV8 Print Material',
              'Print',
            )
            await downloadZIPFile(base64zip)

        } else {
          const pdfFilesToDownload = stepPdfs.map(({ blob }) => ({
            blob,
            filename: 'template.pdf',
          }))
          await zipAndDownloadFiles(
            pdfFilesToDownload,
            'AV8 Print Material',
            'Print',
          )
        }
        
      } else {
        stepImages.forEach((childImage: PrintModalStepImage) => {
          const imageFile = new File([childImage.blob], childImage.step, {
            type: 'image/png',
          })
          imagesFormData.append('images', imageFile)
        })
        const { data } = await uploadMultipleListingImage(
          agentId,
          imagesFormData,
        )
        const uploadedImagesUrl = data.map(
          ({ imageUrl }: { imageUrl: string }) => imageUrl,
        )
        await zipDownloadImages(
          uploadedImagesUrl,
          'AV8 Print Material',
          'Print',
        )
      }

      setLoadingModalStatus({
        loading: false,
        show: false,
        message: '',
      })
    } catch (err) {
      setLoadingModalStatus({
        loading: true,
        show: true,
        message: 'Oops, something went wrong, please try again...',
      })
      setTimeout(() => {
        setLoadingModalStatus({ loading: false, show: false, message: '' })
      }, 3000)
    }
  }

  return (
    <>
      <Modal open={loadingModalStatus.show}>
        <MLSLoader
          loading={loadingModalStatus.loading}
          text={loadingModalStatus.message}
          setModalStatus={setLoadingModalStatus}
        />
      </Modal>
      <Header>
        <HeaderNavigation />
      </Header>
      <Grid>
        <Grid
          container
          className={classes.bodyContainer}
          justify='space-between'
          alignItems='center'
        >
          <TemplateStepper
            activeStep={currentStepIdx < 0 ? 0 : currentStepIdx}
            steps={steps.map(({ name }, idx): TemplateStepData => {
              return {
                name,
                number: idx,
                label: capitalize(name),
                bulletText: capitalize(name),
              }
            })}
          />
          <CustomizePrintModalStepper
            steps={steps}
            closeHandler={closeHandler}
            saveHandler={validateAndSave}
          />
        </Grid>
        <Grid className={classes.titleContainer}>
          <div className={classes.title}>Customize</div>
          <div className={classes.subTitle}>{title} Template</div>
        </Grid>
        <Box className={classes.contentContainer}>
          {step?.type === 'edit' && activeTemplate && (
            <Box className={classes.container}>
              <PrintTemplatePreview
                key={step.name + activeTemplate.id}
                categoryTitle={title}
                stepName={step.name}
                baseTemplateData={baseTemplateData}
                template={activeTemplate}
              />
            </Box>
          )}
          {step?.type === 'review' && (
            <PrintTemplateReview
              appendStepImage={appendStepImage}
              template={template}
              templateCategory={categoryName}
              formData={formData}
              baseTemplateData={baseTemplateData}
              setFormData={setFormData}
              setStepImages={setStepImages}
              disableContinue={disableContinue}
              handleDownloadImages={
                // flyer/brochure pdf-only for now
                () => handleDownloadImages({ asPDF: true })
              }
            />
          )}
        </Box>
      </Grid>
    </>
  )
}
