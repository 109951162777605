import { createContext, useContext } from 'react'

export type TemplateTypes = Record<string, any>[];

export type TemplateTypesContext = {
  templateTypes: TemplateTypes;
  setTemplateTypes: (templateTypes: TemplateTypes) => void
}

export const TemplateTypesContext = createContext<TemplateTypesContext>({
  templateTypes: [],
  setTemplateTypes: () => {},
})

export const useTemplateTypesContext = (): TemplateTypesContext => useContext<TemplateTypesContext>(TemplateTypesContext)
