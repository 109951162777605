export const ROUTE_PREFIX = process.env.REACT_APP_ROUTE_PREFIX || '(/account)?'
export const HISTORY_PUSH_PREFIX =
  process.env.REACT_APP_PUSH_PREFIX || '/account'
export const TEMPLATE_EXCLUDE_LIST = [
  process.env.REACT_APP_EMAIL_RTR && process.env.REACT_APP_EMAIL_RTR === 'true'
    ? 'email-blast'
    : 'email-rtr',
]
export const IMPORTING_TYPES = Object.freeze({
  Logo: 'Logo',
  MLS: 'MLS',
})
export const RTR_SMALL_PREVIEW_IMAGE_SIZE = 250
export const RTR_PREVIEW_IMAGE_SIZE = 512
export const RTR_FULL_RENDER_IMAGE_SIZE = 1080
export const RTR_V2_ENABLED_TEMPLATES = []
export const SIGNAGE_PACKAGE = 'outdoor'

export const CSVTemplateS3URL =
  'https://avenue8-visual-marketing-production.s3.us-east-2.amazonaws.com/csvTemplate/Contacts+Import+Template.csv'
export const PrintCSVTemplateS3URL =
  'https://avenue8-visual-marketing-staging.s3.us-east-2.amazonaws.com/contact-list-template.csv'
