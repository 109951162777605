import React, { useState } from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'
import Text from '../../common/Text'
import Button from '../../common/Button'
import Checkbox from '../../common/Checkbox'

const useStyles = makeStyles(() => ({
  container: {
    padding: '25px 20px 25px 25px',
  },
  optionsGrid: {
    maxHeight: '330px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  buttonsGrid: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '41px',
  },
}))

export default function MultiSelectorList({
  list,
  optionHandler,
  IsChecked,
  parentSelectedOptions,
}) {
  const classes = useStyles()
  const parentList =
    parentSelectedOptions.find((item) => item.value == list.value)?.options ||
    []
  const [selectedList, setSelectedList] = useState(parentList)
  const [previousSelectedList, setPreviousSelectedList] = useState([])
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    if (!IsChecked(list)) {
      setSelectedList([])
      setPreviousSelectedList([])
    } else {
      setPreviousSelectedList(selectedList)
    }
    setOpen(true)
  }

  const handleClose = (cancel) => {
    if (cancel) {
      setSelectedList(previousSelectedList)
    } else {
      if (selectedList !== previousSelectedList) {
        optionHandler({
          value: list.value,
          type: list.type,
          options: selectedList,
          id: list.id,
          isExclusionary: list?.isExclusionary,
        })
      }
    }
    setOpen(false)
  }

  const changeHandler = (option) => {
    const newList = selectedList.filter(
      (_selectedOption) => _selectedOption !== option,
    )
    if (newList.length < selectedList.length) {
      setSelectedList(newList)
    } else {
      setSelectedList([...selectedList, option])
    }
  }

  return (
    <>
      <Checkbox
        key={list.id}
        checked={IsChecked(list)}
        type={'checkbox'}
        label={
          <>
            <Text type="regular" component="span">
              {list.listLabel + ' '}
            </Text>
            <Text
              type="regular"
              component="span"
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              {`${parentList.length || list.singleArticle} ${list.highlightedLabel}`}
            </Text>
          </>
        }
        value={'list'}
        onChange={handleClickOpen}
      />
      <Dialog
        onClose={() => handleClose(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
            margin: 16,
            maxWidth: 375,
            overflow: 'hidden',
          },
        }}
      >
        <Grid className={classes.container}>
          <Grid container alignItems="center">
            <Grid container style={{ marginBottom: 30 }}>
              <Text type="strong" fontSize="35px">
                Select&nbsp;
              </Text>
              <Text
                type="strong"
                fontSize="35px"
                style={{
                  fontFamily: 'BookmaniaRegularItalic',
                  display: 'inline',
                }}
              >
                a list.
              </Text>
              <Text
                type="regular"
                style={{ fontSize: '12px', marginTop: '20px' }}
              >
                Select from the following MLS Lists:
              </Text>
            </Grid>
            <Grid container className={classes.optionsGrid}>
              <Grid>
                {list.options.map((_option) => {
                  return (
                    <Checkbox
                      label={
                        _option.name.length > 28
                          ? _option.name.slice(0, 25) + '...'
                          : _option.name
                      }
                      type={'checkbox'}
                      checked={
                        selectedList?.some(
                          (_selectedOption) => _selectedOption.id === _option.id,
                        ) ?? false
                      }
                      plainText
                      plainBorder
                      checkmarkProps={{
                        width: '34px',
                        height: '34px',
                        borderRadius: '0',
                      }}
                      style={{ marginLeft: '10px' }}
                      handleClick={() => {
                        changeHandler(_option)
                      }}
                      key={_option.name}
                    />
                  )
                })}
              </Grid>
            </Grid>
            <Grid container className={classes.buttonsGrid}>
              <Button
                medium
                title="Cancel"
                clickHandler={() => handleClose(true)}
                secondary
                style={{ width: '109px', height: '40px' }}
                dataAttrs={{
                  metaAction: 'click-cancel-options-mailing-list',
                }}
              />
              <Button
                medium
                title="Apply"
                disabled={!selectedList.length}
                clickHandler={() => handleClose(false)}
                primary
                style={{ width: '109px', height: '40px' }}
                dataAttrs={{
                  metaAction: 'click-apply-options-mailing-list',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
