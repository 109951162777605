import { authServiceApi } from './api'

export const getAgentProfile = async () => {
  try {
    const { data } = await authServiceApi.get('/agent')
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getIntercomCredentials = async () => {
  try {
    const { data } = await authServiceApi.get('/intercom/credentials')
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}
