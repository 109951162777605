import React, { useState } from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'
import CropIcon from '@material-ui/icons/Crop'
import CheckIcon from '@material-ui/icons/Check'
import Text from '../common/Text'
import Button from '../common/Button'
import CroppableImage from '../common/CroppableImage'
import { isElement } from 'lodash'
import { generateDataAttrs } from '../../utils/utils'

const styles = {
  container: {
    padding: '15px',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px 15px',
    marginBottom: 20,
    maxHeight: 400,
    overflowY: 'scroll',
  },
  cropShadow: {
    background: '#FAFAFA',
    padding: '20px',
    marginBottom: '68px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
  },
  checkIcon: {
    color: '#12B777',
    fontSize: 18,
  },
  checkLabel: {
    marginLeft: 8,
    color: '#12B777',
    fontSize: 12,
  },
}
const useStyles = makeStyles(styles)

export default function CropImageModal({ image, handleUpdateCrop }) {
  const classes = useStyles()
  const [cropResolution, setCropResolution] = useState(null)
  const [open, setOpen] = useState(false)

  const [currentCrop, setCurrentCrop] = useState(null)

  const handleClickOpen = () => {
    setCurrentCrop(null)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getPreviewCrop = (image, crop) => {
    if (!isElement(image)) return null

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height

    const futureSize = (crop.height / image.height) * 1080

    setCropResolution(Math.round(futureSize).toString())

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    return canvas.toDataURL()
  }

  const handleCropCompleted = async (image, crop) => {
    const previewUrl = getPreviewCrop(crop.refImage, crop.refCrop)
    setCurrentCrop({
      crop: { ...crop, previewUrl },
      image,
    })
  }

  const handleApply = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { refImage, ...serializableCrop } = currentCrop.crop
    handleUpdateCrop(serializableCrop, currentCrop.image)
    handleClose()
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        onClick={handleClickOpen}
        {...generateDataAttrs({ metaAction: 'crop-image' })}
      >
        <CropIcon
          style={{
            fontSize: 18,
            cursor: 'pointer',
          }}
        />
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{
          style: {
            borderRadius: '0',
            margin: 16,
            maxWidth: 375,
            minHeight: 400,
          },
        }}
      >
        <Grid
          className={classes.container}
          {...generateDataAttrs({
            metaName: 'crop-image-modal',
            metaType: 'modal',
          })}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 40 }}
          >
            <Text
              type="medium"
              onClick={handleClose}
              style={{ cursor: 'pointer', marginTop: 5 }}
              {...generateDataAttrs({
                metaAction: 'go-back',
              })}
            >
              Back
            </Text>
            <Text
              type="medium"
              onClick={handleClose}
              style={{ cursor: 'pointer', marginTop: 5 }}
              {...generateDataAttrs({
                metaAction: 'cancel-image-crop',
              })}
            >
              Cancel
            </Text>
          </Grid>
          <Grid style={{ marginBottom: 20 }}>
            <Text type="strong">Crop Property Image:</Text>
          </Grid>
          <Grid className={classes.cropShadow}>
            <Text
              type="muted"
              style={{
                marginBottom: 20,
                marginTop: 16,
              }}
            >
              Crop and resize your photo by dragging and zooming the frame:
            </Text>
            <CroppableImage
              initialCrop={
                image.refCrop ?? {
                  unit: 'px',
                  aspect: 1,
                  width: 100,
                }
              }
              url={image.url}
              onCompleted={(c) => handleCropCompleted(image, c)}
            />
            <Text type="muted" style={{ marginTop: 10 }}>
              {image?.name || image?.caption || image?.id || ''}
            </Text>
            {cropResolution !== null && (
              <Grid container justify="flex-start" alignItems="center">
                <CheckIcon className={classes.checkIcon} />
                <Text type="muted" className={classes.checkLabel}>
                  Size {cropResolution}x{cropResolution}
                </Text>
              </Grid>
            )}
          </Grid>
          <Grid container justify="center" style={{ marginBottom: 40 }}>
            <Button
              medium
              disabled={currentCrop == null}
              title={'Apply (1/1)'}
              clickHandler={handleApply}
              primary
              dataAttrs={{ metaAction: 'apply-image-crop' }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
