import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../common/Text'
import Button from '../common/Button'
import ContentGridItem from '../custom/ContentGridItem'

export default function ContentGridCategory({ category }) {
  const styles = (theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      justifyItems: 'center',
      gridGap: '15px',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
      marginTop: 16,
      marginBottom: category.posts.length > 0 ? 20 : 0,
    },
    seeMoreBtnGrid: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(9)

  const handleSeeMoreClick = () => {
    setNumberOfItemsDisplayed(numberOfItemsDisplayed + 9)
  }

  const showSeeMoreButton = numberOfItemsDisplayed < category.posts.length

  return (
    <Grid key={category.name}>
      <Text
        style={{
          borderBottom: '1px solid rgba(42, 41, 166, 0.25)',
          paddingBottom: 6,
        }}
      >
        {category.name}
      </Text>
      <Grid className={classes.grid}>
        {category?.posts?.map((post, index) => {
          const isVisible = index < numberOfItemsDisplayed
          const itemKey = String(post.id) + category.name.replaceAll(' ', '')

          return (
            <ContentGridItem
              gridItem={post}
              key={itemKey}
              itemKey={itemKey}
              category={category}
              isVisible={isVisible}
            />
          )
        })}
      </Grid>
      <Grid className={classes.seeMoreBtnGrid}>
        {showSeeMoreButton && (
          <Button
            small
            bordered
            secondary
            title="See More"
            classes={{ disabled: classes.disabledButton }}
            clickHandler={handleSeeMoreClick}
          />
        )}
      </Grid>
    </Grid>
  )
}
