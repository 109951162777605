import React from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import {
  Devices as DevicesIcon,
  CallReceived as ImportIcon,
} from '@material-ui/icons'
import {
  Image as ImageType,
  ImportFromGalleryModal,
} from '../../../marketing/components/ImportFromGalleryModal/ImportFromGalleryModal'
import {
  CropImageModal,
  CropAndPreview,
  Image as CropImageType,
} from '../../../marketing/components/CropImageModal/CropImageModal'
import Image from '../../../../components/common/Image'
import MenuDropdown from '../../../../components/common/MenuDropdown'
import { getBase64 } from '../../../shared/utils/getBase64FromFile'
import { presentActionSheet } from '../../../../services/appServices'
import { ImageSelectorStyles } from './ImageSelector.styles'
import Button from '../../../../components/common/Button'
import Text from '../../../../components/common/Text'

export type ImageSelectorProps = {
  label: string
  name: string
  previewImage: ImageType
  onImageChange: (image: ImageType) => void
  ignoreCrop?: boolean
  isEmbedded: boolean
  images: ImageType[]
  cropAspectRatio?: number
  cropTitle?: string
  importFromGalleryLabel: string
  importingType?: 'MLS' | 'Logo'
  singleAction?: boolean
  disableImportFromMLS?: boolean
}

const IMPORT_FROM_GALLERY_LABEL = 'Import from MLS'
const IMPORT_FROM_DEVICE_LABEL = 'Import from device'

export const ImageSelector = (props: ImageSelectorProps): JSX.Element => {
  const {
    label,
    name,
    previewImage,
    onImageChange,
    isEmbedded,
    images,
    ignoreCrop = false,
    cropAspectRatio,
    cropTitle,
    importFromGalleryLabel = IMPORT_FROM_GALLERY_LABEL,
    importingType = 'MLS',
    singleAction = false,
    disableImportFromMLS = false,
  } = props
  const classes = ImageSelectorStyles()
  const [fileUploadMode, setFileUploadMode] = React.useState(false)
  const [showSelectImageFromGalleryModal, setShowSelectImageFromGalleryModal] =
    React.useState(false)
  const [showCropImageModal, setShowCropImageModal] = React.useState(false)
  const [selectedImage, setSelectedImage] =
    React.useState<ImageType>(previewImage)
  const importFromGalleyRef = React.createRef<HTMLDivElement>()
  const inputFileRef = React.createRef<HTMLInputElement>()

  const handleIOSActionSheetCallback = (actionId: string) => {
    switch (actionId) {
      case 'handleImageFromGallery':
        inputFileRef.current?.click()
        break
      case 'importFromMLS':
        importFromGalleyRef.current?.click()
        break
      default:
        console.error('Error calling callback from iOS app')
        break
    }
  }

  const sentDataSheetRequest = () => {
    const options = [
      {
        actionID: 'openGallery',
        actionLabel: IMPORT_FROM_DEVICE_LABEL,
        callback: 'handleImageFromGallery',
      },
      {
        actionID: 'importFromMLS',
        actionLabel: importFromGalleryLabel,
        callback: 'handleIOSImportFromMLS',
      },
    ]

    presentActionSheet(options, handleIOSActionSheetCallback)
  }

  const handleImageEditSelection = (
    selectedImages: ImageType[],
    cancel = false,
  ) => {
    const [chosenImage] = selectedImages
    setFileUploadMode(false)
    if (chosenImage && !cancel) {
      setSelectedImage(chosenImage)
      if (!ignoreCrop) {
        setShowCropImageModal(true)
      } else {
        onImageChange(chosenImage)
      }
    }
    setShowSelectImageFromGalleryModal(false)
  }

  const handleCropImageUpdate = (
    crop: CropAndPreview | null,
    image: CropImageType | null,
  ) => {
    if (crop && image) {
      const croppedImage = {
        name: image.name,
        url: crop.finalUrl,
        id: image.id,
      }
      setSelectedImage(croppedImage)
      onImageChange(croppedImage)
    }
    setFileUploadMode(false)
    setShowCropImageModal(false)
  }

  const handleImageFromGallery = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files ?? []
    if (files.length === 0) {
      return
    }

    const [file] = files
    const base64Image = await getBase64(file)
    const image: ImageType = {
      id: new Date().getTime().toString(),
      name: file.name,
      url: base64Image as string,
    }

    setSelectedImage(image)
    if (!ignoreCrop) {
      setShowCropImageModal(true)
    }
  }

  const handleGoBackToImageSelection = () => {
    setShowCropImageModal(false)
    if (fileUploadMode) {
      inputFileRef.current?.click()
    } else {
      setShowSelectImageFromGalleryModal(true)
    }
  }

  const importFromMLSButton: JSX.Element = (
    <Grid
      container
      alignItems="center"
      onClick={() => setShowSelectImageFromGalleryModal(true)}
    >
      <ImportIcon
        style={{
          fontSize: 24,
          marginRight: 10,
          transform: 'rotateY(180deg)',
        }}
      />
      {importFromGalleryLabel}
    </Grid>
  )

  const renderDropDown = () => {
    // Don't render dropdown in this case
    if (singleAction)
      return (
        <Button
          onClick={() => setShowSelectImageFromGalleryModal(true)}
          clickHandler={() => {}}
          primary
          xsmall
          title={'Add'}
        />
      )

    return (
      <MenuDropdown title={'Change'} icon={null} showIcon>
        {isEmbedded ? (
          sentDataSheetRequest()
        ) : (
          <div>
            <MenuItem
              onClick={() => {}}
              style={{
                borderBottom: '1px solid #D8D8D8',
              }}
            >
              <label style={{ cursor: 'pointer' }}>
                <Grid container alignItems="center">
                  <input
                    ref={inputFileRef}
                    type='file'
                    name='file'
                    id='file'
                    accept='image/*'
                    onClick={() => setFileUploadMode(true)}
                    onChange={handleImageFromGallery}
                    style={{ display: 'none' }}
                  />
                  <DevicesIcon
                    style={{
                      fontSize: 24,
                      marginRight: 10,
                    }}
                  />
                  {IMPORT_FROM_DEVICE_LABEL}
                </Grid>
              </label>
            </MenuItem>
            <MenuItem disabled={disableImportFromMLS}>
              {importFromMLSButton}
              {showCropImageModal && (
                <CropImageModal
                  showIcon={false}
                  open={true}
                  title={cropTitle ?? 'Crop Property Image'}
                  description="Crop and resize your photo by dragging and zooming the frame:"
                  image={selectedImage}
                  showBackButton
                  onGoBack={handleGoBackToImageSelection}
                  handleUpdateCrop={handleCropImageUpdate}
                  cropAspectRatio={cropAspectRatio}
                />
              )}
            </MenuItem>
          </div>
        )}
      </MenuDropdown>
    )
  }

  return (
    <>
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      {disableImportFromMLS && 
        <Text type="error" fontSize="10px">
          Requires high resolution images
        </Text>}
      <Grid className={classes.container}>
        <div className={classes.imageContainer}>
          <Image
            style={{ objectFit: 'scale-down' }}
            height="100%"
            width="100%"
            src={selectedImage?.url ?? ''}
          />
        </div>
        {renderDropDown()}
        {showSelectImageFromGalleryModal && (
          <ImportFromGalleryModal
            open={showSelectImageFromGalleryModal}
            ref={importFromGalleyRef}
            images={images}
            alreadySelectedImages={[selectedImage]}
            buttonClickHandler={handleImageEditSelection}
            selectableImagesLimit={1}
            title={label}
            subTitle={name}
            quantityOfImagesToShow={6}
            importingType={importingType}
          />
        )}
      </Grid>
    </>
  )
}
