import { DimensionsObject } from '../../../../shared/utils/svgRtrConverters'
import { RtrTemplate } from '../../../components/TemplatePreview/TemplateRTRPreview'

const CM_TO_IN_FACTOR = 2.54

export const calculateTemplateSizeToRender = ({ width, height, dpi, dimensions_unit }: RtrTemplate['input_package']): DimensionsObject => {
  switch (dimensions_unit) {
    case 'in':
      return { width: width * dpi, height: height * dpi }
    case 'cm':
      return { width: (width / CM_TO_IN_FACTOR) * dpi, height: (height / CM_TO_IN_FACTOR) * dpi }
    case 'px':
    default:
      return { width, height }
  }
}
