import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../../common/Text'
import Button from '../../common/Button'
import MarketingRequestReview from './MarketingRequestReview'

const styles = () => ({
  upperAreaContainer: {
    padding: '0 0 40px 0',
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
    marginBottom: 20,
  },
})

export default function RequestReceivedPresentation({
  handleRequestSignageclick,
}) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.upperAreaContainer}
      >
        <Text type="muted" style={{ marginBottom: 15 }}>
          Do you need new signage or existing signage installation at this
          property?
        </Text>
        <Button
          medium
          clickHandler={handleRequestSignageclick}
          primary
          title="Request Signage"
          dataAttrs={{
            metaAction: 'click-request-signage',
          }}
        />
      </Grid>
      <MarketingRequestReview showTotalCost={false} isEditable={false} />
    </Grid>
  )
}
