import React from 'react'
import Input from '../../../../components/common/Input'

const Field = ({ field, onFieldChange, type, value, error }) => {

  const { placeholder = '' } = field?.metadata
  return (
    <div key={field._uid}>
      <Input
        label={field.label}
        name={field.name}
        type={type}
        value={value}
        error={error}
        placeholder={placeholder}
        onChange={(e) => {
        onFieldChange(field._uid, { value: e.target.value, ...field}, field.label)
      }}
    />
    </div>
  )
}

export default Field
