import { AxiosResponse } from 'axios'
import { marketingApi } from '../../../../../services/api'
import { distanceRegex } from '../../../../shared/utils/distanceRegex'
import { zipRegex } from '../../../../shared/utils/zipRegex'
import { UploadedCSVFile } from '../../../../shared/interfaces/UploadedCSVFile'

const DATA_AXLE_MAX_SEARCH_LIMIT = 4000

export type PeopleSearchRecord = {
  street: string
  city: string
  state: string
  [k: string]: any
}

export type SearchPeopleResult = {
  count: number
  records: PeopleSearchRecord[]
}

export async function searchPeopleInZipCode(agentId: string, zip: string, distance: string, limit = 1) {
  const urlParams = new URLSearchParams({
    agentId,
    limit: limit.toFixed(0),
    zip,
    distance,
  })
  const url = `/data-axle/search/postal-code?${urlParams}`
  return marketingApi.get<SearchPeopleResult>(url)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function matchPeopleInZipCode(agentId: string, zip: string, distance: string, limit = 1) {
  const urlParams = new URLSearchParams({
    agentId,
    zip,
    distance,
  })
  const url = `/data-axle/match/postal-code?${urlParams}`
  return marketingApi.get<SearchPeopleResult>(url)
}

export const searchPeopleInZipCodeToCSVFile = (agentId: number, zip: string, distance: string, limit: number) => {
  if (Number.isNaN(agentId) || agentId < 1) {
    throw new Error('invalid agentId')
  }
  if (!zipRegex.test(zip)) {
    throw new Error('invalid zip (postal code)')
  }
  if (!distanceRegex.test(distance)) {
    throw new Error('invalid distance')
  }
  if (Number.isNaN(limit) || limit < 1 || limit > DATA_AXLE_MAX_SEARCH_LIMIT) {
    throw new Error('invalid limit')
  }
  const params = new URLSearchParams({
    agentId: agentId.toString(),
    zip: zip,
    distance: distance,
    limit: limit.toString(),
  })
  return marketingApi.get<UploadedCSVFile>(`/data-axle/search/postal-code/csv?${params}`)
    .then((response: AxiosResponse<UploadedCSVFile>) => response.data)
}
