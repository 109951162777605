import React, { useEffect, useState } from 'react'
import AV8Loading from '../../../../assets/icons/AV8Loading.gif'
import RTRError from '../../../../assets/RTRError.png'
import {
  BaseTemplateData,
  RENDER_STATUS,
  RTRLayer,
} from '../../../../hooks/useRTRender'
import PackageDetailsItem from '../../../../components/custom/PackageDetailsItem'
import { toStrapiImageFormat } from '../../../../utils/rtr'
import { TemplatesPackage } from '../../../../context/MarketingContext'
import { DynamicFormOption } from '../../utils/inputPackage'
import { RTREngine } from 'av8-rtrt-renderer'
import {
  PackageDetailsData,
  usePackageDetailsContext,
} from '../../../../context/PackageDetailsContext'
import update from 'immutability-helper'
import { isSocialMedia } from '../../../../utils/filters'

export type RtrTemplate = {
  children: RtrTemplate[]
  id: number
  input_package: {
    created_at: string
    id: number
    input_type: string | null
    name: string | null
    type: 'front' | 'back' | 'inside' | 'outside'
    dpi: number
    height: number
    width: number
    dimensions_unit?: 'in' | 'cm' | 'px'
    inputs: Array<DynamicFormOption>
  }
  layers: RTRLayer[]
  name: string
  templateOrder: number
}

export type TemplateRTRPreviewProps = {
  index: number
  category: TemplatesPackage
  dynamicFormTrigger: string
  rtrTemplate: RtrTemplate & {
    url?: SVGElement
    baseUrl?: string
  }
  templateData: BaseTemplateData
  staticImagePreview: string
}

const statusImages: Record<RENDER_STATUS, any> = {
  [RENDER_STATUS.FINISHED]: RTRError,
  [RENDER_STATUS.RENDER_ERROR]: RTRError,
  [RENDER_STATUS.ENGINE_ERROR]: RTRError,
  [RENDER_STATUS.RENDERING]: AV8Loading,
  [RENDER_STATUS.IDLE]: AV8Loading,
}

export type StrapiImage = {
  itemId: number
  id: number
  templateGroupNumber: number
  name: string
  templateOrder: number
  url: string | SVGElement
  baseUrl: string
  formats: {
    small: {
      url: string
    }
  }
  isRTR: boolean
  layers: RTRLayer[]
  inputs: Record<string, any>
  children: RtrTemplate[]
}

export const TemplateRTRPreview = (props: TemplateRTRPreviewProps) => {
  const {
    category,
    dynamicFormTrigger,
    index,
    rtrTemplate,
    templateData,
    staticImagePreview = '',
  } = props

  const [renderStatus, setRenderStatus] = useState<RENDER_STATUS>(
    RENDER_STATUS.IDLE,
  )

  const error = false

  const {
    packageDetailsData: packageDetailsDataCtx,
    setPackageDetailsData: setPackageDetailsDataCtx,
  } = usePackageDetailsContext()

  const renderSocialRTRImage = async () => {
    console.log('Render RTR template for the first time')
    setRenderStatus(RENDER_STATUS.RENDERING)

    const engine = new RTREngine()
    engine.setup()

    const result = await engine.render(
      rtrTemplate.layers,
      templateData,
      1080,
      1080,
    )
    // Post-generation image adjustment

    result.setAttribute('width', '100%')
    result.setAttribute('height', '100%')

    const { digital } = packageDetailsDataCtx

    const socialMediaCategoryIndex = digital.findIndex(isSocialMedia)
    const templateId = digital[socialMediaCategoryIndex].rtrTemplates.findIndex(
      (c) => c.id === rtrTemplate.id,
    )

    const updateCurrentState = (initial: PackageDetailsData) => {
      return update(initial, {
        digital: {
          [socialMediaCategoryIndex]: {
            rtrTemplates: {
              [templateId]: {
                $apply: function (item: RtrTemplate) {
                  return {
                    ...item,
                    url: result,
                    dataUsedForRender: templateData,
                  }
                },
              },
            },
          },
        },
      })
    }

    setPackageDetailsDataCtx(updateCurrentState)

    setRenderStatus(RENDER_STATUS.FINISHED)
  }

  useEffect(() => {
    if (!rtrTemplate.url) {
      renderSocialRTRImage()
    } else {
      setRenderStatus(RENDER_STATUS.FINISHED)
    }
  }, [])

  const [, ...otherImages] = templateData.images

  const imageToShow = staticImagePreview
    ? staticImagePreview
    : renderStatus === RENDER_STATUS.FINISHED
    ? (rtrTemplate.url as SVGElement)
    : statusImages[renderStatus]

  const baseUrl = templateData.propertyImage

  const image: StrapiImage = toStrapiImageFormat(
    index,
    rtrTemplate,
    category,
    imageToShow,
    rtrTemplate.baseUrl ?? baseUrl,
    otherImages,
  )

  return error ? (
    <p>Oops...</p>
  ) : (
    <PackageDetailsItem
      index={index}
      image={image}
      packageType={category.packageType}
      packageData={category}
      propThatTriggersDynamicForm={dynamicFormTrigger}
    />
  )
}
