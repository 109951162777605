import { makeStyles } from '@material-ui/core/styles'

export const CustomizePrintModalStyles = makeStyles((theme) => ({
  container: {},
  bodyContainer: {
    padding: '15px 20px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
      margin: '5% auto 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      marginTop: '2%',
      marginBottom: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
      margin: '5% auto 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      marginTop: '2%',
      marginBottom: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  titleContainer: {
    padding: '0px 20px',
    maxWidth: 350,
    [theme.breakpoints.up('sm')]: {
      margin: '5% auto 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      marginTop: '2%',
      marginBottom: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    marginTop: '12px',
  },
  gridSpace: {
    marginTop: '10%',
  },
  title: {
    fontFamily: 'Bookmania',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
  },
  subTitle: {
    fontFamily: 'Bookmania',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
    marginBottom: '25px',
  },
}))
