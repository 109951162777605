export const isRtrTemplateItemId = (itemId: number): boolean => itemId < 0

export const toRtrTemplateImageItemId = (itemId: number): number => {
  if (itemId < 0) {
    return itemId
  }
  return (itemId + 1) * -1
}

export const fromRtrTemplateImageItemId = (itemId: number): number => {
  if (itemId > -1) {
    return itemId
  }
  return (itemId * -1) - 1
}
