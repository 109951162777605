import React from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '../common/Divider'
import RightIcon from '../custom/HeaderNavRightIcon'
import LeftIcon from '../custom/HeaderNavLeftIcon'
import { NonEmbeddedHeader } from 'av8-ui'
import { useConfirmationDialog } from '../custom/ModalDialog'
import { useRequestContext } from '../../context/RequestContext'
import { useAuthContext } from '../../context/AuthContext'

const styles = {
  container: {
    maxHeight: '20vh',
    minHeight: '55px',
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '25% 50% 25%',
    alignItems: 'center',
    justifyItems: 'center',
  },
  headerText: {
    fontSize: '15px',
    fontFamily: 'BookmaniaBold',
    letterSpacing: '0px',
    color: 'var(--white)',
  },
  headerTextWrapper: {
    display: 'flex',
  },
  iconText: {
    fontFamily: 'StyreneMedium',
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: 3,
  },
  selectedPropertyWrapper: {
    width: '82%',
    marginLeft: '9%',
  },
  selectedPropertyTextBox: {
    height: '45px',
    backgroundColor: 'var(--blue)',
    padding: '14px 0 7px 0',
    textAlign: 'center',
  },
}

export default function HeaderNavigation({ confirmLeave = false }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { pathname } = useLocation()
  const { getConfirmation } = useConfirmationDialog()
  let headerNavigationTitle = 'Marketing Request'
  const { authSBL } = useAuthContext()
  const { currentFlow, selectedPropertyCtx, isEmbedded } = useRequestContext()
  if (currentFlow) {
    headerNavigationTitle = currentFlow
  }

  const handleLogoClick = () => {
    getConfirmation({
      title: 'Exit?',
      message: 'Are you sure you want to exit? All unsaved data will be lost.',
    }).then((confirmed) => {
      if (confirmed) {
        location.href = location.origin
      }
    })
  }

  const menuOptions = [
    // TODO: double check this being redundant as we already have the "Exit" btn
    {
      text: 'Menu',
      onClick: () => {
        const leave = () => {
          window.location.href = `${window.location.origin}/account`
        }
        if (confirmLeave) {
          getConfirmation({
            title: 'Exit?',
            message:
              'Are you sure you want to exit? All unsaved data will be lost.',
          }).then((confirmed) => {
            if (confirmed) {
              leave()
            }
          })
        } else {
          leave()
        }
      },
    },
    {
      text: 'Log Out',
      onClick: () => {
        getConfirmation({
          title: 'Sign out?',
          message: `Are you sure you want to log out?${
            confirmLeave ? ' All unsaved data will be lost.' : ''
          }`,
        }).then((confirmed) => {
          if (confirmed && authSBL?.signOut) {
            authSBL.signOut()
          }
        })
      },
    },
  ]

  return (
    <>
      {!isEmbedded && (
        <NonEmbeddedHeader
          centralText=""
          menuOptions={menuOptions}
          logoHref={window.location.origin}
          onLogoClick={confirmLeave && handleLogoClick}
        />
      )}
      <Grid container className={classes.container}>
        <Grid item>
          <LeftIcon pathname={pathname} confirmLeave={confirmLeave} />
        </Grid>
        <Grid item className={classes.headerTextWrapper}>
          <Typography className={classes.headerText}>
            {headerNavigationTitle}
          </Typography>
        </Grid>
        <Grid item>
          <RightIcon pathname={pathname} />
        </Grid>
      </Grid>
      {selectedPropertyCtx && (
        <>
          <Grid className={classes.selectedPropertyWrapper}>
            <Divider />
            <Grid className={classes.selectedPropertyTextBox}>
              <Typography
                className={classes.iconText}
                data-test-name="selected-property"
              >
                {selectedPropertyCtx}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
