import { RTREngine } from 'av8-rtrt-renderer'
import { useRTRenderParams } from '../../../hooks/useRTRender'
import { RenderedTemplateImage, useRTRStore } from '../store/rtr.store'
import { generateRTRImageParamsHash } from '../utils/generateRTRImageParamsHash'
import { RTRTemplateData } from '../utils/formatTemplateData'
import { swapPropertyImageForCropped } from '../utils/swapPropertyImageForCropped'

export type RenderTemplateParams<T = RTRTemplateData> = useRTRenderParams<T>

export type RenderTemplate = {
  paramsHash: string
  image: RenderedTemplateImage
}

const engine: RTREngine = new RTREngine()
engine.setup()

export async function renderTemplate(
  params: RenderTemplateParams,
): Promise<RenderTemplate> {
  const { id, layers, data } = params
  let { width = undefined, height = undefined } = params

  const paramsHash = generateRTRImageParamsHash(params)

  if (!width && !height) {
    width = layers[0].width
    height = layers[0].height
  }
  const aspectRatio = layers[0].width / layers[0].height

  if (!width) throw Error('At least one dimension must be informed')

  if (!height) height = width / aspectRatio

  const savedImage: RenderedTemplateImage | undefined =
    useRTRStore.getState().images[id]?.[paramsHash]

  if (savedImage) {
    return { paramsHash, image: savedImage }
  }

  const templateData = swapPropertyImageForCropped(data)

  const image = await engine.render(layers, templateData, width, height)
  useRTRStore.getState().setRTRImage(id, paramsHash, image)

  return { paramsHash, image }
}
