import React, { useEffect, useState } from 'react'
import Input from '../../common/Input'
import { minLengthRule } from './rules/min-length.rule'
import { maxLengthRule } from './rules/max-length.rule'
import { isNumberRule } from './rules/is-number.rule'
import { isPositiveNumberRule } from './rules/is-positive-number.rule'
import { isNumberGreaterThanRule } from './rules/is-number-greater-than.rule'
import { isNumberLesserThanRule } from './rules/is-number-lesser-than.rule'
import { compileRules } from './rules/compile-rules'
import { validateRules } from './rules/validate-rules'
import moneyFormatter from './formatters/money.formatter'

const inputItemRules = {
  minLength: minLengthRule,
  maxLength: maxLengthRule,
  isNumber: isNumberRule,
  isPositiveNumber: isPositiveNumberRule,
  isNumberGreaterThan: isNumberGreaterThanRule,
  isNumberLesserThan: isNumberLesserThanRule,
  // ['max-length']: maxLengthRule
}

const inputItemFormatters = {
  money: moneyFormatter,
}

export const InputItem = ({ data, formData: formDataContext }) => {
  const { label, name, metadata, required = false } = data
  const {
    placeholder,
    rules = [],
    formatter = null,
    type: inputType = 'text',
    attrs = {},
  } = metadata ?? {}
  const { formData, setFormData } = formDataContext
  const [formattedValue, setFormattedValue] = useState(null)
  const [errorRulesList, setErrorRulesList] = useState([])
  const [warningRulesList, setWarningRulesList] = useState([])

  useEffect(() => {
    const errorRules = compileRules(
      inputItemRules,
      rules.filter(({ level }) => !level || level === 'error'),
    )
    const warningRules = compileRules(
      inputItemRules,
      rules.filter(({ level }) => level && level === 'warning'),
    )
    setErrorRulesList(errorRules)
    setWarningRulesList(warningRules)
  }, [`${rules}`])

  useEffect(() => {
    const { value = '' } = formData[name] || {}
    const isEmptyValue = value === ''
    const { errors } = validateRules(value, formData[name], errorRulesList)
    const { errors: warnings } = validateRules(
      value,
      formData[name],
      warningRulesList,
    )
    if (required && isEmptyValue) {
      errors.push('This is a required field.')
    }
    const error = !isEmptyValue && errors[0]
    const warning = !isEmptyValue && warnings[0]
    const { set: setter } = (formatter && inputItemFormatters[formatter]) || {}
    const formattedValue = error
      ? formData[name]?.value
      : !isEmptyValue && setter
      ? setter(value)
      : ''
    setFormattedValue(formattedValue)
    const newInputData = {
      ...(formData[name] || {}),
      metadata,
      optionLabel: label || placeholder,
      placeholder,
      formattedValue: formattedValue ? formattedValue : null,
      warning,
      error,
      name,
    }
    setFormData({ ...formData, [name]: newInputData })
  }, [formData[name]?.value])

  const changeHandler = (event) => {
    const { get: getter } = inputItemFormatters[formatter] || {}
    const { target: { value: inputValue } = {} } = event
    const isEmptyValue = inputValue === ''
    const newValue = !isEmptyValue && getter ? getter(inputValue) : inputValue
    const previousValue = formData[name]?.value
    const { errors } = validateRules(
      newValue,
      formData[name],
      errorRulesList,
    )

    if (newValue !== previousValue) {
      // resetting value to empty because: https://github.com/facebook/react/issues/10829#issuecomment-332207341
      const isInputInvalid = inputType === 'number' && newValue.length < 1
      setFormData({
        ...formData,
        [name]: {
          ...(formData[name] || {}),
          value: isInputInvalid ? '0' : newValue,
          error: !isEmptyValue && errors[0],
        },
      })
    }
  }

  const maxLengthRule = rules.find((rule) => rule.type === 'max-length')
  return (
    <Input
      {...attrs}
      label={label}
      name={name}
      type={inputType}
      value={formattedValue || formData[name]?.value}
      error={formData[name]?.error}
      warning={formData[name]?.warning}
      placeholder={placeholder}
      lengthCounting={
        maxLengthRule
          ? `${formData[name]?.value.length} / ${maxLengthRule?.value}`
          : ''
      }
      maxLength={maxLengthRule ? maxLengthRule?.value : ''}
      onChange={changeHandler}
    />
  )
}
