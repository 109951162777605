export default function EmailBlastChecker(requestId, action) {
    switch (action) {
        case 'editEmail':
            if(requestId){
                return `marketing/mylisting/${requestId}/edit/emailblast`
            }
            break

        case 'confirmEmail':
            if(requestId){
                return `marketing/mylisting/${requestId}/confirm/emailblast`
            }
            break
        default:
            return null 
    }
    return null
  }