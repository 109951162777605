import React from 'react'
import { Grid } from '@material-ui/core'
import { generateDataAttrs } from '../../../../utils/utils'
import Datepicker from '../../../../components/common/Datepicker'
import Text from '../../../../components/common/Text'

export default function DatepickerField({
  field,
  onFieldChange,
  value,
  error,
}) {
  const { placeholder = '' } = field?.metadata

  return (
    <Grid
      style={{ marginBottom: 10 }}
      {...generateDataAttrs({
        //   ...meta,
        metaName: name,
        metaType: 'datepicker',
      })}
    >
      <Text type='muted'>{field.label}</Text>
      <Datepicker
        label={field.label}
        value={value}
        name={field.name}
        dateFormat='MM/dd/yyyy'
        minDate={new Date()}
        //   {...rulesObj}
        onChange={(date) => {
          onFieldChange(
            field._uid,
            { value: date, ...field },
            field.label,
          )
        }}
        placeholderText={placeholder || 'MM/DD/YYYY'}
      />
      <Text type='error' style={{ marginTop: 5 }}>
        {error}
      </Text>
    </Grid>
  )
}
