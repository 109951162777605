import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Grid, makeStyles } from '@material-ui/core'
import CropImageModal from './CropImageModal'
import Text from '../common/Text'
import ImageComponent from '../common/Image'
import FileUploadInput from '../common/FileUploadInput'
import Button from '../common/Button'
import UploadIcon from '../../assets/icons/UploadIcon.svg'
import { formatMlsImages, generateDataAttrs } from '../../utils/utils'
import { useMarketingHelpersContext } from '../../context/MarketingHelpersContext'

const maxNumberOfImagesIfMLSDataFound = 36
const maxNumberOfImagesIfMLSDataNotFound = 8

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 25,
    maxWidth: 300,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 375,
    },
  },
  innerContainer: {
    background: '#F3F3F7',
    maxWidth: 335,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 375,
    },
  },
  imagesGrid: {
    display: 'grid',
    gridTemplateColumns: '115px 115px',
    gridGap: '25px',
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '145px 145px',
    },
  },
  imagesFullGrid: {
    display: 'grid',
    gridTemplateColumns: '280px',
    padding: '10px 0',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '290px',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  cropImageBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    background: 'white',
    color: 'black',
    height: 25,
    width: 25,
  },
}))

export default function ListingDetailsImageInput({
  imagesArray,
  listingMlsImages,
  setFieldValue,
  error,
  setError,
}) {
  const classes = useStyles()
  const [images, setImages] = useState(imagesArray || [])
  const [hasMLSData, setHasMLSData] = useState(false)

  const { marketingHelpers, setMarketingHelpers } = useMarketingHelpersContext()

  useEffect(() => {
    setHasMLSData(listingMlsImages.length > 0)
  }, [listingMlsImages])

  useEffect(() => {
    if (imagesArray) {
      setImages(imagesArray)
      setFieldValue('images', imagesArray)
    }
  }, [imagesArray])

  const handleLoadMoreClick = () => {
    // filter to not load repeated images and get the first 4
    const nextImages = formatMlsImages(listingMlsImages)
      .filter((image) => !images.find((_image) => _image.id === image.id))
      .slice(0, 4)

    const updatedImageArray = [...images, ...nextImages].slice(
      0,
      maxNumberOfImagesIfMLSDataFound,
    )
    setImages(updatedImageArray)
    setFieldValue('images', updatedImageArray)
    setMarketingHelpers({ ...marketingHelpers, shouldUploadImagesAgain: true })
  }

  const readAndValidateImage = (image) => {
    return new Promise((resolve) => {
      const newImage = new Image()
      if (image.type === 'url') {
        newImage.src = image.url
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.addEventListener('load', (event) => {
          const result = event.target.result
          newImage.src = result
        })
      }
      newImage.onload = () => {
        if (newImage.height < 1000 && newImage.width < 800) {
          setError(
            'Image resolution is too low. Please contact Avenue 8 via chat for assistance.',
          )
          resolve(false)
        } else if (newImage.height < 2000 && newImage.width < 1600) {
          setError(
            'Low resolution image will produce poor results. Please upload a higher-quality image',
          )
        }
        resolve(true)
      }
    })
  }

  const handleMultipleChange = async (e) => {
    const filesArray = e.target.files
    const imageArray = [...images]
    setError('')
    for (let i = 0; i < filesArray.length; i++) {
      try {
        const isValid = await readAndValidateImage(filesArray[i])
        if (isValid === true) {
          const imageObject = {
            url: URL.createObjectURL(filesArray[i]),
            id: uuidv4(),
            name: filesArray[i].name,
            image: filesArray[i],
            type: 'file',
          }
          imageArray.push(imageObject)
        }
      } catch (err) {
        setError(err)
      }
    }

    const imagesMaxQuantity = hasMLSData
      ? maxNumberOfImagesIfMLSDataFound
      : maxNumberOfImagesIfMLSDataNotFound
    setMarketingHelpers({ ...marketingHelpers, shouldUploadImagesAgain: true })
    setImages(imageArray.slice(0, imagesMaxQuantity))
    // setFieldValue('images', imageArray)
    setFieldValue('images', imageArray.slice(0, imagesMaxQuantity))
  }

  const handleRemove = (image) => {
    const updatedImageArray = images.filter((image_) => image_.id !== image.id)
    setError('')
    setImages(updatedImageArray)
    setFieldValue('images', updatedImageArray)
  }

  const handleUpdateCrop = (crop, refImage) => {
    const updatedImageArray = images.map((image) => {
      if (`${image.url}` === `${refImage.url}`) {
        return {
          ...image,
          crop,
          refCrop: crop.refCrop,
        }
      }
      return { ...image }
    })

    setImages(updatedImageArray)
    setFieldValue('images', updatedImageArray)
  }

  const selectGridClass = () => {
    const className =
      images.length > 0 ? classes.imagesGrid : classes.imagesFullGrid
    return className
  }

  const hasNoMLSDataAndNotReachedImagesLimit =
    !hasMLSData && images.length < maxNumberOfImagesIfMLSDataNotFound
  const hasMLSDataAndNotReachedImagesLimit =
    hasMLSData &&
    (images.length <
      listingMlsImages.slice(0, maxNumberOfImagesIfMLSDataFound).length ||
      images.length < maxNumberOfImagesIfMLSDataFound)

  const showLoadMoreButton =
    images.length <
    listingMlsImages.slice(0, maxNumberOfImagesIfMLSDataFound).length

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid container>
        <Text type="muted" style={{ marginBottom: 10 }}>
          {hasMLSData
            ? `Property photos (${
                images?.length || 0
              }/${maxNumberOfImagesIfMLSDataFound})`
            : `Property Photos (1 required, max ${maxNumberOfImagesIfMLSDataNotFound})`}
        </Text>
        {hasMLSData && <Text type="error" style={{ marginBottom: 10 }}>
          {'MLS Photos are not suitable for print material.'}
        </Text>}
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.innerContainer}
      >
        <Grid className={selectGridClass()}>
          {images.map((image, idx) => {
            return (
              <Grid key={image.id} className={classes.imageContainer}>
                <ImageComponent
                  miniGrid
                  src={image.crop?.previewUrl ?? image.url}
                  alt=""
                  // style={{
                  //   objectFit: 'contain'
                  // }}
                />
                <Grid
                  className={classes.cropImageBtn}
                  {...generateDataAttrs({
                    metaAction: 'crop-image',
                    metaId: image.id,
                    metaIdx: idx,
                  })}
                >
                  <CropImageModal
                    image={image}
                    handleUpdateCrop={handleUpdateCrop}
                  />
                </Grid>
                <Text type="muted">{image.name?.toString()}</Text>
                <Text
                  onClick={() => handleRemove(image)}
                  type="blue"
                  style={{ cursor: 'pointer' }}
                  {...generateDataAttrs({
                    metaAction: 'remove-image',
                    metaIdx: idx,
                    metaId: image.id,
                  })}
                >
                  Remove
                </Text>
              </Grid>
            )
          })}
          <Grid className={classes.imageContainer}>
            {(hasNoMLSDataAndNotReachedImagesLimit ||
              hasMLSDataAndNotReachedImagesLimit) && (
              <FileUploadInput
                hasUploadAreaContainer
                label={'Upload a Photo'}
                handleOnChange={handleMultipleChange}
                icon={UploadIcon}
                dataAttrs={{ metaName: 'listing-image-upload' }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          {showLoadMoreButton && (
            <Button
              muted
              noBorder
              title="Load More"
              fullWidth
              clickHandler={handleLoadMoreClick}
              dataAttrs={{ metaAction: 'load-more-images' }}
            />
          )}
          {error && (
            <Text
              type="error"
              style={{ margin: '15px' }}
              dataAttrs={{ metaName: 'listing-image-upload-error' }}
            >
              {error}
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
