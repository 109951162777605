import { rtrBackendApi } from '../../../services/api'

export type DimensionsObject = {
  height: number | string
  width: number | string
}

export const svgToBlobUrl = (svgElement: SVGElement) => {
  const xml = new XMLSerializer().serializeToString(svgElement)

  const blob = new Blob([xml], {
    type: 'image/svg+xml',
  })

  return URL.createObjectURL(blob)
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

export const renderPngInBackend = async (svgElement: SVGElement) => {
  const xml = new XMLSerializer().serializeToString(svgElement)

  const png = await rtrBackendApi.post('/png', xml, {
    responseType: 'blob',
  })

  return {
    blob: png.data as Blob,
    url: URL.createObjectURL(png.data as Blob),
    base64encoded: await blobToBase64(png.data as any),
  }
}

export const renderPdfInBackend = async (
  svgElement: SVGElement,
  size: DimensionsObject | Record<string, any> = {},
) => {
  const { width = 1080, height = 1080 } = size

  const xml = new XMLSerializer().serializeToString(svgElement)

  const png = await rtrBackendApi.post(`/pdf/${width}/${height}`, xml, {
    responseType: 'blob',
  })

  return {
    blob: png.data as Blob,
    url: URL.createObjectURL(png.data as Blob),
    base64encoded: await blobToBase64(png.data as any),
  }
}
