/**
 * Validate an array of rules where each rule returns true if valid or a string
 * if not with a error messages to be displayed for each rule
 *
 * @param {any} value to be validated
 * @param {any} element reference to be used in relative rules
 * @param {(function(*,*?): (boolean|string))[]} rules array ((v: any, element: any?):(true|string))
 * @returns {{valid: boolean, errors: string[]}}
 */
export const validateRules = (value, element, rules = []) => {
  const errors = rules
    .map((rule) => rule(value, element))
    .filter((validation) => validation !== true)

  return { valid: !errors.length, errors }
}
