import create from 'zustand'
import { RtrTemplate } from '../components/TemplatePreview/TemplateRTRPreview'

export type ChildImage = {
  step: string
  image?: string
  template: RtrTemplate
  data: Record<string, any>
}

export type PrintModalStep = { name: string; type: 'edit' | 'review' }
export type PrintModalStepImage = ChildImage & {
  svgImage?: SVGElement,
  image: string
  localUrl: string
  blob: Blob
}
export type PrintModalFormData = Record<string, Record<string, any>>

export type PrintModalStoreState = {
  step: PrintModalStep | null
  errors: Record<string, boolean>
  formData: PrintModalFormData
  hasErrors: () => boolean
  clearErrors: () => void
  setError: (key: string, value: boolean) => void
  setStep: (step: PrintModalStep | null) => void
  setFormData: (data: PrintModalFormData) => void
  updateFormData: (key: string, data: PrintModalFormData) => void
  enableContinue: () => void
  disableContinue: () => void
  mayContinue: boolean
  appendStepImage: (stepImage: PrintModalStepImage) => void
  setStepImages: (stepImages: PrintModalStepImage[]) => void
  stepImages: PrintModalStepImage[]
  clear: () => void
}

export const PrintModalStore = create<PrintModalStoreState>((set, get) => ({
  step: null,
  errors: {},
  formData: {},
  stepImages: [],
  clearErrors() {
    set({ errors: {} })
  },
  hasErrors() {
    const { errors } = get()
    return Object.values(errors).some(error => error)
  },
  appendStepImage(image) {
    const { stepImages } = get()
    set({ stepImages: [...stepImages, image] })
  },
  setError(key, value) {
    const { errors } = get()
    set({ errors: { ...errors, [key]: value }})
  },
  setStepImages(stepImages) {
    set({ stepImages: stepImages })
  },
  mayContinue: true,
  enableContinue() {
    set({ mayContinue: true })
  },
  disableContinue() {
    set({ mayContinue: false })
  },
  setStep(step) {
    set({ step })
  },
  setFormData(data) {
    set({ formData: data })
  },
  updateFormData(key, data) {
    const { formData } = get()
    formData[key] = data
    set({ formData })
  },
  clear() {
    set({ step: null, formData: {}, mayContinue: true })
  },
}))
