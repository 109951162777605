import React, { useEffect, useState } from 'react'
import { Box, capitalize } from '@material-ui/core'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import {
  emptyPrintNeedsFormRecord,
  PrintNeedsForm,
  PrintNeedsFormValue,
  PrintNeedsRecord,
} from '../../PrintNeedsForm/PrintNeedsForm'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import { FormikErrors } from 'formik'
import { merge, min } from 'lodash'
import { mockEstimatedCostPriceAPICall } from '../mockApi'
import { getPaperSizeFromRTRTemplate } from '../../../../../utils/formatTemplateData'

type PrintNeedsSectionType = {
  options: PrintNeedsSectionOptionType[]
}

type PrintNeedsSectionOptionType = {
  id: string
  label: string
  value: string
  placeholder?: string
  type?: 'select' | 'input' | '' | undefined
  options?: PrintNeedsSectionOptionType[] | undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function PrintNeedsSection({ options }: PrintNeedsSectionType) {
  const [maxQuantity, setMaxQuantity] = useState<number | undefined>(undefined)
  const [waitingForQuantity, setIsWaitingForQuantity] = useState<boolean>(true)
  const {
    printNeedsSectionData,
    setPrintingNeedsSectionData,
    rtrTemplate,
    mailingSectionData,
  } = usePTRFormContextProvider()

  const updateFormData = PrintModalStore(
    ({ formData, setFormData }) =>
      (printNeeds: PrintNeedsFormValue) => {
        const presentation = {
          quantity: {
            label: 'Quantity',
            value: `${printNeeds.quantity}`,
          },
          paperWeight: {
            label: 'Paper Weight',
            value: `${capitalize(printNeeds.paperWeightType)}`,
          },
          paperFinish: {
            label: 'Paper Finish',
            value: `${capitalize(printNeeds.paperFinishType)}`,
          },
        }

        // This part will come from a service soon
        const { data } = mockEstimatedCostPriceAPICall

        let amount =
          (data.unitPrice - data.discountPerUnitPrice) *
          parseInt(printNeeds.quantity)

        if (
          data.discountPerUnitPercentage &&
          data.discountPerUnitPercentage > 0
        )
          amount *= amount * (1.0 - data.discountPerUnitPercentage)

        setFormData(
          merge(formData, {
            presentationData: presentation,
            submittableData: {
              totalEstimatedCost: {
                key: 'estimatedCost',
                label: 'Estimated Cost',
                value: amount,
              },
              size: {
                key: 'size',
                label: 'Paper Size',
                value: printNeeds.size,
              },
            },
          }),
        )
      },
  )

  const setError = PrintModalStore(
    ({ setError }) =>
      (value: boolean) =>
        setError('printNeeds', value),
  )

  const getSize = () => getPaperSizeFromRTRTemplate(rtrTemplate)

  const clearData = () => {
    const data = emptyPrintNeedsFormRecord()

    try {
      data.values.size = getSize()
    } catch (error) {
      console.log(error)
    }

    setPrintingNeedsSectionData(data)
    updateFormData(data.values)
  }

  const handleFormChange = (
    values: Partial<PrintNeedsFormValue>,
    errors: FormikErrors<PrintNeedsFormValue> = printNeedsSectionData.errors,
  ) => {
    const updatedValues: PrintNeedsRecord['values'] = {
      ...printNeedsSectionData.values,
      ...values,
    }
    const updatedSectionData = {
      ...printNeedsSectionData,
      values: updatedValues,
      errors,
    }

    updateFormData(updatedValues)
    setError(Object.keys(errors).length > 0)
    setPrintingNeedsSectionData(updatedSectionData)
  }

  // on-mount first set
  useEffect(() => {
    clearData()
  }, [])

  useEffect(() => {
    const { data } = mailingSectionData

    if (!data || !data.reach) {
      setIsWaitingForQuantity(true)
      clearData()
    } else {
      setIsWaitingForQuantity(false)
    }

    // Soft limit in 1k
    const quantity = min([1000, data?.reach ?? 0])
    setPrintingNeedsSectionData({
      ...printNeedsSectionData,
      values: {
        ...printNeedsSectionData.values,
        size: getSize(),
        quantity,
      },
    })

    setMaxQuantity(quantity)
  }, [mailingSectionData])

  return (
    <Box px={2} width="100%">
      <PrintNeedsForm
        paperFinishList={['Standard', 'Glossy']}
        paperSizeList={['4x6', '6x9', '6x11']}
        paperWeightList={['Standard']}
        maxQuantity={maxQuantity}
        disableQuantity={waitingForQuantity}
        disablePaperSize={true}
        disablePaperFinishType={true}
        disablePaperWeightType={true}
        value={printNeedsSectionData.values}
        onChange={handleFormChange}
      />
    </Box>
  )
}
