import {createContext, useContext} from 'react'

export type ListingMlsImages = Record<string, any>[]

export type ListingMlsImagesContext = {
  listingMlsImages: ListingMlsImages;
  setListingMlsImages: (listingMlsImages: ListingMlsImages) => void
}

export const ListingMlsImagesContext = createContext<ListingMlsImagesContext>({
  listingMlsImages: [],
  setListingMlsImages: () => {},
})

export const useListingMlsImagesContext = (): ListingMlsImagesContext => useContext<ListingMlsImagesContext>(ListingMlsImagesContext)
