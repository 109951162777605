import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Tooltip from './Tooltip'
import { generateDataAttrs } from '../../utils/utils'

export default function PagePresentation(props) {
  const styles = {
    container: { width: '100%' },
    mutedText: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: '#292929',
      opacity: '0.65',
    },
    boldCTA: {
      fontSize: '35px',
      fontFamily: 'BookmaniaBold',
      letterSpacing: '-1.5px',
      color: '#292929',
      lineHeight: '37px',
    },
    italicCTA: {
      fontSize: '35px',
      fontFamily: 'BookmaniaRegularItalic',
      letterSpacing: '-1.5px',
      color: '#292929',
      lineHeight: '37px',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { dataAttrs = {} } = props

  return (
    <Grid
      container
      className={classes.container}
      {...generateDataAttrs(dataAttrs)}
    >
      <Grid item>
        <Typography className={classes.boldCTA}>{props.CTATextBold}</Typography>
      </Grid>
      <Grid item style={{ marginLeft: '10px' }}>
        <Typography className={classes.italicCTA}>
          {props.CTATextItalic1}
        </Typography>
      </Grid>
      {<span>&nbsp;&nbsp;</span>}
      <Grid item>
        <Typography className={classes.italicCTA}>
          {props.CTATextItalic2}
        </Typography>
      </Grid>
      {props.CTADescriptionTooltip && (
        <Grid item style={{ marginTop: '40px' }}>
          <Typography className={classes.mutedText}>
            {props.CTADescription}
            <Tooltip text={props.CTADescriptionTooltip} />
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
