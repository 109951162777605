import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Layout from '../components/common/Layout'
import StepCounter from '../components/common/StepCounter'
import Loading from '../components/common/Loading'
import CustomGridContainer from '../components/common/CustomGridContainer'
import PagePresentation from '../components/common/PageTitle'
import FormikInput from '../components/common/FormikInput'
import Modal from '../components/common/Modal'
import MLSLoader from '../components/custom/MLSLoader'
import ListingDetailsInput from '../components/custom/ListingDetailsImageInput'
import {
  formatMlsImages,
  renderNumberOfSteps,
  wordsCounter,
} from '../utils/utils'
import { useAuthContext } from '../context/AuthContext.js'
import {
  uploadMultipleListingImage,
  uploadListingImageByUrlArray,
} from '../services/marketingServices'

import { getListingDetails } from '../services/propertySearchServices'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useRequestContext } from '../context/RequestContext'
import { useMarketingHelpersContext } from '../context/MarketingHelpersContext'
import { useListingDescriptionContext } from '../context/ListingDescriptionContext'
import {useListingMlsImagesContext} from '../context/ListingMlsImagesContext'
import { useRTRStore } from '../modules/marketing/store/rtr.store'
import { useConfirmationDialog } from '../components/custom/ModalDialog'

const DESCRIPTION_MAX_LENGTH = 250

const validationSchema = Yup.object().shape({
  headline: Yup.string()
    .min(2, 'The headline is too short')
    .required('You must write a headline to listing'),
  description: Yup.string(),
  bedCount: Yup.number()
    .min(0, 'Invalid bed number')
    .max(15, 'The maximum allowed input for bed is 15')
    .typeError('Bed must be a number.'),
  bathCount: Yup.number()
    .min(0, 'Invalid bath number')
    .max(15, 'The maximum allowed input for bath is 15')
    .typeError('Bath must be a number.'),
  estimatedPrice: Yup.string().min(0, 'Invalid listing price'),
  images: Yup.mixed()
    .test(
      'images is not empty',
      'A property photo is required',
      (images) => images.length > 0,
    )
    .required('A Property Photo is required.'),
})

export default function ListingDescription() {
  const { listingAddressCtx, selectedPropertyCtx } = useRequestContext()
  const {
    setListingMlsImages,
    listingMlsImages,
  } = useListingMlsImagesContext()

  const {
    listingDescription: listingDescriptionCtx,
    setListingDescription: setListingDescriptionCtx,
  } = useListingDescriptionContext()

  const {
    marketingHelpers,
    setMarketingHelpers,
  } = useMarketingHelpersContext()

  const { agentId } = useAuthContext()
  const styles = (theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: '45% 44%',
      gridGap: 18,
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '46% 46%',
      },
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const formRef = useRef()
  const history = useHistoryPrefix()
  const { flow, subflow, requestTypePath } = useParams()
  const [initialValues, setInitialValues] = useState(listingDescriptionCtx)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [mlsFetchingStatus, setModalStatus] = useState({
    loading: false,
    message: '',
    show: false,
  })
  const { getConfirmation } = useConfirmationDialog()


  useEffect(() => {
    const fetchListingDetails = async (listingAddressData) => {
      setModalStatus({
        show: true,
        message: 'Populating from MLS...',
        loading: true,
      })
      let mlsFetchingData = {}
      let listingMlsImagesArr
      const { mlsId } = listingAddressData
      const listingDataToBeSent = mlsId ? mlsId : listingAddressData
      const { data } = await getListingDetails(listingDataToBeSent, !!mlsId)
      const response = data

      if (response) {
        mlsFetchingData = {
          show: true,
          loading: false,
          message: 'MLS Data Found, populating now...',
        }
        setInitialValuesWithFetchedData(response)
        listingMlsImagesArr = response.photos.map((photo) => ({
          ...photo,
          url: photo.photoUrl,
          id: photo.id,
          name: photo.caption || '',
        }))
      } else {
        mlsFetchingData = {
          show: true,
          loading: false,
          message:
            'No MLS data found, please manually upload listing images and input listing details...',
        }
        listingMlsImagesArr = []
      }
      setListingMlsImages(listingMlsImagesArr)
      setModalStatus(mlsFetchingData)
      setTimeout(() => {
        setModalStatus({ ...mlsFetchingStatus, show: false })
        getConfirmation({
        title: '',
        message:
          'MLS Photos are not suitable for print material due low resolution.',
        firstButtonText: 'OK',
      })
      }, 3000)
      
    }

    if (
      !Object.values(listingDescriptionCtx)[0] ||
      !(marketingHelpers?.lastFetchedListingAddress === selectedPropertyCtx)
    ) {
      fetchListingDetails(listingAddressCtx)
    }
  }, [])

  const setInitialValuesWithFetchedData = (fetchedData) => {
    const parsedBathCount = parseInt(
      fetchedData.numBathrooms ?? fetchedData.numFullBathrooms ?? '',
    )
    const parsedBedCount = parseInt(fetchedData.numBedrooms)

    setInitialValues({
      ...initialValues,
      headline: '',
      description: fetchedData.description,
      estimatedPrice: fetchedData.currentPrice,
      bedCount: parsedBedCount >= 0 ? parsedBedCount : '',
      bathCount: parsedBathCount >= 0 ? parsedBathCount : '',
      images: formatMlsImages(fetchedData.photos.slice(0, 5)),
    })
  }

  const handleNextPageClick = async () => {
    const form = formRef.current
    const values = form.values
    const errors = await form.validateForm()
    useRTRStore.getState().clear()
    if (Object.keys(errors).length === 0) {
      if (typeof values.estimatedPrice === 'string') {
        values.estimatedPrice = parseInt(
          values.estimatedPrice.replace('$', '').replaceAll(',', ''),
        )
      }

      if (values.bedCount)
        values.bedCount = parseFloat(values.bedCount.toString())
      if (values.bathCount)
        values.bathCount = parseFloat(values.bathCount.toString())

      setLoading(true)
      const nextPath = [flow, subflow, requestTypePath, 'digital/package'].join(
        '/',
      )

      if (marketingHelpers.shouldUploadImagesAgain) {
        const { success, images } = await handleImageUpload(values.images)
        if (success) {
          setMarketingHelpers({
            ...marketingHelpers,
            shouldUploadImagesAgain: false,
            lastFetchedListingAddress: selectedPropertyCtx,
          })
          // Update image URL
          values.images = images.map((c) => ({
            ...values.images.find(
              (i) =>
                i.name == c.metadata.filename ||
                i.url === c.metadata.originalUrl,
            ),
            url: c.imageUrl,
          }))
          values.imagesIds = images.map(({ id }) => id)
          setListingDescriptionCtx(values)
          setLoading(false)
          history.push(nextPath)
        }
      } else {
        setListingDescriptionCtx(values)
        history.push(nextPath)
      }
    }
    setLoading(false)
  }

  const handleImageUpload = async (imagesArray) => {
    try {
      const fetchedImagesUrls = imagesArray
        .filter((image) => image.type === 'url')
        .map((image) => image.url)

      const imagesFormData = new FormData()
      imagesArray
        .filter((image) => image.type === 'file')
        .forEach((image) => {
          imagesFormData.append('images', image.image)
        })

      let fetchedImagesUpload = []
      if (fetchedImagesUrls.length > 0) {
        fetchedImagesUpload = await handleFetchedImageUpload(fetchedImagesUrls)
      }

      let userImagesUpload = []
      if (imagesFormData.get('images')) {
        userImagesUpload = await handleUserImagesUpload(imagesFormData)
      }

      const uploadedImages = [...fetchedImagesUpload, ...userImagesUpload]
      return { success: true, images: uploadedImages }
    } catch (err) {
      setError("We're sorry, but the image upload failed, please try again.")
      return { success: false }
    }
  }

  const handleUserImagesUpload = async (fileFormData) => {
    const response = await uploadMultipleListingImage(agentId, fileFormData)
    if (response.success) {
      return response.data
    }
  }

  const handleFetchedImageUpload = async (urlArray) => {
    const response = await uploadListingImageByUrlArray(agentId, urlArray)
    if (response.success) {
      return response.data
    }
  }

  const validateDescription = (value) => {
    if (wordsCounter(value) > DESCRIPTION_MAX_LENGTH) {
      return `The maximum allowable word count is ${DESCRIPTION_MAX_LENGTH}`
    }
  }

  return (
    <Layout>
      <CustomGridContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid item>
              <Modal open={mlsFetchingStatus.show}>
                <MLSLoader
                  loading={mlsFetchingStatus.loading}
                  text={mlsFetchingStatus.message}
                  setModalStatus={setModalStatus}
                  dataAttrs={{ metaType: 'modal' }}
                />
              </Modal>
              <StepCounter
                numberOfSteps={renderNumberOfSteps(requestTypePath)}
                activeStep={3}
                handleNext={handleNextPageClick}
              />
            </Grid>
            <PagePresentation
              CTATextUpper={'Listing'}
              CTATextLower={'Details'}
              component={'span'}
              isSingleLine
              dataAttrs={{ metaName: 'page-title' }}
            />
            <Grid item>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
              >
                {({ values, setFieldValue, errors }) => {
                  const handleNumberInputChange = (e, fieldName) => {
                    const { value } = e.target

                    const selectRegex = () => {
                      switch (fieldName) {
                        case 'bathCount':
                          return /^\d+(\.5{0,1})?$/
                        case 'bedCount':
                          return /^\d+?$/
                        default:
                          return /^\d+(\.5{0,1})?$/
                      }
                    }

                    if (selectRegex().test(value.toString()) || value === '') {
                      setFieldValue(fieldName, value)
                    }
                  }

                  return (
                    <Form>
                      <ListingDetailsInput
                        imagesArray={
                          initialValues.images || listingDescriptionCtx.images
                        }
                        error={error || errors.images}
                        setError={setError}
                        listingMlsImages={listingMlsImages}
                        setFieldValue={setFieldValue}
                      />
                      <FormikInput
                        id="headline"
                        name="headline"
                        label="Headline"
                        as="textarea"
                        placeholder="e.g Modern Luxury and Timeless Elegance"
                        rows="4"
                        maxLength="45"
                        lengthCounting={`${values.headline.length}/45`}
                        error={errors.headline}
                      />
                      <FormikInput
                        id="estimatedPrice"
                        name="estimatedPrice"
                        label="List price"
                        placeholder="$0,000,000"
                        rows="1"
                        maxLength={999999999}
                        error={errors.estimatedPrice}
                        setFieldValue={setFieldValue}
                        value={values.estimatedPrice}
                        isCurrency
                      />
                      <Grid container className={classes.grid}>
                        <FormikInput
                          id="bedCount"
                          as="input"
                          name="bedCount"
                          label="Bed"
                          onChange={(e) =>
                            handleNumberInputChange(e, 'bedCount')
                          }
                          placeholder="e.g. 2"
                          rows="1"
                          error={errors.bedCount}
                        />
                        <FormikInput
                          id="bathCount"
                          name="bathCount"
                          label="Bath"
                          onChange={(e) =>
                            handleNumberInputChange(e, 'bathCount')
                          }
                          placeholder="e.g. 2"
                          rows="1"
                          error={errors.bathCount}
                        />
                      </Grid>

                      <FormikInput
                        id="description"
                        name="description"
                        label="Description"
                        placeholder="Write here..."
                        as="textarea"
                        rows="8"
                        lengthCounting={`${wordsCounter(
                          values.description,
                        )}/${DESCRIPTION_MAX_LENGTH}`}
                        validate={validateDescription}
                        error={errors.description}
                      />
                    </Form>
                  )
                }}
              </Formik>
            </Grid>
            <Grid style={{ margin: '15px 0' }}>
              <StepCounter
                showOnlyNavButtons
                handleNext={handleNextPageClick}
              />
            </Grid>
          </>
        )}
      </CustomGridContainer>
    </Layout>
  )
}
