import React from 'react'
import { Radio, Typography } from 'av8-ui'
import { Formik, Field, FormikHelpers, FormikErrors } from 'formik'
import { SelectContainer } from '../../../../../../components/common/FormikSelect.style'
import { DebouncedSubmitOnChangeFormik } from '../../../../../shared/components/Formik/DebouncedSubmitOnChangeFormik'
import { zipRegex } from '../../../../../shared/utils/zipRegex'
import { BuildAListSectionDataRecord } from '../CustomizePrintModalForm/CustomizePrintModalContext'
import { BuildAListFormStyles } from './BuildAListForm.styles'

export type BuildAListFormCoordinates = {
  lat: number
  lon: number
}

export type BuildAListFormDistance = {
  value: number
  unit: 'mi' | 'm' | 'km' | 'ft'
}

export type BuildAListFormValue = BuildAListSectionDataRecord['values'] & {
  coordinates?: BuildAListFormCoordinates
  address?: string
}

export type BuildAListFormProps = {
  onlyZip?: boolean
  radioState?: 'zip' | 'address'
  value?: BuildAListFormValue
  onChange?: (value: Partial<BuildAListFormValue>, errors?: FormikErrors<BuildAListFormValue>) => void;
  onAddressLookup?: (address: string) => BuildAListFormCoordinates | null
}

const distanceOptions: string[] = ['1 mi', '5 mi', '10 mi']

export const BuildAListForm: React.FC<BuildAListFormProps> = (props) => {
  const classes = BuildAListFormStyles()
  const [radioButtonState, setRadioButtonState] = React.useState<'zip' | 'address'>(props.radioState || 'zip')
  const handleRadioButtonClick = (value: 'zip' | 'address') => {
    setRadioButtonState(value)
  }

  const distanceValue = props.value?.distance
    ? props.value.distance
    : null

  const [firstDistanceOption] = distanceOptions

  const initialValues: BuildAListFormValue = {
    zip: props.value?.zip ?? '',
    distance: distanceOptions.find(option => option === distanceValue) ?? firstDistanceOption,
    address: props.value?.address ?? '',
  }

  const handleSubmit = async (values: Partial<BuildAListFormValue>, helpers: FormikHelpers<BuildAListFormValue>) => {
    const errors = await helpers.validateForm(values)
    helpers.setErrors(errors)
    if (props.onChange) {
      props.onChange({...(props.value ?? {}), ...values}, errors)
    }
  }
  const handleValidation = (values: BuildAListFormValue) => {
    const errors: FormikErrors<BuildAListFormValue> = {}
    if (radioButtonState === 'zip' && (values.zip.length < 1 || !zipRegex.test(values.zip))) {
      errors.zip = 'Invalid zip, please inform a zip'
    }
    if (!distanceOptions.includes(values.distance)) {
      errors.distance = 'Invalid distance option'
    }
    return errors
  }
  return (
    <div>
      <Typography className={[classes.MutedText, classes.SectionTitle].join(' ')}>Target Zip:</Typography>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidation} enableReinitialize validateOnBlur>

        {({ errors }) => (
          <form className={classes.Form}>
            <DebouncedSubmitOnChangeFormik debounceMs={500}/>
            <div className={classes.FlexRow}>
              {!props.onlyZip && <Radio
                label={''}
                key={'build-a-list-zip'}
                onClick={() => handleRadioButtonClick('zip')}
                checked={radioButtonState === 'zip'}
              />}
              <div className={classes.FieldContainer}>
                <Field
                  disabled={radioButtonState !== 'zip'}
                  className={classes.InputField}
                  type={'text'}
                  name={'zip'}
                  placeholder={'94133'}
                  isCurrency={false}
                  error={errors.zip}
                />
                {errors.zip && (
                  <span className={classes.InputErrorMessage} data-meta-name="error">
                    {errors.zip}
                  </span>
                )}
              </div>
            </div>
            <div className={[classes.FlexRow, classes.RadioButtonContainer].join(' ')}>
              {!props.onlyZip && (<Radio
                label={''}
                key={'build-a-list-address'}
                onClick={() => handleRadioButtonClick('address')}
                checked={radioButtonState === 'address'}
              />)}
              <div className={classes.FlexColumn}>
                <div className={classes.FlexRow}>
                  <div className={[
                    classes.FlexVerticalCenter,
                    classes.FlexShrink,
                    classes.FieldMargins,
                    classes.RightMargin,
                  ].join(' ')}>
                    <Typography className={classes.MutedText}>
                      Within:
                    </Typography>
                  </div>
                  <SelectContainer style={{width: '100%'}}>
                    <div className={['select', classes.SelectField].join(' ')}>
                      <Field
                        as="select"
                        name="distance"
                        type="input"
                        error={errors.distance}
                        id="distance"
                      >
                        {distanceOptions.map((value, idx) => (
                          <option key={`distance-option-${idx}`} value={value}>
                            {value}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </SelectContainer>
                  {errors.distance && (
                  <span className={classes.InputErrorMessage} data-meta-name="error">
                    {errors.distance}
                  </span>
                )}
                </div>
                {!props.onlyZip && (
                  <div className={classes.FlexRow}>
                    <div className={[
                      classes.FlexVerticalCenter,
                      classes.FlexShrink,
                      classes.FieldMargins,
                      classes.RightMargin,
                    ].join(' ')}>
                      <Typography className={classes.MutedText}>
                        Of:
                      </Typography>
                    </div>
                    <div className={classes.FieldContainer}>
                      <Field
                        disabled={radioButtonState !== 'address'}
                        className={classes.InputField}
                        type={'text'}
                        name={'address'}
                        placeholder={'Enter Zip, Address, or City'}
                        isCurrency={false}
                        error={errors.address}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
