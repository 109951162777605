/**
 * Compile the rules into an array of validators
 * @param {Object} rulesMap
 * @param {Object[]} rulesToApply
 * @return {(function(*=): (boolean|string))[]}
 */
export const compileRules = (rulesMap, rulesToApply) =>
  rulesToApply
    .filter(({ type }) => type in rulesMap)
    .map(({ type, message = 'Invalid field', ...config }) => {
      const ruleGenerator = rulesMap[type]
      const rule = ruleGenerator(message, config)
      rule.type = type
      rule.config = config
      return rule
    })
