import React from 'react'
import { Grid, makeStyles, Modal as MuiModal } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 250,
    backgroundColor: '#FEFEFE',
    margin: 'auto',
    padding: '30px',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function Modal({ children, open = false }) {
  const classes = useStyles()

  return (
    <div>
      <MuiModal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        data-meta-name="modal-overlay"
        data-meta-type="overlay"
      >
        <Grid className={classes.paper}>{children}</Grid>
      </MuiModal>
    </div>
  )
}
