import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Loading from './Loading'
import ErrorMsg from './ErrorMsg'
import Button from './Button'
import Text from './Text'
import Image from './Image'
import Carousel from './Carousel'
import TextArea from './TextAreaInput'
import DnDGrid from '../custom/DnDGrid'
import DynamicFormGenerator from '../custom/DynamicFormGenerator'
import { formDataBuilder, generateDataAttrs } from '../../utils/utils'
import { useAuthContext } from '../../context/AuthContext'
import { useInputDataContext } from '../../context/InputDataContext'
import { getTemplateModalOptions } from '../../services/marketingServices'
import { AdsEstimates } from '../custom/MarketingRequest/ads-estimates/AdsEstimates'
import { useMarketingStore } from '../../modules/marketing/store/marketing.store'
import { useTemplateTypesContext } from '../../context/TemplateTypesContext'

const styles = (theme) => ({
  container: {
    padding: '15px 20px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    marginTop: '12px',
  },
  media: {
    width: '100%',
    minHeight: 300,
    maxHeight: 335,
    marginTop: 7,
    marginBottom: 15,
  },
  dotListClass: {
    margin: '0 auto',
    position: 'absolute',
    maxWidth: '330px',
    overflow: 'hidden',
    marginBottom: 0,
    top: 395,
    left: 10,
    height: 20,
    [theme.breakpoints.up('sm')]: {
      top: 410,
    },
  },
  carouselContainer: {
    position: 'relative',
    marginBottom: 15,
  },
})
const useStyles = makeStyles(styles)

export default function PackageDialog({
  isEditing,
  image,
  inputData,
  packageData,
  packageType,
  propThatTriggersDynamicForm,
  metaData = {},
}) {
  const { templateTypes } = useTemplateTypesContext()
  const { formMarketingDataCtx, setFormMarketingDataCtx } =
    useInputDataContext()
  const setIsDialogOpen = useMarketingStore((state) => state.setIsDialogOpen)
  const { agentId } = useAuthContext()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState({})
  const [isFormBeingValidated, setIsFormBeingValidated] = useState(false)
  const [dynamicFormData, setDynamicFormData] = useState({})
  const [showAdEstimates, setShowAdEstimates] = useState(false)
  const [adEstimates, setAdEstimates] = useState(null)
  const [loadingEstimates, setLoadingEstimates] = useState(false)

  const [additionalNotes, setAdditionalNotes] = useState(
    inputData?.formData?.additionalNotes || '',
  )
  const [carouselImages, setCarouselImages] = useState(
    image?.carouselImages || [],
  )

  const classes = useStyles()
  const isSocialMedia = packageData.displayName === 'Social Media'
  const isRTR = image?.isRTR

  const buttonStyle = isEditing
    ? { primary: true, title: 'Edit' }
    : { secondary: true, title: 'Customize' }

  let selectedTriggerOption = {}
  if (options.length > 0) {
    const propThatTriggersInfo = options.find(
      (prop) => prop.name === propThatTriggersDynamicForm,
    )
    selectedTriggerOption = propThatTriggersInfo?.options?.find(
      (option) =>
        option.value === formData?.[propThatTriggersDynamicForm]?.value,
    )
  }

  useEffect(() => {
    const { formData = {}, dynamicFormData = {} } = inputData || {}
    setFormData({ ...formData })
    setDynamicFormData({ ...dynamicFormData })
    if (formData.promotedAdEstimates) {
      setAdEstimates(formData.promotedAdEstimates)
    }
    return () => {
      setAdEstimates(null)
      setFormData({})
      setDynamicFormData({})
    }
  }, [inputData])

  useEffect(() => {
    const fetchModalOptions = async () => {
      try {
        const templateType = templateTypes.find(
          (templateType) => templateType.key === packageData.templateCategory,
        )
        const response = await getTemplateModalOptions(agentId, templateType.id)

        if (response.success) {
          setOptions(response.data)
          setLoading(false)
        } else {
          setLoading(false)
          setError(true)
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    }
    fetchModalOptions()
  }, [])

  useEffect(() => {
    if (
      formData?.[propThatTriggersDynamicForm] &&
      formData?.[propThatTriggersDynamicForm].value !== '' &&
      selectedTriggerOption
    ) {
      if (
        formData[propThatTriggersDynamicForm] !==
          inputData?.formData[propThatTriggersDynamicForm] &&
        !isFormBeingValidated
      ) {
        const updatedDynamicForm = formDataBuilder(
          selectedTriggerOption?.dynamicOptions || [],
        )

        setDynamicFormData(updatedDynamicForm)
      }
      setIsFormBeingValidated(false)
    }
  }, [formData?.[propThatTriggersDynamicForm]])

  useEffect(() => {
    const form = {
      ...formData,
      ...dynamicFormData,
    }
    const use = form?.paidAdBudget?.metadata?.use ?? []
    const conditions = [
      form?.paidAdBudget,
      use.includes('facebookAdsEstimate'),
      form?.campaignStartDate?.value,
      form?.campaignEndDate?.value,
    ]
    const needAdEstimates = conditions.every((condition) => !!condition)
    if (showAdEstimates && !needAdEstimates) {
      handleSaveAdEstimates(null)
    }
    setShowAdEstimates(needAdEstimates)
  }, [dynamicFormData, formData, open])

  const handleClickOpen = () => {
    // setMarketingHelpers({ ...marketingHelpers, isDialogOpen: true })
    setOpen(true)
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    if (!isEditing) {
      setFormData((prevState) => {
        return { ...prevState, [propThatTriggersDynamicForm]: {} }
      })
      setDynamicFormData({})
      setShowAdEstimates(false)
      handleSaveAdEstimates(null)
    }
    // setMarketingHelpers({ ...marketingHelpers, isDialogOpen: false })
    setOpen(false)
    setIsDialogOpen(false)
  }

  const validateFormData = () => {
    setIsFormBeingValidated(true)
    let isFormValid = true

    const validateForm = (form) => {
      const validatedForm = {}
      Object.entries(form).forEach(([fieldName, fieldValuesObj]) => {
        if (fieldValuesObj.error) {
          isFormValid = false
          validatedForm[fieldName] = { ...fieldValuesObj }
        } else if (fieldValuesObj.required && fieldValuesObj.value === '') {
          isFormValid = false
          validatedForm[fieldName] = {
            ...fieldValuesObj,
            error: `${fieldValuesObj?.label || 'This'} is a required field.`,
          }
        } else {
          validatedForm[fieldName] = {
            ...fieldValuesObj,
            error: null,
          }
        }
      })

      return validatedForm
    }
    const validatedFormData = validateForm(formData)
    const validatedDynamicFormData = validateForm(dynamicFormData)

    setFormData(validatedFormData)
    setDynamicFormData(validatedDynamicFormData)

    return { isFormValid, validatedFormData, validatedDynamicFormData }
  }

  const handleSave = () => {
    const { isFormValid, validatedFormData, validatedDynamicFormData } =
      validateFormData()
    const { templateCategory } = packageData
    const previousInputDataTemplates =
      formMarketingDataCtx?.[packageType]?.[templateCategory] || []
    const filteredInputData = previousInputDataTemplates.filter(
      (data) => data.itemId !== image.itemId,
    )

    if (isFormValid && !loadingEstimates) {
      const updatedFormData = { ...validatedFormData, additionalNotes }

      if (adEstimates?.value?.estimate && adEstimates?.value?.coefficients) {
        updatedFormData.promotedAdEstimates = adEstimates
      }

      const updatedFormDataCtx = {
        ...(formMarketingDataCtx[packageType] || {}),
        [templateCategory]: [
          ...filteredInputData,
          {
            itemId: image.itemId,
            formData: updatedFormData,
            dynamicFormData: validatedDynamicFormData,
          },
        ],
      }

      setFormMarketingDataCtx({
        ...formMarketingDataCtx,
        [packageType]: updatedFormDataCtx,
      })
      // setMarketingHelpers({ ...marketingHelpers, isDialogOpen: false })
      setOpen(false)
      setIsDialogOpen(false)
    }
  }

  const handleRemove = () => {
    const { templateCategory } = packageData
    const inputData =
      formMarketingDataCtx[packageType]?.[templateCategory] || []
    const updatedFormDataCtx = {
      ...(formMarketingDataCtx[packageType] || {}),
      [templateCategory]: [
        ...inputData.filter((data) => data.itemId !== image.itemId),
      ],
    }
    setFormMarketingDataCtx({
      ...formMarketingDataCtx,
      [packageType]: updatedFormDataCtx,
    })
    setAdditionalNotes('')

    if (formData?.[propThatTriggersDynamicForm]) {
      setFormData({ ...formData, [propThatTriggersDynamicForm]: {} })
    }
    handleSaveAdEstimates(null)
    setShowAdEstimates(false)
    setOpen(false)
    setIsDialogOpen(false)
  }

  const handleSaveAdEstimates = (estimate) => {
    // save the estimate data for this template specific
    if (estimate) {
      setAdEstimates({
        type: 'object',
        visible: false,
        value: estimate,
      })
    } else {
      setAdEstimates(null)
    }
  }

  const renderCarouselImage = (carouselImage, index) => {

    const { baseUrl, url } = carouselImage
    const imageUrl = baseUrl || url

    if (typeof imageUrl === 'object') {
      // Post-generation image adjustment
      imageUrl?.setAttribute('width', '100%')
      imageUrl?.setAttribute('height', '100%')

      return (
        <Image
          key={url + index}
          medium
          className={classes.media}
          alt="Carousel Image"
          renderAsDiv={true}
          dangerouslySetInnerHTML={{
            __html: new XMLSerializer().serializeToString(imageUrl),
          }}
        />
      )
    }

    return (
      <Image
        key={url + index}
        medium
        className={classes.media}
        src={imageUrl}
        alt="Carousel Image"
      />
    )
  }

  useEffect(() => {
    const notHiddenImages =
      image?.carouselImages?.filter((img) => !img.isHidden) || []
    // The carousel only needs the image url
    const coverImage = { url: image.url }

    setCarouselImages([coverImage, ...notHiddenImages])
  }, [image])

  return (
    <>
      <Button
        small
        bordered
        onClick={handleClickOpen}
        dataAttrs={{
          metaAction: isEditing ? 'edit-template' : 'select-template',
          ...metaData,
        }}
        {...buttonStyle}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{ style: { borderRadius: '0', margin: 16, maxWidth: 375 } }}
      >
        <Grid
          className={classes.container}
          {...generateDataAttrs({
            metaName: 'template-modal',
            metaType: 'modal',
            ...metaData,
          })}
        >
          <Grid container justify="space-between" alignItems="center">
            <Text>
              {isEditing ? 'Edit ' : ''}
              {image.name}
            </Text>
            {!loading && (
              <Text
                type="medium"
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
                {...generateDataAttrs({ metaAction: 'cancel' })}
              >
                Cancel
              </Text>
            )}
          </Grid>

          <Text type="muted" style={{ marginTop: 10 }}>
            Design
          </Text>
          <Grid>
            {!isSocialMedia ? (
              <Image
                medium
                className={classes.media}
                src={image?.formats?.small?.url}
                alt="Section Thumbnail"
              />
            ) : (
              <Carousel
                containerClass={classes.carouselContainer}
                dotListClass={classes.dotListClass}
                arrowColor="white"
                arrowAlignment="centered"
              >
                {carouselImages?.map((carouselImage, index) =>
                  renderCarouselImage(carouselImage, index),
                )}
              </Carousel>
            )}
          </Grid>
          {error && <ErrorMsg />}
          {loading && <Loading heightVh="20vh" />}
          {isRTR && (
            <Grid>
              <DnDGrid cover={image} images={image?.carouselImages} />
            </Grid>
          )}
          <Grid>
            {formData && options && open && (
              <>
                <DynamicFormGenerator
                  formData={formData}
                  setFormData={setFormData}
                  options={options}
                  isEditing={isEditing}
                />
              </>
            )}
          </Grid>
          <Grid>
            {dynamicFormData && selectedTriggerOption && (
              <DynamicFormGenerator
                formData={dynamicFormData}
                setFormData={setDynamicFormData}
                options={selectedTriggerOption.dynamicOptions}
                isEditing={isEditing}
              />
            )}
          </Grid>
          {showAdEstimates && (
            <AdsEstimates
              formData={{ ...formData, ...dynamicFormData }}
              dynamicOptions={[
                ...options,
                ...(selectedTriggerOption?.dynamicOptions || []),
              ]}
              setAdEstimatesHandler={handleSaveAdEstimates}
              loadingEstimatesHandler={setLoadingEstimates}
            />
          )}
          <Grid>
            {!loading && packageType === 'print' && (
              <TextArea
                rows="4"
                placeholder="Add any additional notes..."
                value={additionalNotes.value}
                onChange={(e) =>
                  setAdditionalNotes({
                    value: e.target.value,
                    optionLabel: 'Additional Notes',
                  })
                }
              />
            )}
          </Grid>
          {!loading && (
            <>
              <Grid
                container
                justify="center"
                style={{ margin: '40px 0 40px 0' }}
              >
                <Button
                  medium
                  bordered
                  primary
                  disabled={loadingEstimates}
                  title={error ? 'Close' : 'Save'}
                  clickHandler={error ? handleClose : handleSave}
                  dataAttrs={{
                    metaAction: error
                      ? 'close-template-modal'
                      : 'save-template',
                    ...metaData,
                  }}
                />
              </Grid>
              {isEditing && (
                <Grid
                  container
                  justify="center"
                  style={{ margin: '0 0 40px 0' }}
                >
                  <Button
                    medium
                    bordered
                    secondary
                    title="Remove"
                    noBorder
                    clickHandler={handleRemove}
                    dataAttrs={{
                      metaAction: 'remove-template',
                      ...metaData,
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Dialog>
    </>
  )
}
