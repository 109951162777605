/**
 * Generates rule to validate value being valid number greater than (or equal)
 * then the given n parameter
 * @param {string} errorMessage
 * @param {Object} options
 * @returns {function(*=): (boolean|string)} validator function
 */
export const isNumberLesserThanRule = (errorMessage, options) => {
  const { n, lte: useLesserThanOrEqual = false } = options
  if (Number.isNaN(+n)) {
    throw new Error(`Invalid n parameter provided: ${n}`)
  }
  /**
   * Validates if given value is a number lesser than (or equal) n
   * @param {any} value input value
   * @returns {boolean|string} true if valid, string if invalid
   */
  const rule = (value) =>
    (!value && value !== '' && +value !== 0) ||
    (!Number.isNaN(+value) && useLesserThanOrEqual
      ? +value <= +n
      : +value < +n) ||
    errorMessage
  rule.options = options
  return rule
}
