import React from 'react'
import ReactLoading from 'react-loading'
import { Grid } from '@material-ui/core'
import Text from '../common/Text'
import { generateDataAttrs } from '../../utils/utils'

export default function Loading({
  type = 'spin',
  color = '#2A29A6',
  sizePx = '55px',
  heightVh = '80vh',
}) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ height: heightVh }}
      {...generateDataAttrs({ metaName: 'loading', metaType: 'modal' })}
    >
      <ReactLoading type={type} color={color} height={sizePx} width={sizePx} />
      <Text type="muted" style={{ marginTop: 8 }}>
        Loading...
      </Text>
    </Grid>
  )
}
