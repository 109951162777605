/* eslint-disable no-constant-condition */
import { authServiceApi } from './api'
import { marketingApi } from './api'

const SECOND = 1000 // in ms

export const checkTokenValidation = async (agentId) => {
  try {
    const { data } = await marketingApi.get(`/secret?agentId=${agentId}`)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getStageSelectionOptions = async (agentId) => {
  try {
    const { data } = await marketingApi.get(`/stages?agentId=${agentId}`)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getTemplatesByStageAndType = async (
  stage,
  packageType,
  agentId,
) => {
  try {
    const { data } = await marketingApi.get(
      `/visual-marketing-templates?stage=${stage}&type=${packageType}&agentId=${agentId}`,
    )
    return data
  } catch (err) {
    return { success: false }
  }
}

export const getTemplatesTypes = async (agentId) => {
  try {
    const { data } = await marketingApi.get(
      `/templates-types?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getRequestById = async (requestId, agentId) => {
  try {
    const { data } = await marketingApi.get(
      `/requests/email-draft/${requestId}?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const submitEmailRTRDraft = async (submittedData) => {
  try {
    const { data } = await marketingApi.post(
      '/requests/email-draft',
      submittedData,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

/**
 * Retrieves the mailing lists associated with the agent's contact in HubSpot 
 * @param {number} agentId
 * @returns {array}
 */
export const getMailingListsByEmail = async (agentId) => {
  try {
    const { data } = await marketingApi.get(`/contact-lists?agentId=${agentId}`)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

/**
 * Fetch ad estimates for simulated campaign in the backend
 * @param {string} agentId
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {number} budget
 * @param {string} city
 * @param {any} cancelToken
 * @returns {Promise<{data: Object, success: boolean}|{success: boolean}>}
 */
export const getAdsEstimates = async (
  agentId,
  startDate,
  endDate,
  budget,
  city,
  cancelToken = null,
) => {
  try {
    const params = {
      agentId,
      budget: budget.toString(),
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      city,
    }
    const queryParams = new URLSearchParams(params)

    const url = `/ads/facebook/delivery-estimates?${queryParams}`

    const config = {
      timeout: 40 * SECOND, // set request to timeout after 2 min
    }

    if (cancelToken) {
      config.cancelToken = cancelToken
    }

    const { data: estimatesData = {} } = await marketingApi.get(url, config)

    return { success: true, data: estimatesData }
  } catch (err) {
    return { success: false, error: err }
  }
}

export const getTemplateModalOptions = async (agentId, templateId) => {
  try {
    const { data } = await marketingApi.get(
      `/options?agentId=${agentId}&options[templateTypeId]=${templateId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const submitMarketingRequest = async (submittedData) => {
  try {
    const { data } = await marketingApi.post('/requests', submittedData)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const submitEmailRTRequest = async (submittedData) => {
  try {
    const { data } = await marketingApi.post('/requests/email', submittedData)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const approveEmailRTRequest = async (orderId, agentId) => {
  try {
    const { data } = await marketingApi.post(
      `/requests/email/${orderId}/confirmation?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const uploadSingleListingImage = async (agentId, image) => {
  try {
    const { data } = await marketingApi.post(
      `/request-image/single?agentId=${agentId}`,
      image,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const uploadMultipleListingImage = async (agentId, images) => {
  try {
    const { data } = await marketingApi.post(
      `/request-image/multiple?agentId=${agentId}`,
      images,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const uploadListingImageByUrlArray = async (agentId, urls) => {
  try {
    const { data } = await marketingApi.post(
      `/request-image/urls?agentId=${agentId}`,
      {
        urls,
      },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const uploadImageUploadCare = async (image, name) => {
  try {
    const { data } = await marketingApi.post('/url/to/upload', {
      name,
      image,
    })
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getEmailTemplate = async (agentId) => {
  try {
    const { data } = await marketingApi.get(
      `/visual-marketing-email-templates?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getMailingList = async (zipcode) => {
  try {
    const { data } = await marketingApi.get(`/mailing-lists?zipCode=${zipcode}`)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const submitMarketingForMeRequest = async (agentId, notes) => {
  try {
    const { data } = await marketingApi.post('/requests/me', { agentId, notes })
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getAgentData = async () => {
  try {
    const { data } = await authServiceApi.get('/agent')
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}


/**
 * @params 
 * agentId: number
 * acl: authenticated-read | public-read
 *        
 */
export const uploadToBucket = async (file, agentId, acl) => {
  try {
    const filePayload = new FormData()
    filePayload.append('file', file)
    const { data } = await marketingApi.post(`/files?agentId=${agentId}&acl=${acl}`, filePayload)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}
