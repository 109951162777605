import styled from 'styled-components'
import checkmarkSvg from '../../assets/icons/Checkmark.svg'
import { StyledTaggedComponent } from './StyledTaggedComponent'

export const PlainCheckboxContainer = StyledTaggedComponent(styled.div`
  /* Customize label (container) */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 6.5px;
    left: 8px;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #2a29a6;
    border-radius: 0;
  }

  .information-container {
    margin-left: 15px;
    height: 38px;
    display: flex;
    align-items: center;
  }

  .information-text {
    font-family: StyreneMedium, serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2a29a6;
  }

  .labelOver {
    margin-left: 15px;
  }
  .container input:checked ~ .checkmark {
    background-color: #2a29a6;
  }

  /* Create indicator - hidden when not checked */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show indicator when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style indicator */
  .container .checkmark:after {
    background: url(${checkmarkSvg}) no-repeat;
    top: 5.5px;
    left: 3px;
    width: 20px;
    height: 16px;
  }
`)
