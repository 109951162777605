import React, { memo } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generateDataAttrs } from '../../utils/utils'

function TextAreaInput({
  label,
  name,
  error,
  lengthCounting,
  dataAttrs = {},
  rows = 4,
  ...props
}) {
  const styles = {
    label: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      color: '#292929',
      opacity: '0.65',
    },
    field: {
      marginTop: '8px',
      borderRadius: '5px',
      border: '1px solid var(--blue)',
      padding: '10px',
      '&::placeholder': {
        fontFamily: 'StyreneMedium',
        fontSize: '12px',
        color: 'var(--blue)',
        opacity: '0.5',
      },
      width: '90%',
      color: 'var(--blue)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      background: 'white',
    },
    textContainer: {
      position: 'relative',
      width: '100%',
      marginTop: 2,
      marginBottom: lengthCounting ? '7px' : '15px',
    },
    textCounter: {
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      color: 'var(--blue)',
      opacity: '0.5',
    },
    errorText: {
      fontSize: '10px',
      color: 'var(--red)',
      fontFamily: 'StyreneRegular',
    },
    textArea: {
      resize: 'none',
      '&::placeholder': {
        fontFamily: 'StyreneMedium',
        fontSize: '12px',
        color: 'var(--blue)',
        opacity: '0.5',
      },
      width: '100%',
      color: 'var(--blue)',
      fontFamily: 'StyreneMedium',
      fontSize: '12px',
      border: 'none',
      background: 'white',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <Grid container direction="column" {...generateDataAttrs(dataAttrs)}>
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      <div className={classes.textContainer}>
        <div className={classes.field}>
          <textarea
            name={name}
            className={classes.textArea}
            rows={rows}
            {...props}
          />
          {lengthCounting && (
            <span className={classes.textCounter}>{lengthCounting}</span>
          )}
        </div>
        {!lengthCounting && <br />}
        {error && <span className={classes.errorText}>{error}</span>}
      </div>
    </Grid>
  )
}

export default memo(TextAreaInput)
