import React, { createContext, useContext, useState } from 'react'

const SignageContext = createContext({})

export const useSignageContext = () => useContext(SignageContext)

export default function SignageContextProvider({ children }) {
  const [installationType, setInstallationType] = useState('')
  const [outdoorSignageData, setOutdoorSignageData] = useState({})
  const [dataToBePresented, setDataToBePresented] = useState({})
  const [requestTypes, setRequestTypes] = useState([])
  const [signTypes, setSignTypes] = useState([])

  return (
    <SignageContext.Provider
      value={{
        installationType,
        setInstallationType,
        outdoorSignageData,
        setOutdoorSignageData,
        dataToBePresented,
        setDataToBePresented,
        requestTypes,
        setRequestTypes,
        signTypes,
        setSignTypes,
      }}
    >
      {children}
    </SignageContext.Provider>
  )
}
