import React from 'react'
import CustomGridContainer from '../components/common/CustomGridContainer'
import ErrorMsg from '../components/common/ErrorMsg'

export default function UnauthorizedPage() {
  return (
    <CustomGridContainer>
      <ErrorMsg
        errorMsg="Hmm, seems like you don't have authorization to access this!"
        subErrorMsg="If you think this is a mistake: try signing in again or contact us!"
      ></ErrorMsg>
    </CustomGridContainer>
  )
}
