import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ContentGridContextProvider from '../context/ContentGridContext'
import { MarketingContextProvider } from '../context/MarketingContext'
import SignageContextProvider from '../context/SignageContext'
import InputDataContextProvider from '../context/InputDataContext'
import Header from '../components/common/Header'
import HeaderNavigation from '../components/common/HeaderNavigation'
import { Intercom } from '../components/custom/Intercom'

const removePrefix = (path) => {
  const pathArray = path.split('/')
  return pathArray[pathArray.length - 1]
}

export default function Layout({ confirmLeave = false, flowType, children }) {
  return (
    <LazyLoadingContext forceFlow={flowType}>
      <Header>
        <HeaderNavigation confirmLeave={confirmLeave} />
      </Header>
      {children}
      <Intercom />
    </LazyLoadingContext>
  )
}

const LazyLoadingContext = ({ children, forceFlow }) => {
  const { flow = forceFlow } = useParams()
  const { pathname } = useLocation()
  const flowType = flow || removePrefix(pathname)

  return (
    <>
      {flowType === 'marketing' ? (
        <MarketingContextProvider>
          <InputDataContextProvider>{children}</InputDataContextProvider>
        </MarketingContextProvider>
      ) : flowType === 'signage' ? (
        <SignageContextProvider>
          <InputDataContextProvider>{children}</InputDataContextProvider>
        </SignageContextProvider>
      ) : flowType === 'content' ? (
        <ContentGridContextProvider>{children}</ContentGridContextProvider>
      ) : (
        children
      )}
    </>
  )
}
