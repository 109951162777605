import React, { createContext, useContext, useState } from 'react'

export const EmailRTRContext = createContext({})

export const useEmailRTRContext = () => useContext(EmailRTRContext)

export default function EmailRTRContextProvider({ children }) {
  const [emailRTRData, setEmailRTRData] = useState({
    emailHTML: '',
    emailData: {},
    mailingLists: [],
  })

  const [EmailBodyData, setEmailBodyData] = useState({})
  const [HeroContentData, setHeroContentData] = useState({})

  return (
    <EmailRTRContext.Provider
      value={{
        emailRTRData,
        setEmailRTRData,
        EmailBodyData,
        setEmailBodyData,
        HeroContentData,
        setHeroContentData,
      }}
    >
      {children}
    </EmailRTRContext.Provider>
  )
}
