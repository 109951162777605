import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DynamicFormGenerator from '../DynamicFormGenerator'
import InformationInput from '../../custom/InformationInput'
import { useRequestContext } from '../../../context/RequestContext'

export default function ExistingSignageReview({
  options,
  formData,
  setFormData,
}) {
  const styles = () => ({
    container: {
      borderTop: '1px solid rgba(42, 41, 166, 0.35)',
      marginTop: '20px',
      padding: '40px 0 0 0',
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { selectedPropertyCtx } = useRequestContext()

  return (
    <Grid className={classes.container}>
      <Grid container direction="column" style={{ marginBottom: 15 }}>
        <InformationInput
          label="Installation Address"
          information={selectedPropertyCtx}
        />
        <DynamicFormGenerator
          options={options}
          formData={formData}
          setFormData={setFormData}
        />
      </Grid>
    </Grid>
  )
}
