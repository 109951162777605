import React from 'react'
import { Grid } from '@material-ui/core'
import CustomGridContainer from '../components/common/CustomGridContainer'
import SectionList from '../components/common/SectionList'
import StepCounter from '../components/common/StepCounter'
import ErrorMsg from '../components/common/ErrorMsg'
import { TEMPLATE_EXCLUDE_LIST } from '../utils/constants'

export default function PackageDetailsView({
  packageType,
  packageDetailsData,
  error,
  handleNextFunction,
  isNextButtonDisabled,
}) {
  return (
    <>
      <CustomGridContainer isFullScreen>
        {error && <ErrorMsg />}
        {packageDetailsData?.length > 0 ? (
          <SectionList
            sectionsData={packageDetailsData}
            packageType={packageType}
            excludeList={TEMPLATE_EXCLUDE_LIST}
          />
        ) : (
          <Grid style={{ marginTop: 25 }}>
            <ErrorMsg
              errorMsg={"Hmm, seems like you don't have any templates here..."}
              subErrorMsg={'Please, get in contact with us.'}
            />
          </Grid>
        )}
      </CustomGridContainer>
      <CustomGridContainer noMinHeight>
        <StepCounter
          showOnlyNavButtons
          handleNext={handleNextFunction}
          isNextButtonDisabled={isNextButtonDisabled}
        />
      </CustomGridContainer>
    </>
  )
}
