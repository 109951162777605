/**
 * Generated rule to validate min length
 * @param {string} errorMessage
 * @param {Object} options { size: number, gte: boolean = false }
 * @returns {function(*=): (boolean|string)} validator function
 */
export const minLengthRule = (errorMessage, options) => {
  const { size = 0, gte = false } = options
  /**
   * Validates min length rule
   * @param {string} v input value
   * @returns {boolean|string} true if valid, string if invalid
   */
  const rule = (v) =>
    !v || (gte ? v.length >= size : v.length > size) || errorMessage
  rule.options = options
  return rule
}
