import React, { createContext, useContext, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { A8Button, Typography } from 'av8-ui'
import { makeStyles } from '@material-ui/styles'

const styles = () => ({
  dialog: {
    maxWidth: 'min(calc(100vw - 32px), 360px)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dialogTitleWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    justifyItems: 'center',
    width: '100%',
  },
  dialogTitle: {
    fontFamily: 'BookmaniaBold',
    fontSize: '20px',
    flexGrow: 1,
    color: 'text-primary',
  },
  dialogClose: {
    flexShrink: 1,
    cursor: 'pointer',
    textAlign: 'right',
    fontFamily: 'StyreneA',
    fontSize: '14px',
  },
  dialogMessage: {
    fontFamily: 'StyreneA',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    lineBreak: 'auto',
    wordBreak: 'break-word',
    color: 'text.secondary',
  },
  dialogMessageTypography: {
    color: 'text.secondary',
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: '0.5em',
    marginRight: '0.5em',
  },
})

const ModalDialog = ({
  open = false,
  title,
  message,
  firstButtonText = 'Confirm',
  firstButtonActionClick,
  secondButtonText = null,
  secondButtonActionClick,
  onConfirm,
  onDismiss,
  onExited = () => {},
}) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onBackdropClick={onDismiss}
      onExited={onExited}
      onClose={onDismiss}
      PaperProps={{ square: true, elevation: 0 }}
      className={classes.dialog}
    >
      <DialogTitle color="text.primary">
        <div className={classes.dialogTitleWrapper}>
          <Typography as="h2" className={classes.dialogTitle}>
            {title}
          </Typography>
          <Typography
            as="a"
            className={classes.dialogClose}
            onClick={onDismiss}
          >
            Cancel
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogMessage}>
          <Typography as="p" className={classes.dialogMessageTypography}>
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <A8Button
          disableElevation
          size="large"
          color="primary"
          variant="contained"
          onClick={() => onConfirm(firstButtonActionClick)}
        >
          {firstButtonText}
        </A8Button>
        {secondButtonText ? (
          <A8Button
            disableElevation
            size="large"
            color="primary"
            variant="contained"
            onClick={() => onConfirm(secondButtonActionClick)}
          >
            {secondButtonText}
          </A8Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

const ModalDialogContext = createContext({})

export const ModalDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState({})

  const openDialog = (options) => {
    setDialogOpen(true)
    const config = {}
    Object.entries(options)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => (config[key] = value))
    setDialogConfig(config)
  }

  const onConfirm = (action = true) => {
    setDialogOpen(false)
    dialogConfig.actionCallback(action)
  }

  const onDismiss = () => {
    setDialogOpen(false)
    dialogConfig.actionCallback(false)
  }

  const onExited = () => {
    setDialogConfig({})
  }

  return (
    <ModalDialogContext.Provider value={{ openDialog }}>
      <ModalDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        firstButtonText={dialogConfig?.firstButtonText}
        secondButtonText={dialogConfig?.secondButtonText}
        firstButtonActionClick={dialogConfig?.firstButtonActionClick}
        secondButtonActionClick={dialogConfig?.secondButtonActionClick}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        onExited={onExited}
      />
      {children}
    </ModalDialogContext.Provider>
  )
}

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ModalDialogContext)

  const getConfirmation = (options) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options })
    })

  return { getConfirmation }
}
