import React, { createContext, useContext, useState } from 'react'

const ContentGridContext = createContext({})

export const useContentGridContext = () => useContext(ContentGridContext)

export default function ContentGridContextProvider({ children }) {
  const [isSelecting, setIsSelecting] = useState(false)
  const [isSharing, setIsSharing] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [hasDownloaded, setHasDownloaded] = useState(false)

  return (
    <ContentGridContext.Provider
      value={{
        isSelecting,
        setIsSelecting,
        isSharing,
        setIsSharing,
        selectedImages,
        setSelectedImages,
        isDownloading,
        setIsDownloading,
        hasDownloaded,
        setHasDownloaded,
        isRemoving,
        setIsRemoving,
      }}
    >
      {children}
    </ContentGridContext.Provider>
  )
}
