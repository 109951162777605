import React from 'react'
import { default as MaterialButton } from '@material-ui/core/Button'
import { Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowRight from '../../assets/icons/Arrow_Right.svg'
import classNames from 'classnames'
import { generateDataAttrs } from '../../utils/utils'

export default function ButtonCard(props) {
  const {
    className,
    primary,
    secondary,
    bordered,
    large,
    medium,
    boxMedium,
    small,
    clickHandler,
    title,
    cardTitle,
    cardSubtitle,
    dataAttrs = {},
  } = props
  const styles = {
    primary: { backgroundColor: 'var(--white)', color: 'var(--blue)' },
    secondary: { backgroundColor: 'var(--blue)', color: 'var(--white)' },
    bordered: { border: 'solid 1px var(--blue)' },
    large: { width: '300px', height: '60px', fontSize: '20px' },
    medium: { width: '260px', height: '50px', fontSize: '14px' },
    small: { width: '140px', height: '40px', fontSize: '14px' },
    boxLarge: { width: '300px', minHeight: '120px', fontSize: '20px' },
    boxMedium: { width: '260px', minHeight: '80px', fontSize: '14px' },
    boxSmall: { width: '140px', minHeight: '40px', fontSize: '14px' },
    wrapperBox: {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
      backgroundColor: '#ffffff',
      marginBottom: '30px',
      width: '300px',
    },
    button: {
      borderRadius: '0',
      fontFamily: 'BookmaniaBold',
      lineHeight: '1.29',
      fontWeight: 500,
      letterSpacing: '0.25px',
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 15px 0 25px',
      '&:hover': {
        backgroundColor: 'var(--blue)',
      },
    },
    boxContainer: {
      padding: '0',
    },
    cardTitle: {
      fontSize: '12px',
      fontFamily: 'StyreneA',
      fontWeight: 400,
      color: '#292929',
      lineHeight: '12px',
      letterSpacing: '0.25px',
      opacity: '0.70',
      marginBottom: '10px',
      textAlign: 'left',
    },
    boxSubtitle: {
      fontFamily: 'StyreneA',
      color: 'var(--blue)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.05px',
    },
    icon: {
      marginRight: '12px',
    },
    typoGroup: {
      width: '260px',
      padding: '20px 20px 25px 20px',
    },
    titleGrid: {
      textAlign: 'left',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const buttonClasses = classNames({
    [classes.button]: true,
    [classes.primary]: primary,
    [classes.secondary]: secondary,
    [classes.bordered]: bordered,
    [classes.large]: large,
    [classes.medium]: medium,
    [classes.small]: small,
    [className]: className !== undefined,
  })

  const bottomBoxClasses = classNames({
    [classes.boxContainer]: true,
    [classes.boxLarge]: large,
    [classes.boxMedium]: boxMedium,
    [classes.boxSmall]: small,
    [className]: className !== undefined,
  })

  return (
    <Box className={classes.wrapperBox} {...generateDataAttrs(dataAttrs)}>
      <MaterialButton
        className={buttonClasses}
        onClick={() => clickHandler()}
        endIcon={
          <img src={ArrowRight} alt="av8box" className={classes.icon}></img>
        }
      >
        <Grid className={classes.titleGrid}>
          {title &&
            title.split('\n').map((i, key) => {
              return <p key={key}>{i}</p>
            })}
        </Grid>
      </MaterialButton>
      <Box className={bottomBoxClasses}>
        <Box className={classes.typoGroup}>
          <Typography className={classes.cardTitle}>{cardTitle}</Typography>
          <Typography className={classes.boxSubtitle}>
            {cardSubtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
