import { useContext } from 'react'
import { createContext } from 'react'
import { RTRLayer } from '../hooks/useRTRender'
import { RtrTemplate } from '../modules/marketing/components/TemplatePreview/TemplateRTRPreview'

export type PackageDetailsData = Record<
  string,
  {
    displayName: string
    packageType: string
    templateCategory: string
    images: {
      url: string
      name: string
      itemId: number
      id: number
      layers: RTRLayer[]
      isRTR: boolean
      formats: Record<string, any>
      carouselImages: [{ isHidden?: boolean; url: string }]
    }[]
    rtrTemplates: RtrTemplate[]
  }[]
>

export type PackageDetailsContext = {
  packageDetailsData: PackageDetailsData
  setPackageDetailsData: (
    data:
      | PackageDetailsData
      | ((data: PackageDetailsData) => PackageDetailsData)
  ) => void
}

export const PackageDetailsContext = createContext<PackageDetailsContext>({
  packageDetailsData: {},
  setPackageDetailsData: () => {},
})

export const usePackageDetailsContext = (): PackageDetailsContext =>
  useContext<PackageDetailsContext>(PackageDetailsContext)
