import React from 'react'
import { TemplateStepperStyles } from './TemplateStepper.styles'
import { Grid } from '@material-ui/core'
import { TemplateStep } from './TemplateStep'
import { generateDataAttrs } from '../../../../utils/utils'

export type TemplateStepData = {
  label?: string;
  bulletText?: string;
  number: number;
  name: string;
}

export type TemplateStepperProps = {
  activeStep: number;
  steps: TemplateStepData[];
  dataAttrs?: Record<string, string>;
}

export const TemplateStepper = (props: TemplateStepperProps): JSX.Element => {
  const { steps, activeStep, dataAttrs = {} } = props
  const classes = TemplateStepperStyles()
  const stepItems = steps
    .slice(0)
    .sort(({ number: a }, { number: b }) => a - b)
    .flatMap((step, idx) => {
      const isFirst = idx < 1
      const isActive = step.number === activeStep
      const stepAttrs = generateDataAttrs({
        metaName: 'step',
        testName: 'step-counter-step',
        metaIdx: step.number + 1,
        metaActive: isActive ? true : void 0,
      })
      const divider = (
        <Grid item xs>
          <hr className={step.bulletText ? classes.bulletDivider : classes.numericDivider} />
        </Grid>
      )
      return (
        <>
          {!isFirst && (divider)}
          <Grid item xs key={step.number} className={classes.stepContainer} {...stepAttrs}>
            <TemplateStep
              key={step.number}
              active={isActive}
              isBulletCounter={!!step.bulletText}
              {...step}
            />
          </Grid>
        </>
      )
    })
  const numberOfSteps = steps.length
  const elDataAttrs: Record<string, string> = generateDataAttrs({
    ...dataAttrs,
    metaName: 'step-counter',
    testName: 'step-counter',
    metaStepCount: isNaN(+numberOfSteps) ? null : +numberOfSteps,
  })
  return (
    <Grid
      container
      className={classes.container}
      direction="row"
      justify="space-between"
      alignItems="center"
      {...elDataAttrs}>
      {stepItems}
    </Grid>
  )
}
