import React, { useEffect, useState } from 'react'
import { IntercomFAB } from 'av8-ui'
import {
  getAgentProfile,
  getIntercomCredentials,
} from '../../services/authService'
import { useRequestContext } from '../../context/RequestContext'

export const Intercom = () => {
  const { isEmbedded } = useRequestContext()
  const [token, setToken] = useState(null)
  const [agent, setAgentData] = useState(null)
  const [appId, setAppId] = useState(null)
  const [userHash, setUserHash] = useState(null)
  useEffect(() => {
    const updateToken = () => {
      setToken(window.localStorage.getItem('token'))
    }
    updateToken()
    window.addEventListener('storage', updateToken)
    return () => {
      window.removeEventListener('storage', updateToken)
    }
  }, [])
  useEffect(() => {
    const fetchAgentAndIntercomCredentials = async () => {
      const { success: agentProfileSuccess, data: profile } =
        await getAgentProfile()
      if (agentProfileSuccess) {
        const { id, firstName, lastName, email } = profile
        const { success: credentialsSuccess, data: intercomCredentials } =
          await getIntercomCredentials()
        if (credentialsSuccess) {
          const agent = {
            name: [firstName, lastName].join(' '),
            email,
            id,
            userHash: intercomCredentials.userHash,
          }
          setAgentData(agent)
          setAppId(intercomCredentials.appId)
          setUserHash(intercomCredentials.userHash)
        }
      }
    }
    if (token && !isEmbedded) {
      fetchAgentAndIntercomCredentials()
    }
  }, [token])
  return (
    !isEmbedded &&
    appId &&
    userHash && (
      <IntercomFAB
        appId={appId}
        agent={agent}
        horizontalPadding={50}
        verticalPadding={100}
      />
    )
  )
}
