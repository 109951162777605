import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../../../../components/common/Text'
import Image from '../../../../components/common/Image'
import InformationInput from '../../../../components/custom/InformationInput'
import { OptionType, SignPresentationDataType } from '../../types'

const styles = (theme: any) => ({
  image: { alignSelf: 'center' },
  container: {
    borderTop: '1px solid rgba(42, 41, 166, 0.35)',
    marginTop: '20px',
    padding: '20px 0 0 0',
  },
  upperAreaContainer: {
    padding: '0 0 40px 0',
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
    marginBottom: 20,
  },
  signContainer: {
    padding: '0 0 10px 0',
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
    marginBottom: 15,
    boxSizing: 'border-box',
    marginTop: '0',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      padding: '0 120px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '650px',
      padding: '0 170px',
    },
  },
})

const useStyles = makeStyles(styles as any)

type SignReviewPresentationType = {
  sign: SignPresentationDataType
  index: number
}

export default function SignReviewPresentation({
  sign,
  index,
}: SignReviewPresentationType) {
  const classes = useStyles()

  return (
    <Grid className={classes.signContainer}>
      <Text type='muted'>{sign.typeDisplayName} Sign</Text>
      <Grid>
        <Text type='strong' style={{ marginBottom: 20 }}>
          {sign.typeDisplayName} Sign #{index + 1}
        </Text>
      </Grid>

      <Grid className={classes.image}>
        <Image
          src={sign.url}
          medium
          alt={sign.type}
          rider={sign.type === 'rider'}
        />
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        {sign.formDataOptions?.map((option: OptionType) => (
          <InformationInput
            label={option.label || ''}
            information={option.formattedValue || option.value}
            key={option.label}
          />
        ))}
      </Grid>
    </Grid>
  )
}
