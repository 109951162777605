import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../../context/AuthContext'
import { getAdsEstimates } from '../../../../services/marketingServices'
import { useRequestContext } from '../../../../context/RequestContext'
import { AdsEstimatesPresentation } from './AdsEstimatesPresentation'
import { getCancelToken } from '../../../../services/api'

export const AdsEstimates = ({
  formData = {},
  setAdEstimatesHandler,
  loadingEstimatesHandler,
} = {}) => {
  const { agentId } = useAuthContext()
  const { listingAddressCtx } = useRequestContext()
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [coefficients, setCoefficients] = useState(
    formData.promotedAdEstimates?.value?.coefficients,
  )
  const [requestError, setRequestError] = useState(null)
  const [requestLoading, setRequestLoading] = useState(!coefficients)
  const [requestSuccess, setRequestSuccess] = useState(!!coefficients)
  const [formError, setFormError] = useState(false)
  const [selectedEstimate, selectEstimate] = useState({})
  const [budget, setBudget] = useState(formData?.paidAdBudget?.value)
  const [startDate, setStartDate] = useState(formData.campaignStartDate?.value)
  const [endDate, setEndDate] = useState(formData.campaignEndDate?.value)
  const [previousStartDate, setPreviousStartDate] = useState(
    formData.campaignStartDate?.value,
  )
  const [previousEndDate, setPreviousEndDate] = useState(
    formData.campaignEndDate?.value,
  )

  const setSelectedEstimate = (estimate = null) => {
    selectEstimate(estimate)
    if (setAdEstimatesHandler && typeof setAdEstimatesHandler === 'function') {
      setAdEstimatesHandler({
        estimate,
        coefficients,
      })
    }
  }

  useEffect(() => {
    // this runs when the component is unmounted
    return () => {
      if (cancelTokenSource) {
        // tries to cancel the ongoing request to avoid memory leaks
        cancelTokenSource.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (loadingEstimatesHandler) {
      loadingEstimatesHandler(requestLoading)
    }
  }, [requestLoading])

  useEffect(() => {
    const budgetValue = formData?.paidAdBudget?.value
    setBudget(budgetValue)
    setFormError(
      Number.isNaN(budgetValue) || +budgetValue > 0 || +budgetValue <= 1000000,
    )
  }, [formData?.paidAdBudget?.value])

  useEffect(() => {
    setStartDate(formData.campaignStartDate?.value)
  }, [formData.campaignStartDate?.value])

  useEffect(() => {
    setEndDate(formData.campaignEndDate?.value)
  }, [formData.campaignEndDate?.value])

  useEffect(() => {
    let shouldCancel = false
    let localCancelSource = null
    const getEstimates = async () => {
      const paidAdBudget =
        budget && !Number.isNaN(+budget) && +budget >= 100 ? budget : 100
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
      }
      localCancelSource = getCancelToken()
      setCancelTokenSource(localCancelSource)
      const { success, data, error } = await getAdsEstimates(
        agentId,
        startDate,
        endDate,
        paidAdBudget,
        listingAddressCtx.city,
        cancelTokenSource,
      )

      if (!success) {
        setRequestError(error.message)
        setFormError(false)
        return
      }
      data._city = listingAddressCtx.city
      data._start = startDate
      data._end = endDate
      data._budget = paidAdBudget
      data._network = 'facebook'
      data._timestamp = new Date().getTime()
      return data
    }

    const haveTheRequiredValues = startDate && endDate && budget !== null
    const changedDates =
      previousEndDate !== endDate || previousStartDate !== startDate

    if (haveTheRequiredValues && (changedDates || !coefficients)) {
      setRequestLoading(true)
      getEstimates()
        .then((data) => {
          if (!shouldCancel) {
            setRequestSuccess(!!data)
            setCoefficients(data || null)
          }
        })
        .catch((error) => {
          console.error('Failed to load estimations:', error.message, error)
          if (!shouldCancel) {
            setRequestError(error.message)
            setFormError(false)
            setRequestSuccess(false)
          }
        })
        .then(() => {
          if (!shouldCancel) {
            setRequestLoading(false)
            localCancelSource = null
            setCancelTokenSource(null)
          }
        })
    }
    setPreviousStartDate(startDate)
    setPreviousEndDate(endDate)
    return () => {
      setRequestLoading(false)
      shouldCancel = true // if the request fails to cancel this is a fallback
      if (localCancelSource) {
        // tries to cancel the local reference
        localCancelSource.cancel()
      }
      if (cancelTokenSource) {
        // try to cancel other request that could be left behind
        cancelTokenSource.cancel()
      }
    }
  }, [startDate, endDate, budget, coefficients])

  // Processes the response to pass data to the presentation
  useEffect(() => {
    if (requestLoading || !requestSuccess) {
      setSelectedEstimate(null)
      return
    }
    const { paidAdBudget: { error } = {} } = formData
    setFormError(!budget || error)
    if (!budget || error) {
      return
    }
    const budgetNumericValue = budget.toString().replace(/\D/g, '')
    const budgetValue = parseInt(budgetNumericValue, 10) * 100
    if (!Number.isNaN(budgetValue)) {
      const estimate = Object.fromEntries(
        Object.entries(coefficients || {})
          .filter(([key]) => !/^_/.test(key)) // eliminates keys starting with _
          .map(([key, coefficients]) => {
            const val = coefficients.reduce(
              (sum, { order: n, coefficient: a }) =>
                sum + a * Math.pow(budgetValue, n),
              0,
            )
            return [key, Math.ceil(val)]
          }),
      )
      setSelectedEstimate(estimate)
    }
  }, [budget, formData.paidAdBudget?.error, coefficients, requestLoading])

  return (
    <AdsEstimatesPresentation
      budget={formData.paidAdBudget?.value}
      loading={requestLoading}
      success={requestSuccess}
      data={selectedEstimate}
      formError={formError}
      error={requestError}
    />
  )
}
