import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, List } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Layout from '../components/common/Layout.js'
import StepCounter from '../components/common/StepCounter'
import CustomGridContainer from '../components/common/CustomGridContainer'
import PagePresentation from '../components/common/PageTitle'
import Checkbox from '../components/common/Checkbox'
import Loading from '../components/common/Loading'
import Text from '../components/common/Text'
import Input from '../components/common/Input'
import CustomAddressInput from '../components/custom/CustomAddressInput'
import FormikSelect from '../components/common/FormikSelect'
import { useAuthContext } from '../context/AuthContext.js'
import { generateDataAttrs, statesOptions } from '../utils/utils'
import { useRequestContext } from '../context/RequestContext.js'
import {
  getAddressLookup,
  getAgentRecentAddress,
  getAddressZip,
} from '../services/addressServices'
import Button from '../components/common/Button'
import useHistoryPrefix from '../hooks/useHistoryPrefix.js'
import DividerWithText from '../components/common/DividerWithText.js'

const stepCounterNumber = {
  marketing: 5,
  signage: 3,
}

const customAddressLabel = {
  existing: 'Property not here?',
  'new-installation': 'Property not here? Or just need signs?',
}

const pageNavigation = {
  'new-installation': 'outdoordetails',
  existing: 'installsignage',
  mylisting: 'stageselection',
}

const customAddressFormValidationSchema = Yup.object().shape({
  zip: Yup.string()
    .length(5, 'Zip code must be 5 digit long.')
    .required('Zip code is required.')
    .matches('^[0-9]+$', 'Invalid ZIP.'),
  firstAddressLine: Yup.string()
    .min(2, 'This address is incomplete')
    .required('Address is required.'),
  city: Yup.string().required('City is a required field.'),
  state: Yup.string()
    .required('State is a required field.')
    .min(2, 'Invalid state.'),
})

export default function PropertySelection() {
  const history = useHistoryPrefix()
  const {
    setSelectedPropertyCtx,
    selectedPropertyCtx,
    listingAddressCtx,
    setListingAddressCtx,
  } = useRequestContext()
  const { agentId } = useAuthContext()
  const [showPage, setShowPage] = useState(false)
  const [pageData, setPageData] = useState({})
  const [selectedPropertyPage, setSelectedPropertyPage] =
    useState(selectedPropertyCtx)
  const [showCustomAddress, setShowCustomAddress] = useState(false)
  const [customAddressInput, setCustomAddressInput] =
    useState(selectedPropertyCtx)
  const [autoCompleteAddresses, setAutoCompleteAddresses] = useState([])
  const [showCustomAddressForm, setShowCustomAddressForm] = useState(false)
  const [customAddressFormData, setCustomAddressFormData] = useState({
    firstAddressLine: listingAddressCtx.firstAddressLine || '',
    secondAddressLine: listingAddressCtx.secondAddressLine || '',
    city: listingAddressCtx.city || '',
    state: listingAddressCtx.state || '',
    zip: listingAddressCtx.zip || '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const [showSeeAll, setShowSeeAll] = useState(true)

  const { flow, subflow } = useParams()

  const mountAddress = (addressArray) => {
    const mountedAddressArray = addressArray?.map((address) => {
      return {
        formattedAddress: `${address.firstAddressLine || ''}, ${
          address.city || ''
        }, ${address.state || ''}, ${address.zip || ''}`,
        listing: address,
      }
    })
    return mountedAddressArray
  }

  useEffect(() => {
    const loadPageData = async () => {
      const response = await getAgentRecentAddress(agentId)
      const agentAddresses = mountAddress(response.data) || []

      setPageData(agentAddresses)
      setIsLoading(false)
      setShowPage(true)

      if (selectedPropertyPage) {
        const isCustomOption = !isSelectedPropertyInArray(
          selectedPropertyPage,
          agentAddresses,
        )

        if (isCustomOption) {
          setShowCustomAddress(true)
          setShowCustomAddressForm(true)
        }
      }
    }
    loadPageData()
  }, [])

  useEffect(() => {
    setIsNextButtonDisabled(
      !selectedPropertyPage || (showCustomAddress && !showCustomAddressForm),
    )
  }, [selectedPropertyPage, showCustomAddress, showCustomAddressForm])

  const isSelectedPropertyInArray = (selectedProperty, array) => {
    const isInArray = array.some(
      (option) => selectedProperty === option.formattedAddress,
    )
    return isInArray
  }

  const handleCheckboxChange = (e) => {
    const propertyValue = e.target.value
    setShowCustomAddress(false)
    setShowCustomAddressForm(false)
    setSelectedPropertyPage(propertyValue)
  }

  const handleNextPageClick = () => {
    const propertyValue = selectedPropertyPage || customAddressInput
    setSelectedPropertyCtx(propertyValue)

    const selectedAddress = pageData.find(
      (address) => address.formattedAddress === propertyValue,
    )

    setListingAddressCtx(selectedAddress?.listing || customAddressFormData)
    history.push(`/${flow}/${subflow}/${pageNavigation[subflow]}`)
  }

  const handleAddressClick = async (addressData) => {
    if (addressData.description !== '') {
      const splitAddress = addressData.description.split(',')
      if (splitAddress) {
        const { zip } = await getAddressZip(addressData.placeId)
        const address = {
          firstAddressLine: splitAddress[0].trim() || '',
          secondAddressLine: '',
          city: splitAddress[1].trim() || '',
          state: splitAddress[2].trim() || '',
          zip,
        }
        setCustomAddressFormData(address)
      }
    }
    setShowCustomAddressForm(true)
  }

  const handleCustomInputChange = async (e) => {
    const input = e.target.value
    setCustomAddressInput(input)
    if (input.length > 3) {
      const response = await getAddressLookup(input)
      if (response.success) {
        setAutoCompleteAddresses(response.data)
      }
    }
  }

  const handleCustomAddressFormSubmit = (e) => {
    const mountedCustomAddress = Object.values(e)
      .filter((e) => e)
      .join(', ')
    setListingAddressCtx(e)

    setSelectedPropertyCtx(mountedCustomAddress)
    history.push(`/${flow}/${subflow}/${pageNavigation[subflow]}`)
  }

  const handleClickSeeAll = async () => {
    const response = await getAgentRecentAddress(agentId, 50)
    const agentAddresses = mountAddress(response.data) || []
    setPageData(agentAddresses)
    setShowSeeAll(false)
  }

  return (
    <Layout>
      {isLoading && <Loading />}
      {showPage && (
        <>
          <CustomGridContainer>
            <Grid item>
              <StepCounter
                numberOfSteps={stepCounterNumber[flow]}
                activeStep={1}
                handleNext={handleNextPageClick}
                isNextButtonDisabled={isNextButtonDisabled}
              />
            </Grid>
            <PagePresentation
              CTATextUpper={'Property'}
              CTATextLower={'Selection'}
              component={'span'}
              dataAttrs={{ metaName: 'title' }}
              isSingleLine
            />
            <Grid>
              {pageData?.map((option, i) => (
                <Checkbox
                  dataAttrs={{
                    metaAction: 'select-address',
                    metaIdx: i + 1,
                  }}
                  label={option.formattedAddress}
                  key={option.formattedAddress + i}
                  defaultChecked={
                    selectedPropertyPage === option.formattedAddress
                  }
                  name={'propertySelection'}
                  type={'radio'}
                  onChange={handleCheckboxChange}
                />
              ))}
              {showSeeAll && (
                <Grid
                  onClick={handleClickSeeAll}
                  style={{ marginBottom: 20 }}
                  {...generateDataAttrs({
                    metaAction: 'see-all-listings',
                  })}
                >
                  <DividerWithText
                    text="SEE ALL"
                    icon={<KeyboardArrowDown fontSize={'medium'} />}
                  />
                </Grid>
              )}
            </Grid>
            {!showCustomAddressForm && (
              <Checkbox
                labelOverText={customAddressLabel[subflow]}
                label={'Add custom address'}
                key={'customProperty'}
                value={'customProperty'}
                defaultChecked={
                  selectedPropertyPage &&
                  !isSelectedPropertyInArray(selectedPropertyPage, pageData)
                }
                name={'propertySelection'}
                type={'radio'}
                dataAttrs={{ metaAction: 'select-custom-address' }}
                onChange={() => {
                  setShowCustomAddress(true)
                  setSelectedPropertyPage(null)
                  setCustomAddressInput('')
                }}
              />
            )}
            {showCustomAddress && !showCustomAddressForm && (
              <Grid item style={{ paddingBottom: 95 }}>
                <Input
                  label="Custom address"
                  name="address"
                  type="input"
                  value={customAddressInput}
                  onChange={handleCustomInputChange}
                  id="address"
                  placeholder="Enter address name"
                  style={{ marginBottom: 10 }}
                  dataAttrs={{ metaName: 'customAddressInput' }}
                />
                <Text
                  style={{ cursor: 'pointer', marginBottom: 10 }}
                  type="muted"
                  onClick={() => handleAddressClick({ description: '' })}
                >
                  Can't find your address? Click here
                </Text>
                {autoCompleteAddresses.length > 0 && !showCustomAddressForm && (
                  <>
                    <Text type="medium">Are you looking for this address?</Text>
                    <List component="nav">
                      {autoCompleteAddresses.map((address) => (
                        <Text
                          key={address.placeId}
                          style={{ cursor: 'pointer', marginBottom: 10 }}
                          type="muted"
                          onClick={() => handleAddressClick(address)}
                        >
                          {address.description}
                        </Text>
                      ))}
                    </List>
                  </>
                )}
              </Grid>
            )}
            {showCustomAddressForm && (
              <Grid style={{ marginBottom: 50 }}>
                <Formik
                  initialValues={customAddressFormData}
                  enableReinitialize
                  onSubmit={(e) => handleCustomAddressFormSubmit(e)}
                  validationSchema={customAddressFormValidationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ errors, setFieldValue }) => {
                    const handleInputChange = async (e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }

                    return (
                      <Form>
                        <Grid
                          {...generateDataAttrs({
                            metaName: 'customAddressForm',
                          })}
                        >
                          <CustomAddressInput
                            name="firstAddressLine"
                            type="input"
                            label="Street"
                            error={errors.firstAddressLine}
                            handleInputChange={handleInputChange}
                            id="firstAddressLine"
                            placeholder="Street Address"
                          />
                        </Grid>
                        <Grid style={{ marginTop: 10 }}>
                          <CustomAddressInput
                            name="secondAddressLine"
                            type="input"
                            label="Unit (optional)"
                            error={errors.secondAddressLine}
                            handleInputChange={handleInputChange}
                            id="secondAddressLine"
                            placeholder="Unit no"
                          />
                        </Grid>
                        <Grid style={{ marginTop: 10 }}>
                          <CustomAddressInput
                            name="city"
                            type="input"
                            label="City"
                            error={errors.city}
                            handleInputChange={handleInputChange}
                            id="city"
                            placeholder="City"
                          />
                        </Grid>
                        <Grid style={{ display: 'flex', marginTop: 10 }}>
                          <FormikSelect
                            as="select"
                            name="state"
                            type="input"
                            label="State"
                            error={errors.state}
                            id="state"
                          >
                            {statesOptions.map((option) => (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormikSelect>
                          <CustomAddressInput
                            name="zip"
                            type="input"
                            label="Zip"
                            error={errors.zip}
                            handleInputChange={handleInputChange}
                            id="zip"
                            placeholder="ZIP"
                            maxLength={5}
                            number="number"
                            style={{ width: '82%' }}
                          />
                        </Grid>
                        <Grid
                          style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            title={'Use this address'}
                            primary
                            medium
                            clickHandler={() => {}}
                            type="submit"
                          />
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Grid>
            )}
            <StepCounter
              showOnlyNavButtons
              handleNext={handleNextPageClick}
              isNextButtonDisabled={isNextButtonDisabled}
            />
          </CustomGridContainer>
        </>
      )}
    </Layout>
  )
}
