import { ErrorBoundary } from '@sentry/react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import CustomGridContainer from '../../../components/common/CustomGridContainer'
import Layout from '../../../components/common/Layout.js'
import Loading from '../../../components/common/Loading'
import MarketingRequestReview from '../../../components/custom/MarketingRequest/MarketingRequestReview'
import { useAuthContext } from '../../../context/AuthContext'
import { useRequestContext } from '../../../context/RequestContext'
import ReviewRequestView from '../../../components/custom/ReviewRequestView'
import { useConfirmationDialog } from '../../../components/custom/ModalDialog'
import {
  submitEmailRTRDraft,
  submitEmailRTRequest,
  submitMarketingRequest,
} from '../../../services/marketingServices'
import {
  formatObjectIntoArrayOfKeyValues,
  reduceKeyValueArrayToOptionObject,
} from '../../../utils/utils'
import useHistoryPrefix from '../../../hooks/useHistoryPrefix'
import { useInputDataContext } from '../../../context/InputDataContext'
import { RTREngine } from 'av8-rtrt-renderer'
import {
  renderHighResolutionSvg,
  renderHighResolutionTemplate,
} from '../actions/renderHighResolutionTemplate'
import { usePackageDetailsContext } from '../../../context/PackageDetailsContext'
import { useListingDescriptionContext } from '../../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../../context/StageSelectionContext'
import { parseEmailRTROptions } from '../modules/EmailBlast/actions/parseEmailRTROptions'

const MARKETING_REQUEST_REVIEW_STEP = 5
const RTR_IMAGE_SIZE_TO_SUBMIT = 1080 // in px

enum PackagesTypes {
  PRINT = 'print',
  DIGITAL = 'digital',
}

enum TemplateCategoryType {
  EMAILBLAST = 'email-rtr',
}

export function VisualMarketingReviewRequestPage(): JSX.Element {
  const history = useHistoryPrefix()
  const { getConfirmation } = useConfirmationDialog()
  const { formMarketingDataCtx } = useInputDataContext() as {
    formMarketingDataCtx: Record<string, any>
    formSignageDataCtx: {
      outdoor?: Record<string, Record<string, any>[]>
      [k: string]: any
    }
  }
  const {
    flow = 'marketing',
    subflow,
    requestTypePath,
    packageType,
  } = useParams() as any
  const { listingAddressCtx } = useRequestContext() as any
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()

  const { packageDetailsData: packageDetailsDataCtx } =
    usePackageDetailsContext()
  const { agentId } = useAuthContext() as { agentId?: number }
  const [loading, setLoading] = useState(false)
  const [isEmailSentDialogOpen, setIsEmailSentDialogOpen] =
    useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const formatAndSubmit = async (): Promise<{
    success: boolean
    data?: any
  }> => {
    try {
      let data
      let success = false
      const formattedData = await formatMarketingData()

      const formattedRegularRTRTemplates = {
        ...formattedData,
        templates: formattedData.templates.filter(
          (template) =>
            template.templateCategory !== TemplateCategoryType.EMAILBLAST,
        ),
      }
      const emailRTRTemplates = {
        ...formattedData,
        templates: formattedData.templates.filter(
          (template) =>
            template.templateCategory === TemplateCategoryType.EMAILBLAST,
        ),
      }

      //Email Blast RTR are submitted to a different endpoint
      if (emailRTRTemplates?.templates.length > 0) {
        const parsedEmailRTRTemplates = await Promise.all(
          emailRTRTemplates.templates.map(async (template) => {
            return await parseEmailRTROptions(
              template.inputData,
              template.image,
              agentId,
              template.packageType,
              template.templateCategory,
            )
          }),
        )
        const submitableEmailRTRData = {
          ...emailRTRTemplates,
          templates: parsedEmailRTRTemplates,
        }
        const _response = await submitEmailRTRequest(submitableEmailRTRData)
        if (_response.success) {
          data = _response.data
          delete submitableEmailRTRData.templates[0].options['emailTemplate']
          await submitEmailRTRDraft({
            orderConfirmationId: data.orderId,
            agentId,
            emailBlastData: submitableEmailRTRData,
          })
          setResponse({ success: true, data })
          return { success: true, data }
        } else {
          return { success: false }
        }
      }

      if (formattedRegularRTRTemplates.templates.length > 0) {
        const marketingRequestResponse = await submitMarketingRequest(
          formattedRegularRTRTemplates,
        )
        data = marketingRequestResponse?.data
        success = marketingRequestResponse?.success
      }

      setResponse({ success, data })
      return { success, data }
    } catch (err) {
      console.error('failed format and submit', err)
      setResponse({ success: false, data: null })
      return { success: false }
    }
  }

  const formatMarketingData = async () => {
    const formattedTemplateData = Object.values(packageDetailsDataCtx)
      .map((packageData) => {
        // digital | print
        return packageData.flatMap((category) => {
          const { packageType, templateCategory } = category
          const categoryInputs =
            formMarketingDataCtx?.[packageType]?.[templateCategory] ?? []
          return categoryInputs.map(
            (inputData: { itemId: number; [k: string]: any }) => {
              const { itemId } = inputData
              const rtrIdx = Math.abs(+itemId) - 1
              const image =
                itemId < 0
                  ? category.rtrTemplates[rtrIdx] ||
                    category.rtrTemplates.find(
                      (rtrTemplate) => rtrTemplate.id === rtrIdx,
                    )
                  : category.images.find((image) => {
                      return image.itemId === itemId || image.id === itemId
                    })

              if (!image) {
                return null
              }

              // to do: refactor this by package type
              const options =
                packageType === PackagesTypes.PRINT
                  ? inputData?.formData?.submittableData ?? {}
                  : reduceKeyValueArrayToOptionObject(
                      formatObjectIntoArrayOfKeyValues({
                        ...inputData.formData,
                        ...inputData.dynamicFormData,
                      }),
                    )

              if (templateCategory === TemplateCategoryType.EMAILBLAST) {
                const formattedEmailBlastTemplateData = {
                  packageType,
                  templateCategory,
                  inputData,
                  image,
                  agentId,
                }
                return formattedEmailBlastTemplateData
              }
              const data =
                packageType === PackagesTypes.PRINT
                  ? inputData?.formData?.template?.dataForTemplate ?? {}
                  : {}

              const auxiliaryImageUrls =
                'carouselImages' in image
                  ? image.carouselImages
                      ?.filter(({ isHidden }) => !isHidden || isHidden !== true)
                      ?.map((c) => c.url)
                  : []

              return {
                packageType,
                templateCategory,
                options,
                image: { ...image, isRTR: itemId < 0, data: data || {} },
                auxiliaryImageUrls,
                templateId: itemId,
                name: image.name || `${category.displayName} #${rtrIdx}`,
                layers: image?.layers || {},
                metadata: inputData?.formData?.metadata || {},
              }
            },
          )
        })
      })
      .flat()
      .filter((template) => template)

    const engine: RTREngine = new RTREngine()
    engine.setup()

    const payloadImagesPromises = formattedTemplateData.map(
      async (template, index) => {
        if (!template) {
          return template
        }
        const { image: templateImage, ...templateWithoutImage } = template
        if (!template.image.isRTR) {
          return {
            ...templateWithoutImage,
            imageUrl: template.image.url,
            image: templateImage,
          }
        }

        let imageUrl = ''
        // We are dealing with Social Media RTR
        if (templateImage.url && typeof templateImage.url === 'object') {
          imageUrl = await renderHighResolutionSvg({
            agentId: agentId || -1,
            image: templateImage.url,
            width: RTR_IMAGE_SIZE_TO_SUBMIT,
            height: RTR_IMAGE_SIZE_TO_SUBMIT,
          }).catch((err) => {
            console.error('Failed to render/upload higher res template')
            throw err
          })
        } else {
          // We are dealing with Print RTR
          imageUrl = await renderHighResolutionTemplate({
            index,
            id: templateImage.id || templateImage.itemId,
            data: templateImage.data,
            width: RTR_IMAGE_SIZE_TO_SUBMIT,
            layers: templateImage.layers,
            agentId: agentId || 0,
            ignoreCache: true,
          }).catch((err) => {
            console.error('Failed to render/upload higher res template')
            throw err
          })
        }
        return { ...templateWithoutImage, imageUrl }
      },
    )

    const data = {
      agentId,
      headline: listingDescriptionCtx.headline,
      stage: stageSelectionDataCtx.value,
      stageName: stageSelectionDataCtx.stageName,
      listing: {
        ...listingAddressCtx,
        ...listingDescriptionCtx,
        images: listingDescriptionCtx.imagesIds,
      },
      templates: await Promise.all(payloadImagesPromises),
    }

    return data
  }

  const handleNextClick = async () => {
    setLoading(true)
    const _response = await formatAndSubmit()
    if (_response.success) {
      setLoading(false)
      const hasEmailBlastToSend = Object.values(
        formMarketingDataCtx?.digital?.['email-rtr'] ?? {},
      ).some((item: any) => !item.alreadySent)

      if (hasEmailBlastToSend) {
        setIsEmailSentDialogOpen(true)
        setResponse(_response)
      } else {
        redirectToRequestReceivedPage(_response.data)
      }
    } else {
      setLoading(false)
      getConfirmation({
        title: '',
        message:
          'Ooops, something went wrong... Please try again or get in touch with us.',
        firstButtonText: 'OK',
      })
      Sentry.captureException('Error while submitting a request')
    }
  }

  const redirectToRequestReceivedPage = (data: any) => {
    const pathname = [
      flow,
      subflow,
      requestTypePath,
      packageType,
      'requestreceived',
    ].join('/')
    history.push({
      pathname,
      state: {
        data: data,
      },
    })
  }

  const handleFinishClick = () => {
    setIsEmailSentDialogOpen(false)
    console.debug('respose', response)
    redirectToRequestReceivedPage(response.data)
  }

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <ErrorBoundary showDialog fallback={<p>Oops... Something broke here!</p>}>
          <CustomGridContainer>
            <ReviewRequestView
              numberOfSteps={MARKETING_REQUEST_REVIEW_STEP}
              activeStep={MARKETING_REQUEST_REVIEW_STEP}
              handleNext={handleNextClick}
              isNextButtonDisabled={false}
              CTATextLower={'request'}
              reviewComponent={<MarketingRequestReview />}
              nextButtonLabel={'Submit'}
              isEmailSentDialogOpen={isEmailSentDialogOpen}
              handleFinishClick={handleFinishClick}
            />
          </CustomGridContainer>
        </ErrorBoundary>
      )}
    </Layout>
  )
}
