import React from 'react'
import { Grid, makeStyles, Dialog } from '@material-ui/core'
import Button from '../../common/Button'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    paddingLeft: '23px',
  },
  itemContainer: {
    height: 70,
    width: '100%',
  },
  label: {
    margin: '45px 0 25px 0',
  },
  title: {
    fontFamily: 'Bookmania',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
    marginRight: '7px',
  },
  subTitle: {
    fontFamily: 'Bookmania',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '35px',
    lineHeight: '35px',
    letterSpacing: '-1.5px',
    color: '#292929',
  },
  message: {
    width: '90%',
    marginBottom: '20px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    alignSelf: 'baseline',
  },
  messageContent: {
    fontFamily: 'StyreneRegular',
    fontSize: '14px',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    color: '#292929',
  },
  sentTo: {
    marginBottom: '35px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    alignSelf: 'baseline',
  },
  sentLabel: {
    color: '#292929',
    fontFamily: 'StyreneRegular',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    opacity: 0.65,
    marginBottom: '10px',
  },
  email: {
    fontFamily: 'StyreneRegular',
    fontSize: '14px',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
  },
  twoButtons: {
    marginBottom: '33px',
  },
  oneButton: {
    marginBottom: '33px',
    justifyContent: ' center',
    marginLeft: '-27px',
  },
  noButton: {
    width: '115px',
    height: '50px',
    fontSize: '14px',
    marginRight: '14px',
  },
  yesButton: {
    width: '170px',
    height: '50px',
    fontSize: '14px',
  },
}))

export default function EmailRequestConfirmation({
  open,
  title,
  subtitle,
  message,
  email,
  noText = null,
  yesText,
  onNo,
  onYes,
  bottomMessage = false,
}) {
  const classes = useStyles()
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      disableBackdropClick
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          pointerEvents: 'none',
        },
      }}
      PaperProps={{
        style: { borderRadius: '10px', margin: 0, maxWidth: 340, width: '100%' },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid container className={classes.label}>
          <p className={classes.title}>{title}</p>
          <p className={classes.subTitle}>{subtitle}</p>
        </Grid>
        {!bottomMessage && (
          <Grid container className={classes.message}>
            <p className={classes.messageContent}>{message}</p>
          </Grid>
        )}
        {email && (
        <Grid className={classes.sentTo}>
          <p className={classes.sentLabel}>Preview will be sent to:</p>
          <p className={classes.email}>{email}</p>
        </Grid>
        )}
        {bottomMessage && (
          <Grid
            container
            className={classes.message}
            style={{ marginBottom: '58px' }}
          >
            <p className={classes.messageContent}>{message}</p>
          </Grid>
        )}
        <Grid
          container
          className={!noText ? classes.oneButton : classes.twoButtons}
        >
          <Grid>
            {noText && (
              <Button
                className={classes.noButton}
                bordered
                secondary
                title={noText}
                onClick={onNo}
                dataAttrs={{ metaAction: 'click-not-now' }}
              />
            )}
          </Grid>
          <Grid>
            <Button
              className={classes.yesButton}
              bordered
              primary
              title={yesText}
              onClick={onYes}
              dataAttrs={{ metaAction: 'click-send-email-request' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
