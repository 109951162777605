import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ContentGridCategory from '../custom/ContentGridCategory'
import Text from '../common/Text'
import Modal from '../common/Modal'
import MLSLoader from '../custom/MLSLoader'
import { useContentGridContext } from '../../context/ContentGridContext'

export default function ContentGrid({ gridData }) {
  const {
    isRemoving,
    isDownloading,
    hasDownloaded,
    isSelecting,
  } = useContentGridContext()
  const styles = (theme) => ({
    categoryGrid: {
      // background: 'var(--blue)',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: isSelecting ? 75 : 5,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      justifyItems: 'center',
      gridGap: '15px',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
      marginTop: 16,
      marginBottom: 20,
    },
    seeMoreBtnGrid: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const [loadingModalStatus, setLoadingModalStatus] = useState({
    loading: false,
    message: '',
    show: false,
  })

  useEffect(() => {
    setLoadingModalStatus({
      loading: isDownloading,
      message: isDownloading ? (
        'Preparing your download...'
      ) : (
        <>
          <Text>Download Completed</Text>
          <Text style={{ marginTop: 10 }} type="regular" color="#777">
            Check your photo library or local folder.
          </Text>
        </>
      ),
      show: isDownloading || hasDownloaded,
    })
  }, [isDownloading])

  useEffect(() => {
    setLoadingModalStatus({
      loading: isRemoving,
      message: 'Removing this content',
      show: isRemoving,
    })
  }, [isRemoving])

  return (
    <Grid className={classes.categoryGrid}>
      <Modal open={loadingModalStatus.show}>
        <MLSLoader
          loading={loadingModalStatus.loading}
          text={loadingModalStatus.message}
          setModalStatus={setLoadingModalStatus}
        />
      </Modal>
      <Text type="muted" style={{ marginBottom: 15 }}>
        Select one to share and download, select several to download
      </Text>
      {gridData &&
        gridData.map((category) => (
          <ContentGridCategory category={category} key={category.name} />
        ))}
    </Grid>
  )
}
