import React from 'react'
import CallReceivedIcon from '@material-ui/icons/CallReceived'

import Button from '../../common/Button'
import { IMPORTING_TYPES } from '../../../utils/constants'

const ImportFromGalleryOpenButton = ({ importingType, isNoLogo }) => {
  switch (importingType) {
    case IMPORTING_TYPES.Logo:
      return (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          clickHandler={() => {}}
          primary
          xsmall
          title={isNoLogo ? 'Add' : 'Change'}
        />
      )

    case IMPORTING_TYPES.MLS:
      return (
        <>
          <CallReceivedIcon
            style={{
              fontSize: 24,
              marginRight: 10,
              transform: 'rotateY(180deg)',
            }}
          />
          Import from MLS
        </>
      )
  }
}

export default ImportFromGalleryOpenButton
