import { createContext, useContext } from 'react'

export type StageSelection = {
  value: string;
  selectedFlowName: string;
  stageName: string;
}

export type StageSelectionContext = {
  stageSelection: StageSelection
  setStageSelection: (stageSelection: StageSelection) => void;
}

export const StageSelectionContext = createContext<StageSelectionContext>({
  stageSelection: {
    value: '',
    selectedFlowName: '',
    stageName: '',
  },
  setStageSelection: () => {},
})

export const useStageSelectionContext = (): StageSelectionContext => useContext<StageSelectionContext>(StageSelectionContext)
