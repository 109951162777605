import React from 'react'
import { AdsEstimatesPresentation } from '../../../../../../../components/custom/MarketingRequest/ads-estimates/AdsEstimatesPresentation'
import { useAuthContext } from '../../../../../../../context/AuthContext'
import { zipRegex } from '../../../../../../shared/utils/zipRegex'
import { searchPeopleInZipCode } from '../../../actions/dataAxleProxy'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'

export const MailingReach = () => {
  const { agentId } = useAuthContext() as { agentId: string }
  const { mailingSectionData, setMailingSectionData } =
    usePTRFormContextProvider()
  const [fetchingData, setFetchingData] = React.useState<boolean>(false)
  const [showEstimates, setShowEstimates] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | false>(false)
  const [reach, setReach] = React.useState<number>(0)

  React.useEffect(() => {
    const { values: { zip = '', distance = '' } = {}, errors = {} } =
      mailingSectionData.data

    if (Object.values(errors).length) {
      setFetchingData(false)
      setShowEstimates(false)
      setReach(0)
      return
    }

    const validZip = zip && zipRegex.test(zip)
    const validDistance = distance && /^\d+(\.\d+)?(\s?(m|km|mi|ft))$/

    if (validZip && validDistance) {
      setFetchingData(true)
      setShowEstimates(true)
      searchPeopleInZipCode(agentId, zip, distance)
        .then((response) => {
          if ('count' in response.data) {
            const { count } = response.data
            setReach(count)
            setMailingSectionData({
              ...mailingSectionData,
              data: {
                ...mailingSectionData.data,
                DataAxle: response.data,
                reach: count,
              },
            })
            setError(false)
          } else {
            setError('Failed to get estimates')
          }
          setFetchingData(false)
          setShowEstimates(true)
        })
        .catch((err) => {
          setError(err.message)
          setFetchingData(false)
        })
    } else {
      setShowEstimates(false)
    }
  }, [
    mailingSectionData.data.values?.zip,
    mailingSectionData.data.values?.distance,
  ])

  return (
    <div>
      {showEstimates && (
        <AdsEstimatesPresentation
          style={{
            backgroundColor: '#E8E8F8',
          }}
          loading={fetchingData}
          data={{ reach }}
          success={!error}
          title=""
          comment=""
          failMessage="Sorry we could not get a list"
        />
      )}
    </div>
  )
}
