import React, { useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import Select from '../../../../../../../components/common/Select'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import { merge } from 'lodash'

type ShippingSectionType = {
  options: ShippingSectionOptionType[]
}

type ShippingSectionOptionType = {
  id: string
  label: string
  value: string
  type?: 'select' | '' | undefined
  options?: ShippingSectionOptionType[] | undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ShippingSection({ options }: ShippingSectionType) {
  const { setShippingSectionData } = usePTRFormContextProvider()

  const updateFormData = PrintModalStore(
    ({ formData, setFormData }) =>
      (shipping: string) => {
        setFormData(
          merge(formData, {
            submittableData: {
              deliveryEstimate: {
                key: 'deliveryEstimate',
                label: 'Delivery Estimate',
                value: shipping,
              },
            },
          }),
        )
      },
  )

  useEffect(() => {
    setShippingSectionData('standard')
    updateFormData('standard')
  }, [])

  return (
    <Box px={2} width="100%">
      <Grid container>
        <Grid item xs={12}>
          <Select
            style={{ backgroundColor: 'white' }}
            label={'Estimated Delivery'}
            options={[
              {
                label: 'Standard (5-7 business days)',
                value: 'standard',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
