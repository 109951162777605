import React, { useEffect, useState } from 'react'
import Layout from '../components/common/Layout.js'
import Loading from '../components/common/Loading'
import EmailRTRContextProvider from '../context/EmailRTRContext.js'
import EmailBlastDialog from '../components/custom/MarketingRequest/EmailBlastDialog'
import {
  getEmailTemplate,
  getRequestById,
} from '../services/marketingServices.js'
import { useListingDescriptionContext } from '../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../context/StageSelectionContext'
import { useAuthContext } from '../context/AuthContext'
import { useRequestContext } from '../context/RequestContext.js'
import { useConfirmationDialog } from '../components/custom/ModalDialog.js'
import useHistoryPrefix from '../hooks/useHistoryPrefix.js'
import { useParams } from 'react-router-dom'
import EmailSentView from '../components/custom/EmailTemplate/EmailSentView.js'

export default function EmailBlastWrapper() {
  const { setListingDescription: setListingDescriptionCtx } = useListingDescriptionContext()
  const { setStageSelection: setStageSelectionDataCtx } = useStageSelectionContext()

  const { agentId } = useAuthContext()

  const { setListingAddressCtx } = useRequestContext()

  const { getConfirmation } = useConfirmationDialog()
  const history = useHistoryPrefix()
  const { requestId, action } = useParams()

  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState({})
  const [inputData, setInputData] = useState({})
  const packageType = 'digital'
  useEffect(() => {
    const handleDeepLink = async () => {
      const response = await getRequestById(requestId, agentId)
      const _response = await getEmailTemplate(agentId)
      if (response.success && _response.success && response.data !== '') {
        const template = response.data.emailBlastData.templates[0]
        const { name, templateId } = template
        const { emailData, templateIdentifier } = template.options

        const packageImage = _response.data.filter(
          (template) => template.id == templateId,
        )[0]

        if (!packageImage) {
          await getConfirmation({
            message: 'Package not Found!',
            firstButtonText: 'Ok',
            onConfirm: () => {
              history.push('/')
            },
          }).then(() => {
            history.push('/')
          })
        }
        setImage(packageImage)
        const HTMLTemplate = packageImage.html
        const parsedEmailData = JSON.parse(emailData)

        setListingAddressCtx({ ...parsedEmailData.listing })

        setListingDescriptionCtx({
          headline: parsedEmailData.heading,
          description: parsedEmailData.description,
          estimatedPrice: parsedEmailData.formattedPrice,
          bedCount: parsedEmailData.bedrooms,
          bathCount: parsedEmailData.bathroom,
          images: parsedEmailData.images.map((id) => {
              return{ image: { id, photoUrl: '' } } 
          }),
          imagesIds: parsedEmailData.images,
        })
        
        setStageSelectionDataCtx({
            stageName: parsedEmailData.stage,
            value: parsedEmailData.stageEnum,
        })

        const sendDate = parsedEmailData.emailSendDate
          ? new Date(parsedEmailData.emailSendDate)
          : ''
        const updatedInputData = {
          templateName: name,
          identifier: templateIdentifier,
          itemId: templateId,
          mailingLists: [],
          emailData: {
            ...parsedEmailData,
            emailSendDate: sendDate,
            showHero: true,
            showBody: false,
            disableLinks: true,
          },
          emailHTML: HTMLTemplate,
          options: {
            dynamicFormData: {
              campaignStartDate: sendDate,
            },
          },
          dynamicFormData: {
            dynamicFormData: sendDate,
          },
        }
        parsedEmailData.lists.map((item, index) => {
          if (item?.csvEmailListUrl) {
            updatedInputData.mailingLists.push({
              name: item.csvName,
              url: item.csvEmailListUrl,
              value: 'CSV',
              type: 'file_selector',
              format: '.csv',
              base64: '',
              id: index + 1,
            })
          } else if (item?.mlsMailingLists) {
            updatedInputData.mailingLists.push({
              options: item.mlsMailingLists,
              value: 'MLSList',
              type: 'MLS_list',
              id: index + 1,
            })
          } else {
            updatedInputData.mailingLists.push({
              name: item.defaultList,
              value: item.defaultList,
              type: 'default',
              id: index + 1,
            })
          }
        })

        setInputData(updatedInputData)
        setLoading(false)
      } else {
        await getConfirmation({
          message: 'Request not Found!',
          firstButtonText: 'Ok',
          onConfirm: () => {
            history.push('/')
          },
        }).then(() => {
          history.push('/')
        })
      }
    }
    try {
      handleDeepLink()
    } catch (err) {
      console.log(err)
    }
  }, [])

  const ViewComponent = () => {
    switch (action) {
      case 'edit':
          return (
            <EmailRTRContextProvider>
              <EmailBlastDialog
                isEditing={true}
                packageType={packageType}
                inputData={inputData}
                image={image}
                isDeepLink={true}
              />
            </EmailRTRContextProvider>
          )
      case 'confirm':
        return (
          <EmailSentView/>
        )
      default:
          return <></>
  }
  }

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <ViewComponent/>
      )}
    </Layout>
  )
}
