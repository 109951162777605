import create from 'zustand'
import { SignType } from '../types'

export type SignageStore = {
  signs: Record<SignType['signId'], SignType | null>
  clear: () => void
  setSigns: (signId: SignType['signId'], sign: SignType | null) => void
}

export const useSignageStore = create<SignageStore>((set) => ({
  signs: {},
  clear: () => set({ signs: {} }),
  setSigns: (itemId, sign) => {
    set((state) => {
      return { signs: { ...state.signs, [itemId]: sign } }
    })
  },
}))
