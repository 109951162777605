import React, { createContext, useContext, useEffect, useState } from 'react'
import { AgentInfo } from '../modules/marketing/utils/formatTemplateData'
import { proxyCDN } from '../services/uploadCareServices'
import { useAuthContext } from './AuthContext'

export type AgentRTRContextType = {
  agentInfo?: AgentInfoData
}
export const AgentRTRContext = createContext<AgentRTRContextType>({})

export const useAgentRTRContext = () =>
  useContext<AgentRTRContextType>(AgentRTRContext)

export interface AgentInfoData {
  agentPhoto?: string
  agentName?: string
  agentPhone?: string
  agentDre?: string
  agentEmail?: string
  agentWebsite?: string
  agentGroupDetails?: string
}

export const AgentRTRContextProvider: React.FC = ({ children }) => {
  const { agentData } = useAuthContext() as { agentData: AgentInfo }

  const [agentInfo, setAgentInfo] = useState<AgentInfoData>({
    agentDre: 'Unknown DRE',
    agentEmail: 'Unknown Email',
    agentGroupDetails: '',
    agentName: 'Unknown Agent Name',
    agentPhone: 'Unknown Agent Phone',
    agentWebsite: 'Unknown Agent Website',
  })

  useEffect(() => {
    if (agentData) {
      const {
        phone: rawPhone,
        dre,
        firstName,
        lastName,
        email: rawEmail,
        profile,
      } = agentData

      const email = rawEmail ?? profile?.email
      const phone = rawPhone ?? profile?.phone ?? ''
      const name = profile?.name ?? `${firstName} ${lastName}`

      if (!agentData?.profile?.photo || !dre || !name || !phone || !email)
        return

      setAgentInfo({
        agentPhoto: proxyCDN(profile?.photo),
        agentName: name,
        agentEmail: email ?? profile?.email,
        agentPhone: phone,
        agentDre: dre,
        agentWebsite: 'avenue8.com',

        agentGroupDetails:
          `${phone} | DRE ${dre} <br/>${email}<br/>avenue8.com`.trim(),
      })
    }
  }, [agentData])

  return (
    <AgentRTRContext.Provider
      value={{
        agentInfo,
      }}
    >
      {children}
    </AgentRTRContext.Provider>
  )
}
