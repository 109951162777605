import axios from 'axios'

const contentGridBaseURL =
  process.env.REACT_APP_CONTENT_GRID_BACKEND_URL ||
  'https://content-grid.staging.svc.avenue8.com'

const appBaseURL =
  process.env.REACT_APP_BACKEND_URL ||
  'https://backend-app.staging.svc.avenue8.com'

const signageBaseURL =
  process.env.REACT_APP_SIGNAGE_BACKEND_URL ||
  'https://signage-backend.staging.svc.avenue8.com'

const marketingBASEURL =
  process.env.REACT_APP_MARKETING_BACKEND_URL ||
  'https://visual-marketing-backend.staging.svc.avenue8.com'

const propertySearchBASEURL =
  process.env.REACT_APP_PROPERTY_SEARCH_URL ||
  'https://property-search.staging.svc.avenue8.com'

const authServiceBaseURL =
  process.env.REACT_APP_AUTH_SERVICE_URL ||
  'https://auth.staging.svc.avenue8.com'

const uploadCareURL =
  process.env.REACT_APP_UPLOAD_CARE_URL ||
  'https://4cd4900ec8e2e4b1c0ca.ucr.io/'

const visualMarketingS3URL =
  process.env.REACT_APP_VISUAL_MARKETING_S3_URL ||
  'https://avenue8-visual-marketing-production.s3.us-east-2.amazonaws.com'

const rtrBackendURL =
  process.env.REACT_APP_RTR_BACKEND_URL ||
  'https://rtr-backend.staging.svc.avenue8.com'

const contentGridApi = axios.create({
  baseURL: contentGridBaseURL,
})

const appApi = axios.create({
  baseURL: appBaseURL,
})

const rtrBackendApi = axios.create({
  baseURL: rtrBackendURL,
})

const signageApi = axios.create({
  baseURL: signageBaseURL,
})

const marketingApi = axios.create({
  baseURL: marketingBASEURL,
})

const propertySearchApi = axios.create({
  baseURL: propertySearchBASEURL,
})

const authServiceApi = axios.create({
  baseURL: authServiceBaseURL,
})

const setAuthToken = (token) => {
  if (token) {
    appApi.defaults.headers.common.Authorization = `Bearer ${token}`
    contentGridApi.defaults.headers.common.Authorization = `Bearer ${token}`
    signageApi.defaults.headers.common.Authorization = `Bearer ${token}`
    marketingApi.defaults.headers.common.Authorization = `Bearer ${token}`
    propertySearchApi.defaults.headers.common.Authorization = `Bearer ${token}`
    authServiceApi.defaults.headers.common.Authorization = `Bearer ${token}`
    rtrBackendApi.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

const getCancelToken = () => axios.CancelToken.source()

export {
  contentGridApi,
  appApi,
  signageApi,
  marketingApi,
  propertySearchApi,
  rtrBackendApi,
  authServiceApi,
  uploadCareURL,
  visualMarketingS3URL,
  setAuthToken,
  getCancelToken,
}
