import React from 'react'
import RequestContextProvider from './context/RequestContext'
import AuthContextProvider from './context/AuthContext'
import { ModalDialogProvider } from './components/custom/ModalDialog'

const CombinedContexts = ({ children }) => (
  <AuthContextProvider>
    <ModalDialogProvider>
      <RequestContextProvider>{children}</RequestContextProvider>
    </ModalDialogProvider>
  </AuthContextProvider>
)

export default CombinedContexts
