import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Text from '../../../../../../../components/common/Text'
import { toMoney } from '../../../../../../../components/custom/DynamicForm/formatters/money.formatter'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { mockEstimatedCostPriceAPICall } from '../mockApi'

const styles = {
  container: {
    marginTop: '24px',
    padding: '16px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.65)',
  },
}
export const EstimatedPrice = () => {
  const { printNeedsSectionData } = usePTRFormContextProvider()
  const [price, setPrice] = useState<number | undefined>(undefined)

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  useEffect(() => {
    const {
      values: { quantity },
    } = printNeedsSectionData

    const { data } = mockEstimatedCostPriceAPICall

    let amount =
      (data.unitPrice - data.discountPerUnitPrice) * parseInt(quantity)

    if (data.discountPerUnitPercentage && data.discountPerUnitPercentage > 0)
      amount *= amount * (1.0 - data.discountPerUnitPercentage)

    setPrice(amount ?? 0)
  }, [printNeedsSectionData])

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <Text type="sectionName">Estimated Cost</Text>
        </Grid>
        <Grid item>
          <Text type="sectionName">{toMoney(price, 2)}</Text>
        </Grid>
      </Grid>
    </div>
  )
}
