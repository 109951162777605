import { format } from 'date-fns'

const inpuTypes = {
  INPUT: 'input',
  DATEPICKER: 'datepicker',
}

const generateid = (label, type, name, id = 0) => {
  return `${label}${type}${name}${id}`
}

// *26/08/2021* TO DO: Break this into 2 functions. One to map the missing ids
// other to format this 
/**
 * Receiving a signage options array and formats into flatten options array
 * with a conditional properity that shows what value and field should be filled.
 * @param {array} signageOptionArray Response from signage options endpoint
 * @returns {array} formated and flattaned array where fieldMeetsCondition can map throught
 */

export const formatArrayOfOptions = (array) => {
  const finalArray = []
  array.forEach((option) => {
    option._uid =
      option._uid ||
      generateid(option.label, option.type, option.name, option.id)
    finalArray.push(option)
    if (option.options) {
      option?.options.forEach((subItem) => {
        subItem._uid = `${option._uid}_${
          subItem._uid ||
          generateid(subItem.label, subItem.type, subItem.name, subItem.id)
        }`
        if (subItem.dynamicOptions) {
          subItem.dynamicOptions.forEach((dynamicOption) => {
            dynamicOption._uid =
              dynamicOption._uid ||
              generateid(
                dynamicOption.label,
                dynamicOption.type,
                dynamicOption.name,
                dynamicOption.id,
              )
            finalArray.push({
              ...dynamicOption,
              conditional: {
                value: subItem.value,
                field: `${subItem._uid}_${dynamicOption._uid}`,
              },
            })
          })
        }
      })
    }
  })
  return finalArray
}

export const fieldMeetsCondition = (values) => (field) => {
  if (field.conditional && field.conditional.field) {
    const segments = field.conditional.field.split('_')
    const fieldId = segments[0]
    return values[fieldId]?.value == field.conditional.value
  }
  return true
}

export const formatStringToFirstLetterAsUpperCase = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatFormDataIntoRequestData = (formData) => {
  try {
    const requestOptions = {}
    Object.values(formData)
      .filter((item) => item.value)
      .forEach((option) => {
        const { name, value } = option
        requestOptions[name] = value
      })
    return requestOptions
  } catch (err) {
    console.log(err)
  }
}

export const formatFormDataIntoPresentationData = (formData) => {
  return Object.values(formData)
    .filter((item) => item.value)
    .map((item) => {
      let formattedValue
      if (item?.options?.length > 1) {
        formattedValue = item.options.find(
          (option) => option.value === item.value,
        )?.label
      }
      if (item.type === inpuTypes.DATEPICKER) {
        formattedValue = format(item.value, 'MM/dd/yyyy')
      }

      return {
        label: item.label || formatStringToFirstLetterAsUpperCase(item.name),
        value: item.value,
        formattedValue: formattedValue,
        type: item.type,
      }
    })
}

export const validateForm = (options, formData) => {
  let validatedFields
  let hasError = false
  options?.filter(fieldMeetsCondition(formData)).map((field) => {
    if (field.required && !formData[field._uid]?.value) {
      hasError = true
      validatedFields = {
        ...validatedFields,
        [field._uid]: { ...field, error: 'This field is required.' },
      }
    } else {
      validatedFields = {
        ...validatedFields,
        [field._uid]: {
          ...field,
          value: formData[field._uid]?.value,
          error: '',
        },
      }
    }
  })
  return { validatedFields, hasError }
}
