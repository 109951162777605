import React from 'react'
import { Field } from 'formik'
import { SelectContainer } from './FormikSelect.style'
import Text from '../common/Text'

export default function FormikSelect({ label = '', ...props }) {
  return (
    <SelectContainer>
      {label && (
        <label htmlFor="custom-select">
          <Text type="muted">{label}</Text>
        </label>
      )}
      <div className="select">
        <Field id="custom-select" {...props} />
      </div>
    </SelectContainer>
  )
}
