import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { isElement } from 'lodash'

export default function CroppableImage({ url, onCompleted, initialCrop }) {
  const [image, setImage] = useState({})
  const [crop, setCrop] = useState(initialCrop)

  const updateCrop = (crop) => {
    if (!crop || !isElement(image)) return

    const calculateRelative = (distanceToCenter, boxWidth, canvasWidth) =>
      (distanceToCenter - boxWidth / 2) / (canvasWidth - boxWidth) || 0

    const x = calculateRelative(
      crop.x + crop.width / 2,
      crop.width,
      image?.width ?? 100,
    )

    const y = calculateRelative(
      crop.y + crop.height / 2,
      crop.height,
      image?.height ?? 100,
    )

    const normalize = (x) => Math.max(0, Math.min(100, Math.round(x * 100)))

    // Width/Height doesn't matter here, aspect ratio is 1/1
    const size = Math.round((1080 / image.width) * crop.width)

    onCompleted({
      x: normalize(x),
      y: normalize(y),
      size,
      refCrop: crop,
      refImage: image,
    })
  }

  return (
    <ReactCrop
      src={url}
      crop={crop}
      imageStyle={{
        maxHeight: 300,
        maxWidth: 300,
        objectFit: 'contain',
      }}
      crossorigin={'anonymous'}
      keepSelection
      onImageLoaded={(image) => {
        setImage(image)
        updateCrop({
          ...crop,
          width: image.width,
          height: image.height,
        })
      }}
      onChange={setCrop}
      onComplete={updateCrop}
    />
  )
}
