import React, { useEffect, useState, useRef } from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import Image from '../common/Image'
import Text from '../common/Text'
import PackageFilledInformation from '../custom/PackageFilledInformation'
import PackageDialog from '../common/PackageDialog'
import { CustomizePrintModal } from '../../modules/marketing/modules/PrintRTR/components/CustomizePrintModal/CustomizePrintModal'
import { scaleCrop1080p } from '../../services/uploadCareServices'
import { addressFormatter, generateDataAttrs } from '../../utils/utils'
import { useInputDataContext } from '../../context/InputDataContext'
import { useRequestContext } from '../../context/RequestContext'
import checkmarkSvg from '../../assets/icons/Checkmark.svg'
import SaveButton from './SaveButton'
import EmailBlastDialog from '../custom/MarketingRequest/EmailBlastDialog'
import {
  isRtrTemplateItemId,
  toRtrTemplateImageItemId,
} from '../../modules/shared/utils/rtrTemplateImageItemId'
import HtmlPreviewer from '../common/HtmlPreviewer'
import EmailRTRContextProvider from '../../context/EmailRTRContext'
import { toMoney } from './DynamicForm/formatters/money.formatter'
import { useStageSelectionContext } from '../../context/StageSelectionContext'
import { useListingDescriptionContext } from '../../context/ListingDescriptionContext'
import { useParams } from 'react-router-dom'

const DEFAULT_FEATURE_TEXT = 'A detail about the feature you want to highlight'

const styles = (theme) => ({
  imageContainer: {
    width: 250,
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewContainer: {
    background: 'transparent',
    overflowY: 'hidden',
    width: 250,
    height: 250,
    boxShadow:
      '2px 4px 10px -1px rgb(0 0 0 / 12%), 0px 1px 1px 0px rgb(0 0 0 / 25%), 0px 1px 3px 0px rgb(0 0 0 / 7%)',
    borderRadius: 1,
  },
  image: {
    width: 250,
  },
  carouselItemContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  disabledContainer: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    width: 250,
    height: 250,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledLabel: {
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    width: '172px',
    textAlign: 'center',
  },
  checkmark: {
    transform: 'scale(1.5)',
    backgroundColor: '#2a29a6',
    height: '36px',
    width: '36px',
    border: '1px solid #2a29a6',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '38px',
    '& > div': {
      background: `url(${checkmarkSvg}) no-repeat`,
      width: '20px',
      height: '16px',
      marginTop: '5px',
    },
  },
})

const useStyles = makeStyles(styles)

const selectDialog = (packageType, modalProps) => {
  const isRTR = modalProps?.image?.isRTR
  const templateImageHasChildren = modalProps?.image?.children?.length

  switch (packageType) {
    case 'digital':
      return <PackageDialog {...modalProps} />
    case 'print':
      if (isRTR && templateImageHasChildren) {
        return <CustomizePrintModal {...modalProps} />
      }
      return <PackageDialog {...modalProps} />
    default:
      return 'Ooops'
  }
}

export default function PackageDetailsItem({
  image,
  index,
  packageType,
  packageData,
  propThatTriggersDynamicForm,
  isSocialMedia = false,
}) {
  const classes = useStyles()
  const { formMarketingDataCtx, formSignageDataCtx } = useInputDataContext()
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()
  const { listingAddressCtx } = useRequestContext()

  const { flow } = useParams()

  const isSignageItem = packageType === 'outdoor'
  const isMarketing = flow === 'marketing'

  const formDataContext = isSignageItem
    ? formSignageDataCtx
    : formMarketingDataCtx
  const category = isSignageItem
    ? packageData.type
    : packageData.templateCategory
  const keyId = isSignageItem ? image.signId : image?.itemId || image?.id

  const categoryInputData = formDataContext[packageType]?.[category] ?? []

  const [inputData, setInputData] = useState(
    categoryInputData.find(
      // negative itemIds indicate RTR generated images
      (data) =>
        isRtrTemplateItemId(data.itemId)
          ? image.isRTR &&
            data.itemId === toRtrTemplateImageItemId(image.itemId)
          : data.itemId === image.itemId || data.signId === keyId,
    ),
  )

  const [divHeight, setDivHeight] = useState(null)

  const [isEditing, setIsEditing] = useState(!!inputData)

  const emailBodyImages = isMarketing
    ? listingDescriptionCtx.images.slice(0, 4)
    : []

  const getCurrentEmailBodyImages = () => {
    if (emailBodyImages) {
      const imageList = { listingImages: [] }
      emailBodyImages.map((item) => {
        imageList['listingImages'].push(item.photoUrl || item.url)
      })

      imageList['headerImage'] = emailBodyImages[0]?.url || undefined

      return imageList
    }

    return {}
  }

  const previewInitialData = {
    disableLinks: true, // Set this parameter as false before sending the final HTML
    showListingImages: false,
    showHero: true, // Display/hide the email Hero Content section
    showBody: false, // Display/hide the email body section
    stage: stageSelectionDataCtx?.stageName,
    address: addressFormatter(listingAddressCtx),
    heading: listingDescriptionCtx?.headline,
    price: toMoney(listingDescriptionCtx?.estimatedPrice),
    bedrooms: listingDescriptionCtx?.bedCount,
    bathrooms: listingDescriptionCtx?.bathCount,
    headerImage: listingDescriptionCtx?.images[0].image.photoUrl,
    description:
      'This is a long description where you can highlight your listing, its history, style, and all of the other unique elements that make it special. This will help buyers see how special it is and they will value your expertise.',
    featuresList: [
      {
        title: 'Feature 1',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 2',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 3',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 4',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
    ],
    listingUrl: 'https://avenue8.com',
    agentPhoto:
      'https://staging.images.avenue8.com/1f0c748b39d14900903df449ccf89a6e.jpg',
    agentName: 'Test Agent',
    agentEmail: 'chloe.adams@avenue8.com',
    agentPhone: '340 112 9985',
    agentDre: '02543480',
    socialMedia: [
      {
        socialMediaPlatform: 'facebook',
        link: 'https://facebook.com',
      },
      {
        socialMediaPlatform: 'twitter',
        link: 'https://twitter.com',
      },
      {
        socialMediaPlatform: 'linkedin',
        link: 'https://linkedin.com',
      },
      {
        socialMediaPlatform: 'instagram',
        link: 'https://instagram.com',
      },
    ],
    year: new Date().getFullYear(),
    displayRTRFooter: true,
  }

  const initialEmailHTML = image?.html
  const initialEmailData = isMarketing
    ? {
        ...previewInitialData,
        ...getCurrentEmailBodyImages(),
      }
    : {}

  useEffect(() => {
    const updatedInputData = (
      formDataContext[packageType]?.[category] ?? []
    ).find((data) => data.itemId == keyId || data.signId == keyId)
    setInputData(updatedInputData)
    setIsEditing(!!updatedInputData)
  }, [formDataContext[packageType]?.[category]])

  const refContainer = useRef(null)

  useEffect(() => {
    if (refContainer?.current && isMarketing) {
      setTimeout(() => setDivHeight(refContainer?.current?.clientHeight), 1500)
    }
  }, [])

  const isEmailRTR = packageData.displayName === 'Email Blasts'
  let showDownloadButton = image?.isRTR
  const title = `${packageData.displayName} #${index + 1}`
  const imageUrl = image?.url || image?.formats?.small?.url

  const metaData = {
    metaPackage: packageType,
    metaCategory: category,
    metaListingStage: packageData.listingStage,
    metaTemplate: title,
    metaIndex: +index + 1,
  }

  const modalProps = {
    image,
    isEditing,
    id: keyId,
    inputData,
    packageData,
    packageType,
    propThatTriggersDynamicForm,
    title,
    metaData,
  }

  let printUrlForDownload = []

  if (inputData && !isEmailRTR) {
    if (inputData?.formData?.metadata) {
      const { front, back, outside, inside } = inputData?.formData?.metadata
      printUrlForDownload.push(front, back, outside, inside)
    }
  }

  printUrlForDownload = printUrlForDownload.filter((c) => c)

  showDownloadButton =
    showDownloadButton &&
    (packageType !== 'print' || (isEditing === true && packageType === 'print'))

  return (
    <Grid
      container
      key={keyId}
      direction="column"
      justify="flex-start"
      className={classes.carouselItemContainer}
      {...generateDataAttrs({
        metaType: 'template',
        ...metaData,
      })}
    >
      <Grid item>
        {!isEmailRTR ? (
          <Paper elevation={3} className={classes.imageContainer}>
            {typeof imageUrl === 'object' ? (
              <Image
                medium
                className={classes.image}
                draggable={false}
                alt={'It shows a sign'}
                rider={image.templateType === 'rider-for-sale'}
                {...generateDataAttrs({ metaName: 'image' })}
                renderAsDiv={true}
                dangerouslySetInnerHTML={{
                  __html: new XMLSerializer().serializeToString(imageUrl),
                }}
              />
            ) : (
              <Image
                medium
                className={classes.image}
                draggable={false}
                src={imageUrl}
                alt={'It shows a sign'}
                rider={image.templateType === 'rider-for-sale'}
                {...generateDataAttrs({ metaName: 'image' })}
              />
            )}
          </Paper>
        ) : !isEditing ? (
          <Paper item className={classes.previewContainer}>
            <Grid
              style={
                divHeight
                  ? {
                      marginTop: (divHeight - 250) / -2 + 10,
                      transform: `scale(${250 / divHeight})`,
                    }
                  : { transform: 'scale(1.0)' }
              }
              ref={refContainer}
            >
              <HtmlPreviewer
                html={initialEmailHTML}
                data={initialEmailData}
                style={{ background: 'white' }}
              />
            </Grid>
          </Paper>
        ) : (
          <Paper item className={classes.previewContainer}>
            <div
              className={classes.disabledContainer}
              style={{
                display: inputData?.alreadySent ? '' : 'none',
              }}
            >
              <span className={classes.checkmark}>
                <div />
              </span>
              <p className={classes.disabledLabel}>
                We’ve started creating your email. You’ll receive a preview in
                your inbox within 1 business day.
              </p>
            </div>
            <Grid
              style={
                divHeight
                  ? {
                      marginTop: (divHeight - 250) / -2 + 10,
                      transform: `scale(${250 / divHeight})`,
                    }
                  : { transform: 'scale(1.0)' }
              }
              ref={refContainer}
            >
              <HtmlPreviewer
                html={inputData.emailHTML}
                data={{
                  ...inputData.emailData,
                  showBody: false,
                  showHero: true,
                  showListingImages: false,
                }}
                style={{ background: 'white' }}
              />
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid item style={{ width: '250px', marginTop: '10px' }}>
        <Grid
          {...generateDataAttrs({ metaName: 'title' })}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: 20 }}>
            <Text type="blue">{image.name || title}</Text>
          </Grid>
        </Grid>
        {isEditing && (
          <Grid style={{ marginTop: isSocialMedia ? 20 : 0 }}>
            <PackageFilledInformation
              packageType={packageType}
              inputData={inputData}
            />
          </Grid>
        )}
        <Grid
          container
          justify="space-around"
          alignItems="center"
          style={{ marginTop: isSocialMedia ? 30 : 0 }}
          {...generateDataAttrs({ metaName: 'actions' })}
        >
          {!isEmailRTR ? (
            selectDialog(packageType, modalProps)
          ) : (
            <EmailRTRContextProvider>
              <EmailBlastDialog
                isEditing={isEditing}
                packageType={packageType}
                inputData={inputData}
                image={image}
              />
            </EmailRTRContextProvider>
          )}
          {showDownloadButton && (
            <SaveButton
              template={image}
              url={printUrlForDownload[0] ?? imageUrl}
              auxiliaryImageUrls={
                printUrlForDownload.length > 1
                  ? printUrlForDownload.slice(1)
                  : image?.carouselImages
                      ?.filter(({ isHidden }) => isHidden !== true)
                      .map(({ url }) => scaleCrop1080p(url))
              }
              size="small"
              shouldBuildBeforeDownload={
                image.isRTR && printUrlForDownload.length === 0
              }
              imageLayers={image.layers}
              metaData={generateDataAttrs(metaData)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
