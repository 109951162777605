import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { Radio } from 'av8-ui'
import { PrintModalStore } from '../../../../../store/PrintModal.store'
import { usePTRFormContextProvider } from '../CustomizePrintModalContext'
import { MailingValues } from '../mockApi'
import { BuildAListSection } from './BuildAListSection'
import { MailingReach } from './MailingReach'
import UploadListSection from './UploadListSection'

type MailingSectionType = {
  options: MailingOptionType[]
  disabled?: boolean
}

type MailingOptionType = {
  id: string
  label: string
  value: string
  disabled?: boolean
}

export default function MailingSection({
  options,
  disabled = false,
}: MailingSectionType) {
  const setError = PrintModalStore(
    ({ setError }) =>
      (value: boolean) =>
        setError('mailing', value),
  )
  const { mailingSectionData, setMailingSectionData } =
    usePTRFormContextProvider()

  React.useEffect(() => {
    if (!disabled) {
      setError(!mailingSectionData?.section.length)
    } else {
      setError(false)
    }
  }, [disabled, mailingSectionData?.section.length])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Box px={2} width='100%'>
        <Grid container>
          {options
            .filter((option: MailingOptionType) => !option.disabled)
            .map((option: MailingOptionType) => {
              return (
                <Grid item xs={6} key={option.id}>
                  <Radio
                    key={option.id}
                    label={option.label}
                    value={option.value}
                    disabled={option.disabled === true}
                    checked={mailingSectionData?.section === option.value}
                    onChange={() =>
                      setMailingSectionData({ section: option.value, data: {} })
                    }
                  />
                </Grid>
              )
            })}
        </Grid>
      </Box>
      {mailingSectionData?.section === MailingValues.SOI && (
        <Box mt={3}>
          <UploadListSection />
        </Box>
      )}

      {mailingSectionData?.section === MailingValues.BuildList && (
        <>
          <Box px={2} width='100%'>
            <BuildAListSection />
          </Box>
          <Box px={2} width='100%'>
            <MailingReach />
          </Box>
        </>
      )}
      {mailingSectionData?.section === MailingValues.UploadList && (
        <Box mt={3}>
          <UploadListSection />
        </Box>
      )}
    </div>
  )
}
