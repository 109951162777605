import React, { useEffect } from 'react'
import { PrintModalStore } from '../../../../store/PrintModal.store'
import { usePTRFormContextProvider } from './CustomizePrintModalContext'
import FormAccordionManager from './FormAccordionManager/FormAccordionManager'
import { mockFormOptionsAPICall } from './mockApi'
import { EstimatedPrice } from './Sections/EstimatedPrice'

type PrintTemplateReviewFormType = {
  reviewFormData: Record<string, any>
}

export default function PrintTemplateReviewForm({ reviewFormData }: PrintTemplateReviewFormType) {
  const clearErrors = PrintModalStore(({ clearErrors }) => clearErrors)
  const { setPrintingAndDesignSectionData, setMailingSectionData, setPrintingNeedsSectionData } =
    usePTRFormContextProvider()

  useEffect(() => {
    // Rehydrates form

    if(reviewFormData && Object.keys(reviewFormData).length > 0) {
      setPrintingAndDesignSectionData(reviewFormData?.printingAndDesignSectionData)
      setMailingSectionData(reviewFormData?.mailingSectionData)
      setPrintingNeedsSectionData(reviewFormData?.printNeedsSectionData)
    }

    return clearErrors
  }, [])

  return (
    <div>
      <FormAccordionManager
        accordions={mockFormOptionsAPICall?.data?.accordions}
      />
      <EstimatedPrice />
    </div>
  )
}
