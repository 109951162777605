import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'
import { useRequestContext } from '../context/RequestContext'
import Layout from '../pages/Layout'

export default function RouteWrapper({
  component: Component,
  isPublic = false,
  confirmLeave = false,
  flowType = null,
  ...rest
}) {
  const { isEmbedded } = useRequestContext()
  const { isAuthed } = useAuthContext()

  if (!isAuthed && !isPublic) {
    if (isEmbedded) {
      return <Redirect to="/unauthorized" />
    } else {
      window.location.href = `${window.location.origin}/login`
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout confirmLeave={confirmLeave} flowType={flowType}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}
