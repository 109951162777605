import React, { createContext, useState, useContext } from 'react'

export const RequestContext = createContext({})

export const useRequestContext = () => useContext(RequestContext)

export default function RequestContextProvider({ children }) {
  const [currentFlow, setCurrentFlow] = useState('')

  const [selectedPropertyCtx, setSelectedPropertyCtx] = useState('')
  const [listingAddressCtx, setListingAddressCtx] = useState({
    firstAddressLine: '',
    secondAddressLine: '',
    city: '',
    state: '',
    zip: '',
  })

  const [isEmbedded, setIsEmbeddedCtx] = useState(false)

  return (
    <RequestContext.Provider
      value={{
        currentFlow,
        setCurrentFlow,
        selectedPropertyCtx,
        setSelectedPropertyCtx,
        listingAddressCtx,
        setListingAddressCtx,
        isEmbedded,
        setIsEmbeddedCtx,
      }}
    >
      {children}
    </RequestContext.Provider>
  )
}
