import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import TodayIcon from '@material-ui/icons/Today'
import DatePicker from 'react-datepicker'
import { Input } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    border: '1px solid var(--blue)',
    borderRadius: 5,
    padding: '3px 10px 3px 10px',
    width: '99%',
    fontFamily: 'StyreneMedium',
    fontSize: 12,
    marginTop: 6,
    color: '#2a29a6',
    '&::placeholder': {
      color: 'blue',
    },

    [theme.breakpoints.up('sm')]: {
      width: '98%',
    },
    [theme.breakpoints.up('md')]: {
      width: '96%',
    },
  },
  icon: {
    color: '#2a29a6',
  },
}))

function CustomInput({ onClick, value, placeholder }) {
  const classes = useStyles()
  return (
    <div className="" onClick={onClick}>
      <Input
        className={classes.textField}
        value={value}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <TodayIcon className={classes.icon} />
          </InputAdornment>
        }
        disableUnderline={true}
      />
    </div>
  )
}

export default function Datepicker({ value, onChange, fieldName, ...props }) {
  return (
    <DatePicker
      selected={value}
      dateFormat="dd/MM/yyyy"
      popperPlacement="top-end"
      onChange={(date) => onChange(date, fieldName)}
      {...props}
      customInput={<CustomInput />}
    />
  )
}
