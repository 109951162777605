import { createPost } from '../services/contentServices'
import { useAuthContext } from '../context/AuthContext'
import { useState } from 'react'
import { useListingDescriptionContext } from '../context/ListingDescriptionContext'
import { uploadTemplateImage } from '../modules/marketing/utils/uploadImage'

type LoadingModalStatusType = {
  loading: boolean
  message: string
  show: boolean
}

export default function useContentLibrary() {
  const { agentId } = useAuthContext() as any
  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()

  const [loadingModalStatus, setLoadingModalStatus] =
    useState<LoadingModalStatusType>({
      loading: false,
      show: false,
      message: '',
    })

  const uploadBlobImages = async (blobs: Blob[]) => {
    setLoadingModalStatus({
      loading: true,
      show: true,
      message: 'Preparing your images...',
    })

    let result: string[] = []

    try {
      result = await Promise.all(
        blobs.map(async (blob) => {
          const fileBlob = new File([blob], 'photo.png', {
            type: 'image/png',
          })

          const response = await uploadTemplateImage(agentId, fileBlob)

          return response.url
        }),
      )

      setLoadingModalStatus({
        loading: false,
        show: false,
        message: 'Images ready...',
      })
    } catch (error) {
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Oops, something went wrong, please try again.',
      })
    }

    return result
  }

  const handleSaveToContentLibrary = async (images: string[]) => {
    setLoadingModalStatus({
      loading: true,
      show: true,
      message: 'Saving to your Content Library...',
    })
    try {
      await createPost(
        agentId,
        listingDescriptionCtx.headline,
        listingDescriptionCtx.description,
        images,
      )
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Content saved to your Library.',
      })
    } catch (err) {
      setLoadingModalStatus({
        loading: false,
        show: true,
        message: 'Oops, something went wrong, please try again.',
      })
    }
  }

  return {
    uploadBlobImages,
    handleSaveToContentLibrary,
    setLoadingModalStatus,
    loadingModalStatus,
  }
}
