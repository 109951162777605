import React from 'react'
import Carousel from 'react-multi-carousel'
import { makeStyles } from '@material-ui/core'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomDot = ({ onClick, ...rest }) => {
  const {
    // onMove,
    // index,
    active,
    carouselState: { totalItems },
  } = rest

  const marginX = totalItems < 12 ? '6px' : totalItems < 24 ? '3px' : '1px'

  const styles = {
    customDot: {
      margin: `12px ${marginX}`,
      height: 6,
      width: 6,
      borderRadius: '50%',
      border: '1px solid var(--blue)',
      background: 'var(--blue)',
      opacity: active ? '1' : '0.5',
    },
  }

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <button
      type="button"
      className={classes.customDot}
      onClick={onClick}
    ></button>
  )
}
export const CustomArrow = ({
  onClick,
  side = 'right',
  arrowColor = 'blue',
  arrowAlignment = 'default',
}) => {
  const isRightArrow = side === 'right'
  const arrowDistance = arrowAlignment === 'default' ? 0 : 20
  const positionBySide = isRightArrow
    ? { right: arrowDistance }
    : { left: arrowDistance }

  const arrowColorStyling = {
    blue: {
      background: 'rgba(42, 41, 166, 0.45)',
      border: 0,
      '&:hover': {
        background: 'rgba(42, 41, 166, 1)',
      },
    },
    white: {
      background: 'rgba(255, 255, 255, 0.35)',
      border: '1px solid white',
      '&:hover': {
        background: 'rgba(255,255,255, 0.65)',
      },
    },
  }

  const arrowPositioning = {
    default: {
      ...positionBySide,
    },
    centered: {
      ...positionBySide,
      top: '40%',
    },
  }

  const styles = {
    customArrow: {
      position: 'absolute',
      ...arrowColorStyling[arrowColor],
      ...arrowPositioning[arrowAlignment],
      outline: 0,
      transition: 'all .5s',
      fontSize: 15,
      borderRadius: 35,
      zIndex: 1000,
      color: 'white',
      minWidth: 43,
      minHeight: 43,
      cursor: 'pointer',
    },
  }

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <button type="button" className={classes.customArrow} onClick={onClick}>
      {isRightArrow ? <ChevronRight /> : <ChevronLeft />}
    </button>
  )
}

export default function CarouselWrapper({
  children,
  arrowColor = 'blue',
  arrowAlignment = 'default',
  ...props
}) {
  const defaultSettings = {
    swipeable: true,
    customDot: <CustomDot />,
    renderDotsOutside: true,
    arrows: true,
    responsive: responsive,
    showDots: true,
    removeArrowOnDeviceType: 'mobile',
    customRightArrow: (
      <CustomArrow
        side="right"
        arrowColor={arrowColor}
        arrowAlignment={arrowAlignment}
      />
    ),
    customLeftArrow: (
      <CustomArrow
        side="left"
        arrowColor={arrowColor}
        arrowAlignment={arrowAlignment}
      />
    ),
  }

  return (
    <Carousel {...defaultSettings} {...props}>
      {children}
    </Carousel>
  )
}
