import React, { useState, useEffect } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import MLSLoader from '../../../../components/custom/MLSLoader'
import Modal from '../../../../components/common/Modal'
import Text from '../../../../components/common/Text'
import Tooltip from '../../../../components/common/Tooltip'
import { useRequestContext } from '../../../../context/RequestContext'
import { downloadCSVFiles } from '../../../../services/appServices'
import { triggerDownload } from '../../../../utils/utils'
import FileUploadInput from '../../../../components/common/FileUploadInput'
import { PrintCSVTemplateS3URL } from '../../../../utils/constants'
import { blobToBase64 } from '../../../shared/utils/svgRtrConverters'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing(3),
    width: 150,
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 1500,
    maxHeight: '350px',
    overflowY: 'scroll',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFF',
    borderRadius: 5,
    alignItems: 'center',
    padding: '0 8px',
    height: 70,
    width: '100%',
  },
}))

type LoadingModalStatusType = {
  loading: boolean
  show: boolean
  message: string | React.ReactNode
}

export type UploadCSVListType = {
  uploadLabel?: string
  templateLabel?: string
  templateDownloadURL?: string
  formats?: string
  file?: File
  onChange?: (file: File) => void
  validateCSVFileRule?: (file: File) => Promise<boolean>
}

export default function UploadCSVList({
  uploadLabel = 'Upload Custom List (CSV):',
  templateLabel = 'Download CSV Template',
  templateDownloadURL = PrintCSVTemplateS3URL,
  formats = '.csv, .numbers',
  file,
  onChange,
  validateCSVFileRule,
}: UploadCSVListType) {
  const classes = useStyles()
  const { isEmbedded } = useRequestContext() as { isEmbedded: boolean }
  const [selectedFile, setSelectedFile] = useState<File>(
    file || new File([''], ''),
  )
  const [desktopDownloadStatus, setDesktopDownloadStatus] = useState(true)
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [loadingModalStatus, setLoadingModalStatus] =
    useState<LoadingModalStatusType>({
      loading: false,
      message: '',
      show: false,
    })
  const [error, setError] = useState<string | boolean>(false)

  useEffect(() => {
    setLoadingModalStatus({
      loading: isDownloading,
      message: isDownloading ? (
        'Your CSV Template is downloading...'
      ) : (
        <>
          <Text>Download Completed</Text>
          <Text style={{ marginTop: 10 }} type='regular' color='#777'>
            Your CSV template is available in "Recents" or your local folder.
          </Text>
        </>
      ),
      show: isDownloading || hasDownloaded,
    })
  }, [isDownloading, hasDownloaded])

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      setIsDownloading(true)
      const csvResponse = await fetch(fileUrl)

      if (csvResponse.ok) {
        if (isEmbedded) {
          const fileBlob: Blob = await csvResponse.blob()
          const fileBase64: string = await blobToBase64(fileBlob)
          downloadCSVFiles([fileBase64])
        } else {
          const fileString: string = await csvResponse.text()
          triggerDownload(fileString, fileName, {
            type: 'text/csv',
          })
        }

        setIsDownloading(false)
        setHasDownloaded(true)
        setDesktopDownloadStatus(true)
      } else {
        setIsDownloading(false)
        setHasDownloaded(false)
        setDesktopDownloadStatus(false)
      }
    } catch (err) {
      console.error(err)
      setIsDownloading(false)
      setHasDownloaded(false)
      setDesktopDownloadStatus(false)
    }
  }

  const changeHandler = async (event: any) => {
    const file = event.target.files[0]

    if (validateCSVFileRule) {
      const isCSVValid = await validateCSVFileRule(file)
      if (!isCSVValid) {
        setError('Invalid Custom List, please try again using our template.')
        return
      }
      setError(false)
    }

    if (file) {
      setSelectedFile(file)
      !!onChange && onChange(file)
    }
  }

  const isEmpty = (file: File) => {
    return !file.name
  }

  return (
    <Grid>
      <Modal open={loadingModalStatus.show}>
        <MLSLoader
          loading={loadingModalStatus.loading}
          text={loadingModalStatus.message}
          setModalStatus={setLoadingModalStatus}
        />
      </Modal>
      <Grid container alignItems='center'>
        <Grid style={{ marginBottom: 15 }}>
          <Text type='muted'>{uploadLabel}</Text>
        </Grid>
        <Grid
          className={classes.itemContainer}
          style={{
            marginBottom: 20,
          }}
        >
          <Text type='muted'>
            {isEmpty(selectedFile)
              ? 'No file selected'
              : selectedFile.name.length < 20
              ? selectedFile.name
              : selectedFile.name.slice(0, 16) + '...'}
          </Text>
          <Grid>
            <FileUploadInput
              label={isEmpty(selectedFile) ? 'Add' : 'Change'}
              handleOnChange={changeHandler}
              noUploadAreaContainer
              hasUploadAreaContainer={false}
              acceptTypes={formats}
            />
          </Grid>
        </Grid>
        {error && (
          <Box mb={3}>
            <Text type='error'>{error}</Text>
          </Box>
        )}
        <Grid
          container
          style={{ marginBottom: 40 }}
          onClick={() => {
            handleDownload(templateDownloadURL, 'Contacts Import Template.csv')
          }}
        >
          <Grid>
            <Text type='blue'>
              <DownloadIcon fontSize='small' />
            </Text>
          </Grid>
          <Grid style={{ margin: '3px 0px 0px 5px' }}>
            <Text type='blue'>{templateLabel}</Text>
          </Grid>
        </Grid>
      </Grid>
      {!desktopDownloadStatus ? (
        <Tooltip text='Error downloading the post' type='error' status='open' />
      ) : null}
    </Grid>
  )
}
