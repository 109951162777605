import React, { useState, useEffect } from 'react'
import { getAgentData } from '../../../services/marketingServices'
import { useRequestContext } from '../../../context/RequestContext'
import { useEmailRTRContext } from '../../../context/EmailRTRContext'
import Loading from '../../common/Loading'
import Text from '../../common/Text'
import Loader from '../../custom/Loader'
import Modal from '../../common/Modal'
import HeroContent from '../EmailTemplate/HeroContent'
import EmailBody from '../EmailTemplate/EmailBody'
import EmailReview from '../EmailTemplate/EmailReview'
import { useListingDescriptionContext } from '../../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../../context/StageSelectionContext'
import { addressFormatter } from '../../../utils/utils'

const DEFAULT_FEATURE_TEXT = 'A detail about the feature you want to highlight'

// Dialog styles
const styles = (theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '45% 44%',
    gridGap: 18,
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '46% 46%',
    },
  },
  formContainer: {
    background: '#F3F3F7',
    padding: '3%',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '404px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '404px',
    },
  },
  previewContainer: {
    background: 'white',
    overflowY: 'scroll',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%',
      margin: '-5% auto 0 auto',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '404px',
      height: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '404px',
    },
  },
  btnPreview: {
    '& > span > span': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
      background: '#ffffff',
    },
    width: '64px',
    height: '64px',
    borderRadius: '0px',
    background: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: 0,
    border: '0px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('xs')]: {
      margin: '0px 0px 0px 72%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '5px 0px 0px 73%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '5px 0px 0px 80%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '5px 0px 0px 80%',
    },
  },
  btnEdit: {
    '& > span > span': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
      background: '#ffffff',
    },
    width: '64px',
    height: '64px',
    borderRadius: '0px',
    background: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: 0,
    border: '0px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('xs')]: {
      margin: '6.5% 0px 0px 71.5%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '6.5% 0px 0px 73%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '7.5% 0 0 75%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '7.5% 0 0 75%',
    },
  },
})

// Function to load the agent data
async function loadAgentData() {
  try {
    // Call an endpoint to retrieve the agent data
    return await getAgentData()
  } catch (err) {
    console.error(err)
    return {}
  }
}

export default function CustomizeEmailTemplateBody({
  step,
  handleClose,
  imgMaxQuantity,
  isEditing,
  emailHTML,
  setButtonIsEnabled,
  formRef,
  errorMsg,
  setErrorMsg,
  isError,
  setError,
}) {
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()
  const { listingDescription: listingDescriptionCtx } = useListingDescriptionContext()

  // Form handlers
  const { listingAddressCtx } = useRequestContext()
  const {
    setEmailRTRData,
    setEmailBodyData,
    setHeroContentData,
    emailRTRData,
    EmailBodyData,
    HeroContentData,
  } = useEmailRTRContext()

  // Flag to determine whether or not the email preview should be displayed
  const [isEmailPreview, setEmailPreviewFlag] = useState(true)

  // Loading flag
  const [loading, setLoading] = useState(true)

  const emailDataInitialValues = {
    disableLinks: true, // Set this parameter as false before sending the final HTML
    showListingImages: imgMaxQuantity > 1 ? true : false,
    showHero: true, // Display/hide the email Hero Content section
    showBody: false, // Display/hide the email body section
    stage: stageSelectionDataCtx.stageName,
    subject: '',
    address: addressFormatter(listingAddressCtx),
    heading: listingDescriptionCtx.headline,
    price: listingDescriptionCtx.estimatedPrice,
    bedrooms: listingDescriptionCtx.bedCount,
    bathrooms: listingDescriptionCtx.bathCount,
    headerImage:
      listingDescriptionCtx.images[0].image.photoUrl ||
      listingDescriptionCtx.images[0].url,
    description:
      listingDescriptionCtx?.description ||
      'This is a long description where you can highlight your listing, its history, style, and all of the other unique elements that make it special. This will help buyers see how special it is and they will value your expertise.',
    featuresList: [
      {
        title: 'Feature 1',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 2',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 3',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
      {
        title: 'Feature 4',
        features: [
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
          { item: DEFAULT_FEATURE_TEXT },
        ],
      },
    ],
    listingUrl: 'https://avenue8.com',
    agentPhoto:
      'https://staging.images.avenue8.com/1f0c748b39d14900903df449ccf89a6e.jpg',
    agentName: 'Test Agent',
    agentEmail: 'chloe.adams@avenue8.com',
    agentPhone: '340 112 9985',
    agentDre: '02543480',
    socialMedia: [
      {
        socialMediaPlatform: 'facebook',
        link: 'https://facebook.com',
      },
      {
        socialMediaPlatform: 'twitter',
        link: 'https://twitter.com',
      },
      {
        socialMediaPlatform: 'linkedin',
        link: 'https://linkedin.com',
      },
      {
        socialMediaPlatform: 'instagram',
        link: 'https://instagram.com',
      },
    ],
    year: new Date().getFullYear(),
  }

  // Email body initial images
  const emailBodyImages = listingDescriptionCtx.images.slice(0, imgMaxQuantity)

  const getCurrentEmailBodyImages = () => {
    if (emailBodyImages) {
      const imageList = { listingImages: [] }

      emailBodyImages.map((item) => {
        if (item.image) {
          imageList['listingImages'].push(item.image.photoUrl)
        } else {
          imageList['listingImages'].push(item.photoUrl)
        }
      })

      return imageList
    }

    return {}
  }

  // Try to load the email HTML and its initial data
  useEffect(() => {
    const loadAgent = async () => {
      setButtonIsEnabled(false)
      // Load the agentData
      const agentData = await loadAgentData()
      // Set the current email body images
      setEmailRTRData((prevState) =>
        isEditing
          ? {
              ...prevState,
              emailData: {
                ...prevState?.emailData,
                showHero: true,
                showBody: false,
              },
            }
          : {
              ...prevState,
              emailData: {
                ...prevState?.emailData,
                ...emailDataInitialValues,
                ...getCurrentEmailBodyImages(),
              },
              mailingLists: [],
            },
      )

      //Returns sections
      const fillInitialValues = isEditing
        ? emailRTRData.emailData
        : emailDataInitialValues
      const heroContentDataInitialValues = {
        address: fillInitialValues.address,
        bathrooms: fillInitialValues.bathrooms,
        bedrooms: fillInitialValues.bedrooms,
        headerImage: fillInitialValues.headerImage,
        heading: fillInitialValues.heading,
        price: fillInitialValues.price,
        stage: fillInitialValues.stage,
        agentLogo: fillInitialValues?.agentLogo,
      }

      Object.keys(heroContentDataInitialValues).map((key) => {
        if (heroContentDataInitialValues[key] === undefined) {
          delete heroContentDataInitialValues[key]
        }
      })

      const emailBodyDataInitialValues = {
        propertyUrl: fillInitialValues.propertyUrl,
        description:
          fillInitialValues?.description ||
          'This is a long description where you can highlight your listing, its history, style, and all of the other unique elements that make it special. This will help buyers see how special it is and they will value your expertise.',
        emailBodyCopy: fillInitialValues.emailBodyCopy,
        emailBodyImages: fillInitialValues.emailBodyImages,
        featuresList: fillInitialValues.featuresList,
        listingImages: fillInitialValues.listingImages,
        agentUrl: fillInitialValues?.agentUrl,
      }

      Object.keys(emailBodyDataInitialValues).map((key) => {
        if (emailBodyDataInitialValues[key] === undefined) {
          delete emailBodyDataInitialValues[key]
        }
      })

      setEmailBodyData(emailBodyDataInitialValues)
      setHeroContentData(heroContentDataInitialValues)

      if (agentData.data) {
        // Fill the template with agent data
        const agentDataObj = {
          agentName:
            agentData.data?.profile?.name ||
            agentData.data.firstName + ' ' + agentData.data.lastName,
          agentPhone: agentData.data?.profile?.phone,
          agentEmail: agentData.data?.profile?.email,
          agentDre: agentData.data.dre,
          agentPhoto: agentData.data?.profile?.photo,
          socialMedia: agentData.data?.profile?.socialMedia,
        }

        // Check if all the agent data is filled
        const containsError = Object.values(agentDataObj).some(
          (item) => item == null || item == undefined,
        )

        // Set the error message
        setErrorMsg({
          title: 'Information missing',
          message:
            "We're missing your agent details to complete this request. Please contact your Agent Experience Manager for more details.",
          btnText: 'Ok',
          close: true,
        })

        // Determine whether or not show the error alert
        setError(containsError)

        if (!containsError) {
          // Update the email preview
          setEmailRTRData((prevState) => ({
            ...prevState,
            emailData: {
              ...prevState?.emailData,
              ...agentDataObj,
            },
          }))

          setEmailBodyData((prevState) => ({
            ...prevState,
            agentName: agentDataObj.agentName,
            agentPhone: agentDataObj.agentPhone,
            agentEmail: agentDataObj.agentEmail,
            agentDre: agentDataObj.agentDre,
            agentPhoto: agentDataObj.agentPhoto,
          }))
        }
      }

      // Close the loading
      setLoading(false)
      setButtonIsEnabled(true)
    }
    loadAgent()
  }, [])

  // Update the email data and sections visibility
  useEffect(() => {
    let updatedShowContent = {}

    // Show the preview as default
    setEmailPreviewFlag(true)

    if (step === 'Headline') {
      updatedShowContent = { showHero: true, showBody: false }
    } else if (step === 'Details') {
      updatedShowContent = {
        showHero: false,
        showBody: true,
        displayRTRFooter: true,
      }
    } else {
      updatedShowContent = {
        showHero: true,
        showBody: true,
        displayRTRFooter: true,
      }
    }
    setEmailRTRData((prevState) => ({
      ...prevState,
      emailData: {
        ...prevState?.emailData,
        ...updatedShowContent,
      },
    }))
  }, [step])

  useEffect(() => {
    setEmailRTRData((prevState) => ({
      ...prevState,
      emailData: {
        ...prevState?.emailData,
        ...HeroContentData,
        ...EmailBodyData,
      },
    }))
  }, [HeroContentData, EmailBodyData])

  const handleAlertClick = () => {
    // Close the error dialog
    setError(false)

    if (errorMsg.close) {
      // Close the email blast modal
      handleClose()
    }
  }

  return (
    <div>
      {loading && <Loading heightVh="20vh" />}
      <Modal open={isError}>
        <Loader
          text={errorMsg.message}
          loading={false}
          dataAttrs={{ metaType: 'modal' }}
          onClose={handleAlertClick}
          closeTitle={errorMsg.btnText}
          setLoadingStatus={() => {}}
        />
      </Modal>
      {!loading && (
        <div>
          {step !== 'Review' && <Text type="sectionName">{step || ''}</Text>}
          {step === 'Review' && (
            <Text type="muted" style={{ marginTop: -15, marginBottom: 40 }}>
              Please select how you'd like to share your email blast
            </Text>
          )}
          <div style={{ display: step === 'Headline' ? 'block' : 'none' }}>
            <HeroContent
              step={step}
              emailHTML={emailHTML}
              isEmailPreview={isEmailPreview}
              setEmailPreviewFlag={(isEmailPreview) =>
                setEmailPreviewFlag(isEmailPreview)
              }
              isEditing={isEditing}
              styles={styles}
              setButtonIsEnabled={setButtonIsEnabled}
              loading={loading}
            />
          </div>
          <div style={{ display: step === 'Details' ? 'block' : 'none' }}>
            <EmailBody
              step={step}
              emailHTML={emailHTML}
              imgMaxQuantity={imgMaxQuantity - 1}
              isEmailPreview={isEmailPreview}
              setEmailPreviewFlag={(isEmailPreview) =>
                setEmailPreviewFlag(isEmailPreview)
              }
              styles={styles}
              isEditing={isEditing}
              setButtonIsEnabled={setButtonIsEnabled}
            />
          </div>
          <div style={{ display: step === 'Review' ? 'block' : 'none' }}>
            <EmailReview
              step={step}
              emailHTML={emailHTML}
              setEmailPreviewFlag={(isEmailPreview) =>
                setEmailPreviewFlag(isEmailPreview)
              }
              propertyAddress={listingAddressCtx}
              styles={styles}
              formRef={formRef}
              setButtonIsEnabled={setButtonIsEnabled}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  )
}
