import React, { useEffect } from 'react'
import { useAuthContext } from '../../context/AuthContext'

export const HeapIntegration = () => {
  const { agentId } = useAuthContext()
  useEffect(() => {
    if (window && window.heap) {
      if (agentId && agentId.toString().length) {
        window.heap.identify(agentId.toString())
      }
    }
  }, [agentId])
  return <></>
}
