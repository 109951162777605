import React, { useCallback } from 'react'
import update from 'immutability-helper'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../../common/Text'
import ImageSelectorItem from './ImageSelectorItem'
import DnDProvider from '../DnDProvider'
import DndItem from '../DragNDrop/DnDItem'

const styles = {
  container: {
    maxWidth: '90%', // for now
    padding: '15px',
    background: '#F3F3F7',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftItemContainer: {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
  },
}
const useStyles = makeStyles(styles)

export default function ImageSelector({
  isLogoSelector = false,
  imgMaxQuantity,
  images,
  setImages,
  gallery,
}) {
  const classes = useStyles()

  const moveAndUpdateImages = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = images[dragIndex]
      setImages(
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [images],
  )
  const imageItems = []
  if (imgMaxQuantity === 1) {
    imageItems.push(
      <ImageSelectorItem
        isLogoSelector={isLogoSelector}
        image={images[0] || null}
        count={1}
        imgMaxQuantity={imgMaxQuantity}
        setImagesArray={setImages}
        imagesArray={images}
        gallery={gallery}
      />,
    )
  } else {
    for (let index = 0; index < imgMaxQuantity; index++) {
      imageItems.push(
        <DndItem
          key={images[index]?.id || `image ${index}`}
          index={index}
          id={images[index]?.id || index}
          moveItems={moveAndUpdateImages}
        >
          <ImageSelectorItem
            isLogoSelector={isLogoSelector}
            image={images[index] || null}
            count={index + 1}
            imgMaxQuantity={imgMaxQuantity}
            setImagesArray={setImages}
            imagesArray={images}
            gallery={gallery}
          />
        </DndItem>,
      )
    }
  }

  return (
    <DnDProvider>
      <Grid className={classes.container}>
        <Text type="muted">
          {isLogoSelector
            ? 'Logo'
            : imgMaxQuantity === 1
            ? 'Main Image*'
            : `Property images (${images?.length} / ${imgMaxQuantity})`}
        </Text>
        {imageItems}
      </Grid>
    </DnDProvider>
  )
}