import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
// import { FacebookShareButton } from 'react-share'
import { Grid, makeStyles } from '@material-ui/core'
import { GetApp, Instagram, Facebook, Delete } from '@material-ui/icons'
import Button from '../common/Button'
import Text from '../common/Text'
import Tooltip from '../common/Tooltip'
import {
  handleFacebookShareIOS,
  handleInstagramShareIOS,
  sendDownloadEvent,
} from '../../services/appServices'
import {
  downloadPosts,
  increaseDownloadCounter,
  deletePost,
} from '../../services/contentServices'
import { triggerDownload } from '../../utils/utils'
import { useConfirmationDialog } from './ModalDialog'
import { useContentGridContext } from '../../context/ContentGridContext'
import { useRequestContext } from '../../context/RequestContext'
import { useAuthContext } from '../../context/AuthContext'

const duration = 400

const defaultStyle = {
  transition: `all ${duration}ms linear`,
  opacity: 1,
  position: 'fixed',
  bottom: 0,
  left: 0,
  height: 102,
  zIndex: 6,
  width: '100%',
  transform: 'translateY(100%)',
}

const transitionStyles = {
  entering: { transform: 'translateY(0)', display: 'flex' },
  entered: { transform: 'translateY(0)', display: 'flex' },
  exiting: { transform: 'translateY(100%)' },
  exited: { transform: 'translateY(100%)' },
}
function ContentGridFooter({ onDeletePost, gridData }) {
  const { isEmbedded } = useRequestContext()
  const { agentId } = useAuthContext()
  const {
    isSelecting,
    setIsSelecting,
    isSharing,
    setIsSharing,
    selectedImages,
    setIsDownloading,
    setHasDownloaded,
    setSelectedImages,
    setIsRemoving,
  } = useContentGridContext()
  const { getConfirmation } = useConfirmationDialog()
  const [mediaArray, setMediaArray] = useState([])
  const [desktopDownloadStatus, setDesktopDownloadStatus] = useState(true)

  const styles = () => ({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      height: isSelecting ? '105px' : '90px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    textContainer: {
      margin: '15px 0px 10px 0',
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: isSharing ? '25px 0px' : '',
    },
    disabledButton: {
      '&:disabled': {
        background: 'rgba(42, 41, 166, 1)',
        color: 'rgba(255, 255, 255, 1) !important',
        opacity: 0.65,
      },
    },
    downloadStartIcon: {
      margin: 0,
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const downloadButtonDisabled = isSharing ? false : selectedImages.length === 0
  const shareButtonDisabled = isSharing ? false : selectedImages.length !== 1

  useEffect(() => {
    const medias = selectedImages
      .map((post) => post.ContentGridMedia.map((media) => media.src))
      .flat()
    setMediaArray(medias)
  }, [selectedImages])

  const handleDownload = async () => {
    try {
      const postsIds = selectedImages.map((post) => post.id)
      if (isEmbedded) {
        sendDownloadEvent(mediaArray)
        increaseDownloadCounter(postsIds)
      } else {
        setIsDownloading(true)
        const { success, data } = await downloadPosts(postsIds)

        if (success) {
          triggerDownload(data, 'avenue8_posts.zip')
          setDesktopDownloadStatus(true)
          setIsSelecting(false)
          setIsDownloading(false)
          setHasDownloaded(true)
          setSelectedImages([])
        } else {
          setDesktopDownloadStatus(false)
          setIsDownloading(false)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleDelete = async () => {
    const { id } = selectedImages[0]
    const confirmed = await getConfirmation({
      title: 'Delete?',
      message: 'Are you sure you want to delete this post?',
    })

    if (confirmed) {
      setIsRemoving(true)
      await deletePost(agentId, id)

      setIsSharing(false)
      setSelectedImages([])
      onDeletePost(id)
      setIsRemoving(false)
    }
  }

  const handleSharing = async (socialMedia) => {
    try {
      if (isEmbedded) {
        const postDescription = selectedImages[0].full_description
        if (socialMedia === 'instagram') {
          handleInstagramShareIOS(mediaArray, postDescription)
        } else if (socialMedia === 'facebook') {
          handleFacebookShareIOS(mediaArray, postDescription)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const showDeleteButton = () => {
    //The Delete is allowed only for My Marketing Category and for a single post
    if (!gridData || !selectedImages[0]) return
    if (selectedImages.length > 1) return false

    const category = gridData.filter((category) => {
      const isSelectedPostInThisCategory = category.posts.find(
        (post) => post.id === selectedImages[0].id,
      )
      if (isSelectedPostInThisCategory) return true
      return false
    })
    return category[0].name === 'My Marketing'
  }

  return (
    <Grid item className={classes.container}>
      {isSelecting && (
        <Grid item className={classes.textContainer}>
          <Text type="muted">{selectedImages.length} Selected</Text>
        </Grid>
      )}
      <Grid item className={classes.buttonsContainer}>
        {isEmbedded ? (
          <div style={{ display: 'flex' }}>
            <Button
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: 97,
              }}
              small
              bordered
              primary
              title="Share"
              endIcon={<Instagram style={{ fontSize: 16 }} />}
              disabled={!isEmbedded || shareButtonDisabled}
              classes={{ disabled: classes.disabledButton }}
              clickHandler={() => handleSharing('instagram')}
            />
          </div>
        ) : null}

        {isEmbedded ? (
          <Button
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: 97,
            }}
            small
            bordered
            primary
            title="Share"
            endIcon={<Facebook style={{ fontSize: 16 }} />}
            disabled={shareButtonDisabled}
            classes={{ disabled: classes.disabledButton }}
            clickHandler={() => handleSharing('facebook')}
          />
        ) : null}

        <div style={{ display: 'flex' }}>
          <Button
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 40,
              minWidth: 40,
              padding: 0,
            }}
            small
            bordered
            primary
            title=""
            startIcon={<GetApp style={{ width: 20, fontSize: 16 }} />}
            disabled={downloadButtonDisabled}
            classes={{
              disabled: classes.disabledButton,
              startIcon: classes.downloadStartIcon,
            }}
            clickHandler={handleDownload}
          />
          {showDeleteButton() && (
            <Button
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: 40,
                minWidth: 40,
                padding: 0,
                marginLeft: 24,
              }}
              small
              bordered
              secondary
              title=""
              startIcon={<Delete style={{ width: 20, fontSize: 16 }} />}
              classes={{
                startIcon: classes.downloadStartIcon,
              }}
              clickHandler={handleDelete}
            />
          )}
          {!isEmbedded && !desktopDownloadStatus ? (
            <Tooltip
              text="Error downloading the post"
              type="error"
              status="open"
            />
          ) : null}
        </div>
      </Grid>
    </Grid>
  )
}

export default function AnimatedContentGridFooter({
  in: inProp,
  onDeletePost,
  gridData,
}) {
  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <ContentGridFooter onDeletePost={onDeletePost} gridData={gridData} />
        </div>
      )}
    </Transition>
  )
}
