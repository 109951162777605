import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Text from '../common/Text'
import ContentGridDialogItem from './ContentGridDialogItem'
import { useContentGridContext } from '../../context/ContentGridContext'

const styles = (theme) => ({
  container: {
    padding: '15px 20px',
  },
  dialogTitle: {
    fontFamily: 'BookmaniaBold',
    color: '#292929',
    fontSize: '20px',
  },
  span: {
    fontFamily: 'StyreneMedium',
    color: '#292929',
    fontSize: '14px',
  },
  mutedText: {
    fontSize: '12px',
    fontFamily: 'StyreneRegular',
    color: '#292929',
    opacity: '0.75',
    letterSpacing: '0.25px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    marginTop: '12px',
  },
  gridItem: {
    width: 140,
  },
  slideTitle: {
    marginBottom: 10,
  },
  media: {
    width: '100%',
    marginTop: 7,
    marginBottom: 15,
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 140px)',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100% - 64px)',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100% - 132px)',
    },
  },
  paperFullWidth: { paddingBottom: 20 },
})
export default function ContentGridDialog(props) {
  const { onClose, open, post } = props
  const { setIsSharing, setSelectedImages } = useContentGridContext()
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const handleClose = () => {
    setIsSharing(false)
    setSelectedImages([])
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      classes={{
        scrollPaper: classes.scrollPaper,
        paperFullWidth: classes.paperFullWidth,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
      style={{ zIndex: 5 }}
      PaperProps={{
        style: {
          borderRadius: '0',
          margin: '12px',
        },
      }}
    >
      <Grid className={classes.container}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.slideTitle}
        >
          <Text type="strong">Content Detail</Text>
          <Text
            type="medium"
            onClick={handleClose}
            style={{ marginTop: 3, cursor: 'pointer' }}
          >
            Cancel
          </Text>
        </Grid>
        <ContentGridDialogItem post={post} />
      </Grid>
    </Dialog>
  )
}
