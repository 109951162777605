import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/common/Layout'
import Loading from '../components/common/Loading'
import RTRWrapper from '../components/common/RTRWrapper'
import PackageDetailsView from './PackageDetailsView'
import PackageDetailsNavigation from '../modules/marketing/components/PackageDetailsNavigation'

import {
  getTemplatesByStageAndType,
  getTemplatesTypes,
  getEmailTemplate,
} from '../services/marketingServices'
import { renderNumberOfSteps } from '../utils/utils'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useAuthContext } from '../context/AuthContext'
import { useInputDataContext } from '../context/InputDataContext'
import { usePackageDetailsContext } from '../context/PackageDetailsContext'
import { useMarketingHelpersContext } from '../context/MarketingHelpersContext'
import { useStageSelectionContext } from '../context/StageSelectionContext'
import { useTemplateTypesContext } from '../context/TemplateTypesContext'
import { ErrorBoundary } from '@sentry/react'

const PACKAGES = {
  digital: 'digital',
  print: 'print',
}

export default function PackageDetailsWrapper() {
  const history = useHistoryPrefix()
  const { flow, subflow, requestTypePath, packageType } = useParams()
  const { formMarketingDataCtx, setFormMarketingDataCtx } =
    useInputDataContext()
  const { agentId } = useAuthContext()

  const { templateTypes, setTemplateTypes } = useTemplateTypesContext()

  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { marketingHelpers, setMarketingHelpers } = useMarketingHelpersContext()

  const {
    packageDetailsData: packageDetailsDataCtx,
    setPackageDetailsData: setPackageDetailsDataCtx,
  } = usePackageDetailsContext()

  const [error, setError] = useState(false)
  const [isRTRCreated, setIsRTRCreated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const [currentPage, setCurrentPage] = useState(PACKAGES.digital)

  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        setLoading(true)
        if (templateTypes.length === 0) {
          const response = await getTemplatesTypes(agentId)
          setTemplateTypes(response.data)
        }

        const stage = stageSelectionDataCtx.value

        const { data: digitalResponse } = await getTemplatesByStageAndType(
          stage,
          'digital',
          agentId,
        )

        const { data: printResponse } = await getTemplatesByStageAndType(
          stage,
          'print',
          agentId,
        )

        const indexEmailRTR = digitalResponse.findIndex(
          (_item) => _item.templateCategory === 'email-rtr',
        )
        const updatedDigitalResponse = [...digitalResponse]
        if (indexEmailRTR > 0) {
          const _response = await getEmailTemplate(agentId)
          updatedDigitalResponse[indexEmailRTR] = {
            ...updatedDigitalResponse[indexEmailRTR],
            images: _response.data,
          }
        }

        setPackageDetailsDataCtx({
          ...packageDetailsDataCtx,
          digital: updatedDigitalResponse,
          print: printResponse,
        })

        //reset formData
        setFormMarketingDataCtx({
          ...formMarketingDataCtx,
          [packageType]: {},
        })

        setMarketingHelpers({
          ...marketingHelpers,
          lastFetchedTemplateStage: stage,
          baseTemplateData:
            requestTypePath === 'digitalprint'
              ? { ...marketingHelpers.baseTemplateData }
              : null,
        })
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setError(true)
        console.error(err)
      }
    }
    if (
      packageDetailsDataCtx[packageType]?.length > 0 &&
      marketingHelpers.lastFetchedTemplateStage === stageSelectionDataCtx.value
    ) {
      setLoading(false)
      return
    }
    fetchPackageData()
  }, [packageType])

  useEffect(() => {
    const inputs = Object.values(formMarketingDataCtx) ?? []
    //check if any item was filled
    setIsNextButtonDisabled(
      !inputs.some((obj) => Object.values(obj).some((values) => values.length)),
    )
  }, [formMarketingDataCtx, packageType])

  const selectPagePresentationData = (packageType) => {
    switch (packageType) {
      case 'digital':
        return {
          CTATextBold: 'Package',
          CTATextItalic1: 'Details',
          CTADescription:
            'Select templates one at a time to customize or save to your device:',
          CTADescriptionTooltip:
            'Uploaded images and listing details will be sent to our marketing team to complete your request.',
        }
      case 'print':
        return {
          CTATextBold: 'Package',
          CTATextItalic1: 'Details',
          CTADescription:
            'Select templates one at a time to customize or save to your device:',
          CTADescriptionTooltip:
            'Uploaded images and listing details will be sent to our marketing team to create your custom template. Please allow 24 - 48 hours for printing orders to be finalized.',
        }
      default:
        return {}
    }
  }

  const selectStepCounterData = (packageType) => {
    switch (packageType) {
      case 'digital':
        return {
          numberOfSteps: renderNumberOfSteps(requestTypePath),
          activeStep: 4,
        }
      case 'print':
        return {
          numberOfSteps: renderNumberOfSteps(requestTypePath),
          activeStep: 4,
        }
      default:
        return {
          numberOfSteps: 5,
          activeStep: 4,
        }
    }
  }

  const handleNextClick = () => {
    history.push(
      ['', flow, subflow, requestTypePath, 'reviewrequest'].join('/'),
    )
  }

  const handlePackageTypeClick = (packageType, packageClicked) => {
    if (packageType === packageClicked) return
    setCurrentPage(PACKAGES[packageClicked])
  }

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ErrorBoundary
            fallback={<p>Oops! Something went wrong rendering templates...</p>}
          >
            <RTRWrapper
              onFinishRender={() => {
                setIsRTRCreated(true)
              }}
              packageData={packageDetailsDataCtx[packageType]}
              packageType={packageType}
            />
          </ErrorBoundary>
          <PackageDetailsNavigation
            packageType={currentPage}
            showPackageTypeNavigation={requestTypePath === 'digitalprint'}
            stepCounter={selectStepCounterData('digital')}
            pagePresentation={selectPagePresentationData('digital')}
            isNextButtonDisabled={isNextButtonDisabled}
            handleNextFunction={handleNextClick}
            handlePackageTypeClick={handlePackageTypeClick}
          />
          {isRTRCreated && (
            <PackageDetailsView
              packageType={currentPage}
              packageDetailsData={packageDetailsDataCtx[currentPage]}
              error={error}
              isNextButtonDisabled={isNextButtonDisabled}
              handleNextFunction={handleNextClick}
            />
          )}
        </>
      )}
    </Layout>
  )
}
