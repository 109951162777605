import React from 'react'
import Text from '../common/Text'
import { CheckboxContainer } from './Checkbox.style'
import { PlainCheckboxContainer } from './PlainCheckbox.style'

export default function Checkbox({
  label,
  type,
  checked,
  defaultChecked,
  onChange,
  name,
  value,
  labelOverText,
  plainText,
  plainBorder = false,
  dataAttrs = {},
  handleClick = () => {},
  ...props
}) {
  const Container = plainBorder ? PlainCheckboxContainer : CheckboxContainer
  return (
    <Container
      dataAttrs={{
        ...dataAttrs,
        metaName: name,
        metaChecked: defaultChecked || checked,
        metaValue: value,
        metaType: type,
      }}
    >
      <label className="container">
        {labelOverText ? (
          <div className="labelOver">
            <Text type="muted">{labelOverText}</Text>
            <div className="information-text" {...props} onClick={handleClick}>
              {label}
            </div>
          </div>
        ) : (
          <div
            className="information-container"
            {...props}
            onClick={handleClick}
          >
            {plainText ? (
              <Text type="regular" component="span">
                {label}
              </Text>
            ) : (
              <span className="information-text">{label}</span>
            )}
          </div>
        )}
        <input
          type={type}
          name={name}
          checked={checked}
          onChange={onChange}
          value={value || label}
          defaultChecked={defaultChecked}
        />
        <span className="checkmark" onClick={handleClick}></span>
      </label>
    </Container>
  )
}
