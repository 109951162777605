import { readRemoteFile } from 'react-papaparse'

export function readRemoteCsvList(url) {
  return new Promise((resolve) => {
    readRemoteFile(url, {
      complete: (results) => {
        resolve(results)
      },
    })
  })
}

export function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  })
}
