import React, { useState } from 'react'
import { AgentRTRContextProvider } from './AgentRTRContext'
import { RtrTemplate, StrapiImage } from '../modules/marketing/components/TemplatePreview/TemplateRTRPreview'
import { ListingDescription, ListingDescriptionContext } from './ListingDescriptionContext'
import { ListingMlsImages, ListingMlsImagesContext } from './ListingMlsImagesContext'
import { MarketingHelpers, MarketingHelpersContext } from './MarketingHelpersContext'
import { PackageDetailsContext, PackageDetailsData } from './PackageDetailsContext'
import { StageSelection, StageSelectionContext } from './StageSelectionContext'
import { TemplateTypes, TemplateTypesContext } from './TemplateTypesContext'
import { FontsContextProvider } from './FontsContext'
import { AgentDataContextProvider } from '../hooks/useAgentData'


export type TemplatesPackage = {
  displayName: string;
  displaySubtitle: string;
  packageType: string;
  listingStage: string;
  order: number;
  templateCategory: string;
  rtrTemplates: RtrTemplate[];
  images: (StrapiImage & {
    name: string;
    isRTR?: boolean;
    formats?: Record<string, any>;
    carouselImages?: [{isHidden?: boolean; url: string;}]
  })[];
}

export const MarketingContextProvider: React.FC = ({ children }) => {
  const [
    packageDetailsData,
    setPackageDetailsData,
  ] = useState<PackageDetailsData>({})

  const [marketingHelpers, setMarketingHelpers] = useState<MarketingHelpers>({
    lastFetchedListingAddress: null,
    lastFetchedTemplateStage: null,
    shouldUploadImagesAgain: true,
    lastRTRRenderStageForDigital: null,
    lastRTRRenderStageForPrint: null,
  })

  const [listingDescription, setListingDescription] = useState<ListingDescription>({
      headline: '',
      description: '',
      estimatedPrice: '',
      bedCount: '',
      bathCount: '',
      images: [],
      imagesIds: [],
    })

  const [stageSelection, setStageSelection] = useState<StageSelection>({
    value: '',
    selectedFlowName: '',
    stageName: '',
  })

  const [templateTypes, setTemplateTypes] = useState<TemplateTypes>([])

  const [listingMlsImages, setListingMlsImages] = useState<ListingMlsImages>([])

  return (
    <ListingMlsImagesContext.Provider
      value={{
        listingMlsImages, setListingMlsImages,
      }}
    >
      <TemplateTypesContext.Provider
        value={{
          templateTypes,
          setTemplateTypes,
        }}
      >
        <StageSelectionContext.Provider
          value={{
            stageSelection,
            setStageSelection,
          }}
        >
          <ListingDescriptionContext.Provider
            value={{
              listingDescription,
              setListingDescription,
            }}
          >
            <MarketingHelpersContext.Provider
              value={{
                marketingHelpers,
                setMarketingHelpers,
              }}
            >
              <PackageDetailsContext.Provider
                value={{
                  packageDetailsData,
                  setPackageDetailsData,
                }}
              >
                <AgentRTRContextProvider>
                  <AgentDataContextProvider>
                    <FontsContextProvider>{children}</FontsContextProvider>
                  </AgentDataContextProvider>
                </AgentRTRContextProvider>
              </PackageDetailsContext.Provider>
            </MarketingHelpersContext.Provider>
          </ListingDescriptionContext.Provider>
        </StageSelectionContext.Provider>
      </TemplateTypesContext.Provider>
    </ListingMlsImagesContext.Provider>
  )
}
