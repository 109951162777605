import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { getContentGridData } from '../services/contentServices'
import Layout from '../components/common/Layout.js'
import Loading from '../components/common/Loading'
import ContentGrid from '../components/custom/ContentGrid'
import ContentGridFooter from '../components/custom/ContentGridFooter'
import Text from '../components/common/Text'
import { useAuthContext } from '../context/AuthContext'
import { signalAppToShowBackButton } from '../services/appServices'
import { useContentGridContext } from '../context/ContentGridContext'
import { useRequestContext } from '../context/RequestContext'

const styles = (theme) => ({
  gridContainer: {
    // minHeight: '80vh',
    maxWidth: '80%',
    marginLeft: '10%',
    marginTop: '10%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginTop: '5%',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3%',
    },
  },
  errorContainer: {
    margin: '25px 5% 0 5%',
  },
})

export default function ContentGridPage() {
  const { isEmbedded, setCurrentFlow } = useRequestContext()
  const { isSelecting, isSharing } = useContentGridContext()
  const { agentId } = useAuthContext()
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [showPage, setShowPage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setCurrentFlow('Content Library')

    if (isEmbedded) {
      signalAppToShowBackButton()
    }

    const loadPageData = async () => {
      setLoading(true)
      const response = await getContentGridData(agentId)
      if (response.success) {
        setPageData(response.data)
        setShowPage(true)
      } else {
        setHasError(true)
      }
      setLoading(false)
    }
    loadPageData()
  }, [])

  const removeItemFromData = (postId) => {
    if (!pageData) return

    setPageData([
      ...pageData.map((page) => ({
        ...page,
        posts: page.posts.filter((p) => p.id !== postId),
      })),
    ])
  }

  return (
    <Layout>
      {loading && <Loading />}
      {showPage && (
        <Grid className={classes.gridContainer}>
          <ContentGrid gridData={pageData} />
          <ContentGridFooter
            in={isSelecting || isSharing}
            onDeletePost={removeItemFromData}
            gridData={pageData}
          />
        </Grid>
      )}
      {hasError && (
        <Grid className={classes.errorContainer}>
          <Text>Oops, something went wrong...</Text>
          <Text type="muted">Please try again later</Text>
        </Grid>
      )}
    </Layout>
  )
}
