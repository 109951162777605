import { makeStyles } from '@material-ui/core/styles'

export const TemplateStepStyles = makeStyles(() => {
  return {
    active: {
      width: '30px',
      height: '30px',
      border: '1px solid var(--blue)',
      borderRadius: '50%',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '30px',
      fontSize: '12px',
      fontFamily: 'StyreneMedium',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    inactive: {
      width: '30px',
      height: '30px',
      opacity: '0.35',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '30px',
      fontSize: '12px',
      fontFamily: 'StyreneRegular',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    numberContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    bulletTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '16px',
      fontSize: '12px',
      fontFamily: 'StyreneMedium',
      letterSpacing: '0.25px',
      color: 'var(--blue)',
    },
    bulletIcon: {
      fontSize: '14px',
      marginTop: 16,
      textAlign: 'center',
    },
    bulletText: {
      marginTop: 4,
      textAlign: 'center',
      '& span': {
        whiteSpace: 'nowrap !important',
      },
    },
    activeBullet: {},
    inactiveBullet: {
      opacity: '0.35',
    },
  }
})
