import React, { ReactNode } from 'react'
import PackageDetailsItem from '../../../../components/custom/PackageDetailsItem'
import { RTR_V2_ENABLED_TEMPLATES } from '../../../../utils/constants'
import { TemplateRTRPreview } from './TemplateRTRPreview'
import { TemplatesPackage } from '../../../../context/MarketingContext'
import { useRequestContext } from '../../../../context/RequestContext'
import { TemplateCarousel } from './TemplateCarousel'
import { formatTemplateData } from '../../utils/formatTemplateData'
import { useListingDescriptionContext } from '../../../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../../../context/StageSelectionContext'
import { ErrorBoundary } from '@sentry/react'

export type TemplatePreviewProps = {
  category: TemplatesPackage
}

export type TemplateImage = {
  url: string
  crop?: { x: number; y: number; size: number; previewUrl: string }
}

const dynamicPropSelector: Record<string, string> = {
  digital: 'promotePost',
  print: 'productionNeeds',
}

export const TemplatePreviews = ({
  category,
}: TemplatePreviewProps): ReactNode => {
  const { selectedPropertyCtx } = useRequestContext() as any
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()

  const { templateCategory, packageType } = category

  const usingV2 = !RTR_V2_ENABLED_TEMPLATES.includes(templateCategory)
  const dynamicFormTrigger: string = dynamicPropSelector[packageType]
  let children: React.ReactNode[] = []

  if (usingV2) {
    const baseTemplateData = formatTemplateData({
      listingDescriptionData: listingDescriptionCtx,
      addressLine: selectedPropertyCtx,
      stageName: stageSelectionDataCtx.stageName,
    })

    const rtrChildren = category.rtrTemplates.map((template, index) => {
      //Only print images should use static image as preview
      const staticImagePreview =
        category.packageType === 'print'
          ? category.images[0].url || category.images[0].formats.small.url
          : ''

      return (
        <TemplateRTRPreview
          key={`${packageType}-${
            template.id || template.templateOrder + index
          }`}
          index={index}
          dynamicFormTrigger={dynamicFormTrigger}
          rtrTemplate={template}
          templateData={baseTemplateData}
          category={category}
          staticImagePreview={staticImagePreview as string}
        />
      )
    })
    children = [...rtrChildren]
  }

  const imagesChildren = category.images.map((image, index) => {
    return (
      <PackageDetailsItem
        key={`${category.displayName} #${index + 1}`}
        index={index}
        image={image}
        packageType={category.packageType}
        packageData={category}
        propThatTriggersDynamicForm={dynamicFormTrigger}
      />
    )
  })

  // If print and have non RTR it should show static for retrocompability
  // If it is not print show both
  if (category.packageType === 'print') {
    if (category.rtrTemplates?.length == 0) {
      children.push(...imagesChildren)
    }
  } else {
    children.push(...imagesChildren)
  }

  return (
    <ErrorBoundary fallback={<p>Oops! Something went wrong...</p>}>
      <TemplateCarousel
        imgQty={category.rtrTemplates.length + category.images.length}
      >
        {children}
      </TemplateCarousel>
    </ErrorBoundary>
  )
}
