import React, { createContext, useContext, useEffect, useState } from 'react'
import { useEmbedFonts } from '../hooks/useEmbedFonts'

export type FontsContextType = {
  fonts?: string
}
export const FontsContext = createContext<FontsContextType>({})

export const useFontsContext = () => useContext<FontsContextType>(FontsContext)

export const FontsContextProvider: React.FC = ({ children }) => {
  const [fonts, setFonts] = useState<string>()
  const { getFontsAsDataURI } = useEmbedFonts()

  useEffect(() => {
    getFontsAsDataURI().then((fonts) => setFonts(fonts.join('\n')))
  }, [])

  return (
    <FontsContext.Provider
      value={{
        fonts,
      }}
    >
      {children}
    </FontsContext.Provider>
  )
}
