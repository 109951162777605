import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import Text from '../../common/Text'
import HtmlPreviewer from '../../common/HtmlPreviewer'
import checkmarkSvg from '../../../assets/icons/Checkmark.svg'
import { format } from 'date-fns'

const styles = (theme) => ({
  previewContainer: {
    background: 'transparent',
    overflowY: 'scroll',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%',
      height: '500px',
      margin: '-5% auto 0 auto',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      height: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      margin: '0% auto 0 auto',
    },
  },
  disabledContainer: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledLabel: {
    color: 'var(--blue)',
    fontFamily: 'StyreneMedium',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    width: '172px',
    textAlign: 'center',
  },
  checkmark: {
    transform: 'scale(1.8)',
    backgroundColor: '#2a29a6',
    height: '36px',
    width: '36px',
    border: '1px solid #2a29a6',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '38px',
    '& > div': {
      background: `url(${checkmarkSvg}) no-repeat`,
      width: '20px',
      height: '16px',
      marginTop: '5px',
    },
  },
})

export default function TemplatePresentation({ inputData, image = {} }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const listAppend = inputData?.mailingLists?.length === 1 ? ' list' : ' lists'

  return (
    <Grid key={`${image.url}`} style={{ marginTop: 20 }}>
      <Grid
        container
        justify="left"
        direction="column"
        alignItems="left"
        style={{ marginTop: 30 }}
      >
        <Text type="muted">{'Template'}</Text>
        <Grid style={{ marginTop: 5 }}>
          <Text type="medium">{inputData.templateName}</Text>
        </Grid>
      </Grid>
      {inputData?.emailData?.emailSendDate && (
        <Grid
          container
          justify="left"
          direction="column"
          alignItems="left"
          style={{ marginTop: 30 }}
        >
          <Text type="muted">Email Send Date</Text>
          <Grid style={{ marginTop: 5 }}>
            <Text type="medium">
              <span>
                {format(inputData.emailData.emailSendDate, 'MM/dd/yyyy')}
              </span>
            </Text>
          </Grid>
        </Grid>
      )}
      {inputData?.mailingLists?.length > 0 && (
        <Grid
          container
          justify="left"
          direction="column"
          alignItems="left"
          style={{ marginTop: 30 }}
        >
          <Text type="muted">{'Send To'}</Text>
          <Grid style={{ marginTop: 5 }}>
            <Text type="medium">
              {inputData.mailingLists.map((item, index) => {
                return (
                  <span key={index}>
                    {item.type === 'MLS_list'
                      ? `${item.options.length} MLS`
                      : item.name}
                    {index !== inputData.mailingLists.length - 1
                      ? ', '
                      : listAppend}
                  </span>
                )
              })}
            </Text>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justify="left"
        direction="column"
        alignItems="left"
        style={{ marginTop: 45 }}
      >
        <Text type="muted">{'Preview:'}</Text>
        <Grid container justify="center" direction="column" alignItems="center">
          <Grid
            item
            className={classes.previewContainer}
            style={{
              marginTop: 15,
              overflowY: inputData?.alreadySent ? 'hidden' : 'scroll',
              position: 'relative',
            }}
          >
            <div
              className={classes.disabledContainer}
              style={{
                display: inputData?.alreadySent ? 'flex' : 'none',
              }}
            >
              <span className={classes.checkmark}>
                <div />
              </span>
              <p className={classes.disabledLabel}>
                We’ve started creating your email. You’ll receive a preview in
                your inbox within 1 business day.
              </p>
            </div>
            <HtmlPreviewer
              html={inputData.emailHTML}
              data={{
                ...inputData.emailData,
                showBody: true,
                showHero: true,
                showListingImages: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
