import React from 'react'
import { makeStyles } from '@material-ui/core'
import Text from './Text'
import classNames from 'classnames'
import { generateDataAttrs } from '../../utils/utils'
const styles = (theme) => ({
  inputArea: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  field: {
    display: 'none',
  },
  hasUploadAreaContainer: {
    width: '100%',
    height: '110px',
    border: '1px dashed rgba(42, 41, 166, 0.65)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noUploadAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    alignItems: 'center',
  },
})
const useStyles = makeStyles(styles)

export default function FileUploadInput({
  label,
  icon = '',
  handleOnChange,
  hasUploadAreaContainer = true,
  noUploadAreaContainer,
  dataAttrs = {},
  acceptTypes = 'image/*',
}) {
  const classes = useStyles()
  const fileUploadClasses = classNames({
    [classes.hasUploadAreaContainer]: hasUploadAreaContainer,
    [classes.noUploadAreaContainer]: noUploadAreaContainer,
  })

  return (
    <label
      htmlFor="file"
      className={classes.inputArea}
      {...generateDataAttrs({ ...dataAttrs, metaType: 'image-uploader' })}
    >
      <div className={fileUploadClasses}>
       {!!icon && <img src={icon} alt="Upload Icon" srcSet="" />}
        <Text type="blue">{label}</Text>
        <input
          type="file"
          id="file"
          accept={acceptTypes}
          multiple
          className={classes.field}
          onChange={handleOnChange}
          placeholder="upload"
        />
      </div>
    </label>
  )
}
