/**
 * Generates rule to validate value being valid number
 * @param {string} errorMessage
 * @returns {function(*=): boolean|string} validator function
 */
export const isNumberRule = (errorMessage) => {
  /**
   * Validates if given value is numeric
   * @param {any} v input value
   * @returns {boolean|string} validator function
   */
  const rule = (v) => !v || !Number.isNaN(Number(v)) || errorMessage
  rule.options = {}
  return rule
}
