import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../common/Text'

export default function InformationInput({ label, information }) {
  const styles = (theme) => ({
    informationContainer: {
      maxWidth: '315px',
      wordWrap: 'break-word',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '530px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '800px',
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <Grid className={classes.informationContainer}>
      <Text type="muted" style={{ marginBottom: 5 }}>
        {label}
      </Text>
      <Text type="medium" style={{ marginBottom: 12, whiteSpace: 'pre-line' }}>
        {information}
      </Text>
    </Grid>
  )
}
