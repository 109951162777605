import React, { useEffect, useState } from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import { A8Button } from 'av8-ui'
import { PrintModalStepImage } from '../../../../store/PrintModal.store'
import Text from '../../../../../../components/common/Text'
import Modal from '../../../../../../components/common/Modal'
import MLSLoader from '../../../../../../components/custom/MLSLoader'
import { PrintCategory, printCategoryData } from './printCategory.data'

type DownloadOnlyFormType = {
  childrenImages: PrintModalStepImage[]
  handleDownloadImages: () => void
  templateCategory: PrintCategory
}

type LoadingModalStatusType = {
  loading: boolean
  message: string
  show: boolean
}

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    marginTop: 15,
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 375,
    },
  },
  formContainer: {
    background: '#F3F3F7',
    width: '100%',
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {},
  },
  formSection: {
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 375,
    },
    marginBottom: 75,
  },
}))

export default function DownloadOnlyForm({
  childrenImages,
  handleDownloadImages,
  templateCategory,
}: DownloadOnlyFormType) {
  const classes = useStyles()
  const [loadingModalStatus, setLoadingModalStatus] =
    useState<LoadingModalStatusType>({
      loading: true,
      show: true,
      message: 'Rendering...',
    })

  useEffect(() => {
    if (childrenImages.length > 0) {
      setLoadingModalStatus({
        loading: false,
        show: false,
        message: '',
      })
    }
  }, [childrenImages])

  return (
    <Grid
      container
      justifyContent='center'
      className={classes.previewContainer}
    >
      <Modal open={loadingModalStatus.show}>
        <MLSLoader
          loading={loadingModalStatus.loading}
          text={loadingModalStatus.message}
          setModalStatus={setLoadingModalStatus}
        />
      </Modal>
      <Grid
        container
        direction='column'
        alignItems='center'
        style={{ backgroundColor: '#F3F3F7' }}
      >
        {childrenImages.map((childImage: PrintModalStepImage) => (
          <Box my={3} key={childImage.step} boxShadow={1}>
            <img src={childImage.localUrl} width={256} />
          </Box>
        ))}
      </Grid>
      <Grid
        container
        direction='column'
        alignItems='center'
        style={{ margin: '20px 0 50px 0' }}
      >
        <Text type='muted'>
          Download your{' '}
          {printCategoryData[templateCategory].title || templateCategory} to
          your local device:
        </Text>
        <Box width={220} mt={3}>
          <A8Button
            variant='contained'
            fullWidth
            onClick={handleDownloadImages}
          >
            Download
          </A8Button>
        </Box>
      </Grid>
    </Grid>
  )
}
