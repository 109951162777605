import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import {
  add as dateAdd,
  isValid as isValidDate,
  startOfDay as dateStartOfDay,
} from 'date-fns'
import Checkbox from '../common/Checkbox'
import Text from '../common/Text'
import Datepicker from '../common/Datepicker'
import Select from '../common/Select'
import TextArea from '../common/TextAreaInput'
import FeatureLists from '../common/FeaturesList'
import FeatureItems from '../common/FeatureItems'
import { formDataBuilder, generateDataAttrs } from '../../utils/utils'
import { InputItem } from './DynamicForm/InputItem'
import { ImageSelector as ImageSelectorComponent } from '../../modules/shared/components/ImageSelector/ImageSelector'
import { useListingMlsImagesContext } from '../../context/ListingMlsImagesContext'
import { useAgentDataContext } from '../../hooks/useAgentData'
import { proxyCDN } from '../../services/uploadCareServices'
import { ImageLogoSelector } from '../../modules/marketing/components/ImageLogoSelector/ImageLogoSelector'

const CheckboxItem = (props) => {
  const { label, type, name, options, required } = props.data
  const { formData, setFormData } = props.formData
  const styles = () => ({
    checkBoxgrid: {
      display: 'grid',
      gridTemplateColumns:
        name === 'getSignage'
          ? '100%'
          : options.length % 2 === 0
          ? '50% 50%'
          : '100%',
      marginTop: '15px',
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Grid data-meta-name={name} data-meta-type='checkbox-group'>
      <Text type='muted'>{label}</Text>
      <Grid container className={classes.checkBoxgrid}>
        {options &&
          options.map((option) => {
            const { label, value } = option
            return (
              <Checkbox
                label={label}
                type={type}
                name={name}
                key={label}
                value={value}
                defaultChecked={formData[name]?.value === value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [name]: {
                      value: e.target.value,
                      optionLabel: props.data.label,
                      label: label,
                      type: type,
                      required,
                    },
                  })
                }}
              />
            )
          })}
      </Grid>
      <Text type='error'>{formData[name]?.error}</Text>
    </Grid>
  )
}

const countWords = (value) => {
  if (!value) return 0
  value = value.replace(/(^\s*)|(\s*$)/gi, '') //exclude  start and end white-space
  value = value.replace(/[ ]{2,}/gi, ' ') //2 or more space to 1
  value = value.replace(/\n /, '\n') // exclude newline with a start spacing
  return value.split(' ').filter((str) => str !== '').length
}

const TextAreaItem = (props) => {
  const {
    label,
    name,
    metadata: { placeholder, rules = [] },
    required,
  } = props.data
  const { formData, setFormData } = props.formData
  const rulesObj = {}
  const handleRuleSelecting = (rule) => {
    switch (rule.type) {
      case 'max-length':
        return {
          maxLength: rule.value,
        }
      case 'word-count':
        return {
          lengthCounting: countWords(formData[name]?.value)
            ? `${countWords(formData[name]?.value)}/${rule.value}`
            : `0/${rule.value}`,
          maxWordCount: rule.value,
        }
      default:
        return {}
    }
  }

  const isNewValueValid = (value) => {
    if (!rulesObj) return true
    const { maxLength, maxWordCount } = rulesObj

    if (maxLength) {
      return value.length < maxLength ? true : false
    }

    if (maxWordCount) {
      return countWords(value) < maxLength ? true : false
    }

    return true
  }

  const formatInitialValue = (value) => {
    if (!rulesObj) return value
    const { maxLength } = rulesObj
    if (maxLength) {
      return value?.slice(0, maxLength)
    }
    return value
  }

  if (rules) {
    rules.forEach((rule) => {
      Object.assign(rulesObj, handleRuleSelecting(rule))
    })
  }

  const maxLengthRule = rules.find((rule) => rule.type === 'max-length')
  const { maxLength, ...rulesProps } = rulesObj
  return (
    <TextArea
      label={label}
      name={name}
      value={formatInitialValue(formData[name]?.value)}
      placeholder={placeholder}
      error={formData[name]?.error}
      lengthCounting={
        maxLengthRule
          ? `${formatInitialValue(formData[name]?.value)?.length} / ${
              maxLengthRule?.value
            }`
          : ''
      }
      maxLength={maxLengthRule ? maxLengthRule.value : ''}
      onChange={(e) => {
        const isValid =
          rulesProps && maxLength !== undefined && +(maxLength ?? '0') >= 1
            ? isNewValueValid(e.target.value) // countWords(e.target.value) <= maxLength
            : true
        if (isValid) {
          setFormData({
            ...formData,
            [name]: {
              value: formatInitialValue(e.target.value),
              optionLabel: label || placeholder,
              label: label,
              type: 'textarea',
              required,
              name,
            },
          })
        }
      }}
      rows='4'
    />
  )
}
const SelectItem = (props) => {
  const { label, name, options, required } = props.data
  const { formData, setFormData } = props.formData
  return (
    <Select
      label={label}
      options={options}
      value={formData[name]?.value}
      onChange={(e) => {
        const option = options.find((option) => option.value === e.target.value)
        setFormData({
          ...formData,
          [name]: {
            value: e.target.value,
            optionLabel: label,
            label: option.label,
            type: 'select',
            required,
          },
        })
      }}
    />
  )
}
const DatePickerItem = (props) => {
  const {
    label,
    name,
    metadata: { placeholder, rules, meta = {} },
    required,
  } = props.data
  const { formData, setFormData } = props.formData
  let rulesObj = {}
  const handleRuleSelecting = (rule) => {
    const futureDate = (date) => {
      // uses rule's field "offset"
      return dateAdd(
        dateAdd(dateStartOfDay(date), {
          days: 1,
        }),
        rule.offset || {},
      )
    }
    const dateBeforeMinBudget = (rule, formData) => {
      // uses rule's fields "budgetField", "startDateField", "value"
      const budget = formData[rule.budgetField || 'paidAdBudget']?.value
      const startDate =
        formData[rule.startDateField || 'campaignStartDate']?.value
      if (
        !startDate ||
        !isValidDate(startDate) ||
        !budget ||
        Number.isNaN(budget)
      ) {
        return null
      }
      const minValue =
        Number.isNaN(+rule.value) || +rule.value < 1 ? 1 : +rule.value
      const maxDate = dateAdd(new Date(startDate), {
        days: Math.floor(+budget / minValue),
      })
      if (!isValidDate(maxDate)) {
        return null
      }
      return maxDate
    }
    switch (rule.type) {
      case 'date-after':
        return ['minDate', formData[rule.field]?.value]
      case 'date-before':
        return ['maxDate', formData[rule.field]?.value]
      case 'date-before-min-budget':
        return ['maxDate', dateBeforeMinBudget(rule, formData)]
      case 'future-date':
        return [
          'minDate',
          futureDate(formData[rule.field]?.value || new Date()),
        ]
      default:
        return null
    }
  }
  const rulePropsDedup = (prop, values) => {
    switch (prop) {
      case 'minDate':
        return new Date(
          Math.max(...values.map((date) => new Date(date).getTime())),
        )
      case 'maxDate':
        return new Date(
          Math.min(values.map((date) => new Date(date).getTime())),
        )
      default:
        return values
    }
  }
  if (rules && rules.length) {
    rulesObj = rules
      .map((rule) => handleRuleSelecting(rule))
      .filter((rule) => !!rule)
      .filter(([key, val]) => !!key && !!val && val !== false && val !== 0)
      .reduce((props, [ruleKey, ruleValue]) => {
        if (ruleKey in props) {
          return {
            ...props,
            [ruleKey]: rulePropsDedup(ruleKey, [props[ruleKey], ruleValue]),
          }
        }
        return {
          ...props,
          [ruleKey]: ruleValue,
        }
      }, {})
  }
  return (
    <Grid
      style={{ marginBottom: 10 }}
      {...generateDataAttrs({
        ...meta,
        metaName: name,
        metaType: 'datepicker',
      })}
    >
      <Text type='muted'>{label}</Text>
      <Datepicker
        label={label}
        value={formData[name]?.value}
        dateFormat='MM/dd/yyyy'
        minDate={new Date()}
        {...rulesObj}
        onChange={(date) => {
          setFormData({
            ...formData,
            [name]: {
              value: date,
              optionLabel: label,
              label: label,
              type: 'datepicker',
              required,
            },
          })
        }}
        placeholderText={placeholder || 'MM/DD/YYYY'}
      />
      <Text type='error' style={{ marginTop: 5 }}>
        {formData[name]?.error}
      </Text>
    </Grid>
  )
}
const InformationItem = (props) => {
  const { label, information } = props.data
  return (
    <Grid data-meta-name='information'>
      <Text type='muted' style={{ marginBottom: 5 }}>
        {label}
      </Text>
      <Text type='medium' style={{ marginBottom: 12 }}>
        {information}
      </Text>
    </Grid>
  )
}
const FeaturesListItem = (props) => {
  const {
    label,
    name,
    metadata: { placeholder, rules },
    required,
  } = props.data
  const { isEditing } = props
  const { formData, setFormData } = props.formData
  const rulesObj = {}
  const handleRuleSelecting = (rule) => {
    switch (rule.type) {
      case 'max-features':
        return {
          featuresCounting: formData[name]?.value?.length ?? 0, // TODO,
          maxFeatures: rule.value,
        }
      case 'items-per-feature':
        return {
          itemsCounting: `${formData[name]?.value?.length}/${rule.value}`, // TODO
          maxItems: rule.value,
        }
      case 'required-items':
        return {
          filledItemsCounting: `${formData[name]?.value?.length}/${rule.value}`, // TODO
          minItems: rule.value,
        }
      default:
        return {}
    }
  }
  if (rules) {
    rules.forEach((rule) => {
      Object.assign(rulesObj, handleRuleSelecting(rule))
    })
  }
  // Function to initialize the featureList with the defined number of items
  const initializeFeature = () => {
    const featureListValue = [{ title: '', error: true, features: [] }]
    // Create the number of items according to the rule
    featureListValue[0].features = Array.from(
      { length: rulesObj.maxItems || 3 },
      () => ({
        item: '',
      }),
    )
    // Return the feature list initial state
    return featureListValue
  }
  const loadFeature = () => {
    if (
      !formData ||
      !formData.featuresList ||
      formData.featuresList.value.length === 0
    ) {
      return initializeFeature()
    }
    const featureListValue = formData.featuresList.value.map((feature) => ({
      ...feature,
      error: false,
    }))
    // Return the feature list initial state
    return featureListValue
  }
  return (
    <Grid>
      <FeatureLists
        label={label}
        name={name}
        value={formData[name]?.value}
        placeholder={placeholder}
        error={formData[name]?.error}
        featuresListInitialState={
          isEditing ? loadFeature() : initializeFeature()
        }
        {...rulesObj}
        changeFeaturesList={(featuresInputList) => {
          setFormData({
            ...formData,
            [name]: {
              name,
              value: featuresInputList,
              optionLabel: label ?? placeholder,
              label: label,
              type: 'featuresList',
              required,
            },
          })
        }}
      />
      <Text type='error' style={{ marginTop: 5 }}>
        {formData[name]?.error}
      </Text>
    </Grid>
  )
}

const ImageSelector = (props) => {
  const { name, label, metadata = {} } = props.data

  const source = metadata?.source

  const { formData, setFormData } = props.formData
  const { layers = [] } = props

  let cropAspectRatio = 1

  const { id: imageId, name: imageName, value } = formData[name] || {}
  const imageUrl = Array.isArray(value) ? value[0] ?? {} : value || undefined

  const updateImageHandler = (image) => {
    const previousRecord = formData[name] || {}
    const previousMeta = previousRecord?.metadata || {}
    setFormData({
      ...formData,
      [name]: {
        ...previousRecord,
        metadata: { ...previousMeta, ...image },
        value: image.url,
      },
    })
  }

  if (layers?.length > 0) {
    // Each input is associated to one layer to be rendered
    const findAssociatedLayer = layers.find((layer) => layer.tag === name) || {}
    // Calculate the aspect ratio for that layer to match croping image
    cropAspectRatio = Number.parseFloat(
      findAssociatedLayer?.width / findAssociatedLayer?.height,
    ).toFixed(2)
  }

  const { listingMlsImages } = useListingMlsImagesContext()
  const { agentLogos } = useAgentDataContext()

  return source === 'agent-logos' ? (
    <ImageLogoSelector
      name={name}
      label={label}
      images={agentLogos.map((c) => ({ ...c, url: proxyCDN(c.url) }))}
      previewImage={{ id: imageId, name: imageName, url: imageUrl }}
      onImageChange={updateImageHandler}
      {...props.data}
    />
  ) : (
    <ImageSelectorComponent
      name={'Property Image photos:'}
      label={label}
      images={listingMlsImages}
      previewImage={{ id: imageId, name: imageName, url: imageUrl }}
      onImageChange={updateImageHandler}
      cropAspectRatio={cropAspectRatio}
      disableImportFromMLS={metadata?.disableImportFromMLS} 
      {...props.data}
    />
  )
}

const FeatureItemsItem = (props) => {
  const {
    name,
    metadata: { placeholder, maxFeatures },
  } = props.data
  const { formData, setFormData } = props.formData

  const handleInputItemChange = (featureItems) => {
    setFormData({
      ...formData,
      [name]: {
        metadata: { placeholder },
        value: featureItems,
        name,
      },
    })
  }

  return (
    <Grid data-meta-name='feature-items'>
      <FeatureItems
        placeholder={'Feature'}
        name={name}
        maxFeatures={maxFeatures}
        handleInputItemChange={handleInputItemChange}
        featuresItemsInitialValue={
          Array.isArray(formData[name]?.value) ? formData[name]?.value : null
        }
      />
    </Grid>
  )
}

const InputSelector = ({ option, formData, isEditing, layers }) => {
  const selector = {
    radio: <CheckboxItem data={option} formData={formData} />,
    input: <InputItem data={option} formData={formData} />,
    datepicker: <DatePickerItem data={option} formData={formData} />,
    label: <InformationItem data={option} />,
    select: <SelectItem data={option} formData={formData} />,
    textarea: <TextAreaItem data={option} formData={formData} />,
    imageSelector: (
      <ImageSelector data={option} formData={formData} layers={layers} />
    ),
    featuresList: (
      <FeaturesListItem
        data={option}
        formData={formData}
        isEditing={isEditing}
      />
    ),
    featureItems: <FeatureItemsItem data={option} formData={formData} />,
  }
  return selector[option.type] || selector[option.name] || <></>
}

export default function DynamicFormGenerator({
  options = [],
  formData,
  setFormData,
  isEditing = true,
  layers = [],
}) {
  useEffect(() => {
    if (!isEditing) {
      const formDataBuilt = formDataBuilder(options)
      setFormData({ ...formData, ...formDataBuilt })
    }
  }, [options])
  return (
    <form
      data-meta-type='dynamic-form'
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      {options.map((option, index) => (
        <InputSelector
          option={option}
          key={`${option.label}${index}`}
          formData={{ formData, setFormData }}
          isEditing={isEditing}
          layers={layers}
        />
      ))}
    </form>
  )
}
