/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useState } from 'react'
import { Dialog, Grid } from '@material-ui/core'
import CropIcon from '@material-ui/icons/Crop'
import CheckIcon from '@material-ui/icons/Check'
import { Crop } from 'react-image-crop'
import { A8Button, Typography } from 'av8-ui'
import { CropImageModalStyles } from './CropImageModal.styles'
import { CroppableImage, CropResult } from '../CroppableImage/CroppableImage'
import { isElement } from 'lodash'

export type Image = {
  id: string
  url: string
  name: string
  refCrop?: Crop
}

export type CropAndPreview = CropResult & {
  previewUrl: string
  finalUrl: string
}

export type CropImageModalProps = {
  showIcon?: boolean
  open?: boolean
  image: Image
  title: string
  description: string
  showBackButton?: boolean
  onGoBack?: () => void
  handleUpdateCrop: (crop: CropAndPreview | null, image: Image | null) => void
  cropAspectRatio?: number
}

type CurrentCrop = {
  crop: CropAndPreview
  image: Image
} | null

export const CropImageModal = ({
  image,
  handleUpdateCrop,
  title,
  description,
  onGoBack,
  showIcon = true,
  open = false,
  showBackButton = true,
  cropAspectRatio = 1,
}: CropImageModalProps): JSX.Element => {
  const BASE_CROP: Crop = {
    x: 0,
    y: 0,
    width: 100 * cropAspectRatio,
    height: 100,
    unit: 'px',
    aspect: cropAspectRatio,
  }

  const classes = CropImageModalStyles()
  const [cropResolution, setCropResolution] = useState({
    width: 100 * cropAspectRatio,
    height: 100,
  })
  const [showDialog, setOpen] = useState(false)
  const [currentCrop, setCurrentCrop] = useState<CurrentCrop>(null)

  React.useEffect(() => setOpen(open), [open])

  const handleClickOpen = () => {
    setCurrentCrop(null)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getPreviewCrop = (
    image: HTMLImageElement,
    croppedSection: Crop,
  ): string => {
    if (!isElement(image)) return ''

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const pixelRatio = window.devicePixelRatio
    canvas.width = croppedSection.width! * pixelRatio
    canvas.height = croppedSection.height! * pixelRatio

    const futureHeightSize = Math.round(
      (croppedSection.height! / image.height) * image.naturalHeight,
    )
    const futureWidthSize = Math.round(
      (croppedSection.width! / image.width) * image.naturalWidth,
    )

    setCropResolution({ width: futureWidthSize, height: futureHeightSize })

    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
      ctx.imageSmoothingQuality = 'high'
      ctx?.drawImage(
        image,
        croppedSection.x! * scaleX,
        croppedSection.y! * scaleY,
        croppedSection.width! * scaleX,
        croppedSection.height! * scaleY,
        0,
        0,
        croppedSection.width!,
        croppedSection.height!,
      )
    }

    return canvas.toDataURL()
  }

  const getFinalCrop = (
    image: HTMLImageElement,
    croppedSection: Crop,
  ): Promise<string> => {
    return new Promise((resolve) => {
      if (!isElement(image)) resolve('')

      const canvas = document.createElement('canvas')
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const futureHeightSize = Math.round(
        (croppedSection.height! / image.height) * image.naturalHeight,
      )
      const futureWidthSize = Math.round(
        (croppedSection.width! / image.width) * image.naturalWidth,
      )

      canvas.width = futureWidthSize
      canvas.height = futureHeightSize

      const ctx = canvas.getContext('2d')

      if (ctx) {
        ctx.imageSmoothingQuality = 'high'

        ctx?.drawImage(
          image,
          croppedSection.x! * scaleX,
          croppedSection.y! * scaleY,
          futureWidthSize,
          futureHeightSize,
          0,
          0,
          futureWidthSize,
          futureHeightSize,
        )
      }

      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob))
      })
    })
  }

  const handleCropCompleted = async (image: Image, crop: CropResult) => {
    const previewUrl = getPreviewCrop(crop.refImage, crop.refCrop)
    const finalUrl = await getFinalCrop(crop.refImage, crop.refCrop)
    setCurrentCrop({
      crop: { ...crop, previewUrl, finalUrl },
      image,
    })
  }

  const handleApply = () => {
    const crop = currentCrop?.crop ?? null
    const image = currentCrop?.image ?? null
    handleUpdateCrop(crop, image)
    handleClose()
  }

  const handleBack = () => {
    handleUpdateCrop(null, null)
    if (onGoBack) {
      onGoBack()
    }
    handleClose()
  }

  const handleCancel = () => {
    handleUpdateCrop(null, null)
    handleClose()
  }

  return (
    <>
      <Grid
        container
        alignItems='center'
        justify='center'
        onClick={handleClickOpen}
      >
        {showIcon ? (
          <CropIcon
            style={{
              fontSize: 18,
              cursor: 'pointer',
            }}
          />
        ) : null}
      </Grid>
      <Dialog
        onClose={handleCancel}
        aria-labelledby='simple-dialog-title'
        open={showDialog}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{
          style: {
            borderRadius: '0',
            margin: 16,
            maxWidth: 375,
            minHeight: 400,
          },
        }}
      >
        <Grid className={classes.container}>
          <Grid
            container
            className={classes.modalHeaderActions}
            style={{
              justifyContent: showBackButton ? 'space-between' : 'flex-end',
            }}
          >
            {showBackButton && (
              <Typography
                variant='Caption'
                onClick={handleBack}
                style={{ cursor: 'pointer', marginTop: 5 }}
              >
                Back
              </Typography>
            )}

            <Typography
              variant='Caption'
              onClick={handleCancel}
              style={{ cursor: 'pointer', marginTop: 5 }}
            >
              Cancel
            </Typography>
          </Grid>

          <Grid style={{ marginBottom: 20 }}>
            <Typography variant='Subheading'>{title}</Typography>
          </Grid>

          <Grid className={classes.cropShadow}>
            <Typography variant='Caption' color='grey'>
              {description}
            </Typography>

            <div style={{ margin: '20px 0px' }}>
              <CroppableImage
                initialCrop={image.refCrop ?? BASE_CROP}
                url={image.url}
                onCompleted={(cropResult) =>
                  handleCropCompleted(image, cropResult)
                }
              />
            </div>

            <Typography variant='Caption'>
              {image.name || 'Image.jpg'}
            </Typography>

            {cropResolution !== null && (
              <Grid container className={classes.cropSizeContainer}>
                <CheckIcon className={classes.checkIcon} />
                <Typography
                  variant='Caption'
                  style={{ marginLeft: 8, color: '#12B777', fontSize: 12 }}
                >
                  Size {cropResolution.width}x{cropResolution.height}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container justify='center' style={{ marginBottom: 40 }}>
            <A8Button
              onClick={handleApply}
              disabled={currentCrop == null}
              variant='contained'
              wide
            >
              Apply (1/1)
            </A8Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
