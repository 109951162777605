import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

type TextType = {
  children: React.ReactNode
  type?: string
  color?: string
  fontSize?: string
  style?: any
  component?: string
  onClick?: () => void
}

export default function Text({
  children,
  type = 'strong',
  color = undefined,
  fontSize = undefined,
  ...props
}: TextType) {
  const styles: any = {
    muted: {
      fontFamily: 'StyreneRegular',
      color: color || '#292929',
      fontSize: fontSize || '12px',
      opacity: '0.65',
    },
    strong: {
      fontFamily: 'BookmaniaBold',
      color: color || '#292929',
      fontSize: fontSize || '20px',
    },
    medium: {
      fontFamily: 'StyreneMedium',
      color: color || '#292929',
      fontSize: fontSize || '14px',
    },
    regular: {
      fontFamily: 'StyreneRegular',
      color: color || '#292929',
      fontSize: fontSize || '14px',
    },
    blue: {
      fontFamily: 'StyreneMedium',
      fontSize: fontSize || '12px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: 'var(--blue)',
    },
    sectionName: {
      fontFamily: 'StyreneA',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: fontSize || '14px',
      lineHeight: '16px',
      letterSpacing: '0.25px',
      color: color || '#292929',
      opacity: '0.65',
    },
    error: {
      fontSize: fontSize || '10px',
      color: 'var(--red)',
      fontFamily: 'StyreneRegular',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Typography className={classes[type]} {...props}>
      {children}
    </Typography>
  )
}
