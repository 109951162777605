import axios from 'axios'
import { sendDownloadEvent } from '../services/appServices'
import { triggerDownload } from '../utils/utils'
import { useRequestContext } from '../context/RequestContext'
import JSZip from 'jszip'
import { useListingDescriptionContext } from '../context/ListingDescriptionContext'
import {
  renderPdfInBackend,
  renderPngInBackend,
} from '../modules/shared/utils/svgRtrConverters'

export default function useDownload(
  url,
  shouldBuildBeforeDownload = false,
  auxiliaryImageUrls = [],
  renderType = 'png',
  { width, height } = {},
) {
  const { isEmbedded } = useRequestContext()
  const { listingDescription: listingDescriptionCtx } =
    useListingDescriptionContext()
  const desktopDownload = async (urls) => {
    const zip = new JSZip()
    const folder = zip.folder(listingDescriptionCtx?.headline ?? 'images')
    let imageIndex = 0
    try {
      for (const url of urls) {
        imageIndex++
        await axios({
          url,
          responseType: 'arraybuffer',
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }).then(({ data }) => {
          folder.file(imageIndex + '_photo.png', data)
        })
      }

      const content = await zip.generateAsync({ type: 'blob' })
      await triggerDownload(content, 'avenue8_posts.zip')
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeviceDownload = async (urlToDownload) => {
    if (isEmbedded) {
      await sendDownloadEvent([urlToDownload, ...auxiliaryImageUrls])
    } else {
      await desktopDownload([urlToDownload, ...auxiliaryImageUrls])
    }
  }

  const handleDownload = async (w, h) => {
    if (shouldBuildBeforeDownload) {
      // Digital assets already come as SVG
      const isSvg = typeof url === 'object'

      let svg = url

      if (!isSvg) {
        const data = await fetch(url).then((r) => r.text())
        svg = new DOMParser().parseFromString(data, 'image/svg+xml')
      }

      const image = svg.cloneNode(true)
      // Pre-generation image adjustment

      image.setAttribute('width', w ?? '100%')
      image.setAttribute('height', h ?? '100%')

      const renderPromise =
        renderType === 'pdf'
          ? renderPdfInBackend(image, {
              width: width ?? w,
              height: height ?? h,
            })
          : renderPngInBackend(image)

      const result = await renderPromise

      await handleDeviceDownload(result.base64encoded)
    } else {
      await handleDeviceDownload(url)
    }
  }
  return [handleDownload]
}
