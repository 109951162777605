import React from 'react'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'
import InformationInput from '../custom/InformationInput'

export default function OptionsPresentation({ options }) {
  return (
    Object.keys(options).length > 0 ? (
      <Grid style={{ marginTop: 20 }}>
        {Object.values(options)
          .filter((data) => data?.value && data?.visible !== false)
          .map((data, index) => {
            const value =
              data.type === 'radio' || data.type === 'select'
                ? data.label
                : data.type === 'datepicker'
                ? format(data.value, 'MM/dd/yyyy')
                : data.formattedValue || data.value

            return (
              <InformationInput
                label={data.optionLabel || data.label}
                information={value}
                key={data.label + data.value + index}
              />
            )
          })}
      </Grid>
    ) : null
  )
}
