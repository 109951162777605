import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'
import MarketingRequestLandingPage from '../pages/MarketingRequestLandingPage'
import PropertySelection from '../pages/PropertySelection'
import StageSelection from '../pages/StageSelection'
import ListingDescription from '../pages/ListingDescription'
import ContentGrid from '../pages/ContentGridPage'
import SignageNeed from '../pages/SignageNeedPage'
import InstallRemoveSignage from '../pages/InstallRemoveSignage'
import OutdoorDetails from '../pages/OutdoorDetailsPage'
import RequestReceived from '../pages/RequestReceived'
import PackageDetailsWrapper from '../pages/PackageDetailsWrapper'
import UnauthorizedPage from '../pages/UnauthorizedPage'
import ForMeMarketing from '../pages/ForMeMarketing'
import ReviewSignageRequest from '../pages/ReviewSignageRequest'
import EmailBlastWrapper from '../pages/EmailBlastWrapper.js'
import { ROUTE_PREFIX as prefix } from '../utils/constants'
import { VisualMarketingReviewRequestPage } from '../modules/marketing/pages/VisualMarketingReviewRequest.page'

const addPrefix = (path) => `${prefix}${path}`

export default function Routes() {
  return (
    <Switch>
      <Route
        path={addPrefix('/marketing')}
        exact
        component={MarketingRequestLandingPage}
      />
      <Route
        confirmLeave
        path={addPrefix('/:flow/forme')}
        exact
        component={ForMeMarketing}
      />
      <Route
        path={addPrefix('/:flow/:subflow/propertyselection')}
        exact
        component={PropertySelection}
      />
      <Route
        confirmLeave
        path={addPrefix('/:flow/:subflow/:requestId/:action/emailblast')}
        exact
        component={EmailBlastWrapper}
      />
      <Route
        confirmLeave
        path={addPrefix('/:flow/:subflow/stageselection')}
        exact
        component={StageSelection}
      />
      <Route
        confirmLeave
        path={addPrefix('/:flow/:subflow/:requestTypePath/listingdescription')}
        exact
        component={ListingDescription}
      />
      <Route
        confirmLeave
        path={addPrefix(
          '/:flow/:subflow/:requestTypePath/:packageType/package',
        )}
        exact
        component={PackageDetailsWrapper}
      />

      <Route path={addPrefix('/content')} exact component={ContentGrid} />

      <Route path={addPrefix('/signage')} exact component={SignageNeed} />
      <Route
        confirmLeave
        path={addPrefix('/:flow/:subflow/installsignage')}
        exact
        component={InstallRemoveSignage}
      />
      <Route
        confirmLeave
        path={addPrefix('/:flow/:subflow/outdoordetails')}
        exact
        component={OutdoorDetails}
      />

      <Route
        confirmLeave
        flowType={'signage'}
        path={addPrefix(
          '/:flow/:subflow/:requestTypePath/:packageType?/reviewsignagerequest',
        )}
        exact
        component={ReviewSignageRequest}
      />

      <Route
        confirmLeave
        flowType={'marketing'}
        path={addPrefix(
          '/marketing/:subflow/:requestTypePath/:packageType?/reviewrequest',
        )}
        exact
        component={VisualMarketingReviewRequestPage}
      />
      <Route
        path={addPrefix(
          '/:flow/:subflow/:requestTypePath/:packageType?/requestreceived',
        )}
        exact
        component={RequestReceived}
      />
      <Route isPublic path='/unauthorized' component={UnauthorizedPage} />
      <Route
        isPublic
        path='/'
        component={() => <h1>This page does not exist.</h1>}
      />
    </Switch>
  )
}
