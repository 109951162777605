import { makeStyles } from '@material-ui/styles'

export const ImportFromGalleryStyles = makeStyles(() => ({
    container: {
        padding: '15px 12px',
        height: '100%',
        minHeight: '600px',  
    },
    imageGrid: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridGap: '30px 10px',
        marginBottom: 20,
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    selectableImageContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        width: '125px',
    },
    loadMoreButton: {
        width: '100%',
        backgroundColor: '#ECEDF1',
        borderStyle: 'none',
        color: '#979797',
    },
}))
