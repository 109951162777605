import React from 'react'

import {
  ImageSelector,
  ImageSelectorProps,
} from '../../../shared/components/ImageSelector/ImageSelector'

export type ImageLogoSelector = ImageSelectorProps & { data: any }

export type Ref = HTMLDivElement

export const ImageLogoSelector = (props: ImageLogoSelector) => {
  const { label, previewImage, onImageChange, images } = props

  return (
    <>
      <ImageSelector
        name={'Logos on File:'}
        label={label}
        images={images}
        previewImage={previewImage}
        onImageChange={onImageChange}
        ignoreCrop={true}
        isLogoSelector={true}
        importFromGalleryLabel={'Import from your logos'}
        importingType={'Logo'}
        singleAction
        {...props.data}
      />
    </>
  )
}
