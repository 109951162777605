import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import ReorderIcon from '@material-ui/icons/Reorder'
import DevicesIcon from '@material-ui/icons/Devices'

import Text from '../../common/Text'
import MenuDropdown from '../../common/MenuDropdown'
import ImportFromGalleryModal from './ImportFromGalleryModal'
import {useListingMlsImagesContext} from '../../../context/ListingMlsImagesContext'
import { IMPORTING_TYPES } from '../../../utils/constants'
import { CropImageModal } from '../../../modules/marketing/components/CropImageModal/CropImageModal'

const styles = {
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(255,255,255, 0.5)',
    borderRadius: 5,
    alignItems: 'center',
    cursor: 'move',
    padding: '0 8px',
    height: 70,
  },
  leftItemContainer: {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
  },
  draggableImage: {
    width: '72px',
    height: '46px',
    pointerEvents: 'none',
    borderRadius: 5,
  },
  successful: {
    fontSize: '12px',
    marginTop: '8px',
    fontFamily: 'StyreneRegular',
    color: '#12B777',
  },
}
const useStyles = makeStyles(styles)

export default function ImageSelectorItem({
  isLogoSelector = false,
  image,
  count,
  imgMaxQuantity,
  setImagesArray,
  imagesArray,
  gallery,
}) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [imageCrop, setImageCrop] = useState(null)
  const [successfulLabel, setSuccessfulLabel] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const getBase64 = (file) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const changeHandler = (event) => {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file)
    const promise = getBase64(file)
    let base64 = ''

    promise.then(function (result) {
      if (result) {
        base64 = result
      }
      let updatedImages = [...imagesArray]
      if (image) {
        updatedImages = imagesArray.map((_image) => {
          if (_image.id !== image?.id) {
            return _image
          }
          return {
            ..._image,
            type: 'file',
            name: file.name,
            image: file,
            url,
            base64,
          }
        })
      } else {
        updatedImages.push({
          id: uuidv4(),
          type: 'file',
          name: file.name,
          image: file,
          url,
          base64,
        })
      }

      setImageCrop({ ...updatedImages[indexImage] })
      handleOpen()
    })
  }

  const indexImage = count - 1

  const onSave = (newImagesArray) => {
    setImageCrop({ name: 'placeHolder', ...newImagesArray[indexImage] })
    handleOpen()
  }

  const cropHandler = (crop) => {
    const base64 = crop.previewUrl

    let updatedImages = [...imagesArray]
    if (image) {
      updatedImages = imagesArray.map((_image, index) => {
        if (index !== indexImage) {
          return _image
        }
        return {
          ..._image,
          type: 'file',
          name: imageCrop.name,
          url: base64,
          base64,
        }
      })
    } else {
      updatedImages.push({
        id: uuidv4(),
        type: 'file',
        name: imageCrop.name,
        url: base64,
        base64,
      })
    }

    setImagesArray(updatedImages)
    setSuccessfulLabel(true)
    setTimeout(() => setSuccessfulLabel(false), 3000)
  }

  return (
    <>
      <Grid style={{ margin: '7.5px 0' }}>
        <Text type="muted">{imgMaxQuantity > 1 ? `Photo ${count}` : ''} </Text>
        <Grid className={classes.itemContainer}>
          <Grid className={classes.leftItemContainer}>
            <Grid style={{ marginRight: 10, color: 'gray' }}>
              {imgMaxQuantity > 1 && <ReorderIcon />}
            </Grid>
            {image && image.name !== 'No Logo' ? (
              <img
                src={image.baseUrl || image.url}
                alt={''}
                className={classes.draggableImage}
              />
            ) : (
              <Text type="muted">
                {isLogoSelector ? 'No Logo Selected' : 'No Image Selected'}
              </Text>
            )}
          </Grid>
          <Grid className={classes.buttonContainer}>
            {isLogoSelector ? (
              <ImportFromGalleryModal
                gallery={gallery}
                setImagesArray={setImagesArray}
                imagesArray={imagesArray}
                imgMaxQuantity={1}
                image={image}
                importingType={IMPORTING_TYPES.Logo}
              />
            ) : (
              <>
                <ImageSelectorDropDown
                  isLogoSelector={isLogoSelector}
                  changeHandler={changeHandler}
                  setImagesArray={() => {}}
                  imagesArray={imagesArray}
                  imgMaxQuantity={imgMaxQuantity}
                  image={image}
                  gallery={gallery}
                  onSave={onSave}
                />
                {imageCrop && (
                  <CropImageModal
                    title={'Crop Image'}
                    description={'Please your image'}
                    image={imageCrop}
                    handleUpdateCrop={cropHandler}
                    open={open}
                    setOpen={setOpen}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
        {successfulLabel && (
          <p className={classes.successful}>Successfully Updated!</p>
        )}
      </Grid>
    </>
  )
}

const ImageSelectorDropDown = ({
  isLogoSelector,
  changeHandler,
  setImagesArray,
  imagesArray,
  imgMaxQuantity,
  image,
  onSave,
}) => {
  const { listingMlsImages } = useListingMlsImagesContext()

  return (
    <MenuDropdown
      title={isLogoSelector ? 'Add' : image ? 'Change' : 'Add'}
      showIcon={!isLogoSelector}
    >
      <MenuItem
        onClick={() => {}}
        style={{
          borderBottom: '1px solid #D8D8D8',
        }}
      >
        <label style={{ cursor: 'pointer' }}>
          <Grid container alignItems="center">
            <input
              type="file"
              name="file"
              id="file"
              accept="image/*"
              onChange={changeHandler}
              style={{ display: 'none' }}
            />
            <DevicesIcon
              style={{
                fontSize: 24,
                marginRight: 10,
              }}
            />
            Upload from Device
          </Grid>
        </label>
      </MenuItem>
      <MenuItem onClick={() => {}}>
        <ImportFromGalleryModal
          gallery={listingMlsImages}
          setImagesArray={setImagesArray}
          imagesArray={imagesArray}
          imgMaxQuantity={imgMaxQuantity}
          image={image}
          onSave={onSave}
        />
      </MenuItem>
    </MenuDropdown>
  )
}