import React, { createContext, useContext, useState } from 'react'
import { useAuthContext } from '../context/AuthContext'
import { Image as ImageType } from '../modules/marketing/components/ImportFromGalleryModal/ImportFromGalleryModal'
import { getAgentLogos } from '../services/agentServices'

// const noLogoImage: ImageType = {
//   id: '-1',
//   name: 'No Logo',
//   url: NoLogoImg,
// }

export type AgentDataContextType = {
  fetchAgentLogos?: () => Promise<void>
  agentLogos: ImageType[]
}

export const AgentDataContext = createContext<AgentDataContextType>({
  agentLogos: [],
})

export const useAgentDataContext = () =>
  useContext<AgentDataContextType>(AgentDataContext)

export const AgentDataContextProvider: React.FC = ({ children }) => {
  const { agentId } = useAuthContext() as any

  const [agentLogos, setAgentLogos] = useState<ImageType[]>([])
  const fetchAgentLogos = async () => {
    const { data } = await getAgentLogos(agentId)

    if (!data) return

    setAgentLogos(data)

    return data.length
  }

  return (
    <AgentDataContext.Provider
      value={{
        fetchAgentLogos,
        agentLogos,
      }}
    >
      {children}
    </AgentDataContext.Provider>
  )
}
