import packageJson from '../package.json'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  getIsEmbedded,
  getAgentId,
} from './components/custom/AppInitialization'

const debugSetup =
  process.env.NODE_ENV === 'production'
    ? {}
    : {
        denyUrls: [/localhost/],
        debug: true,
      }

export function initSentry() {
  console.log(`Sentry initied in ${process.env.NODE_ENV} mode.`)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: packageJson.version,
    environment: process.env.NODE_ENV,
    ...debugSetup,
    beforeSend: (event) => {
      const isEmbedded = getIsEmbedded()
      const agentId = getAgentId()
      event.tags = event.tags || {}
      event.tags.embed = isEmbedded
      if (agentId) event.user = { id: `agent-${agentId}` }
      return event
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
  })
}
