import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import PagePresentation from '../components/common/PagePresentation'
import CustomGridContainer from '../components/common/CustomGridContainer'
import Layout from '../components/common/Layout.js'
import StepCounter from '../components/common/StepCounter'
import SectionList from '../components/common/SectionList'
import Loading from '../components/common/Loading'
import ErrorMsg from '../components/common/ErrorMsg'
import { getAgentSignages } from '../services/contentServices'
import { useAuthContext } from '../context/AuthContext'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useSignageContext } from '../context/SignageContext'
import { useSignageStore } from '../modules/signage/store/signage.store'

const styles = (theme) => ({
  stepContainer: {
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  errorDiv: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
})

const useStyles = makeStyles(styles)

export default function OutdoorDetailsPage() {
  const classes = useStyles()
  const history = useHistoryPrefix()
  const { flow, subflow } = useParams()
  const { outdoorSignageData, setOutdoorSignageData } = useSignageContext()
  const { agentId } = useAuthContext()
  const signsStore = useSignageStore((store) => store.signs)

  const [showPage, setShowPage] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({ error: false, errorMsg: '' })
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)

  useEffect(() => {
    const loadPageData = async () => {
      const response = await getAgentSignages(agentId)
      if (response.success) {
        setIsLoading(false)
        setOutdoorSignageData(response.data)
        setShowPage(true)
      } else {
        setIsLoading(false)
        setError({ error: true, errorMsg: 'Oops, something went wrong...' })
      }
    }
    if (Object.keys(outdoorSignageData).length > 0) {
      setIsLoading(false)
      setShowPage(true)
      return
    }
    loadPageData()
  }, [])

  useEffect(() => {
    const isAtLeastOneSignFilled = Object.values(signsStore).some(exist => exist)
    setIsNextButtonDisabled(!isAtLeastOneSignFilled)
  }, [outdoorSignageData, signsStore])

  const handleSubmit = () => {
    history.push([flow, subflow, 'new-installation/reviewsignagerequest'].join('/'))
  }

  return (
    <Layout>
      {isLoading && <Loading />}
      {error.error && (
        <Grid style={{ padding: 20 }}>
          <ErrorMsg />
        </Grid>
      )}
      {showPage && (
        <>
          <CustomGridContainer noMinHeight>
            <Grid className={classes.stepContainer}>
              <StepCounter
                numberOfSteps={3}
                activeStep={2}
                handleNext={handleSubmit}
                isNextButtonDisabled={isNextButtonDisabled}
              />
            </Grid>
            <Grid
              item
              style={{
                maxWidth: 375,
                alignSelf: 'center',
                textAlign: 'center',
                marginBottom: 15,
              }}
            >
              <PagePresentation
                CTATextBold={'Outdoor'}
                CTATextItalic1={'Signage'}
                CTATextItalic2={'Details'}
                component={'span'}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer isFullScreen>
            {outdoorSignageData.length > 0 ? (
              <SectionList
                sectionsData={outdoorSignageData}
                packageType={'outdoor'}
              />
            ) : (
              <div className={classes.errorDiv}>
                <ErrorMsg
                  errorMsg={"Hmm, seems like you don't have any signs yet!"}
                  subErrorMsg="Please get in touch with us."
                />
              </div>
            )}
          </CustomGridContainer>
          <CustomGridContainer noMinHeight>
            <StepCounter
              showOnlyNavButtons
              handleNext={handleSubmit}
              isNextButtonDisabled={isNextButtonDisabled}
            />
          </CustomGridContainer>
        </>
      )}
    </Layout>
  )
}
