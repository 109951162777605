// For every page, loadPageInfo should return the texts and style information for its main components
// Example responses
const fakeLandingScreenResponse = (params) => {
  return {
    userId: params.userId,
    menuText: 'Marketing Request',
    CTATextUpper: 'What are your',
    CTATextLower: 'marketing needs?',
    buttons: {
      button1: { text: 'For Me' },
      button2: { text: 'Market a Listing' },
      button3: { text: 'Other' },
    },
  }
}
const fakeDigitalPackageScreenResponse = (params) => {
  return {
    userId: params.userId,
    propertyText: '1234 Church St, Unit 4, San Francisco CA 941117',
    menuText: 'Market a Listing',
    stepButtons: {
      button1: { text: 'Back' },
      button2: { text: 'Next' },
    },
    CTATextBold: 'Digital',
    CTATextItalic1: 'Package',
    CTATextItalic2: 'Details',
    CTADescription: 'You may select more than one of any categories below',
    data: [
      {
        packageType: 'digital',
        listingStage: 'coming_soon',
        templateCategory: 'social-media',
        displayName: 'Social Media',
        displaySubtitle: 'Facebook and Instagram',
        images: [
          {
            itemId: 1,
            name: 'Coming Soon #1',
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_1_538b3f6d56.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'large_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #1.jpg',
                path: null,
                size: 171.27,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'small_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #1.jpg',
                path: null,
                size: 56.24,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'medium_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #1.jpg',
                path: null,
                size: 111.26,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'thumbnail_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #1.jpg',
                path: null,
                size: 7.56,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #2',
            itemId: 2,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_2_4c4724825f.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_2_4c4724825f.jpg',
                hash: 'large_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #2.jpg',
                path: null,
                size: 186.3,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_2_4c4724825f.jpg',
                hash: 'small_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #2.jpg',
                path: null,
                size: 57.63,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_2_4c4724825f.jpg',
                hash: 'medium_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #2.jpg',
                path: null,
                size: 115.94,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_2_4c4724825f.jpg',
                hash: 'thumbnail_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #2.jpg',
                path: null,
                size: 7.8,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #3',
            itemId: 3,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_3_35fbf565d6.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'large_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #3.jpg',
                path: null,
                size: 180.78,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'small_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #3.jpg',
                path: null,
                size: 57.49,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'medium_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #3.jpg',
                path: null,
                size: 116.39,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'thumbnail_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #3.jpg',
                path: null,
                size: 7.55,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #4',
            itemId: 4,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_4_b0f1ccabd9.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'large_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'large_Coming Soon #4.png',
                path: null,
                size: 1518.71,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'small_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'small_Coming Soon #4.png',
                path: null,
                size: 416.62,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'medium_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'medium_Coming Soon #4.png',
                path: null,
                size: 888.44,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'thumbnail_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'thumbnail_Coming Soon #4.png',
                path: null,
                size: 49.01,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Email Coming Soon.png',
            itemId: 5,
            url:
              'https://staging.content-grid-media.avenue8.com/Email_Coming_Soon_589661221b.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Email_Coming_Soon_589661221b.png',
                hash: 'large_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'large_Email Coming Soon.png',
                path: null,
                size: 818.26,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Email_Coming_Soon_589661221b.png',
                hash: 'small_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'small_Email Coming Soon.png',
                path: null,
                size: 234.48,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Email_Coming_Soon_589661221b.png',
                hash: 'medium_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'medium_Email Coming Soon.png',
                path: null,
                size: 490.66,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Email_Coming_Soon_589661221b.png',
                hash: 'thumbnail_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'thumbnail_Email Coming Soon.png',
                path: null,
                size: 29.71,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
      {
        packageType: 'digital',
        listingStage: 'coming_soon',
        templateCategory: 'display-ads',
        displayName: 'Display Ads',
        displaySubtitle: 'Ad Networks',
        images: [
          {
            name: 'Display ad Coming soon',
            itemId: 6,
            url:
              'https://staging.content-grid-media.avenue8.com/Display_ad_Coming_soon_8903f82fba.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'large_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'large_Display ad Coming soon.png',
                path: null,
                size: 907.16,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'small_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'small_Display ad Coming soon.png',
                path: null,
                size: 252.84,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'medium_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'medium_Display ad Coming soon.png',
                path: null,
                size: 535.35,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'thumbnail_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'thumbnail_Display ad Coming soon.png',
                path: null,
                size: 31.28,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
      {
        packageType: 'digital',
        listingStage: 'coming_soon',
        templateCategory: 'email-blast',
        displayName: 'Email Blast',
        images: [
          {
            name: 'Email Coming Soon',
            itemId: 7,
            url:
              'https://staging.content-grid-media.avenue8.com/Email_Coming_Soon_589661221b.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Email_Coming_Soon_589661221b.png',
                hash: 'large_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'large_Email Coming Soon.png',
                path: null,
                size: 818.26,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Email_Coming_Soon_589661221b.png',
                hash: 'small_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'small_Email Coming Soon.png',
                path: null,
                size: 234.48,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Email_Coming_Soon_589661221b.png',
                hash: 'medium_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'medium_Email Coming Soon.png',
                path: null,
                size: 490.66,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Email_Coming_Soon_589661221b.png',
                hash: 'thumbnail_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'thumbnail_Email Coming Soon.png',
                path: null,
                size: 29.71,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
    ],
  }
}

const fakePrintPackageScreenResponse = () => {
  return {
    success: true,
    data: [
      {
        packageType: 'print',
        listingStage: 'coming_soon',
        templateCategory: 'social-media',
        displayName: 'Postcard - For Sale',
        images: [
          {
            name: 'Coming Soon #2222',
            itemId: 1,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_1_538b3f6d56.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'large_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #1.jpg',
                path: null,
                size: 171.27,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'small_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #1.jpg',
                path: null,
                size: 56.24,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'medium_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #1.jpg',
                path: null,
                size: 111.26,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_1_538b3f6d56.jpg',
                hash: 'thumbnail_Coming_Soon_1_538b3f6d56',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #1.jpg',
                path: null,
                size: 7.56,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #2',
            itemId: 2,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_2_4c4724825f.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_2_4c4724825f.jpg',
                hash: 'large_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #2.jpg',
                path: null,
                size: 186.3,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_2_4c4724825f.jpg',
                hash: 'small_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #2.jpg',
                path: null,
                size: 57.63,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_2_4c4724825f.jpg',
                hash: 'medium_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #2.jpg',
                path: null,
                size: 115.94,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_2_4c4724825f.jpg',
                hash: 'thumbnail_Coming_Soon_2_4c4724825f',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #2.jpg',
                path: null,
                size: 7.8,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #3',
            itemId: 3,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_3_35fbf565d6.jpg',
            formats: {
              large: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'large_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'large_Coming Soon #3.jpg',
                path: null,
                size: 180.78,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'small_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'small_Coming Soon #3.jpg',
                path: null,
                size: 57.49,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'medium_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'medium_Coming Soon #3.jpg',
                path: null,
                size: 116.39,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.jpg',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_3_35fbf565d6.jpg',
                hash: 'thumbnail_Coming_Soon_3_35fbf565d6',
                mime: 'image/jpeg',
                name: 'thumbnail_Coming Soon #3.jpg',
                path: null,
                size: 7.55,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Coming Soon #4',
            itemId: 4,
            url:
              'https://staging.content-grid-media.avenue8.com/Coming_Soon_4_b0f1ccabd9.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'large_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'large_Coming Soon #4.png',
                path: null,
                size: 1518.71,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'small_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'small_Coming Soon #4.png',
                path: null,
                size: 416.62,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'medium_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'medium_Coming Soon #4.png',
                path: null,
                size: 888.44,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Coming_Soon_4_b0f1ccabd9.png',
                hash: 'thumbnail_Coming_Soon_4_b0f1ccabd9',
                mime: 'image/png',
                name: 'thumbnail_Coming Soon #4.png',
                path: null,
                size: 49.01,
                width: 156,
                height: 156,
              },
            },
          },
          {
            name: 'Email Coming Soon.png',
            itemId: 5,
            url:
              'https://staging.content-grid-media.avenue8.com/Email_Coming_Soon_589661221b.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Email_Coming_Soon_589661221b.png',
                hash: 'large_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'large_Email Coming Soon.png',
                path: null,
                size: 818.26,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Email_Coming_Soon_589661221b.png',
                hash: 'small_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'small_Email Coming Soon.png',
                path: null,
                size: 234.48,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Email_Coming_Soon_589661221b.png',
                hash: 'medium_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'medium_Email Coming Soon.png',
                path: null,
                size: 490.66,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Email_Coming_Soon_589661221b.png',
                hash: 'thumbnail_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'thumbnail_Email Coming Soon.png',
                path: null,
                size: 29.71,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
      {
        packageType: 'print',
        listingStage: 'coming_soon',
        templateCategory: 'display-ads',
        displayName: 'Double-Sided Flyer - For Sale',
        images: [
          {
            name: 'Display ad Coming soon',
            itemId: 6,
            url:
              'https://staging.content-grid-media.avenue8.com/Display_ad_Coming_soon_8903f82fba.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'large_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'large_Display ad Coming soon.png',
                path: null,
                size: 907.16,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'small_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'small_Display ad Coming soon.png',
                path: null,
                size: 252.84,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'medium_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'medium_Display ad Coming soon.png',
                path: null,
                size: 535.35,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Display_ad_Coming_soon_8903f82fba.png',
                hash: 'thumbnail_Display_ad_Coming_soon_8903f82fba',
                mime: 'image/png',
                name: 'thumbnail_Display ad Coming soon.png',
                path: null,
                size: 31.28,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
      {
        packageType: 'print',
        listingStage: 'coming_soon',
        templateCategory: 'email-blast',
        displayName: 'Bi-Fold Brochure - For Sale',
        images: [
          {
            name: 'Email Coming Soon',
            itemId: 7,
            url:
              'https://staging.content-grid-media.avenue8.com/Email_Coming_Soon_589661221b.png',
            formats: {
              large: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/large_Email_Coming_Soon_589661221b.png',
                hash: 'large_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'large_Email Coming Soon.png',
                path: null,
                size: 818.26,
                width: 1000,
                height: 1000,
              },
              small: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/small_Email_Coming_Soon_589661221b.png',
                hash: 'small_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'small_Email Coming Soon.png',
                path: null,
                size: 234.48,
                width: 500,
                height: 500,
              },
              medium: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/medium_Email_Coming_Soon_589661221b.png',
                hash: 'medium_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'medium_Email Coming Soon.png',
                path: null,
                size: 490.66,
                width: 750,
                height: 750,
              },
              thumbnail: {
                ext: '.png',
                url:
                  'https://staging.content-grid-media.avenue8.com/thumbnail_Email_Coming_Soon_589661221b.png',
                hash: 'thumbnail_Email_Coming_Soon_589661221b',
                mime: 'image/png',
                name: 'thumbnail_Email Coming Soon.png',
                path: null,
                size: 29.71,
                width: 156,
                height: 156,
              },
            },
          },
        ],
      },
    ],
  }
}

const fakeListingSelectionScreenResponse = () => {
  return {
    menuText: 'Market a Listing',
    CTATextUpper: 'Property',
    CTATextLower: 'Selection',
    radios: [
      '1234 Church St, Unit 4, San Francisco CA 94117',
      '1234 McArthur Blvd, Oakland CA 94610',
      '123 E. 1st St, San Jose CA 94610',
    ],
  }
}
const fakeStageSelectionScreenResponse = () => {
  return {
    success: true,
    data: [
      {
        type: 'radio',
        label: 'Property Stage',
        name: 'propertyStage',
        required: true,
        options: [
          {
            label: 'Coming Soon',
            value: 'coming-soon',
            metadata: { packages: ['digital'] },
          },
          {
            label: 'Just Listed',
            value: 'just-listed',
            metadata: { packages: ['digital'] },
          },
          {
            label: 'For Sale',
            value: 'for-sale',
            metadata: { packages: ['digital', 'print'] },
          },
          {
            label: 'In Contract',
            value: 'inconcract',
            metadata: { packages: ['digital', 'print'] },
          },
          {
            label: 'Pending',
            value: 'pending',
            metadata: { packages: ['digital', 'print'] },
          },
          {
            label: 'Just Sold',
            value: 'just-sold',
            metadata: { packages: ['digital', 'print'] },
          },
        ],
      },
    ],
  }
}

const s3BaseUrl = 'https://avenue8-content-grid-test.s3.us-east-2.amazonaws.com'

const fakeContentGridScreenResponse = () => [
  {
    Category: 'Your Marketing',
    posts: [
      {
        id: 8,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 5,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 4,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 55,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 81,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 890,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 801,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 501,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 400,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 112,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 101,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
    ],
  },
  {
    Category: 'Avenue 8 Brand',
    categoryId: 2,
    posts: [
      {
        id: 51,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'This is a video 🔥',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: 'https://i.temp.media/video/180-x-180-10.mp4',
            type: 'video',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 8,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 5,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 4,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 55,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 81,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 890,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 801,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description first post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/1.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/8.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/The+Shelf+-+Dec+2020/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 501,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+LA+Nov+23-29/9.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 400,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
      {
        id: 112,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27SignageNeedScreen:09.213Z',
          },
        ],
      },
    ],
  },
  {
    Category: 'Culture',
    posts: [
      {
        id: 60,
        expiry_datetime: '2020-12-31T15:00:00.000Z',
        go_live_datetime: '2020-12-01T15:00:00.000Z',
        active: true,
        times_downloaded: null,
        short_description: 'Some Simple Description',
        full_description: 'Complete description, second post',
        published_at: '2020-12-16T05:31:17.042Z',
        updated_at: '2020-12-16T05:42:28.294Z',
        ContentGridMedia: [
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/2.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/3.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/4.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/5.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/6.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/7.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/9.png`,
            type: 'image',
          },
          {
            Media: `${s3BaseUrl}/TOP+8+SF+Nov+23-29/10.png`,
            type: 'image',
          },
        ],
        content_grid_agent_groups: [
          {
            GroupName: 'SomeGroup',
            published_at: '2020-12-16T05:27:32.810Z',
            updated_at: '2020-12-16T05:27:32.830Z',
          },
          {
            GroupName: 'SomeOtherGroup',
            published_at: '2020-12-16T05:27:40.191Z',
            updated_at: '2020-12-16T05:27:40.211Z',
          },
        ],
        content_grid_tags: [
          {
            Name: 'FunStuff',
            Category: 'interests',
            published_at: '2020-12-16T05:26:51.033Z',
            updated_at: '2020-12-16T05:26:51.063Z',
          },
        ],
        content_grid_target_regions: [
          {
            ShortCode: 'BA',
            Region: 'Bay Area',
            published_at: '2020-12-16T05:26:59.984Z',
            updated_at: '2020-12-16T05:27:00.012Z',
          },
          {
            ShortCode: 'SF',
            Region: 'San Francisco',
            published_at: '2020-12-16T05:27:09.178Z',
            updated_at: '2020-12-16T05:27:09.213Z',
          },
        ],
      },
    ],
  },
]

const signageNeedScreenResponse = () => {
  return {
    success: true,
    data: {
      CTATextBold: 'What are your',
      CTATextItalic: 'signage needs?',
      CTACard1: 'New Sign',
      CTASubCard1: 'Order and request delivery or installation of a new sign.',
      CTACard2: 'Existing Sign',
      CTASubCard2: 'Request the installation or removal of an existing sign.',
    },
  }
}

const signageInstallRemoveScreenResponse = () => {
  return {
    success: true,
    data: {
      CTATextBold: 'Are you',
      CTATextItalic: ' installing or removing?',
      radios: [
        { label: 'Installing', value: 're-installation' },
        { label: 'Removing', value: 'removal' },
      ],
    },
  }
}

const reviewSignageRequestResponse = () => {
  return {
    success: true,
    data: {
      CTATextBold: 'Review your',
      CTATextItalic: '\ninstalling signage\nrequest?',
      options: [
        {
          type: 'information',
          label: 'Cost estimate',
          name: 'costEstimate',
          information: '$50 - $100',
        },
        {
          type: 'information',
          label: 'Installation address',
          name: 'installationAddress',
          information: '1234 Church St, Unit 4, San Francisco CA 94117',
        },
        {
          type: 'radio',
          label: 'Property Type',
          name: 'propertyType',
          options: [
            {
              label: 'Residential',
              value: 'residential',
            },
            { label: 'Commercial', value: 'commercial' },
          ],
        },
        {
          type: 'radio',
          label: 'Include sign removal',
          name: 'signRemoval',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            { label: 'No', value: false },
          ],
        },
        {
          type: 'radio',
          label: 'Store signs with vendor',
          name: 'storeWithVendor',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            { label: 'No', value: false },
          ],
        },
        {
          type: 'input',
          label: 'Cross streets',
          name: 'crossStreets',
          placeholder: 'e.g Market & Van Ness',
        },
        {
          type: 'datepicker',
          label: 'Installation date',
          name: 'installationDate',
          fieldName: 'installationDate',
          placeholder: 'MM/DD/YYYY',
        },
        {
          type: 'select',
          label: 'Sign Type',
          name: 'signType',
          options: [
            {
              label: 'Sign Post Only',
              value: 'signOnly',
            },
            {
              label: 'Not Sign Post Only',
              value: 'mockValue',
            },
          ],
        },
        {
          type: 'select',
          label: 'Placement location',
          name: 'placementLocation',
          options: [
            {
              label: 'Front of Home',
              value: 'mock1',
            },
            {
              label: 'Not Front of Home',
              value: 'mock2',
            },
          ],
        },
        {
          type: 'textarea',
          label: '',
          name: 'additionalNotes',
          placeholder: 'Add any additional notes...',
        },
      ],
    },
  }
}

const outdoorSignageDetailsRequestResponse = () => {
  return {
    success: true,
    data: {
      signs: [
        {
          type: 'listing',
          displayName: 'Listing Sign',
          images: [
            {
              signId: 1,
              displayOrder: 1,
              formats: {
                large: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/large_Ben_Russell_L3_96a8fa2cbe.png',
                  hash: 'large_Ben_Russell_L3_96a8fa2cbe',
                  mime: 'image/png',
                  name: 'large_Ben Russell L3.png',
                  path: null,
                  size: 107.13,
                  width: 1000,
                  height: 1000,
                },
                small: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/small_Ben_Russell_L3_96a8fa2cbe.png',
                  hash: 'small_Ben_Russell_L3_96a8fa2cbe',
                  mime: 'image/png',
                  name: 'small_Ben Russell L3.png',
                  path: null,
                  size: 44.3,
                  width: 500,
                  height: 500,
                },
                medium: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/medium_Ben_Russell_L3_96a8fa2cbe.png',
                  hash: 'medium_Ben_Russell_L3_96a8fa2cbe',
                  mime: 'image/png',
                  name: 'medium_Ben Russell L3.png',
                  path: null,
                  size: 73.11,
                  width: 750,
                  height: 750,
                },
                thumbnail: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/thumbnail_Ben_Russell_L3_96a8fa2cbe.png',
                  hash: 'thumbnail_Ben_Russell_L3_96a8fa2cbe',
                  mime: 'image/png',
                  name: 'thumbnail_Ben Russell L3.png',
                  path: null,
                  size: 10.79,
                  width: 156,
                  height: 156,
                },
              },
            },
            {
              signId: 5,
              displayOrder: 2,
              formats: {
                large: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/large_Ben_Russell_L1_82781372df.png',
                  hash: 'large_Ben_Russell_L1_82781372df',
                  mime: 'image/png',
                  name: 'large_Ben Russell L1.png',
                  path: null,
                  size: 85.41,
                  width: 1000,
                  height: 1000,
                },
                small: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/small_Ben_Russell_L1_82781372df.png',
                  hash: 'small_Ben_Russell_L1_82781372df',
                  mime: 'image/png',
                  name: 'small_Ben Russell L1.png',
                  path: null,
                  size: 36.01,
                  width: 500,
                  height: 500,
                },
                medium: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/medium_Ben_Russell_L1_82781372df.png',
                  hash: 'medium_Ben_Russell_L1_82781372df',
                  mime: 'image/png',
                  name: 'medium_Ben Russell L1.png',
                  path: null,
                  size: 59.66,
                  width: 750,
                  height: 750,
                },
                thumbnail: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/thumbnail_Ben_Russell_L1_82781372df.png',
                  hash: 'thumbnail_Ben_Russell_L1_82781372df',
                  mime: 'image/png',
                  name: 'thumbnail_Ben Russell L1.png',
                  path: null,
                  size: 8.53,
                  width: 156,
                  height: 156,
                },
              },
            },
          ],
        },
        {
          type: 'a-frame',
          displayName: 'A-frame Sign',
          images: [
            {
              signId: 2,
              displayOrder: 1,
              formats: {
                large: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/large_Ben_Russell_OH_3_a1378c5cbb.png',
                  hash: 'large_Ben_Russell_OH_3_a1378c5cbb',
                  mime: 'image/png',
                  name: 'large_Ben Russell OH3.png',
                  path: null,
                  size: 110.47,
                  width: 1000,
                  height: 1000,
                },
                small: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/small_Ben_Russell_OH_3_a1378c5cbb.png',
                  hash: 'small_Ben_Russell_OH_3_a1378c5cbb',
                  mime: 'image/png',
                  name: 'small_Ben Russell OH3.png',
                  path: null,
                  size: 45.83,
                  width: 500,
                  height: 500,
                },
                medium: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/medium_Ben_Russell_OH_3_a1378c5cbb.png',
                  hash: 'medium_Ben_Russell_OH_3_a1378c5cbb',
                  mime: 'image/png',
                  name: 'medium_Ben Russell OH3.png',
                  path: null,
                  size: 75.62,
                  width: 750,
                  height: 750,
                },
                thumbnail: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/thumbnail_Ben_Russell_OH_3_a1378c5cbb.png',
                  hash: 'thumbnail_Ben_Russell_OH_3_a1378c5cbb',
                  mime: 'image/png',
                  name: 'thumbnail_Ben Russell OH3.png',
                  path: null,
                  size: 11.17,
                  width: 156,
                  height: 156,
                },
              },
            },
            {
              signId: 3,
              displayOrder: 2,
              formats: {
                large: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/large_Ben_Russell_OH_2_4ac99d9037.png',
                  hash: 'large_Ben_Russell_OH_2_4ac99d9037',
                  mime: 'image/png',
                  name: 'large_Ben Russell OH2.png',
                  path: null,
                  size: 93.52,
                  width: 1000,
                  height: 1000,
                },
                small: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/small_Ben_Russell_OH_2_4ac99d9037.png',
                  hash: 'small_Ben_Russell_OH_2_4ac99d9037',
                  mime: 'image/png',
                  name: 'small_Ben Russell OH2.png',
                  path: null,
                  size: 39.26,
                  width: 500,
                  height: 500,
                },
                medium: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/medium_Ben_Russell_OH_2_4ac99d9037.png',
                  hash: 'medium_Ben_Russell_OH_2_4ac99d9037',
                  mime: 'image/png',
                  name: 'medium_Ben Russell OH2.png',
                  path: null,
                  size: 65.11,
                  width: 750,
                  height: 750,
                },
                thumbnail: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/thumbnail_Ben_Russell_OH_2_4ac99d9037.png',
                  hash: 'thumbnail_Ben_Russell_OH_2_4ac99d9037',
                  mime: 'image/png',
                  name: 'thumbnail_Ben Russell OH2.png',
                  path: null,
                  size: 9.76,
                  width: 156,
                  height: 156,
                },
              },
            },
            {
              signId: 4,
              displayOrder: 3,
              formats: {
                large: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/large_Ben_Russell_OH_1_55db749603.png',
                  hash: 'large_Ben_Russell_OH_1_55db749603',
                  mime: 'image/png',
                  name: 'large_Ben Russell OH1.png',
                  path: null,
                  size: 118,
                  width: 1000,
                  height: 1000,
                },
                small: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/small_Ben_Russell_OH_1_55db749603.png',
                  hash: 'small_Ben_Russell_OH_1_55db749603',
                  mime: 'image/png',
                  name: 'small_Ben Russell OH1.png',
                  path: null,
                  size: 48.97,
                  width: 500,
                  height: 500,
                },
                medium: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/medium_Ben_Russell_OH_1_55db749603.png',
                  hash: 'medium_Ben_Russell_OH_1_55db749603',
                  mime: 'image/png',
                  name: 'medium_Ben Russell OH1.png',
                  path: null,
                  size: 80.77,
                  width: 750,
                  height: 750,
                },
                thumbnail: {
                  ext: '.png',
                  url:
                    'https://content-grid-media.avenue8.com/thumbnail_Ben_Russell_OH_1_55db749603.png',
                  hash: 'thumbnail_Ben_Russell_OH_1_55db749603',
                  mime: 'image/png',
                  name: 'thumbnail_Ben Russell OH1.png',
                  path: null,
                  size: 12.23,
                  width: 156,
                  height: 156,
                },
              },
            },
          ],
        },
      ],
    },
  }
}

const requestReceivedScreenResponse = () => {
  return {
    success: true,
    data: {
      orderNumber: '#012345678',
      confirmationDestination: 'chloe.adas@avenue8.com',
      costEstimate: '$50 - $100',
      removalAddress: '1234 Church St, Unit 4, San Francisco CA 94117',
    },
  }
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const outdoorModalResponse = () => {
  return {
    success: true,
    data: {
      options: [
        {
          type: 'radio',
          label: 'How would you like to get your signage?',
          name: 'signageDelivery',
          options: [
            {
              label: 'Install on site at property',
              value: 'install',
            },
            { label: 'Mail to me personally', value: 'mail' },
            { label: 'In-person pick up', value: 'pickup' },
          ],
        },
      ],
      dynamicOptions: {
        install: [
          {
            type: 'information',
            label: 'Cost estimate',
            name: 'costEstimate',
            information: '$50 - $100',
          },
          {
            type: 'radio',
            label: 'Property Type',
            name: 'propertyType',
            options: [
              {
                label: 'Residential',
                value: 'residential',
              },
              { label: 'Commercial', value: 'commercial' },
            ],
          },
          {
            type: 'radio',
            label: 'Include sign removal',
            name: 'signRemoval',
            options: [
              {
                label: 'Yes',
                value: 'yes',
              },
              { label: 'No', value: 'no' },
            ],
          },
        ],
        mail: [
          {
            type: 'information',
            label: 'Cost estimate',
            name: 'costEstimate',
            information: '$150 - $222',
          },
          {
            type: 'input',
            label: 'Mail to address',
            name: 'mailToAddress',
            placeholder: 'Your address',
          },
          {
            type: 'textarea',
            label: '',
            name: 'additionalNotes',
            placeholder: 'Add any additional notes...',
          },
        ],
        pickup: [
          {
            type: 'information',
            label: 'Cost estimate',
            name: 'costEstimate',
            information: '$55 - $125',
          },
          {
            type: 'information',
            label: 'Pick up location',
            name: 'pickupLocation',
            information: '9867 First Street, Menlo Park, CA 94810',
          },
          {
            type: 'textarea',
            label: '',
            name: 'additionalNotes',
            placeholder: 'Add any additional notes...',
          },
        ],
      },
    },
  }
}

const newOutdoorModalResponse = () => {
  return {
    success: true,
    data: [
      {
        type: 'radio',
        label: 'How would you like to get your signage?',
        name: 'getSignage',
        options: [
          {
            id: 41,
            label: 'Install on site at property',
            dynamicOptions: [
              {
                type: 'LABEL',
                label: 'Cost estimate',
                name: 'costEstimate',
                information: 'Cost estimate',
              },
              {
                type: 'LABEL',
                label: 'Installation address',
                name: 'installationAddress',
                information: 'Installation address',
              },
              {
                type: 'radio',
                label: 'Property type',
                name: 'propertyType',
                options: [
                  {
                    id: 44,
                    label: 'Residential',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    value: 'resi dential',
                    order: 1,
                  },
                  {
                    id: 45,
                    label: 'Commercial',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    value: 'resi mercial',
                    order: 2,
                  },
                ],
              },
              {
                type: 'radio',
                label: 'Include sign removal',
                name: 'includeSignRemoval',
                options: [
                  {
                    id: 46,
                    label: 'Yes',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 1,
                  },
                  {
                    id: 47,
                    label: 'No',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 2,
                  },
                ],
              },
              {
                type: 'INPUT_TEXT',
                label: 'Cross streets',
                name: 'crossStreets',
                placeholder: 'e.g. Market & Van Ness',
              },
              {
                type: 'INPUT_TEXT',
                label: 'Installation date',
                name: 'installationDate',
                placeholder: 'MM/DD/YYYY',
              },
              {
                type: 'SELECT',
                label: 'Sign type',
                name: 'signType',
                options: [
                  {
                    id: 50,
                    label: 'Post Only',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 1,
                  },
                  {
                    id: 51,
                    label: 'Post + Sign',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 2,
                  },
                  {
                    id: 52,
                    label: 'Garage Door',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 3,
                  },
                  {
                    id: 53,
                    label: 'Side of Building',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 4,
                  },
                  {
                    id: 54,
                    label: 'Window',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 5,
                  },
                  {
                    id: 55,
                    label: 'Wall',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 6,
                  },
                  {
                    id: 56,
                    label: 'Fence',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 7,
                  },
                ],
              },
              {
                type: 'SELECT',
                label: 'Placement location',
                name: 'placementLocation',
                options: [
                  {
                    id: 57,
                    label: 'Front of House',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 1,
                  },
                  {
                    id: 58,
                    label: 'Side of House',
                    dynamicOptions: [],
                    deliveryType: 'NONE',
                    order: 2,
                  },
                ],
              },
              {
                type: 'TEXT_AREA',
                label: '',
                name: 'notes',
                placeholder: 'Add any additional notes…',
              },
            ],
            value: 'INSTALL',
            order: 1,
          },
          {
            id: 138,
            label: 'Mail to me personally',
            dynamicOptions: [
              {
                type: 'information',
                label: 'Cost estimate',
                name: 'costEstimate',
                information: 'Cost estimate',
              },
              {
                type: 'input',
                label: 'Mail to address',
                name: 'mailToAddress',
                placeholder: 'Mail to address',
              },
              {
                type: 'textarea',
                label: '',
                name: 'notes',
                placeholder: 'Add any additional notes…',
              },
            ],
            value: 'MAIL',
            order: 2,
          },
        ],
      },
    ],
  }
}

const DigitalModalResponse = () => {
  return {
    success: true,
    data: [
      {
        type: 'textarea',
        label: 'Post caption',
        placeholder: 'Write here',
        name: 'caption',
        required: true,
      },
      {
        type: 'radio',
        label: 'Would you like to promote this post?',
        name: 'promotePost',
        required: true,
        options: [
          {
            id: 41,
            label: 'Yes',
            dynamicOptions: [
              {
                type: 'radio',
                label: 'What is the paid ad budget?',
                name: 'paidBudget',
                options: [
                  {
                    id: 44,
                    label: '$25',
                    dynamicOptions: [],
                    value: '$25',
                    order: 1,
                  },
                  {
                    id: 45,
                    label: '$50',
                    dynamicOptions: [],
                    value: '$50',
                    order: 2,
                  },
                  {
                    id: 45,
                    label: '$100',
                    dynamicOptions: [],
                    value: '$100',
                    order: 2,
                  },
                  {
                    id: 45,
                    label: '$250',
                    dynamicOptions: [],
                    value: '$250',
                    order: 2,
                  },
                ],
              },
              {
                type: 'datepicker',
                label: 'Campaign start date',
                name: 'campaignStartDate',
                fieldName: 'campaignStartDate',
                placeholder: 'MM/DD/YYYY',
                required: true,
              },
              {
                type: 'datepicker',
                label: 'Campaign end date',
                name: 'campaignEndDate',
                fieldName: 'campaignEndDate',
                placeholder: 'MM/DD/YYYY',
                required: true,
              },
            ],
            value: 'Yes',
            order: 1,
          },
          {
            id: 138,
            label: 'No',
            dynamicOptions: [],
            value: 'No',
            order: 2,
          },
        ],
      },
    ],
  }
}

const PrintModalResponse = () => {
  return {
    success: true,
    data: [
      {
        type: 'textarea',
        label: 'Post caption',
        placeholder: 'Write here',
        name: 'caption',
        required: true,
      },
      {
        type: 'radio',
        label: 'Production needs',
        name: 'productionNeeds',
        required: true,
        options: [
          {
            id: 41,
            label: 'Print & Mail',
            value: 'print&email',
            order: 1,
            dynamicOptions: [
              {
                type: 'radio',
                label: 'Quantity',
                name: 'quantity',
                options: [
                  {
                    id: 44,
                    label: '100',
                    dynamicOptions: [],
                    value: '100',
                    order: 1,
                  },
                  {
                    id: 250,
                    label: '250',
                    dynamicOptions: [],
                    value: '250',
                    order: 2,
                  },
                ],
              },
              {
                type: 'radio',
                label: 'Material',
                name: 'material',
                options: [
                  {
                    id: 44,
                    label: 'Matte',
                    dynamicOptions: [],
                    value: 'matte',
                    order: 1,
                  },
                  {
                    id: 250,
                    label: 'Glossy',
                    dynamicOptions: [],
                    value: 'glossy',
                    order: 2,
                  },
                ],
              },
            ],
          },
          {
            id: 138,
            label: 'Design Only',
            dynamicOptions: [],
            value: 'design',
            order: 2,
          },
        ],
      },
    ],
  }
}

const fakeOtherRequestsScreenResponse = {}
export default function loadPageInfo(obj) {
  switch (obj.page) {
    case 'LandingScreen':
      return Promise.resolve(fakeLandingScreenResponse(obj.params))
    case 'DigitalPackageScreen':
      return Promise.resolve(fakeDigitalPackageScreenResponse({ userId: 1 }))
    case 'PrintPackageScreen':
      return Promise.resolve(fakePrintPackageScreenResponse())
    case 'SignageNeedScreen':
      return Promise.resolve(signageNeedScreenResponse())
    case 'RequestReceived':
      return Promise.resolve(requestReceivedScreenResponse())
    case 'ReviewSignageScreen':
      return Promise.resolve(reviewSignageRequestResponse())
    case 'OutdoorModal':
      return Promise.resolve(newOutdoorModalResponse(obj.type))
    case 'SignageInstallRemoveScreen':
      return Promise.resolve(signageInstallRemoveScreenResponse())
    case 'OutdoorDetails':
      return Promise.resolve(outdoorSignageDetailsRequestResponse())
    case 'ListingSelectionScreen':
      return Promise.resolve(fakeListingSelectionScreenResponse())
    case 'StageSelectionScreen':
      return Promise.resolve(fakeStageSelectionScreenResponse())
    case 'ContentGrid':
      return Promise.resolve(fakeContentGridScreenResponse())
    case 'OtherRequestsScreen':
      return Promise.resolve(fakeOtherRequestsScreenResponse)
    case 'DigitalModal':
      return Promise.resolve(DigitalModalResponse())
    case 'PrintModal':
      return Promise.resolve(PrintModalResponse())
    default:
      break
  }
}
