import React from 'react'
import { default as MaterialButton } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { generateDataAttrs } from '../../utils/utils'

export default function Button(props) {
  const {
    className,
    primary,
    secondary,
    bordered,
    large,
    medium,
    small,
    clickHandler,
    disabled,
    noBorder,
    dataAttrs = {},
    muted,
    fullWidth,
    mini,
    xsmall,
    ...rest
  } = props
  const styles = {
    primary: {
      backgroundColor: 'var(--blue)',
      color: 'var(--white)',
      '&:hover': {
        backgroundColor: 'var(--blue)',
      },
      '&:disabled': {
        color: 'rgba(255,255,255, 0.5)',
        background: 'rgba(42, 41, 166, 0.35) !important',
        border: '1px solid rgba(42, 41, 166, 0.0)',
      },
    },
    secondary: { backgroundColor: 'var(--white)', color: 'var(--blue)' },
    muted: {
      backgroundColor: '#ECEDF1',
      color: '#979797',
    },
    bordered: { border: 'solid 1px var(--blue)' },
    fullWidth: { width: '100%' },
    large: { width: '300px', height: '50px', fontSize: '18px' },
    medium: { width: '260px', height: '50px', fontSize: '14px' },
    small: { width: '140px', height: '40px', fontSize: '14px' },
    mini: { width: '110px', height: '40px', fontSize: '14px' },
    xsmall: { width: '90px', height: '33px', fontSize: '12px' },
    button: {
      borderRadius: '5px',
      fontFamily: 'StyreneMedium',
      lineHeight: '1.29',
      fontWeight: '500',
      letterSpacing: '0.25px',
      textTransform: 'none',
    },
    noBorder: {
      border: '0',
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const buttonClasses = classNames({
    [classes.button]: true,
    [classes.primary]: primary,
    [classes.secondary]: secondary,
    [classes.bordered]: bordered,
    [classes.large]: large,
    [classes.medium]: medium,
    [classes.small]: small,
    [classes.noBorder]: noBorder,
    [classes.muted]: muted,
    [classes.fullWidth]: fullWidth,
    [classes.mini]: mini,
    [classes.xsmall]: xsmall,
    [className]: className !== undefined,
  })

  return (
    <MaterialButton
      variant="outlined"
      className={buttonClasses}
      onClick={() => clickHandler()}
      disabled={disabled}
      {...generateDataAttrs(dataAttrs)}
      {...rest}
    >
      {props.title}
    </MaterialButton>
  )
}
