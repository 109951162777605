import React from 'react'
import Theme from '../../assets/theme'

export default function Layout({ children }) {
  return (
    <div>
      {children}
      <Theme />
    </div>
  )
}
