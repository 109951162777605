import * as streamSaver from 'streamsaver'
import { RTREngine } from 'av8-rtrt-renderer'
import { WritableStream } from 'web-streams-polyfill/ponyfill'
import { toRtrTemplateImageItemId } from '../modules/shared/utils/rtrTemplateImageItemId'
import { formatAsMoney, generateComboLine } from './utils'

/* eslint-disable no-import-assign */
// If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
if (!window.WritableStream) {
  streamSaver.WritableStream = WritableStream
}

/**
 *
 * @param {Jimp} imageData Input Jimp Image instance
 * @param {string} filename Output filename to download
 * @returns nothing
 */
export const downloadStream = async (imageData, filename) => {
  const buffer = await imageData.getBufferAsync('image/png')
  const blob = new Blob([buffer], { type: 'image/png' })

  try {
    const fileStream = streamSaver.createWriteStream(filename, {
      size: blob.size,
    })
    const readableStream = new Response(buffer).body

    if (window.WritableStream && readableStream.pipeTo) {
      return readableStream
        .pipeTo(fileStream)
        .then(() => console.log('done writing'))
    }

    // Write (pipe) manually
    const writer = fileStream.getWriter()
    const reader = readableStream.getReader()
    const pump = () =>
      reader
        .read()
        .then((res) =>
          res.done ? writer.close() : writer.write(res.value).then(pump),
        )
    pump()
  } catch (e) {
    console.error(e)
  }
}

export async function generateRTR(
  listingDescriptionCtx,
  selectedPropertyCtx,
  stageSelectionDataCtx,
  imgUrl,
  size,
  layers,
) {
  const baseTemplateData = {
    ...listingDescriptionCtx,
    stageName: stageSelectionDataCtx.stageName,
    addressLine: selectedPropertyCtx,
    price: formatAsMoney(listingDescriptionCtx.estimatedPrice),
    propertyImage: imgUrl,
    comboLine: generateComboLine([
      [formatAsMoney(listingDescriptionCtx.estimatedPrice), '    '],
      [
        listingDescriptionCtx.bedCount > 0
          ? listingDescriptionCtx.bedCount + 'BD'
          : '',
        ' | ',
      ],
    ]),
  }

  const engine = new RTREngine()
  engine.setup()

  const buildImageFactory = (size, layers) => () =>
    engine.render(layers, baseTemplateData, size, size)

  const preview = await engine.render(layers, baseTemplateData, size, size)

  return { imagePreviewSVG: preview, buildImageFactory, baseTemplateData }
}

export function toStrapiImageFormat(
  index,
  template,
  category,
  imagePreviewBase64,
  baseUrl,
  otherImages,
) {
  const { children, layers, inputs = [], templateOrder: order = 0 } = template
  const label = String(index + 1).padStart(3, '0')
  const categoryName = category.displayName || category
  const carouselImages = template.carouselImages || otherImages || []

  return {
    itemId: toRtrTemplateImageItemId(template.id ?? index),
    id: template.id,
    templateGroupNumber: index,
    name:
      category.packageType === 'print'
        ? template.name
        : `${categoryName} #${label}`,
    templateOrder: order,
    url: imagePreviewBase64,
    baseUrl: baseUrl,
    formats: {
      small: {
        url: imagePreviewBase64,
      },
    },
    isRTR: true,
    layers,
    inputs,
    carouselImages,
    children: children,
    dataUsedForRender: template.dataUsedForRender,
  }
}
