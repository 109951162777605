import { uploadCareURL } from './api'

const extractGuid = (url) => {
  const result = /[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12}/.exec(url)
  if (result) return result[0]

  return null
}

export const scaleCrop1080p = (imageUrl) => {
  const guid = extractGuid(imageUrl)

  return guid
    ? `https://ucarecdn.com/${guid}/-/preview/-/scale_crop/1080x1080/-/format/png/`
    : `${uploadCareURL}/-/preview/-/scale_crop/1080x1080/${imageUrl}/-/format/png/`
}

export const scaleCrop1080pAtPosition = (
  imageUrl,
  x = 0,
  y = 0,
  size = 1080,
) => {
  if (!Number.isInteger(x) || !Number.isInteger(y) || !Number.isInteger(size))
    return scaleCrop1080p(imageUrl)
  const guid = extractGuid(imageUrl)

  return guid
    ? `https://ucarecdn.com/${guid}/-/resize/1080x/-/crop/${size}x${size}/${x}p,${y}p/-/format/png/`
    : `${uploadCareURL}/-/resize/1080x/-/crop/${size}x${size}/${x}p,${y}p/${imageUrl}/-/format/png/`
}

export const proxyCDN = (url) => 'https://4cd4900ec8e2e4b1c0ca.ucr.io/' + url