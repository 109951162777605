import { contentGridApi } from '../services/api'
import { marketingApi } from '../services/api'

export const getContentGridData = async (agentId) => {
  try {
    const { data } = await contentGridApi.get(
      `/content-grid-search?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getAgentSignages = async (agentId) => {
  try {
    const { data } = await contentGridApi.get(`/signs?agentId=${agentId}`)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getHubspotMailingList = async () => {
  try {
    const { data } = await contentGridApi.get('/hubspot-mailing-lists')
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const downloadPosts = async (posts) => {
  try {
    const { data } = await contentGridApi.post(
      '/content-grid-posts/download',
      {
        posts,
      },
      { responseType: 'arraybuffer' },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const increaseDownloadCounter = async (posts) => {
  try {
    const { data } = await contentGridApi.patch(
      '/content-grid-posts/download',
      { posts },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const createPost = async (
  agentId,
  shortDescription,
  fullDescription,
  images,
) => {
  try {
    const { data } = await marketingApi.post(
      `/content-library-post?agentId=${agentId}`,
      {
        shortDescription,
        fullDescription,
        images,
      },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const deletePost = async (agentId, postId) => {
  try {
    const { data } = await marketingApi.delete(
      `/content-library-post/${postId}?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const uploadSingleCSVList = async (csv) => {
  try {
    const { data } = await contentGridApi.post('/csv-contact-lists', csv)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}
