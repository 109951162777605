import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '../../../components/common/Button'

export enum EModalOpenState {
  'CLOSE',
  'OPEN'
}

export type CustomizeTemplateModalProps = {
  isEditing: boolean;
  open: EModalOpenState;
  onModalStateChange: (state: EModalOpenState) => void;
}

export const CustomizeTemplateModal: React.FC<CustomizeTemplateModalProps> = (params) => {
  const { children, isEditing, open, onModalStateChange } = params

  const handleOpen = () => onModalStateChange(EModalOpenState.OPEN)
  const handleClose = () => onModalStateChange(EModalOpenState.CLOSE)

  const buttonStyle = isEditing
    ? { primary: true, title: 'Edit' }
    : { secondary: true, title: 'Customize' }

  const isOpen = open === EModalOpenState.OPEN

  return (
    <>
      <Button mini bordered {...buttonStyle} onClick={handleOpen} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullScreen
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{ style: { borderRadius: '0' } }}
      >
        {isOpen && (children)}
      </Dialog>
    </>
  )
}
