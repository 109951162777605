import { RTRTemplateData } from './formatTemplateData'

export const swapPropertyImageForCropped = (data: Partial<RTRTemplateData>): Partial<RTRTemplateData> => {
  const propertyImage = data.propertyImage
  const propertyImageRef = data?.images?.find(image => {
    return propertyImage && image.url.indexOf(propertyImage) > -1
  })
  if (!propertyImageRef || !propertyImageRef.crop) {
    return data
  }
  return {
    ...data,
    images: [
      ...data?.images?.filter(image => image !== propertyImageRef) || [],
      {
        ...propertyImageRef,
        url: propertyImageRef.crop.previewUrl,
      },
    ],
    propertyImage: propertyImageRef.crop.previewUrl,
  }
}
