import React, { cloneElement } from 'react'

export default function ContentOverlay({
  children,
  extraOnClick = () => {},
  ...props
}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    extraOnClick()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {cloneElement(children[0], {
        open,
        onClick: handleClickOpen,
        ...props,
      })}
      {cloneElement(children[1], {
        open,
        onClose: handleClose,
        ...props,
      })}
    </div>
  )
}
