/**
 * Generates rule to validate max length
 * @param {string} errorMessage
 * @param {Object} options { size: number, lte: boolean = false }
 * @returns {function(*=): (boolean|string)} validator function
 */
export const maxLengthRule = (errorMessage, options) => {
  const { size = 0, lte = false } = options
  if (Number.isNaN(+size)) {
    throw new Error(`Parameter "size" is not a number: ${typeof size} ${size}`)
  }
  const n = size < 0 ? (lte ? 0 : 1) : size
  if (size < 0) {
    console.error('Max length given is less than zero:', size)
  }
  /**
   * Validates max length rule
   * @param {string} v input value
   * @returns {boolean|string} validator function
   */
  const rule = (v) => !v || (lte ? v.length <= n : v.length < n) || errorMessage
  rule.options = options
  return rule
}
