import React, { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useRequestContext } from '../../context/RequestContext'
import RTRLoader from '../custom/RTRLoader'
import {
  scaleCrop1080p,
  scaleCrop1080pAtPosition,
} from '../../services/uploadCareServices'
import { formatAsMoney, generateComboLine } from '../../utils/utils'
import useRTRImages from '../../hooks/useRTRImages'
import { usePackageDetailsContext } from '../../context/PackageDetailsContext'
import { useMarketingHelpersContext } from '../../context/MarketingHelpersContext'
import { useListingDescriptionContext } from '../../context/ListingDescriptionContext'
import { useStageSelectionContext } from '../../context/StageSelectionContext'

const selectFieldsByPackageType = {
  digital: {
    lastRTRRenderStage: 'lastRTRRenderStageForDigital',
    lastRTRBaseTemplate: 'lastRTRBaseTemplateForDigital',
  },
  print: {
    lastRTRRenderStage: 'lastRTRRenderStageForPrint',
    lastRTRBaseTemplate: 'lastRTRBaseTemplateForPrint',
  },
}

const RTRWrapper = ({ onFinishRender, packageType, packageData }) => {
  const { selectedPropertyCtx } = useRequestContext()

  const {
    stageSelection: stageSelectionDataCtx,
  } = useStageSelectionContext()

  const { listingDescription: listingDescriptionCtx } = useListingDescriptionContext()

  const {
    marketingHelpers,
  } = useMarketingHelpersContext()

  const {
    packageDetailsData: packageDetailsDataCtx,
  } = usePackageDetailsContext()

  const { generateRTRImages } = useRTRImages()

  const [isCreatingRTR, setIsCreatingRTR] = useState(false)

  useEffect(() => {
    const baseTemplateData = {
      ...listingDescriptionCtx,
      images: listingDescriptionCtx.images.map((image) => ({
        ...image,
        url: image.crop
          ? scaleCrop1080pAtPosition(
              image.url,
              image.crop?.x,
              image.crop?.y,
              image.crop?.size,
            )
          : scaleCrop1080p(image.url),
      })),
      stageName: stageSelectionDataCtx.stageName,
      addressLine: selectedPropertyCtx,
      price: formatAsMoney(listingDescriptionCtx.estimatedPrice),
      comboLine: generateComboLine([
        [formatAsMoney(listingDescriptionCtx.estimatedPrice), '    '],
        [
          listingDescriptionCtx.bathCount > 0
            ? listingDescriptionCtx.bathCount + 'BA'
            : '',
          listingDescriptionCtx.bedCount > 0
            ? listingDescriptionCtx.bedCount + 'BD'
            : '',
          ' | ',
        ],
      ]),
    }

    const generate = async () => {
      setIsCreatingRTR(true)
      await generateRTRImages(baseTemplateData, packageType, packageData)
      setIsCreatingRTR(false)
      onFinishRender()
    }

    const hasNewStage =
      marketingHelpers[
        selectFieldsByPackageType[packageType]?.lastRTRRenderStage
      ] !== stageSelectionDataCtx.value

    const hasUpdatedListingData = !isEqual(
      marketingHelpers[
        selectFieldsByPackageType[packageType]?.lastRTRBaseTemplate
      ],
      baseTemplateData,
    )

    if (hasNewStage || hasUpdatedListingData) {
      generate()
    } else {
      onFinishRender()
    }
  }, [
    marketingHelpers,
    packageDetailsDataCtx,
    selectedPropertyCtx,
    packageType,
  ])

  const estimatedRenderTime = () => {
    const estimatedRenderTimePerImage = 2 // seconds
    if (packageDetailsDataCtx) {
      const { digital = [] } = packageDetailsDataCtx
      const socialMediaCategory = digital.find(
        (pack) => pack.displayName === 'Social Media',
      )

      if (socialMediaCategory) {
        const templatesCount = socialMediaCategory?.rtrTemplates?.length ?? 0
        return templatesCount * estimatedRenderTimePerImage
      }
    }
    return 8
  }

  return (
    <RTRLoader loading={isCreatingRTR} estimatedTime={estimatedRenderTime()} />
  )
}

export default RTRWrapper
