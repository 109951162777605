import { makeStyles } from '@material-ui/core/styles'

export const CustomizePrintTemplateStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 32,
    position: 'relative',
    padding: '15px 20px',
    marginTop: '18px',
    maxWidth: 350,
    [theme.breakpoints.up('sm')]: {
      margin: '5% auto 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      marginTop: '2%',
      marginBottom: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  previewBtnContainer: {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 64,
    width: 64,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    border: 0,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
  },
  previewContainer: {
    padding: 2,
    margin: 16,
    maxWidth: '100%',
    marginBottom: 64,
    minHeight: 300,
  },
}))
