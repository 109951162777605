import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import Text from '../common/Text'
import { TemplatePreviews } from '../../modules/marketing/components/TemplatePreview/TemplatePreviews'
import { SIGNAGE_PACKAGE } from '../../utils/constants'
import { SectionCarousel } from '../custom/Signage/SectionCarousel'
import { useStageSelectionContext } from '../../context/StageSelectionContext'

export default function SectionPresentation({ sectionData, packageType }) {
  const styles = (theme) => ({
    subtitleContainer: {
      marginTop: '5px',
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
    subtitle: {
      fontFamily: 'StyreneRegular',
      fontSize: '12px',
      letterSpacing: '0.25px',
      lineHeigth: '16px',
      color: '#292929',
      opacity: '0.65',
    },
    titleContainer: {
      marginTop: '5px',
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
    title: {
      fontSize: '20px',
      fontFamily: 'BookmaniaBold',
      color: '#292929',
    },
    header: {
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
        textAlign: 'center',
      },
    },
    sectionCarouselContainer: {
      maxWidth: '100%',
      marginBottom: 41,
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
      },
    },
  })
  const useStyles = makeStyles(styles)
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()
  const classes = useStyles()

  const { displayName, displaySubtitle } = sectionData
  const headerTitle =
    displayName === 'Social Media' ? 'INSTANT DOWNLOAD' : 'EMAILED TO YOU'

  return (
    <Grid
      container
      direction="column"
      style={{ marginRight: '0%', maxWidth: '100%' }}
    >
      <Grid item className={classes.titleContainer} data-meta-name="title">
        <Grid className={classes.header}>
          <Text type="regular" color="#2A29A6" style={{ fontSize: 12 }}>
            {packageType === 'digital' && headerTitle}
          </Text>
        </Grid>

        <Text type="strong">
          {displayName}
          {stageSelectionDataCtx?.stageName &&
            ` - ${stageSelectionDataCtx?.stageName}`}
        </Text>
      </Grid>
      <Grid
        item
        className={classes.subtitleContainer}
        data-meta-name="subtitle"
      >
        <Text type="muted">{displaySubtitle}</Text>
      </Grid>
      <Grid
        className={classes.sectionCarouselContainer}
        data-meta-name="carousel"
      >
        {
          packageType === SIGNAGE_PACKAGE
          ? <SectionCarousel data={sectionData} packageType={packageType} />
          : <TemplatePreviews category={sectionData}/>
        }
      </Grid>
    </Grid>
  )
}
