import React, { useState, useEffect } from 'react'
import { useRequestContext } from '../../../context/RequestContext.js'
import { handleLeaveWebview } from '../../../services/appServices.js'
import EmailRequestConfirmation from './EmailRequestConfirmation.js'
import { approveEmailRTRequest } from '../../../services/marketingServices'
import { useAuthContext } from '../../../context/AuthContext.js'
import { useParams } from 'react-router'
import Loader from '../Loader'
import Modal from '../../common/Modal'
import useHistoryPrefix from '../../../hooks/useHistoryPrefix.js'

export default function EmailSentView() {
  const { isEmbedded } = useRequestContext()
  const { agentId } = useAuthContext()
  const { requestId } = useParams()
  const history = useHistoryPrefix()

  const [emailLoadingStatus, setEmailLoadingStatus] = useState({
    loading: true,
    message: 'Processing your request...',
    show: true,
    error: false,
  })

  useEffect(() => {
    approveEmailRTRequest(requestId, agentId)
      .then(({ success }) => {
        setEmailLoadingStatus({
          loading: false, show: !success,
          error: !success,
          message: !success && 'We were unable to complete your request. Please try again.',
        })
      })
  }, [])

  const handleClose = () => {
    history.push('/')
  }

  const handleLeave = () => {
    if (isEmbedded) {
      return handleLeaveWebview()
    } else {
      return handleLeaveSubApp()
    }
  }

  const handleLeaveSubApp = () => {
    window.location.href = `${window.location.origin}/account`
  }

  return (
    <>
      <EmailRequestConfirmation
        open={!emailLoadingStatus.show}
        title={'Email'}
        subtitle={'Sent!'}
        message={
          'We’ve started creating your email. You’ll receive a preview in your inbox within 1 business day. Would you like to request more marketing assets?'
        }
        yesText={'OK'}
        onYes={handleLeave}
      />
      {emailLoadingStatus.show && (
        <Modal open={emailLoadingStatus.show}>
          <Loader
            loading={emailLoadingStatus.loading}
            text={emailLoadingStatus.message}
            setLoadingStatus={setEmailLoadingStatus}
            dataAttrs={{ metaType: 'modal' }}
            onClose={handleClose}
            closeTitle={'OK'}
          />
        </Modal>
      )}
    </>
  )
}
