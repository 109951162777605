import orderBy from 'lodash/orderBy'
import { RTREngine } from 'av8-rtrt-renderer'

import useUpdatePackage from '../hooks/useUpdatePackage'
import { isNotRTR } from '../utils/filters'
import { generateComboLine } from '../utils/utils'

import { toStrapiImageFormat } from '../utils/rtr'
import { RTR_V2_ENABLED_TEMPLATES } from '../utils/constants'
import { useMarketingHelpersContext } from '../context/MarketingHelpersContext'
import { useStageSelectionContext } from '../context/StageSelectionContext'
import { svgToBlobUrl } from '../modules/shared/utils/svgRtrConverters'

const useRTRImages = () => {
  const { stageSelection: stageSelectionDataCtx } = useStageSelectionContext()

  const { marketingHelpers, setMarketingHelpers } = useMarketingHelpersContext()

  const { updateEntirePackageTypeData } = useUpdatePackage()

  const updatePackageAndHelpers = async (
    packageType,
    packageData,
    baseTemplateData,
  ) => {
    const selectFieldsByPackageType = {
      digital: {
        lastRTRRenderStage: 'lastRTRRenderStageForDigital',
        lastRTRBaseTemplate: 'lastRTRBaseTemplateForDigital',
      },
      print: {
        lastRTRRenderStage: 'lastRTRRenderStageForPrint',
        lastRTRBaseTemplate: 'lastRTRBaseTemplateForPrint',
      },
    }

    const marketingHelpersNewData = {
      [selectFieldsByPackageType[packageType]?.lastRTRBaseTemplate]:
        baseTemplateData,
      [selectFieldsByPackageType[packageType]?.lastRTRRenderStage]:
        stageSelectionDataCtx.value,
    }

    setMarketingHelpers({
      ...marketingHelpers,
      ...marketingHelpersNewData,
    })
    updateEntirePackageTypeData(packageType, packageData)
  }

  const generateRTR = async (
    baseUrl,
    baseTemplateData,
    template,
    category,
    index,
    // layers,
    // inputs = [],
    // order,
  ) => {
    const templateData = (imgUrl) => ({
      ...baseTemplateData,
      propertyImage: imgUrl,
      comboLine: generateComboLine([
        [baseTemplateData.price, '    '],
        [
          baseTemplateData.bathCount > 0
            ? baseTemplateData.bathCount + 'BA'
            : '',
          baseTemplateData.bedCount > 0 ? baseTemplateData.bedCount + 'BD' : '',
          ' | ',
        ],
      ]),
    })
    const [coverImage, ...otherImages] = baseTemplateData.images

    const engine = new RTREngine()
    engine.setup()

    const buildImageFactory = (size, layers) => () =>
      engine.render(layers, templateData(coverImage.url), size, size, true)

    try {
      const preview = await buildImageFactory(512, template.layers)()
      // The object below is just to mimic the reply from the server
      return toStrapiImageFormat(
        index,
        template,
        category,
        svgToBlobUrl(preview),
        baseUrl,
        otherImages,
      )
    } catch (err) {
      console.error(err)
    }
  }

  const generateRTRImages = async (
    baseTemplateData,
    packageType,
    packageData,
  ) => {
    // Build RTR only for first image (cover)
    const [coverImage] = baseTemplateData.images

    if (packageData) {
      const imagesGenerationPromises = packageData
        .filter(({ templateCategory }) =>
          RTR_V2_ENABLED_TEMPLATES.includes(templateCategory),
        )
        .map(async (category) => {
          category.images = category.images.filter(isNotRTR)
          await Promise.all(
            // Build an RTR with each template available
            category.rtrTemplates.map(async (template, templateIdx) => {
              const imagePreview = await generateRTR(
                coverImage.url,
                baseTemplateData,
                template,
                category,
                templateIdx,
              )
              category.images.push(imagePreview)
            }),
          )

          category.images = orderBy(category.images, [
            'isRTR',
            'templateOrder',
            'name',
          ])
        })

      await Promise.all(imagesGenerationPromises)
      await updatePackageAndHelpers(packageType, packageData, baseTemplateData)
    }
  }

  return { generateRTRImages, generateRTR }
}

export default useRTRImages
