/* eslint-disable comma-dangle */
import { camelCase } from 'lodash'
import {
  fromMoney,
  toMoney,
} from '../../../components/custom/DynamicForm/formatters/money.formatter'
import { generateComboLine } from '../../../utils/utils'
import { Image } from '../components/ImportFromGalleryModal/ImportFromGalleryModal'

export type DynamicFormOption = {
  name: string
  value: any
  type: string
  metadata?: {
    combineFields?: string[]
    template?: string | string[][]
    [j: string]: any
  }
  [k: string]: any
}

export type DynamicFormOptionsRecord = Record<string, DynamicFormOption>

const fromImageSelectorValue = (
  input: DynamicFormOption,
): DynamicFormOption => {
  if (input?.type !== 'imageSelector') {
    return input
  }
  return {
    ...input,
    value: (Array.isArray(input.value) && input.value[0]) || input.value,
  }
}

const fieldFormatters: Record<string, (value: any) => string> = {
  money: (value: string | number) => {
    if (typeof value === 'string') {
      return toMoney(fromMoney(value))
    }
    return toMoney(value, 2, 0)
  },
  bedCount: (bedCount: string | number) =>
    Number.isNaN(+bedCount) || +bedCount < 1 ? '' : `${bedCount}BD`,
  bathCount: (bathCount: string | number) =>
    Number.isNaN(+bathCount) || +bathCount < 1 ? '' : `${bathCount}BA`,
  lotSize: (lotSize: string | number) =>
    Number.isNaN(+lotSize) || +lotSize < 1
      ? ''
      : `${Intl.NumberFormat().format(+lotSize)} SQ FT`,
}

export const normalizeInputPackage = (
  inputs: DynamicFormOption[],
): DynamicFormOption[] => {
  const processedInputs = [...inputs].map((input) => ({
    ...input,
    name: camelCase(input?.metadata?.formName ?? input.name),
  }))
  return processedInputs
}

export const formatDynamicFormOptions = (
  data: DynamicFormOption[],
): DynamicFormOption[] => {
  return data
}

export const parseTemplate = (
  fields: string[],
  map: Map<string, DynamicFormOption>,
  startingValue: string,
) => {
  return fields.reduce((value: string, fieldName: string) => {
    const normalizedFieldName = camelCase(fieldName)
    const field = map.get(normalizedFieldName)
    const formatterName = field?.metadata?.formatter
    const formatter: (value: any) => string =
      fieldFormatters[formatterName] ?? ((v: any) => v)
    const replacement = field?.value ?? ''

    // eslint-disable-next-line no-useless-escape
    const regexTag = `\\$\\{${fieldName}\\}`
    const regex = new RegExp(regexTag, 'ig')
    try {
      return value.slice(0).replace(regex, formatter(replacement))
    } catch (err) {
      return value
    }
  }, startingValue)
}

const templatedField = (
  input: string | DynamicFormOption,
  obj: DynamicFormOptionsRecord,
): string | DynamicFormOption => {
  if (typeof input !== 'string' && (input.type === 'featuresList' || input.name == 'featureItems')) {
    return formatFeatureList(input)
  }
  

  if (
    typeof input === 'string' ||
    !input?.metadata?.combineFields ||
    !input?.metadata?.template
  ) {
    return input
  }
  const map = new Map(
    Object.values(obj).map((input): [string, DynamicFormOption] => [
      camelCase(input.name),
      input,
    ]),
  )
  const fields: string[] = Array.from(new Set(input.metadata.combineFields))
  if (typeof input.metadata.template === 'string') {
    return {
      ...input,
      value: parseTemplate(fields, map, input.metadata.template),
    }
  }
  const startingValue: string[][] = input.metadata.template
  const combinedValue = generateComboLine(
    startingValue.map((pieces) => {
      const separator = pieces.length > 1 ? pieces.slice(-1) : ''
      const values =
        pieces.length > 1 ? pieces.slice(0, pieces.length - 1) : pieces
      const templatedPieces = values.map((value) =>
        parseTemplate(fields, map, value),
      )
      return [...templatedPieces, separator]
    }),
  )

  return {
    ...input,
    value: combinedValue.trim(),
  }
}

const formatFeatureList = (
  input: DynamicFormOption,
): string | DynamicFormOption => {
  if ((input.type !== 'featuresList' && input.name !== 'featureItems') || typeof input.value === 'string') {
    return input
  }

  const value: {
    title: string
    value: string
    error: boolean
    features: [any]
  }[] = input.value

  const transformedValuesInHTMLList =
    value
      ?.map(
        (feature) =>  feature.value ? `<li>${feature.value}</li>` : ''
      )
      .filter(value => value)
      .join(' ') || 'FEATURE'

  return {
    ...input,
    value: `
  <style>
  ul {
    list-style: initial; 
    padding-left: 1.5em; 
  }
 
  </style>
  <ul>${transformedValuesInHTMLList}</ul>`,
  }
}

export const optionDataToTemplate = (
  data: DynamicFormOptionsRecord,
): DynamicFormOptionsRecord => {
  return Object.fromEntries(
    Object.entries(data)
      .map(
        ([, input]): Readonly<[string, DynamicFormOption]> => [
          input.name,
          input,
        ],
      )
      .map(([dataName, dataInput]) => [
        dataName,
        fromImageSelectorValue(dataInput),
      ])
      .map(([dataName, dataInput]) => [
        dataName,
        templatedField(dataInput, data),
      ]),
  )
}

export const fillImageSelectorWithPlaceholder = (
  input: DynamicFormOption,
  listingDescriptionImages: string[],
  baseTemplateDataName: string,
  agentLogos: Image[]
) => {

  // Agent logo
  if(input.name == 'agentLogo') {
    if(agentLogos?.length > 0) { 
      return [input.name, {
        ...input,
        value: agentLogos[0].url
      }]
    }
    return [input.name, input]
  }
  const inputIndex = parseInt(input.name.slice(-1)) - 1
  const placeholderIndex =
    inputIndex ?? 0 > listingDescriptionImages?.length
      ? listingDescriptionImages?.length - 1
      : inputIndex || 0
  const placeholderImage =
    listingDescriptionImages[placeholderIndex] || undefined
  const filledInput = {
    ...input,
    value: (baseTemplateDataName || placeholderImage) ?? undefined,
  }
  return [input.name, filledInput]
}
