import { debounce } from '@material-ui/core'
import React from 'react'
import { useFormikContext } from 'formik'

export const DebouncedSubmitOnChangeFormik = ({debounceMs}: { debounceMs: number }) => {
  const formik = useFormikContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastSaved, setLastSaved] = React.useState<string | null>(null)
  const debouncedSubmit = React.useCallback(
    debounce(
      () =>
        formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs,
    ),
    [debounceMs, formik.submitForm],
  )

  React.useEffect(() => {
    debouncedSubmit()
  }, [debouncedSubmit, formik.values])

  return (
    <></>
  )
}
