import styled from 'styled-components'

export const SelectContainer = styled.div`
  margin-top: 15px;
  // A reset of styles
  select {
    margin-top: 8px;
    border-radius: 5px;
    border: 1px solid var(--blue);
    padding: 10px;

    ::placeholder {
      font-family: 'StyreneMedium';
      font-size: '12px';
      color: var(--blue);
      opacity: '0.5';
    }

    width: 100%;
    font-family: 'StyreneMedium';
    font-size: '12px';
    background-color: transparent;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    appearance: none;
    color: var(--blue);
    z-index: 2;
  }

  .select {
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    width: 96%;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    color: #2a29a6;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  // Style the select arrow
  .select::after {
    content: '';
    border-top: 2px solid #2a29a6;
    border-right: 2px solid #2a29a6;
    transform: rotate(135deg);
    width: 10px;
    height: 10px;
    margin-right: 20px;
    z-index: 0;
    justify-self: end;
  }
  select,
  .select:after {
    grid-area: select;
  }

  #custom-select {
    margin-left: -4px;
  }
`
