import React from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import Text from '../../../../components/common/Text'
import Tooltip from '../../../../components/common/Tooltip'
import StepCounter from '../../../../components/common/StepCounter'
import PagePresentation from '../../../../components/common/PagePresentation'
import CustomGridContainer from '../../../../components/common/CustomGridContainer'
import { generateDataAttrs } from '../../../../utils/utils'

type PackageDetailsNavigationProps = {
  packageType: string
  showPackageTypeNavigation: boolean
  stepCounter: Record<string, unknown>
  handleNextFunction: () => void
  isNextButtonDisabled: boolean
  pagePresentation: Record<string, any>
  handlePackageTypeClick: (packageType: string, packageClicked: string) => void
}

const styles = (theme: any) => ({
  stepContainer: {
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 650,
    },
  },
  upperContainer: {
    maxWidth: '80%',
    margin: '10% 0 0 10%',
  },
})

const PackageDetailsNavigation = ({
  packageType,
  showPackageTypeNavigation = true,
  stepCounter,
  handleNextFunction,
  isNextButtonDisabled,
  pagePresentation,
  handlePackageTypeClick,
}: PackageDetailsNavigationProps) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const selectTextDecoration = (type: string) => {
    return type === packageType
      ? {
          textDecoration: 'underline',
          fontWeight: 'bold',
        }
      : {}
  }

  return (
    <CustomGridContainer noMinHeight>
      <Grid item className={classes.stepContainer}>
        <StepCounter
          numberOfSteps={stepCounter.numberOfSteps}
          activeStep={stepCounter.activeStep}
          handleNext={handleNextFunction}
          isNextButtonDisabled={isNextButtonDisabled}
        />
      </Grid>
      <Grid
        item
        style={{
          maxWidth: 375,
          alignSelf: 'center',
          textAlign: 'center',
          marginBottom: 15,
        }}
      >
        <PagePresentation
          CTATextBold={pagePresentation.CTATextBold}
          CTATextItalic1={pagePresentation.CTATextItalic1}
          CTATextItalic2={pagePresentation.CTATextItalic2}
          CTADescription={pagePresentation.CTADescription}
          dataAttrs={{ dataName: 'page-title' }}
        />
        {showPackageTypeNavigation && (
          <Box display='flex' justifyContent='space-evenly' mt={3} mb={1}>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => handlePackageTypeClick(packageType, 'digital')}
              {...generateDataAttrs({ metaAction: 'select-digital-package' })}
            >
              <Text
                type='blue'
                fontSize='14px'
                style={selectTextDecoration('digital')}
              >
                Digital
              </Text>
            </Box>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => handlePackageTypeClick(packageType, 'print')}
              {...generateDataAttrs({ metaAction: 'select-print-package' })}
            >
              <Text
                type='blue'
                fontSize='14px'
                style={selectTextDecoration('print')}
              >
                Print
              </Text>
            </Box>
          </Box>
        )}
      </Grid>
      <Box maxWidth={375} textAlign='center' alignSelf='center'>
        <Text type='muted' component='span'>
          {pagePresentation?.CTADescription}
        </Text>
        <Tooltip text={pagePresentation.CTADescriptionTooltip} />
      </Box>
    </CustomGridContainer>
  )
}

export default PackageDetailsNavigation
