import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import Image from '../../../components/common/Image'
import Text from '../../../components/common/Text'
import { SignageDetailsModal } from './SignageDetailsModal'
import { generateDataAttrs } from '../../../utils/utils'
import { useSignageStore } from '../store/signage.store'
import { SignCategoryType, SignType } from '../types'

const styles = (theme: any) => ({
  imageContainer: {
    width: 250,
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 250,
  },
  carouselItemContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
})
const useStyles = makeStyles(styles)

export type SignageDetailsItemType = {
  sign: SignType
  category: SignCategoryType
  index: string
}

const SignageDetailsItem = ({
  sign,
  category,
  index,
}: SignageDetailsItemType) => {
  const classes = useStyles()

  const signsStore = useSignageStore((store) => store.signs)
  const inputData = signsStore[sign.signId] || null

  const isEditing = !!inputData
  const imageUrl =
    sign?.url ||
    sign?.formats?.small?.url ||
    sign?.formats?.medium?.url ||
    sign?.formats?.large?.url
  const signTitle = `${category.displayName} #${index + 1}`

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      className={classes.carouselItemContainer}
      {...generateDataAttrs({ metaName: 'package', metaIndex: +index + 1 })}
    >
      <Grid item>
        <Paper elevation={3} className={classes.imageContainer}>
          <Image
            medium
            className={classes.image}
            draggable={false}
            src={imageUrl}
            alt={'It shows a sign'}
            rider={sign.templateType === 'rider-for-sale'}
            {...generateDataAttrs({ metaName: 'image' })}
          />
        </Paper>
      </Grid>
      <Grid item style={{ width: '250px', marginTop: '10px' }}>
        <Grid
          {...generateDataAttrs({ metaName: 'title' })}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Grid item style={{ marginBottom: 20 }}>
            <Text type='blue'>{signTitle}</Text>
          </Grid>
        </Grid>
        <Grid
          container
          justify='space-around'
          alignItems='center'
          {...generateDataAttrs({ metaName: 'actions' })}
        >
          <SignageDetailsModal
            isEditing={isEditing}
            sign={sign}
            signInputData={inputData}
            category={category}
            title={signTitle}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignageDetailsItem
