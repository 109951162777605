import { makeStyles } from '@material-ui/styles'

export const CropImageModalStyles = makeStyles(() => ({
    container: {
      padding: '15px',
    },
    cropShadow: {
      background: '#FAFAFA',
      padding: '20px',
      paddingTop: '40px',
      marginBottom: '68px',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    },
    checkIcon: {
      color: '#12B777',
      fontSize: 15,
    },
    cropSizeContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    modalHeaderActions: { 
      display: 'flex', 
      justifyItems: 'center', 
      marginBottom: 40, 
    },
  }))
