import React, { useRef, useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { ClickAwayListener, Tooltip as MatTooltip } from '@material-ui/core'
import { Info as InfoIcon, Error as ErrorIcon } from '@material-ui/icons'
import Text from './Text'

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'var(--white)',
    border: '1px solid #292929',
    width: 200,
    fontSize: 14,
    margin: '5px 0px 0px 0px',
  },
}))(MatTooltip)

export default function Tooltip({ text, type = 'info', status = 'closed' }) {
  const styles = {
    infoIcon: {
      color: '#292929',
      position: 'relative',
      top: 5,
      marginLeft: 5,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    errorIcon: {
      color: '#dc3545',
      position: 'relative',
      top: 5,
      marginLeft: 5,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [isOpened, setIsOpened] = useState(false)
  const popperRef = useRef(null)

  useEffect(() => {
    if (status === 'open') {
      setIsOpened(true)
    }
  }, [])

  const handleClickAway = ({ target }) => {
    if (isOpened && popperRef.current) {
      const popperElement = popperRef.current.popper
      if (!popperElement.contains(target)) {
        setIsOpened(false)
      }
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <CustomTooltip
        interactive
        enterTouchDelay={0}
        open={isOpened}
        PopperProps={{
          popperRef,
        }}
        title={<Text type="muted">{text}</Text>}
        placement="top"
      >
        {type === 'error' ? (
          <ErrorIcon
            className={classes.errorIcon}
            fontSize="small"
            onClick={() => setIsOpened(!isOpened)}
          />
        ) : (
          <InfoIcon
            className={classes.infoIcon}
            fontSize="small"
            onClick={() => setIsOpened(!isOpened)}
          />
        )}
      </CustomTooltip>
    </ClickAwayListener>
  )
}
