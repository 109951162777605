import React, { useState } from 'react'
import { withStyles, Menu } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Button from './Button'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 5,
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function MenuDropdown({
  title = 'Change',
  icon,
  children,
  showIcon = true,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const Icon = icon ? (
    icon
  ) : anchorEl ? (
    <ExpandLessIcon style={{ fontSize: 20 }} />
  ) : (
    <ExpandMoreIcon style={{ fontSize: 20 }} />
  )

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        clickHandler={() => {}}
        primary
        xsmall
        title={title}
        endIcon={showIcon ? Icon : ''}
        {...props}
      />
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  )
}
