import React from 'react'
import { Container } from './ContentGridItem.styles.js'

export default function SelectableImage({
  isVisible = true,
  disabled = false,
  checked,
  itemKey,
  children,
  onSelect = () => {},
}) {
  return (
    <Container isVisible={isVisible} disabled={disabled}>
      <label htmlFor={itemKey} className="container">
        <input
          type="checkbox"
          id={itemKey}
          disabled={disabled}
          checked={checked}
          onChange={onSelect}
        />
        {children}
        <span className="checkmark"></span>
      </label>
    </Container>
  )
}
