import React from 'react'
import { GetApp } from '@material-ui/icons'
import Button from '../common/Button'
import useDownload from '../../hooks/useDownload'

const buttonCustomSize = {
  small: { width: 120, height: 50 },
  medium: { width: 180, height: 50 },
}

export default function PNGDownload({
  url,
  size = 'small',
  shouldRenderInBackend = false,
  auxiliaryImageUrls = [],
  ...props
}) {
  const [handleDownload] = useDownload(
    url,
    shouldRenderInBackend,
    auxiliaryImageUrls,
  )

  return (
    <Button
      style={buttonCustomSize[size]}
      primary
      title="PNG"
      endIcon={<GetApp style={{ width: 20, fontSize: 16 }} />}
      clickHandler={handleDownload}
      {...props}
    />
  )
}
