import React from 'react'
import { Grid } from '@material-ui/core'
import Text from '../common/Text'
import Button from '../common/Button'
import AV8Logo from '../../assets/icons/AV8LogoBlue.png'
import { generateDataAttrs } from '../../utils/utils'

export default function ContentLibraryInfoModal({ text, handleClose }) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textAlign: 'center' }}
      {...generateDataAttrs({
        metaName: 'content-library-info-modal',
      })}
    >
      <img src={AV8Logo} alt={'AV8 Logo'} height={16} width={120} />

      <Text style={{ marginTop: 20 }}>{text}</Text>

      <Button
        style={{ marginTop: 25 }}
        medium
        bordered
        secondary
        title="Ok"
        onClick={handleClose}
        {...generateDataAttrs({ metaAction: 'close' })}
      >
        Ok
      </Button>
    </Grid>
  )
}
