import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Layout from '../components/common/Layout.js'
import Loading from '../components/common/Loading'
import CustomGridContainer from '../components/common/CustomGridContainer'
import PagePresentation from '../components/common/PageTitle'
import Textarea from '../components/common/TextAreaInput'
import ErrorMsg from '../components/common/ErrorMsg'
import Button from '../components/common/Button'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useAuthContext } from '../context/AuthContext'
import { submitMarketingForMeRequest } from '../services/marketingServices'

export default function StageSelection() {
  const { agentId } = useAuthContext()
  const history = useHistoryPrefix()
  const { flow } = useParams()
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const isNextButtonDisabled = description.length < 3

  const handleNextPageClick = async () => {
    setLoading(true)
    try {
      const { data } = await submitMarketingForMeRequest(agentId, description)
      const pathname = ['', flow, 'forme', 'requestreceived'].join('/')

      data.description = description
      history.push({
        pathname,
        state: {
          data,
        },
      })
    } catch (err) {
      setLoading(false)
      setError(true)
      console.error(err)
    }
  }

  const handleChange = (e) => {
    setDescription(e.target.value)
  }

  const NavButtons = ({ marginBottom }) => {
    const marginStyle = marginBottom
      ? { margin: '0 0 40px 0' }
      : { margin: '40px 0 0 0' }

    return (
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={marginStyle}
        >
          <Button
            small
            bordered
            secondary
            title="Back"
            style={{ width: '130px !important' }}
            clickHandler={() => history.goBack()}
            dataAttrs={{
              metaAction: 'go-next',
              testName: 'step-counter-next-button',
            }}
          />
          <Button
            small
            bordered
            primary
            title="Submit"
            style={{ width: 130 }}
            disabled={isNextButtonDisabled}
            clickHandler={handleNextPageClick}
            dataAttrs={{
              metaAction: 'go-next',
              testName: 'step-counter-next-button',
            }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Layout>
      <CustomGridContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <NavButtons marginBottom />
            {error && (
              <Grid style={{ marginBottom: 10 }}>
                <ErrorMsg />
              </Grid>
            )}
            <PagePresentation
              CTATextUpper={'What would you'}
              CTATextLower={'like to do?'}
            />
            <Grid>
              <Textarea
                label="Description"
                name="description"
                placeholder="Write here..."
                lengthCounting={`${description.length}/500`}
                maxLength={500}
                rows={8}
                onChange={handleChange}
                value={description}
              />
            </Grid>
            <NavButtons />
          </>
        )}
      </CustomGridContainer>
    </Layout>
  )
}
