import styled from 'styled-components'

export const SelectContainer = styled.div`
  width: 90%;

  select {
    appearance: none;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid var(--blue);
    padding: 10px;
    margin: 0;
    width: 100%;
    font-family: 'StyreneMedium';
    font-size: 14px;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: #2a29a6;
    z-index: 2;
    height: 36px;
  }

  .select {
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    width: 97.5%;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    color: #2a29a6;
    margin-top: 7px;
    margin-bottom: 15px;
  }

  // Style the select arrow
  .select::after {
    content: '';
    border-top: 2px solid var(--blue);
    border-right: 2px solid var(--blue);
    transform: rotate(135deg);
    width: 8px;
    height: 8px;
    margin-right: 12px;
    z-index: 0;
    justify-self: end;
    margin-bottom: 3px;
  }
  select,
  .select:after {
    grid-area: select;
  }

  #custom-select {
    margin-left: -4px;
  }
`
