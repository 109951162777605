import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/common/Layout'
import ButtonCard from '../components/common/ButtonCard'
import PagePresentation from '../components/common/PageTitle'
import CustomGridContainer from '../components/common/CustomGridContainer'
import { useAuthContext } from '../context/AuthContext'
import { useRequestContext } from '../context/RequestContext'
import { getTemplatesTypes } from '../services/marketingServices'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { generateDataAttrs } from '../utils/utils'
import {useTemplateTypesContext} from '../context/TemplateTypesContext'
import EmailBlastChecker from '../components/custom/EmailTemplate/EmailBlastChecker.js'

export default function MarketingRequestLandingPage() {
  const styles = (theme) => ({
    container: {
      maxHeight: '100vh',
    },
    button: {
      marginBottom: '5%',
    },
    title: {
      maxHeight: '145px',
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
    buttonsContainer: {
      marginTop: 5,
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const history = useHistoryPrefix()
  const {setCurrentFlow, setSelectedPropertyCtx} = useRequestContext()
  const { setTemplateTypes } = useTemplateTypesContext()
  const { agentId } = useAuthContext()
  const [showPage, setShowPage] = useState(false)

  const handleNavigation = (pathname, flowName) => {
    setCurrentFlow(flowName)
    history.push(pathname)
  }

  const redirectEmailBlastFromQueryParams = () => {
    const urlParams = new URLSearchParams(location.search)
    const action = urlParams.get('action')
    const requestId = urlParams.get('requestId')
    const redirect = EmailBlastChecker(requestId, action)
    if (redirect) {
      history.push(redirect)
    }
  }

  useEffect(() => {
    setCurrentFlow('Marketing Request')
    setSelectedPropertyCtx('')
    redirectEmailBlastFromQueryParams()

    const fetchData = async () => {
      const response = await getTemplatesTypes(agentId)
      if (response.success) {
        setShowPage(true)
        setTemplateTypes(response.data)
      }
    }
    fetchData()
  }, [])

  return (
    <Layout>
      {showPage && (
        <>
          <CustomGridContainer>
            <Grid
              item
              className={classes.title}
              {...generateDataAttrs({ metaName: 'page-title' })}
            >
              <PagePresentation
                CTATextUpper={'What are your'}
                CTATextLower={'marketing needs?'}
                dataAttrs={{ metaName: 'page-title' }}
              />
            </Grid>
            <Grid
              className={classes.buttonsContainer}
              {...generateDataAttrs({ metaName: 'subflow-selection' })}
            >
              <Grid item className={classes.button}>
                <ButtonCard
                  large
                  bordered
                  secondary
                  title={'Market a Listing'}
                  cardTitle={'Standard Templates, Instant Download'}
                  cardSubtitle={
                    'Postcards, email, social media posts, and more - everything you need to market your listing.'
                  }
                  dataAttrs={{
                    metaAction: 'for-my-listing',
                  }}
                  clickHandler={() =>
                    handleNavigation(
                      '/marketing/mylisting/propertyselection',
                      'Market a Listing',
                    )
                  }
                />
              </Grid>
              <Grid item className={classes.button}>
                <ButtonCard
                  large
                  bordered
                  secondary
                  title={'For Me'}
                  cardTitle={'Includes'}
                  cardSubtitle={
                    'Build your brand, market your recent wins, and tell your story.'
                  }
                  dataAttrs={{ metaAction: 'for-me' }}
                  clickHandler={() =>
                    handleNavigation('/marketing/forme', 'For Me')
                  }
                />
              </Grid>
            </Grid>
          </CustomGridContainer>
        </>
      )}
    </Layout>
  )
}
