import React from 'react'
import { Grid } from '@material-ui/core'
import { TemplateStepStyles } from './TemplateStep.styles'

export type TemplateStepProps = {
  bulletText?: string;
  active?: boolean;
  number: number;
  isBulletCounter?: boolean;
}

export const TemplateStep = (props: TemplateStepProps): JSX.Element => {
  const { active = false, number, isBulletCounter = false, bulletText } = props
  const classes = TemplateStepStyles()

  const numberedStep = (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs className={classes.numberContainer}>
        <div className={active ? classes.active : classes.inactive}>
          {number}
        </div>
      </Grid>
    </Grid>
  )

  const bulletedStep = (
    <div className={classes.bulletTitleContainer}>
      <Grid item xs className={classes.bulletIcon}>
        <div
          className={active ? classes.activeBullet : classes.inactiveBullet}
        >
          <span>&#8226;</span>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.bulletText}>
        <div
          className={active ? classes.activeBullet : classes.inactiveBullet}
        >
          <span>{bulletText}</span>
        </div>
      </Grid>
    </div>
  )

  return !isBulletCounter ? numberedStep : bulletedStep
}
