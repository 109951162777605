import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import InformationInput from '../../custom/InformationInput'
import TextAreaInput from '../../common/TextAreaInput'
import SignReviewPresentation from '../../../modules/signage/components/ReviewRequest/SignReviewPresentation'
import { useSignageContext } from '../../../context/SignageContext'
import { useRequestContext } from '../../../context/RequestContext'

export default function NewSignageReview() {
  const { selectedPropertyCtx } = useRequestContext()
  const { dataToBePresented } = useSignageContext()
  const [pageData, setPageData] = useState([])
  const styles = () => ({
    container: {
      borderTop: '1px solid rgba(42, 41, 166, 0.35)',
      marginTop: '20px',
      padding: '20px 0 0 0',
    },
    upperAreaContainer: {
      padding: '0 0 40px 0',
      borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
      marginBottom: 20,
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  useEffect(() => {
    setPageData(dataToBePresented)
  }, [])

  return (
    <Grid className={classes.container}>
      <Grid className={classes.upperAreaContainer}>
        <InformationInput
          label="Installation address"
          information={selectedPropertyCtx}
        />
        <TextAreaInput
          placeholder="Add any additional notes..."
          name="additionalNotes2"
          rows="4"
        />
      </Grid>
      <Grid container direction="column" style={{ marginBottom: 15 }}>
        {pageData.map((sign, index) => {
          return (
            <SignReviewPresentation
              sign={sign.presentationData}
              index={index}
              key={sign.presentationData?.signId}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}
