import { signageApi } from '../services/api'

export const getSignOptions = async (
  signageRequestTypeId,
  signTypeId,
  city = 'San Francisco',
) => {
  try {
    let baseURL = `/signage/options?options[city]=${city}`
    if (signageRequestTypeId) {
      baseURL = baseURL.concat(
        `&options[signageRequestTypeId]=${signageRequestTypeId}`,
      )
    }
    if (signTypeId) {
      baseURL = baseURL.concat(`&options[signTypeId]=${signTypeId}`)
    }

    const { data } = await signageApi.get(baseURL)
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getSignRequestType = async (agentId) => {
  try {
    const { data } = await signageApi.get(
      `signage/signageRequestTypes?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getSignType = async (agentId) => {
  try {
    const { data } = await signageApi.get(
      `signage/signTypes?agentId=${agentId}`,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getCostEstimate = async (
  city = 'San Francisco',
  deliveryType,
  requestType,
  signType,
) => {
  try {
    let baseURL = `signage/estimate?city=${city}`
    if (deliveryType) {
      baseURL = baseURL.concat(`&deliveryType=${deliveryType}`)
    }
    if (requestType) {
      baseURL = baseURL.concat(`&requestType=${requestType}`)
    }
    if (signType) {
      baseURL = baseURL.concat(`&signType=${signType}`)
    }

    const { data } = await signageApi.get(baseURL)
    if (data.length === 0) {
      return { success: false, data: 'No cost estimation found' }
    } else if (data.length === 1) {
      const vendor = data[0].vendor
      const estimatedCost = `$${data[0].minCost} - $${data[0].maxCost}`
      const formattedVendorInfo = `${vendor.name || ''} - ${
        vendor.firstAddressLine || ''
      }, ${vendor.secondAddressLine || ''}, ${vendor.city || ''}, ${
        vendor.state || ''
      }`
      return {
        success: true,
        data: { estimatedCost, vendor: formattedVendorInfo },
      }
    } else {
      //find the right option
      return { success: true, data: data }
    }
  } catch (err) {
    return { success: false }
  }
}

export const submitSignageRequest = async (dataToBeSubmitted) => {
  try {
    const { data } = await signageApi.post(
      '/signage/requests',
      dataToBeSubmitted,
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const saveDraftRequest = async (dataToBeSubmitted, step) => {
  try {
    const { data } = await signageApi.post('/signage/create-draft', {
      data: dataToBeSubmitted,
      step,
    })
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getActiveDraftRequest = async () => {
  try {
    const { data } = await signageApi.get('/signage/get-active-draft')
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}
