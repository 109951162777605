/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RTREngine, TemplateLayer } from 'av8-rtrt-renderer'
import { useEffect, useState } from 'react'
import {
  RenderedTemplateImage,
  useRTRStore,
} from '../modules/marketing/store/rtr.store'
import { generateRTRImageParamsHash } from '../modules/marketing/utils/generateRTRImageParamsHash'
import { RTRTemplateData } from '../modules/marketing/utils/formatTemplateData'
import { swapPropertyImageForCropped } from '../modules/marketing/utils/swapPropertyImageForCropped'
// import { useFontsContext } from '../context/FontsContext'

export type BaseTemplateData = { propertyImage?: string; [k: string]: any }

export type RTRLayer = TemplateLayer & { id: number }

export enum RENDER_STATUS {
  IDLE = 'IDLE',
  RENDERING = 'RENDERING',
  FINISHED = 'FINISHED',
  RENDER_ERROR = 'RENDER_ERROR',
  ENGINE_ERROR = 'ENGINE_ERROR',
}

export interface useRTRenderParams<T extends BaseTemplateData> {
  id: number
  layers: RTRLayer[]
  data: T
  width?: number
  height?: number
  desiredMime?: string
  ignoreCache?: boolean
}

export type RTRender = {
  status: RENDER_STATUS
  renderedImage: RenderedTemplateImage | undefined
  error: Error | null
}

const getLatestImageFromCacheById = (
  store: Record<any, RenderedTemplateImage>,
): RenderedTemplateImage | undefined => {
  const cacheObject = store || {}
  const lastCachedImage =
    Object.values(cacheObject)[Object.keys(cacheObject).length - 1]
  return lastCachedImage
}

export function useRTRender<T = RTRTemplateData>(
  params: useRTRenderParams<T>,
): RTRender {
  // const { fonts } = useFontsContext()

  const {
    id,
    layers,
    data,
    desiredMime = 'image/png',
    ignoreCache = false,
  } = params
  let { width = undefined, height = undefined } = params

  if (!width && !height) {
    width = layers[0].width
    height = layers[0].height
  }

  const aspectRatio = layers[0].width / layers[0].height

  if (!width) throw Error('Invalid dimensions')

  if (!height) height = width / aspectRatio

  const paramsHash = generateRTRImageParamsHash(params)

  const setRTRImage = useRTRStore((store) => store.setRTRImage)
  const savedImage: RenderedTemplateImage | undefined = useRTRStore((store) => {
    if (ignoreCache) return undefined

    const lastCachedImage = getLatestImageFromCacheById(store.images?.[id])
    if (lastCachedImage) {
      return lastCachedImage
    }
    return store.images?.[id]?.[paramsHash]
  })

  const [renderedImage, setRenderedImage] = useState<
    RenderedTemplateImage | undefined
  >(savedImage)
  const [error, setError] = useState<Error | null>(null)
  const [status, setStatus] = useState<RENDER_STATUS>(
    !savedImage ? RENDER_STATUS.IDLE : RENDER_STATUS.FINISHED,
  )
  let engine: RTREngine

  const renderImage = async (): Promise<SVGElement> => {
    const svgImage = await engine.render(
      layers,
      swapPropertyImageForCropped(data),
      width!,
      height!,
      false,
    )

    return svgImage
  }

  try {
    engine = new RTREngine()
    engine.setup()
    // If we want to use embed fonts, uncomment this.
    // engine.addDefs(`
    // <defs>
    //   <style>
    //   ${fonts}
    //   </style>
    // </defs>`)
  } catch (err: any) {
    setStatus(RENDER_STATUS.ENGINE_ERROR)
    setError(err as any)
  }

  useEffect(() => {
    if (!engine && status === RENDER_STATUS.ENGINE_ERROR) {
      console.error('[ENGINE ERROR]', error) // TODO: replace for Sentry collection
      return
    }
    if (savedImage) {
      return
    }
    setStatus(RENDER_STATUS.RENDERING)
    renderImage()
      .then((svgResult) => {
        setError(null)
        setRenderedImage(svgResult)
        setStatus(RENDER_STATUS.FINISHED)
        setRTRImage(id, paramsHash, svgResult)
      })
      .catch((err) => {
        setStatus(RENDER_STATUS.RENDER_ERROR)
        setError(err)
        setRenderedImage(undefined)
        console.error(err.message, err) // TODO: replace for Sentry collection
      })
  }, [layers, data, width, height, desiredMime, savedImage])

  return { error, status, renderedImage }
}
