import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Container } from './ContentGridItem.styles.js'
import ContentOverlay from '../common/ContentOverlay'
import ContentGridDialog from './ContentGridDialog'
import ContentGridMedia from './ContentGridMedia'
import { useContentGridContext } from '../../context/ContentGridContext.js'

export default function ContentGridItem({ gridItem, isVisible, itemKey }) {
  const {
    selectedImages,
    setSelectedImages,
    isSelecting,
    setIsSharing,
  } = useContentGridContext()
  const styles = () => ({
    container: {},
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const handleItemClick = () => {
    setIsSharing(true)
    setSelectedImages([gridItem])
  }

  const handleSelectionChange = () => {
    let updatedSelectedImages
    const thisImageIsInSelectedImageArray = selectedImages.some(
      (image) => image.itemKey === itemKey,
    )
    if (!thisImageIsInSelectedImageArray) {
      updatedSelectedImages = [...selectedImages, { ...gridItem, itemKey }]
    } else {
      updatedSelectedImages = selectedImages.filter(
        (image) => image.itemKey !== itemKey,
      )
    }
    setSelectedImages(updatedSelectedImages)
  }

  return (
    <Container isVisible={isVisible}>
      {isSelecting
        ? gridItem.ContentGridMedia[0] && (
            <label
              htmlFor={itemKey}
              className="container"
              onChange={handleSelectionChange}
            >
              <input type="checkbox" id={itemKey} className={classes.input} />
              <ContentGridMedia media={gridItem} isSelecting={isSelecting} />
              <span className="checkmark"></span>
            </label>
          )
        : gridItem.ContentGridMedia[0] && (
            <ContentOverlay extraOnClick={handleItemClick}>
              <ContentGridMedia media={gridItem} isSelecting={isSelecting} />
              <ContentGridDialog post={gridItem} />
            </ContentOverlay>
          )}
    </Container>
  )
}
