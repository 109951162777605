import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PagePresentation from '../components/common/PageTitle'
import CustomGridContainer from '../components/common/CustomGridContainer'
import Layout from '../components/common/Layout.js'
import Checkbox from '../components/common/Checkbox'
import StepCounter from '../components/common/StepCounter'
import loadPageInfo from '../utils/pageInformationLoader'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useSignageContext } from '../context/SignageContext'

export default function InstallRemoveSignage() {
  const history = useHistoryPrefix()
  const { flow, subflow } = useParams()
  const { installationType, setInstallationType } = useSignageContext()
  const [showPage, setShowPage] = useState(false)
  const [pageData, setPageData] = useState({})

  useEffect(() => {
    const loadPageData = async () => {
      const response = await loadPageInfo({
        page: 'SignageInstallRemoveScreen',
      })
      if (response.success) {
        setShowPage(true)
        setPageData(response.data)
        setShowPage(true)
      }
    }
    loadPageData()
  }, [])

  const handleNavigation = (path) => {
    history.push(['', flow, subflow, installationType, path].join('/'))
  }

  const handleCheckboxChange = (e) => {
    setInstallationType(e.target.value)
  }

  return (
    <Layout>
      {showPage && pageData.radios && (
        <CustomGridContainer>
          <StepCounter
            numberOfSteps={3}
            activeStep={2}
            handleNext={() => handleNavigation('reviewsignagerequest')}
            isNextButtonDisabled={
              !pageData?.radios.some(
                (radio) => installationType === radio.value,
              )
            }
          />
          <PagePresentation
            CTATextUpper={pageData.CTATextBold}
            CTATextLower={pageData.CTATextItalic}
            component={'span'}
          />
          {pageData.radios &&
            pageData.radios.map((option) => (
              <Checkbox
                label={option.label}
                key={option.label}
                value={option.value}
                defaultChecked={installationType === option.value}
                name={'propertySelection'}
                type={'radio'}
                onChange={(e) => handleCheckboxChange(e)}
              />
            ))}
        </CustomGridContainer>
      )}
    </Layout>
  )
}
