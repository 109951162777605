import React, { useState, useEffect } from 'react'
import { Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Checkbox from '../../common/Checkbox'
import Text from '../../common/Text'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing(3),
    width: 150,
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 1500,
    maxHeight: '350px',
    overflowY: 'scroll',
  },
}))

export default function MultiSelectorPopper({
  list,
  listingItems,
  optionHandler,
  parentIndex,
  IsChecked,
  parentSelectedOptions,
}) {
  const classes = useStyles()
  // array to print options on the parent label
  const [selectedOptions, setSelectedOptions] = useState([])
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChangePopper = (e, option) => {
    optionHandler(option.value)
  }

  useEffect(() => {
    setSelectedOptions(
      list.items.filter((_item) => {
        if (parentSelectedOptions.some((_option) => _item.label === _option))
          return _item
      }),
    )
  }, [parentSelectedOptions, listingItems])

  const handleChangeCheckbox = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget)
    setIsPopperOpen((prev) => !prev)

    const isChecked = listingItems[parentIndex].items.some((item) =>
      IsChecked(item.value),
    )

    if (isChecked && !isPopperOpen) {
      selectedOptions.map((item) => {
        optionHandler(item.value)
      })
      setSelectedOptions([])
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <div>
      <Checkbox
        key={list.id}
        type={'checkbox'}
        checked={listingItems[parentIndex].items.some((item) =>
          IsChecked(item.value),
        )}
        onChange={(e) => handleChangeCheckbox(e, id)}
        label={
          <div className="popper">
            <div aria-describedby={id}>
              <Text type="regular" component="span">
                {list.first + ' '}
              </Text>
              {selectedOptions?.map((option, index) => {
                return (
                  <span key={option.id}>
                    {option.label}
                    {index !== selectedOptions.length - 1 && ', '}
                  </span>
                )
              })}
              <Text type="regular" component="span">
                {' ' + list.second}
              </Text>
            </div>
          </div>
        }
      />
      <Popper
        id={id}
        open={isPopperOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <div className={classes.paper}>
          <Text type="regular" style={{ marginBottom: 15 }}>
            {listingItems[parentIndex].items.length > 0
              ? listingItems[parentIndex].listLabel
              : listingItems[parentIndex].errorLabel}
          </Text>
          {list.items.map((option) => (
            <Checkbox
              key={option.id}
              type={'checkbox'}
              label={<h5>{option.label}</h5>}
              onChange={(e) => handleChangePopper(e, option)}
              value={option.value}
              checked={IsChecked(option.value)}
            />
          ))}
        </div>
      </Popper>
    </div>
  )
}
