import React, { useState, useRef, useEffect } from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import Text from '../../common/Text'
import Button from '../../common/Button'
import Checkbox from '../../common/Checkbox'
import { triggerDownload } from '../../../utils/utils'
import Tooltip from '../../common/Tooltip'
import Modal from '../../common/Modal'
import MLSLoader from '../../custom/MLSLoader'

const LOADING_TIMEOUT = 1500

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing(3),
    width: 150,
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 1500,
    maxHeight: '350px',
    overflowY: 'scroll',
  },
  container: {
    padding: '25px 20px 25px 25px',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F3F3F7',
    borderRadius: 5,
    alignItems: 'center',
    padding: '0 8px',
    height: 70,
    width: '100%',
  },
}))

export default function MultiSelectorFile({
  list,
  optionHandler,
  IsChecked,
  value,
  parentSelectedOptions,
}) {
  const classes = useStyles()
  const [selectedFile, setSelectedFile] = useState(
    parentSelectedOptions.find((item) => item.value == list.value) || {},
  )
  const [previousSelectedFile, setPreviousSelectedFile] = useState({})
  const [open, setOpen] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const [desktopDownloadStatus, setDesktopDownloadStatus] = useState(true)
  const [loadingModalStatus, setLoadingModalStatus] = useState({
    loading: false,
    message: '',
    show: false,
  })

  useEffect(() => {
    setLoadingModalStatus({
      loading: isDownloading,
      message: isDownloading ? (
        'Your CSV Template is downloading...'
      ) : (
        <>
          <Text>Download Completed</Text>
          <Text style={{ marginTop: 10 }} type="regular" color="#777">
            Your CSV template is available in "Recents" or your local folder.
          </Text>
        </>
      ),
      show: isDownloading || hasDownloaded,
    })
  }, [isDownloading, hasDownloaded])

  //get a file and convert him to a base64 string
  const getBase64 = (file) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const handleDownload = async (fileUrl, fileName) => {
    try {
      setIsDownloading(true)
      const csvResponse = await fetch(fileUrl)
      setTimeout(LOADING_TIMEOUT)

      if (csvResponse.ok) {
        const csvData = await csvResponse.text()
        triggerDownload(csvData, fileName, {
          type: 'text/csv',
        })

        setIsDownloading(false)
        setHasDownloaded(true)
        setDesktopDownloadStatus(true)
      } else {
        setIsDownloading(false)
        setHasDownloaded(false)
        setDesktopDownloadStatus(false)
      }
    } catch (err) {
      console.error(err)
      setIsDownloading(false)
      setHasDownloaded(false)
      setDesktopDownloadStatus(false)
    }
  }

  const handleClickOpen = () => {
    if (!IsChecked(list)) {
      setSelectedFile({})
      setPreviousSelectedFile({})
    } else {
      setPreviousSelectedFile(selectedFile)
    }
    setOpen(true)
  }

  const handleClose = (cancel) => {
    if (cancel) {
      setSelectedFile(previousSelectedFile)
    }
    if (
      !isEmpty(selectedFile) &&
      !cancel &&
      selectedFile.base64 != previousSelectedFile.base64
    ) {
      optionHandler(selectedFile)
    }
    setOpen(false)
  }

  //Ref to a not displayed input field
  const hiddenFileInput = useRef(null)

  const changeHandler = (event) => {
    const file = event.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)

      const promise = getBase64(file)
      let base64 = ''

      promise.then(function (result) {
        if (result) {
          base64 = result
        }

        const format = list.format.find(
          (_format) => file.name.indexOf(_format) > -1,
        )

        setSelectedFile({
          name: file.name,
          url,
          type: 'file_selector',
          value,
          format: format,
          base64,
          id: list.id,
        })
      })
    }
  }

  const isEmpty = (file) => {
    return !Object.keys(file).length
  }

  let format = ''

  list.format.map((_format, index) => {
    format += _format + (index !== list.format.length - 1 ? ', ' : '')
  })

  return (
    <>
      <Checkbox
        key={list.id}
        checked={IsChecked(list)}
        type={'checkbox'}
        label={
          <Text type="regular" component="span">
            {!IsChecked(list)
              ? list.listLabel
              : selectedFile.name.length < 35
              ? selectedFile.name
              : selectedFile.name.slice(0, 16) + '...'}
          </Text>
        }
        value={'file'}
        onChange={handleClickOpen}
      />
      <Dialog
        onClose={() => handleClose(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            pointerEvents: 'none',
          },
        }}
        PaperProps={{
          style: { borderRadius: '10px', margin: 16, maxWidth: 375 },
        }}
      >
        <Grid className={classes.container}>
          <Modal open={loadingModalStatus.show}>
            <MLSLoader
              loading={loadingModalStatus.loading}
              text={loadingModalStatus.message}
              setModalStatus={setLoadingModalStatus}
            />
          </Modal>
          <Grid container alignItems="center">
            <Grid container style={{ marginBottom: 30 }}>
              <Text type="strong">Upload&nbsp;</Text>
              <Text
                type="strong"
                style={{
                  fontFamily: 'BookmaniaRegularItalic',
                  display: 'inline',
                }}
              >
                a list.
              </Text>
              <Text
                type="regular"
                style={{ fontSize: '12px', marginTop: '20px' }}
              >
                Upload your custom list in CSV format.
              </Text>
            </Grid>
            <Grid style={{ marginBottom: 15 }}>
              <Text type="muted">{list.uploadLabel}</Text>
            </Grid>
            <Grid
              className={classes.itemContainer}
              style={{
                marginBottom: 20,
              }}
            >
              <Text type="muted">
                {isEmpty(selectedFile)
                  ? 'No file selected'
                  : selectedFile.name.length < 20
                  ? selectedFile.name
                  : selectedFile.name.slice(0, 16) + '...'}
              </Text>
              <label style={{ cursor: 'pointer' }}>
                <Grid>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    download="template"
                    accept={format}
                    ref={hiddenFileInput}
                    onChange={changeHandler}
                    style={{ display: 'none' }}
                  />
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    clickHandler={() => {
                      hiddenFileInput.current.click()
                    }}
                    primary
                    xsmall
                    title={isEmpty(selectedFile) ? 'Add' : 'Change'}
                  />
                </Grid>
              </label>
            </Grid>
            <Grid
              container
              style={{ marginBottom: 40 }}
              onClick={() => {
                handleDownload(
                  list.templateDownload,
                  'Contacts Import Template.csv',
                )
              }}
            >
              <Grid>
                <Text type="blue">
                  <DownloadIcon fontSize="small" />
                </Text>
              </Grid>
              <Grid style={{ margin: '3px 0px 0px 5px' }}>
                <Text type="blue">{list.templateLabel}</Text>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '0px 25px' }}>
              <Button
                medium
                title={'Apply List'}
                disabled={isEmpty(selectedFile)}
                clickHandler={() => handleClose(false)}
                primary
                dataAttrs={{
                  metaAction: 'click-apply-csv-mailing-list',
                }}
              />
              <Text
                type="medium"
                onClick={() => handleClose(true)}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: '20px auto',
                }}
              >
                Cancel
              </Text>
            </Grid>
          </Grid>
          {!desktopDownloadStatus ? (
            <Tooltip
              text="Error downloading the post"
              type="error"
              status="open"
            />
          ) : null}
        </Grid>
      </Dialog>
    </>
  )
}
