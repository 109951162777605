import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../common/Text'

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '35% 30% 35%',
    color: 'var(--blue)',
    opacity: 0.65,
    cursor: 'pointer',
  },
  gridWithLine: {
    position: 'relative',
    '&::after': {
      display: 'flex',
      position: 'absolute',
      left: 0,
      top: '45%',
      content: '""',
      height: 1,
      width: '100%',
      background: 'var(--blue)',
    },
  },
}
const useStyles = makeStyles(styles)

export default function DividerWithText({ text, icon }) {
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <Grid className={classes.gridWithLine}></Grid>
      <Grid container justify="center" alignItems="center">
        {!!icon && icon}
        <Text type="blue">{text}</Text>
      </Grid>
      <Grid className={classes.gridWithLine}></Grid>
    </Grid>
  )
}
