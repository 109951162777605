import { appApi } from '../services/api'

export const getAddressLookup = async (addressInput) => {
  try {
    const { data } = await appApi.get(
      `/google/address/autocomplete/${addressInput}`,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getAgentRecentAddress = async (agentId, limit = 5) => {
  try {
    const { data } = await appApi.get(
      `/listings/getAgentRecentAddress/${agentId}?agentId=${agentId}&limit=${limit}`,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    )
    return { success: true, data }
  } catch (err) {
    return { success: false }
  }
}

export const getAddressZip = async (placeId) => {
  try {
    const { data } = await appApi.get(`/google/place/details/${placeId}`)
    return { success: true, zip: data.zip }
  } catch (err) {
    return { success: false }
  }
}

export const getAddressByListingId = async (listingId) => {
  try {
    const response = await appApi.get(`/listings/${listingId}`)
    return { success: true, data: response.data }
  } catch (err) {
    const { statusCode } = err?.response?.data
    return { success: false, statusCode }
  }
}
