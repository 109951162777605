import { PrintModalStep } from '../../../../store/PrintModal.store'

export type PrintCategory = 'postcard' | 'double-sided-flyer' | 'bi-fold-brochure'

export enum PrintCategories {
  biFoldBrochure = 'bi-fold-brochure',
  postCard = 'postcard',
  doubleSidedFlyer = 'double-sided-flyer'
}

export const printCategoryData: Record<PrintCategory, { title: string; steps: PrintModalStep[] }> = {
  postcard: {
    title: 'Postcard',
    steps: [
      { name: 'front', type: 'edit' },
      { name: 'back', type: 'edit' },
      { name: 'review', type: 'review' },
    ],
  },
  'double-sided-flyer': {
    title: 'Flyer',
    steps: [
      { name: 'front', type: 'edit' },
      { name: 'back', type: 'edit' },
      { name: 'review', type: 'review' },
    ],
  },
  'bi-fold-brochure': {
    title: 'Brochure',
    steps: [
      { name: 'outside', type: 'edit' },
      { name: 'inside', type: 'edit' },
      { name: 'review', type: 'review' },
    ],
  },
}
