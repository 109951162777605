import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { generateDataAttrs } from '../../utils/utils'

const headerStyle = {
  AppBar: {
    height: 'auto',
    backgroundColor: 'var(--blue)',
  },
}

const useStyles = makeStyles(headerStyle)

export default function Header(props) {
  const classes = useStyles()
  const { className, children, ...rest } = props
  const headerClasses = classNames({
    [classes.AppBar]: true,
    [className]: className !== undefined,
  })

  return (
    <AppBar
      position="static"
      className={headerClasses}
      elevation={0}
      {...generateDataAttrs({
        metaName: 'header',
      })}
      {...rest}
    >
      {children}
    </AppBar>
  )
}
