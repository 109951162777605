import React from 'react'
import { Typography, IconButton, makeStyles } from '@material-ui/core'
import BackIcon from '../../assets/icons/Back_Arrow.svg'
import { useConfirmationDialog } from './ModalDialog'
import { useRequestContext } from '../../context/RequestContext'
import { handleLeaveWebview } from '../../services/appServices'

const styles = {
  button: {},
  container: {
    maxHeight: '20vh',
    minHeight: '55px',
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '25% 50% 25%',
    alignItems: 'center',
    justifyItems: 'center',
  },
  header: {
    height: '60px',
    backgroundColor: 'var(--blue)',
  },
  headerText: {
    fontSize: '15px',
    fontFamily: 'BookmaniaBold',
    letterSpacing: '0px',
    color: 'var(--white)',
  },
  headerTextWrapper: {
    display: 'flex',
  },
  iconButton: {
    display: 'flex',
  },
  iconText: {
    fontFamily: 'StyreneMedium',
    fontSize: '14px',
    cursor: 'pointer',
  },
  selectedPropertyWrapper: {
    width: '82%',
    marginLeft: '9%',
  },
  selectedPropertyTextBox: {
    height: '45px',
    backgroundColor: 'var(--blue)',
    padding: '14px 0 7px 0',
  },
  exitButton: {
    fontFamily: 'StyreneMedium',
    fontSize: '14px',
    cursor: 'pointer',
    border: 0,
    padding: 0,
    margin: 0,
  },
}

export default function HeaderNavLeftIcon({ pathname, confirmLeave = false }) {
  const useStyles = makeStyles(styles)
  const { isEmbedded } = useRequestContext()
  const { getConfirmation } = useConfirmationDialog()

  const classes = useStyles()

  const currentPage = pathname.split('/')[pathname.split('/').length - 1]

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const leaveFlowConfirmation = async () => {
    const leave = () => {
      if (isEmbedded) {
        handleLeaveWebview()
      } else {
        location.href = `${location.origin}/account`
      }
    }
    if (confirmLeave) {
      const confirmation = await getConfirmation({
        title: 'Exit?',
        message: 'Are you sure you want to exit? All unsaved data will be lost.',
      })
      if (confirmation) {
        leave()
      }
    } else {
      leave()
    }
  }

  const ExitLabel = ({ clickHandler }) => (
    <Typography className={classes.iconText} onClick={clickHandler}>
      Exit
    </Typography>
  )

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const BackIconNav = () => (
    <IconButton
      className={classes.button}
      aria-label="get-help"
      onClick={() => alert('not available yet')}
    >
      <img src={BackIcon} alt="Back button" srcSet="" />
    </IconButton>
  )

  const selectLeftIcon = (currentPage) => {
    switch (currentPage) {
      case 'requestreceived':
        return <></>
      case 'content':
        // this checks if is inside an iOS Webview
        if (!window?.webkit?.messageHandlers?.exit?.postMessage) {
          return <></>
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return <ExitLabel clickHandler={leaveFlowConfirmation} />
    }
  }

  return <>{selectLeftIcon(currentPage)}</>
}
